import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import {
  HMRC_ACCOUNT,
  mapPaymentToCSVFormatByInstitution,
  yappilyInstitutionToLogo,
} from '@v2/feature/payments/payments.util';
import { UserBankAccountAPI } from '@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import { UserBankAccountDto } from '@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import { getCSVRows, toCsv } from '@v2/util/csv.util';
import { CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PAYMENTS_TEAM_CREATE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StepperHeader } from '@/v2/components/stepper-header.component';
import { IconContentRadioCard } from '@/v2/components/theme-components/icon-content-radio-card.component';
import { StyledRadio } from '@/v2/components/theme-components/styled-radio.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { PaymentsEndpoints } from '@/v2/feature/payments/payments.api';
import { PaymentDto, PaymentInstitutionDto } from '@/v2/feature/payments/payments.dto';
import { ExportCSVPaymentFormat, YapilyInstitutions } from '@/v2/feature/payments/payments.interface';
import { PAYMENTS_CREATE_ROUTE, PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { JUNE_ACTIONS, useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SelectBankFormProps {
  shouldFilterByPaymentType?: boolean;
}

interface SelectBankListFormProps {
  onChange: (institution: PaymentInstitutionDto) => void;
  shouldFilterByPaymentType?: boolean;
}

interface InstitutionButtonProps {
  readonly institution: PaymentInstitutionDto;
  readonly showBorder: boolean;
  readonly setSelectedInstitution: React.Dispatch<React.SetStateAction<YapilyInstitutions | undefined>>;
  readonly selectedInstitution: YapilyInstitutions | undefined;
}

const InstitutionButton = ({
  institution,
  showBorder,
  setSelectedInstitution,
  selectedInstitution,
}: InstitutionButtonProps) => {
  const supportsBulkPayment =
    !!institution.config?.bulkPayment?.supportsImmediatePayments ||
    !!institution.config?.bulkPayment?.supportsScheduledPayments;

  return (
    <IconContentRadioCard
      cardOnClick={() => setSelectedInstitution(institution.id)}
      title={institution.name}
      content={supportsBulkPayment ? 'Supports bulk payment' : null}
      iconMedia={
        institution?.media[0]?.source ? (
          yappilyInstitutionToLogo(institution)
        ) : (
          <AccountBalanceOutlinedIcon sx={{ fontSize: '40px' }} />
        )
      }
      action={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'end' }}>
          <StyledRadio
            name={institution.name}
            id={institution.id}
            value={institution.id}
            onChange={(e) => {
              setSelectedInstitution(e.target.value as YapilyInstitutions);
            }}
            selectedValue={selectedInstitution}
          />
        </Box>
      }
      showDivider={showBorder}
      sx={{ minHeight: '40px', maxHeight: '40px' }}
    />
  );
};

const OtherBanksButton = ({
  setSelectedInstitution,
  selectedInstitution,
}: {
  readonly setSelectedInstitution: React.Dispatch<React.SetStateAction<YapilyInstitutions | undefined>>;
  readonly selectedInstitution: YapilyInstitutions | undefined;
}) => {
  return (
    <IconContentRadioCard
      cardOnClick={() => setSelectedInstitution(YapilyInstitutions.OtherBanks)}
      title="Other banks"
      content="Download your payments as CSV"
      action={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'end' }}>
          <StyledRadio
            name={YapilyInstitutions.OtherBanks}
            id={YapilyInstitutions.OtherBanks}
            value={YapilyInstitutions.OtherBanks}
            onChange={() => {
              setSelectedInstitution(YapilyInstitutions.OtherBanks);
            }}
            selectedValue={selectedInstitution}
          />
        </Box>
      }
      sx={{ minHeight: '40px', maxHeight: '40px' }}
    />
  );
};

function BankList({ onChange, shouldFilterByPaymentType }: SelectBankListFormProps): React.JSX.Element {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [state] = usePaymentContext();
  const routerLocation = useLocation();
  const [selectedInstitution, setSelectedInstitution] = useState<YapilyInstitutions | undefined>(undefined);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const payments = routerLocation.state as PaymentDto[] | [];
  const paymentType = payments?.length === 1 ? 'INITIATE_DOMESTIC_SINGLE_PAYMENT' : 'INITIATE_BULK_PAYMENT';
  const csvRef = useRef<any | undefined>(undefined);
  const { trackEvent } = useJune();

  const [csvFile, setCSVFile] = useState<{ readonly name: string; readonly data: unknown }>({
    name: 'default.csv',
    data: [],
  });
  const { data: allPaymentInstitutions } = useApiClient(PaymentsEndpoints.getPaymentInstitution());
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false);

  const institutionsFilteredByPaymentType = allPaymentInstitutions?.filter((institution) =>
    institution.features.includes(paymentType)
  );

  const institutionsToShow = shouldFilterByPaymentType
    ? institutionsFilteredByPaymentType ?? []
    : allPaymentInstitutions ?? [];

  const institutionObj = institutionsToShow?.find((i) => i.id === selectedInstitution) ?? undefined;

  const fetchCurrentUserBankAccounts = useCallback(async (): Promise<
    Record<
      number,
      Pick<UserBankAccountDto, 'userId' | 'accountName' | 'accountNumber' | 'sortCode' | 'currency' | 'country'>
    >
  > => {
    if (!state?.payments || state.payments.length === 0) return {};
    try {
      const userIds = Array.from(
        new Set(state.payments.map((payment) => payment.userId).filter((userId) => !!userId))
      ) as number[];
      return UserBankAccountAPI.findCurrentByUserIds(userIds);
    } catch (error) {
      showMessage('Could not load users bank details.', 'error');
    }
    return {} as Record<
      number,
      Pick<UserBankAccountDto, 'userId' | 'accountName' | 'accountNumber' | 'sortCode' | 'currency' | 'country'>
    >;
  }, [state?.payments, showMessage]);

  const formatPaymentsToCSV = useCallback(
    async (institutionId: string, payments: readonly PaymentDto[]): Promise<ExportCSVPaymentFormat[]> => {
      try {
        const payeesMappings = await fetchCurrentUserBankAccounts();

        return payments.map((payment) => {
          const payee = payment.userId ? getCachedUserById(payment.userId) : undefined;
          const companyName = !payment?.userId ? HMRC_ACCOUNT.accountName : '';

          const payeeBankAccount = payment.userId ? payeesMappings[payment.userId] ?? null : HMRC_ACCOUNT;
          if ((payment.userId && !payee) || !payeeBankAccount)
            showMessage(
              `Could not find some details for user with userId = ${payment.userId}. You should introduce those details manually`,
              'error'
            );
          return mapPaymentToCSVFormatByInstitution(institutionId, payment, payeeBankAccount, payee, companyName);
        });
      } catch (error) {
        showMessage(`Could not load users bank accounts. ${nestErrorMessage(error)}`, 'error');
        return [];
      }
    },
    [fetchCurrentUserBankAccounts, getCachedUserById, showMessage]
  );

  useEffect(() => {
    if (csvFile?.data && csvFile.name !== 'default.csv' && csvRef.current && csvRef.current.link) {
      // @ts-ignore
      csvRef.current.link.click();
    }
  }, [csvFile]);

  const exportPaymentsCSV = async (): Promise<void> => {
    if (
      (!institutionObj && selectedInstitution !== YapilyInstitutions.OtherBanks) ||
      !state.payments ||
      state.payments.length === 0
    ) {
      showMessage('No payments selected.', 'error');
      return;
    }
    setIsCsvLoading(true);
    try {
      const csvPayments = await formatPaymentsToCSV(
        institutionObj?.id ?? YapilyInstitutions.OtherBanks,
        state.payments
      );
      if (!csvPayments || csvPayments.length === 0) return;
      const data = toCsv(getCSVRows(csvPayments));
      setCSVFile({ name: `payments-${new Date().toLocaleDateString()}.csv`, data });
      trackEvent({
        userId: user.userId,
        event: JUNE_ACTIONS.CREATED_BULK_PAYMENT_VIA_EXPORT,
        properties: {
          institution: institutionObj?.name,
          csvPayments: csvPayments.length,
        },
      });
    } catch (error) {
      showMessage(`Something wrong happened. ${error.message}`, 'error');
    }
    setIsCsvLoading(false);
  };

  const validatePayment = () => {
    if (state.payments && state.payments?.length > 1) {
      const bankObj = institutionsToShow.find((ins) => ins.id === selectedInstitution);
      return bankObj
        ? !!bankObj.config?.bulkPayment?.supportsImmediatePayments ||
            !!bankObj.config?.bulkPayment?.supportsScheduledPayments
        : false;
    }
    return true;
  };

  return (
    <Box>
      <Grid container columnSpacing={4}>
        {institutionsToShow.map((institution, i) =>
          institution.id !== YapilyInstitutions.SiliconValleyBank ? (
            <Grid item sm={12} md={6} key={i}>
              <InstitutionButton
                institution={institution}
                showBorder={i < institutionsToShow.length - 1}
                setSelectedInstitution={setSelectedInstitution}
                selectedInstitution={selectedInstitution}
              />
            </Grid>
          ) : null
        )}
        <Grid item sm={12} md={6} key="other_banks">
          <OtherBanksButton setSelectedInstitution={setSelectedInstitution} selectedInstitution={selectedInstitution} />
        </Grid>
      </Grid>

      {(institutionObj || selectedInstitution === YapilyInstitutions.OtherBanks) && selectedInstitution !== undefined && (
        <Grid container columnSpacing={4}>
          <Grid item sm={12} md={6} sx={{ ...spacing.mt20 }} onClick={exportPaymentsCSV}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" loading={isCsvLoading}>
              Download CSV
            </ButtonComponent>
          </Grid>
          {institutionObj && validatePayment() && (
            <Grid
              item
              sm={12}
              md={6}
              sx={{ ...spacing.mt20 }}
              onClick={() => {
                onChange(institutionObj);
                trackEvent({
                  userId: user.userId,
                  event: JUNE_ACTIONS.CREATED_BULK_PAYMENT_VIA_OPEN_BANKING,
                  properties: {
                    institution: institutionObj?.name,
                    payments: state.payments?.length,
                  },
                });
              }}
            >
              <ButtonComponent fullWidth sizeVariant="medium" colorVariant="primary">
                Continue to pay
              </ButtonComponent>
            </Grid>
          )}

          <CSVLink
            ref={csvRef}
            filename={csvFile.name}
            data={Array.isArray(csvFile.data) || typeof csvFile.data === 'string' ? csvFile.data : []}
          />
        </Grid>
      )}
    </Box>
  );
}

function BankListSkeleton() {
  return (
    <Grid container columnSpacing={4}>
      {Array.from(Array(22).keys()).map((i) => (
        <Grid item sm={12} md={6} key={i}>
          <Skeleton
            key={i}
            width="100%"
            height={80}
            animation="wave"
            sx={{ backgroundColor: themeColors.Background }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export function SelectBankForm({ shouldFilterByPaymentType = false }: SelectBankFormProps) {
  const [, setState] = usePaymentContext();

  const [globalState] = useContext(GlobalContext);
  const scopesContext = { userId: globalState.user.userId };
  const { hasScopes } = useScopes();
  const isAdminUser = hasScopes(['payments:all'], scopesContext);
  const routerHistory = useHistory();

  return (
    <RootStyle>
      <ContentWrapper loading={false} noHeader={true} border={false} sx={{ paddingTop: spacing.p30, ...spacing.px40 }}>
        <StepperHeader
          content={<Typography sx={{ ...themeFonts.title2 }}>Which bank are you sending the wire from?</Typography>}
          contentSx={{ width: '90%', textAlign: 'center' }}
          showStepBack={false}
          showBack={true}
          backAction={() => {
            routerHistory.push(PAYMENTS_ROUTE);
          }}
          backSx={{ width: '10%', textAlign: 'right' }}
        />

        <Box display="flex" justifyContent="center" sx={{ mt: spacing.mt40 }}>
          <Box sx={{ maxWidth: '800px' }}>
            <Suspense fallback={<BankListSkeleton />}>
              <BankList
                onChange={(institution) => {
                  setState(({ payments, requests }) => {
                    return {
                      payments,
                      requests,
                      institution,
                    };
                  });
                  routerHistory.push(isAdminUser ? PAYMENTS_CREATE_ROUTE : PAYMENTS_TEAM_CREATE_ROUTE);
                }}
                shouldFilterByPaymentType={shouldFilterByPaymentType}
              />
            </Suspense>
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
}
