import { isFriday, isMonday, isSameDay, isSaturday, isSunday, isThursday, isTuesday, isWednesday } from 'date-fns';
import Polyglot from 'node-polyglot';

import { AttendanceSettingsDto } from '@/v2/feature/attendance/attendance.dto';
import { AfternoonOnly, CompanyEvent, FullDay, MorningOnly } from '@/v2/feature/calendar/calendar.interface';
import { TableEntryProps } from '@/v2/feature/calendar/features/calendar.page';
import { DARK_CONTRAST_COLOR, themeColors } from '@/v2/styles/colors.styles';

type WorkingDays = Pick<
  AttendanceSettingsDto,
  | 'workingMonday'
  | 'workingTuesday'
  | 'workingWednesday'
  | 'workingThursday'
  | 'workingFriday'
  | 'workingSaturday'
  | 'workingSunday'
>;

export const getStartFullOrHalfDayType = (polyglot: Polyglot, event?: CompanyEvent): string => {
  if (event?.endDate) {
    // Multiple days value
    if (event.afternoonOnly) return AfternoonOnly(polyglot).value;
    return FullDay(polyglot).value;
  }

  if (event?.morningOnly) return MorningOnly(polyglot).value;
  if (event?.afternoonOnly) return AfternoonOnly(polyglot).value;
  return FullDay(polyglot).value;
};

export const getEndFullOrHalfDayType = (polyglot: Polyglot, event?: CompanyEvent): string => {
  if (!event?.endDate) return FullDay(polyglot).value;

  // Multiple days value
  if (event.morningOnly) return MorningOnly(polyglot).value;
  return FullDay(polyglot).value;
};

export const getTextColor = (event: CompanyEvent) => {
  if (event?.color) {
    return DARK_CONTRAST_COLOR.includes(event?.color) ? themeColors.white : themeColors.DarkGrey;
  }
  return themeColors.DarkGrey;
};

export const getWeekWidth = (d: CompanyEvent, day: Date) => {
  if (d?.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return '50%';
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return '50%';
      else return '100%';
    } else return '100%';
  } else {
    return d.morningOnly || d.afternoonOnly ? '50%' : '100%';
  }
};

export const getWeekAlignment = (d: CompanyEvent, day: Date) => {
  if (d?.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return 'flex-end';
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return 'flex-start';
      else return 'center';
    } else return 'center';
  } else {
    if (d.morningOnly || d.afternoonOnly) return d.morningOnly ? 'flex-start' : 'flex-end';
    return 'center';
  }
};

export const getBoxBackgroundColor = (
  entry: TableEntryProps,
  attendanceSettings: AttendanceSettingsDto | null | undefined
): React.CSSProperties => {
  const date = new Date(entry.day);

  // Early return if schedule exists
  const { schedule } = entry.events;
  if (schedule) {
    return {
      backgroundColor: schedule.isScheduleToday ? themeColors.white : themeColors.Background,
      opacity: schedule.isScheduleToday ? 1 : 0.5,
    };
  }

  // If no schedule, check attendance settings
  if (attendanceSettings) {
    // Map day-of-week functions to corresponding keys in WorkingDays type
    const dayOfWeekCheckers: { [K in keyof WorkingDays]: (date: Date) => boolean } = {
      workingMonday: isMonday,
      workingTuesday: isTuesday,
      workingWednesday: isWednesday,
      workingThursday: isThursday,
      workingFriday: isFriday,
      workingSaturday: isSaturday,
      workingSunday: isSunday,
    };

    // Loop through each day checker
    for (const [key, checkDay] of Object.entries(dayOfWeekCheckers)) {
      // Type assertion to ensure TypeScript understands the relationship
      if (checkDay(date) && attendanceSettings[key as keyof WorkingDays]) {
        return { backgroundColor: themeColors.white, opacity: 1 };
      }
    }

    return { backgroundColor: themeColors.Background, opacity: 0.5 };
  }

  // Default return if no schedule or attendance settings
  return { backgroundColor: themeColors.white, opacity: 1 };
};
