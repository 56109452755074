import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';

export const OverlappingCounter = ({
  overlappingEventsCounter,
  activeView,
}: {
  overlappingEventsCounter: number;
  activeView: 'Month' | 'Week';
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: activeView === 'Week' ? 4 : '50%',
        right: activeView === 'Week' ? 4 : '50%',
        transform: activeView === 'Month' ? 'translate(50%, -50%)' : 'none',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: themeColors.DarkGrey,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <Typography variant="captionSmall" color="white">
        {overlappingEventsCounter}
      </Typography>
    </Box>
  );
};
