export function formatSortCodeUI(sortCode: string): string {
  if (typeof sortCode !== 'string') return '';

  const cleanSortCode = sortCode.replace('-', '');
  if (cleanSortCode.length !== 6 || isNaN(parseInt(sortCode))) return '';

  return cleanSortCode.slice(0, 2) + '-' + cleanSortCode.slice(2, 4) + '-' + cleanSortCode.slice(4, 6);
}

export function floatUIToAPI(floatStr: string): number {
  if (!floatStr) {
    return 0;
  } else {
    const f = parseFloat(floatStr);
    return isNaN(f) ? 0 : f;
  }
}

// Handles the case where we have a very small number due to floating-point rounding
export const isZero = (n: string | number | undefined | null) => !n || Math.abs(Number(n)) < 0.005;

export type FormatCurrencyOptions = {
  wholeNumber?: boolean;
  noCurrencySymbol?: boolean;
};

export function formatCurrency(
  value: number | string | undefined | null,
  opts?: FormatCurrencyOptions,
  outputCurrency?: string
): string | null {
  const amount = isZero(value) ? 0 : value;
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: outputCurrency ?? 'GBP',
    ...(opts?.wholeNumber && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    currencyDisplay: opts?.noCurrencySymbol ? 'code' : undefined,
  });
  let result = typeof amount === 'number' || typeof amount === 'string' ? formatter.format(Number(amount)) : null;
  if (result && opts?.noCurrencySymbol) {
    // remove the currency code
    result = result.replace(/[A-Z]+/g, '').trim();
  }
  return result;
}

export function formatTaxYear(taxYearStr: string): string | null {
  const match = taxYearStr && taxYearStr.match(/^Year([0-9]{4}$)/);
  if (match) {
    const year = parseInt(match[1]);
    return `Year ${year}/${year - 1999}`;
  } else {
    return null;
  }
}

const AMOUNT_FORMAT = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatAmount(value: number | undefined): string | null {
  const amount = isZero(value) ? 0 : value;
  return typeof amount === 'number' || typeof amount === 'string' ? AMOUNT_FORMAT.format(amount) : null;
}

export function fixedAmount(value: number | undefined, invertValue = false): string | null {
  const amount = isZero(value) ? 0 : value;
  // ignore invertValue if the amount is zero
  if (amount === 0) return AMOUNT_FORMAT.format(amount);
  if (typeof amount === 'number' || typeof amount === 'string') {
    const formattedAmount = AMOUNT_FORMAT.format(amount);
    if (invertValue && formattedAmount.includes('-')) return formattedAmount.replaceAll('-', '');
    if (invertValue && !formattedAmount.includes('-')) return `-${formattedAmount}`;
    return formattedAmount;
  } else return null;
}
