import { SyntheticEvent, useContext, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { AppIntegrationAPI } from '@v2/feature/app-integration/app-integration.api';
import { AppBasicAuthConnectDrawer } from '@v2/feature/app-integration/features/app-details/components/app-basic-auth-connect-drawer.component';
import { generatePath, useHistory } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_INTEGRATION_DETAILS_SETTINGS_ROUTE } from '@/lib/routes';
import { CurrentUser } from '@/models';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { AppCard } from '@/v2/feature/app-integration/apps.page';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface AppAboutProps {
  readonly currentUser: CurrentUser;
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
}

export const AppAboutPage = ({ currentUser, appStub, app }: AppAboutProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const { hasScopes, getScopesContext } = useScopes();
  const [basicAuthConnectDrawerOpen, setBasicAuthConnectDrawerOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const initiateOauthFlowForApp = (event: React.SyntheticEvent, appStub: AppIntegrationStub) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub);
  };

  const getActionForConnect = async (event: SyntheticEvent<Element, Event>) => {
    // OLD RESTRICTION
    // if (state.user.restrictions.APPS.disableAppCreation || state.user.restrictions.TECH.disableApps) {
    //   setUpgradeModalOpen(true);
    // } else {
    try {
      const canConnect = await AppIntegrationAPI.appConnectAllowed();
      if (canConnect) {
        if (app?.authorised && !app?.directory?.oauth)
          return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub }));
        if (!app?.authorised && !app?.directory?.oauth) return setBasicAuthConnectDrawerOpen(true);
        if (app?.directory?.oauth) return initiateOauthFlowForApp(event, appStub);
      }
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    }
    // }
  };

  return (
    <RootStyle>
      <TopHeader
        title={<AppNameHeader title={polyglot.t('AppAboutPage.title')} app={app} />}
        showAction={
          !app?.authorised && currentUser && hasScopes(AppScopes.CREATE_APP_INTEGRATION, { userId: currentUser.userId })
        }
        actions={
          appStub !== 'quickbooks' ? (
            <ScopesControl
              scopes={['apps.connect', 'apps:all']}
              context={getScopesContext({ userId: state.user.userId })}
            >
              <Button
                sx={primarySmallBtn}
                onClick={async (event) => {
                  getActionForConnect(event);
                }}
              >
                Connect
              </Button>
            </ScopesControl>
          ) : (
            <ScopesControl
              scopes={['apps.connect', 'apps:all']}
              context={getScopesContext({ userId: state.user.userId })}
            >
              <Button
                variant="text"
                disableRipple
                sx={{
                  width: '200px',
                  ml: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
                onClick={(event) => {
                  getActionForConnect(event);
                }}
                startIcon={<img src="/app-icons-v2/images/ctqb_transparent_btn.png" alt={appStub} width="200px"></img>}
              ></Button>
            </ScopesControl>
          )
        }
      />
      <ContentWrapper loading={false}>
        <Box sx={{ maxWidth: '600px' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>About {app?.name}</Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: '10px' }}>
            {app?.shortDescription}
          </Typography>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: '30px' }}>Features</Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              {app?.features.map((eachFeature) => {
                return (
                  <li style={{ lineHeight: '30px' }}>
                    <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                    &nbsp;&nbsp;
                    {eachFeature}
                  </li>
                );
              })}
            </ul>
          </Typography>
          {app?.externalLinks && app?.externalLinks?.length > 0 && (
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: '30px' }}>
              About this integration
            </Typography>
          )}
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: '10px' }}>
            {/* {app?.longDescription} */}
            {app?.externalLinks &&
              app?.externalLinks?.length > 0 &&
              app?.externalLinks?.map((eachLink, idx) => {
                return (
                  <AppCard
                    key={`${eachLink.link}-${idx}`}
                    title={eachLink.type === 'support' ? 'Support article' : 'Marketplace listing'}
                    subtitle={
                      eachLink.type === 'support'
                        ? 'Helpful post about this integration'
                        : `Check this integration on ${app.name} marketplace`
                    }
                    icon={<Document {...iconSize} />}
                    action={() => window.open(eachLink.link, '_blank')}
                    stub={''}
                    showBorder={true}
                    buttonText="Read"
                  />
                );
              })}
          </Typography>
        </Box>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.TECH_PRO}
          messageSuffix="proGeneric"
        />
        {!app?.directory?.oauth && (
          <AppBasicAuthConnectDrawer
            isOpen={basicAuthConnectDrawerOpen}
            setIsOpen={setBasicAuthConnectDrawerOpen}
            appDetails={app}
            currentUser={currentUser}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
