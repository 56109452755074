import { TaxYearStart } from '@shared/modules/payroll/payroll.types';
import { SalaryBasisEnum } from '@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';

export type ExternalPensionRule = ' ReliefAtSource' | 'SalarySacrifice' | 'NetPayArrangement';

export type ExternalWorkerGroupContributionLevelType = 'UserDefined' | 'StatutoryMinimum' | 'Nhs2015' | 'Tp2020';

export type ExternalPayRunEntryState =
  | 'Open'
  | 'SubmittedForProcessing'
  | 'Processing'
  | 'AwaitingApproval'
  | 'Approved'
  | 'Finalised';

export enum ExternalPayRunEntryStateEnum {
  Open = 'Open',
  SubmittedForProcessing = 'SubmittedForProcessing',
  Processing = 'Processing',
  AwaitingApproval = 'AwaitingApproval',
  Approved = 'Approved',
  Finalised = 'Finalised',
}

export type ExternalPayRunPaymentMethod = 'Cash' | 'Cheque' | 'Credit' | 'DirectDebit';

export type ExternalPayRunPaymentType = 'Employee' | 'Hmrc' | 'PensionProvider' | 'Aeo' | 'Deduction';

// we only support monthly or weekly
export type PayPeriod = 'Monthly' | 'Weekly';

export type ExternalPayPeriod = 'Custom' | 'Monthly' | 'FourWeekly' | 'Fortnightly' | 'Weekly' | 'Daily';

export type ExternalFPSLateReason =
  | ExternalFPSLateReasons.NoneGiven
  | ExternalFPSLateReasons.NotionalExpat
  | ExternalFPSLateReasons.NotionalErs
  | ExternalFPSLateReasons.NotionalOther
  | ExternalFPSLateReasons.Class1
  | ExternalFPSLateReasons.MicroEmployer
  | ExternalFPSLateReasons.NoRequirement
  | ExternalFPSLateReasons.ReasonableExcuse
  | ExternalFPSLateReasons.Correction;

export type ExternalGovTalkSubmissionStatus =
  | ExternalGovTalkSubmissionStatuses.notSubmitted
  | ExternalGovTalkSubmissionStatuses.submitted
  | ExternalGovTalkSubmissionStatuses.errorResponse
  | ExternalGovTalkSubmissionStatuses.accepted;

export enum ExternalGovTalkSubmissionStatuses {
  notSubmitted = 'NotSubmitted',
  submitted = 'Submitted',
  errorResponse = 'ErrorResponse',
  accepted = 'Accepted',
}

export enum ExternalFPSLateReasons {
  NoneGiven = 'NoneGiven',
  NotionalExpat = 'NotionalExpat',
  NotionalErs = 'NotionalErs',
  NotionalOther = 'NotionalOther',
  Class1 = 'Class1',
  MicroEmployer = 'MicroEmployer',
  NoRequirement = 'NoRequirement',
  ReasonableExcuse = 'ReasonableExcuse',
  Correction = 'Correction',
}

export interface ExternalItem<T = {}> {
  id: string;
  name: string;
  url: string;
  metadata: T;
}

export interface ExternalPayRunEntryMetadata {
  payrollCode: string;
  gross: number;
  taxAmount: number;
  employeeNi: number;
  employerNi: number;
  employerPensionContribution: number;
  employeePensionContribution: number;
  netPay: number;
  adjustments: number;
  takeHomePay: number;
  totalCost: number;
  employeeId: string;
  paymentDate: string;
  isLeaver: boolean;
  isNewStarter: boolean;
  pensionSchemeId: string;
  pensionSchemeName: string;
  aeNotEnroledWarning: boolean;
  paymentMethod: string;
  hasFps: boolean;
  nilPaid: boolean;
}

export interface CreateExternalPaySchedulePayload {
  firstPaymentDate: string;
  firstPeriodEndDate: string;
  taxYearStart: TaxYearStart;
}

export interface UpdateExternalPaySchedulePayload {
  payPeriod: PayPeriod;
  firstPaymentDate: string;
  firstPeriodEndDate: string;
  taxYearStart: TaxYearStart;
}

export interface ExternalPayOptions {
  period: PayPeriod;
  ordinal: number;
  payAmount: number;
  basis: SalaryBasisEnum.Monthly | SalaryBasisEnum.Daily | SalaryBasisEnum.Hourly;
  nationalMinimumWage: boolean;
  payAmountMultiplier: number;
  baseHourlyRate: number;
  autoAdjustForLeave: boolean;
  method: 'Cash' | 'Cheque' | 'Credit' | 'DirectDebit';
  payCode: string;
  withholdTaxRefundIfPayIsZero: boolean;
  mileageVehicleType: 'Car' | 'Motorcycle' | 'Cycle';
  mapsMiles: number;
  tags: string[];
  taxAndNi: ExternalTaxAndNi;
  fpsFields: ExternalFpsFields;
  regularPayLines: ExternalPayLine[];
}

export interface ExternalTaxAndNi {
  niTable: string;
  secondaryClass1NotPayable: boolean;
  postgradLoan: boolean;
  studentLoan: 'None' | 'PlanOne' | 'PlanTwo' | 'PlanFour';
  taxCode: string;
  week1Month1: boolean;
}

export const MAX_PAYLINE_CODE_LENGTH = 20;
export const MAX_PAYLINE_DESCRIPTION_LENGTH = 100;

export const MAX_PAYROLL_ID_LENGTH = 35;

export interface ExternalPayLine {
  attachmentOrderId?: string;
  code: string;
  childId: string;
  description?: string;
  effectiveFrom?: string;
  effectiveTo?: string;
  multiplier?: number;
  pensionId?: string;
  isAutoGeneratedBasicPayLine?: boolean;
  rate?: number;
  tags?: string[];
  value: number;
}

export interface ExternalFpsFields {
  offPayrollWorker: boolean;
  irregularPaymentPattern: boolean;
  nonIndividual: boolean;
  hoursNormallyWorked: 'LessThan16' | 'MoreThan16' | 'MoreThan24' | 'MoreThan30' | 'NotRegular';
}

export interface ExternalPensionSummary {
  pensionId: string;
  name: string;
  pensionSchemeId: string;
  startDate: string;
  workerGroupId: string;
  pensionRule: ExternalPensionRule;
  papdisPensionProviderId: string;
  papdisEmployerId: string;
  employeePensionContributionMultiplier: number;
  additionalVoluntaryContribution: number;
  avcIsPercentage: boolean;
  autoEnrolled: boolean;
  workerGroup: ExternalWorkerGroup;
  forcedTier: string;
  tiers: ExternalTieredPensionRate[];
  assumedPensionablePay: number;
  pensionablePayCodes: string[];
}

export interface ExternalWorkerGroup {
  id: string;
  name: string;
  contributionLevelType: ExternalWorkerGroupContributionLevelType;
  employeeContribution: number;
  employeeContributionIsPercentage: boolean;
  employerContribution: number;
  employerContributionIsPercentage: boolean;
  employerContributionTopUpPercentage: number;
  customThreshold: boolean;
  lowerLimit: number;
  upperLimit: number;
  papdisGroup: string;
  papdisSubGroup: string;
  localAuthorityNumber: string;
  schoolEmployerType: string;
  workerGroupId: string;
}

export interface ExternalTieredPensionRate {
  name: string;
  description: string;
  rangeStart: number;
  rate: number;
}

export interface ExternalPayrunTotals {
  additions: number;
  adjustments?: number;
  appLevyDeduction?: number;
  attachmentOrderDeductions?: number;
  basicPay: number;
  cisDeduction?: number;
  cisUmbrellaFee?: number;
  cisUmbrellaFeePostTax?: number;
  cisVat?: number;
  dednsFromNetPay?: number;
  deductions?: number;
  empeePenContribnsNotPaid?: number;
  empeePenContribnsPaid?: number;
  employeeNi?: number;
  employeePensionContribution?: number;
  employeePensionContributionAvc?: number;
  employerNi?: number;
  employerNiOffPayroll?: number;
  employerPensionContribution?: number;
  flexiDd_Death_NonTax?: number;
  flexiDd_Death?: number;
  flexiDd_Pension_NonTax?: number;
  flexiDd_Pension?: number;
  gross: number;
  grossForNi: number;
  grossForTax: number;
  itemsSubjectToClass1NIC?: number;
  mapsMiles?: number;
  netPay: number;
  nonTaxOrNICPmt?: number;
  nonTierablePay?: number;
  paymentAfterLeaving?: number;
  pbik?: number;
  pensionableEarnings: number;
  pensionablePay?: number;
  postgradLoanRecovered?: number;
  realTimeClass1ANi?: number;
  sap?: number;
  shpp?: number;
  smp?: number;
  spbp?: number;
  spp?: number;
  ssp?: number;
  studentLoanRecovered?: number;
  takeHomePay: number;
  tax?: number;
  taxOnPaymentAfterLeaving?: number;
  tcp_Op?: number;
  tcp_Pp?: number;
  tcp_Tcls?: number;
  totalCost: number;
  umbrellaFee?: number;
}

export interface ExternalValueOverride {
  type: string;
  value: number;
  originalValue: number;
  note?: string;
  attachmentOrderId?: string;
  pensionId?: string;
}

export interface ExternalNationalInsuranceCalculation {
  earningsUptoIncludingLEL: number;
  earningsAboveLELUptoIncludingPT: number;
  earningsAbovePTUptoIncludingST: number;
  earningsAbovePTUptoIncludingUEL: number;
  earningsAboveSTUptoIncludingUEL: number;
  earningsAboveSTUptoIncludingFUST: number;
  earningsAboveFUSTUptoIncludingUEL: number;
  earningsAboveUEL: number;
  employeeNiGross: number;
  employeeNiRebate: number;
  employerNiGross: number;
  employerNiRebate: number;
  employeeNi: number;
  employerNi: number;
  netNi: number;
}

export interface ExternalUmbrellaPayment {
  payrollCode: string;
  chargePerTimesheet: number;
  invoiceValue: number;
  mapsMiles: number;
  otherExpenses: number;
  numberOfTimesheets: number;
  hoursWorked: number;
  grossDeduction: number;
  grossAddition: number;
}

export interface ExternalPersonalDetails {
  address?: ExternalAddress;
  maritalStatus: 'Single' | 'Married' | 'Divorced' | 'Widowed' | 'CivilPartnership' | 'Unknown';
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  emailPayslip?: boolean;
  passwordProtectPayslip?: boolean;
  pdfPassword?: string;
  emailStatement?: boolean;
  photoUrl?: string;
  telephone?: string;
  mobile?: string;
  dateOfBirth: string;
  readonly statePensionAge: number;
  gender: 'Male' | 'Female';
  niNumber?: string;
  passportNumber?: string;
  partnerDetails?: ExternalPartnerDetails;
}

export interface ExternalAddress {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
  line5?: string;
  postCode: string;
  country: 'England' | 'NorthernIreland' | 'Scotland' | 'Wales' | 'OutsideUk' | 'Uk';
}

export interface ExternalPartnerDetails {
  firstName?: string;
  initials?: string;
  lastName?: string;
  niNumber?: string;
}

export interface ExternalEmploymentDetails {
  cisSubContractor?: boolean;
  payrollCode: string;
  jobTitle?: string;
  onHold?: boolean;
  onFurlough?: boolean;
  furloughStart?: string;
  furloughEnd?: string;
  furloughCalculationBasis?: 'ActualPaidAmount' | 'DailyReferenceAmount' | 'MonthlyReferenceAmount';
  furloughCalculationBasisAmount?: number;
  partialFurlough?: boolean;
  furloughHoursNormallyWorked?: number;
  furloughHoursOnFurlough?: number;
  isApprentice?: boolean;
  workingPattern?: string;
  forcePreviousPayrollCode?: string;
  starterDetails?: ExternalStarterDetails;
  directorshipDetails?: ExternalDirectorshipDetails;
  leaverDetails?: ExternalLeaverDetails;
  cis?: ExternalCisDetails;
  department?: ExternalDepartment;
  posts?: ExternalItem[];
}

export interface ExternalStarterDetails {
  startDate: string;
  starterDeclaration: 'A' | 'B' | 'C';
  overseasEmployerDetails?: ExternalOverseasEmployerDetails;
  pensionerPayroll?: ExternalPensionerPayroll;
}

export interface ExternalOverseasEmployerDetails {
  overseasEmployer?: boolean;
  overseasSecondmentStatus?: 'MoreThan183Days' | 'LessThan183Days' | 'BothInAndOutOfUK';
  eeaCitizen?: boolean;
  epm6Scheme?: boolean;
}

export interface ExternalPensionerPayroll {
  inReceiptOfPension?: boolean;
  bereaved?: boolean;
  amount?: number;
}

export interface ExternalDirectorshipDetails {
  isDirector: boolean;
  startDate: string;
  leaveDate?: string;
  niAlternativeMethod: boolean;
}

export interface ExternalLeaverDetails {
  hasLeft?: boolean;
  leaveDate?: string;
  isDeceased?: boolean;
  paymentAfterLeaving?: boolean;
  p45Sent?: boolean;
}

export interface ExternalCisDetails {
  type: 'SoleTrader' | 'Partnership' | 'Company' | 'Trust';
  utr: string;
  tradingName: string;
  companyUtr: string;
  companyNumber: string;
  vatRegistered: boolean;
  vatNumber: string;
  vatRate: number;
  reverseChargeVAT: boolean;
  verification: ExternalCisVerificationDetails;
}

export interface ExternalCisVerificationDetails {
  manuallyEntered: boolean;
  matchInsteadOfVerify: boolean;
  number: string;
  date: string;
  taxStatus: 'Gross' | 'NetOfStandardDeduction' | 'NetOfHigherDeduction';
  readonly verificationRequest?: string;
  verificationResponse: ExternalCisSubContractor;
}

export interface ExternalCisSubContractor {
  employeeUniqueId: string;
  emailStatementTo: string;
  numberOfPayments: number;
  item: ExternalItem[];
  displayName: string;
  action: string;
  type: string;
  name: ExternalRtiEmployeeName;
  tradingName: string;
  worksRef: string;
  unmatchedRate: string;
  utr: string;
  crn: string;
  nino: string;
  partnership: ExternalCisPartnership;
  address: ExternalRtiEmployeeAddress;
  telephone: string;
  totalPaymentsUnrounded: string;
  costOfMaterialsUnrounded: string;
  umbrellaFee: string;
  validationMsg: string;
  verificationNumber: string;
  totalPayments: string;
  costOfMaterials: string;
  totalDeducted: string;
  matched: string;
  taxTreatment: string;
}

export interface ExternalRtiEmployeeName {
  ttl: string;
  fore: string[];
  initials: string;
  sur: string;
}

export interface ExternalCisPartnership {
  name: string;
  utr: string;
}

export interface ExternalRtiEmployeeAddress {
  line: string[];
  postCode: string;
  postcode: string;
  ukPostcode: string;
  country: string;
}

export interface ExternalDepartment {
  code: string;
  title: string;
  color?: string;
  employeeCount?: number;
}

export interface ExternalBankDetails {
  bankName: string;
  bankBranch: string;
  bankReference: string;
  accountName: string;
  accountNumber: string;
  sortCode: string;
  note: string;
}

export interface ExternalHmrcDetails {
  officeNumber?: string;
  payeReference?: string;
  accountsOfficeReference?: string;
  smallEmployersRelief?: boolean;
  employmentAllowanceMaxClaim?: number;
  quarterlyPaymentSchedule: boolean;
  includeEmploymentAllowanceOnMonthlyJournal: boolean;
  carryForwardUnpaidLiabilities: boolean;
}

export interface ExternalEmpReferences {
  officeNo: string;
  payeRef: string;
  aoRef: string;
  econ: string;
  cotaxRef: string;
  sautr: string;
}

export interface ExternalGovTalkSubmission {
  id: string;
  rawResponse: string;
  messageClass: string;
  pollInterval: number;
  lastPoll: string;
  correlationId: string;
  url: string;
  status: ExternalGovTalkSubmissionStatus;
  message: string;
  iRmark: string;
  errorsJson: string;
  errors: ExternalGovTalkError[];
  submittedAt: string;
}

export interface ExternalGovTalkError {
  raisedBy: string;
  number: string;
  type: string;
  text: string;
  location: string;
}

export interface ExternalullPaymentSubmission {
  employee: ExternalFpsEmployee[];
  relatedTaxYear: string;
}

export interface ExternalFpsEmployee {
  employeeUniqueId: string;
  item: ExternalItem;
  employeeDetails: ExternalFpsEmployeeDetails;
  employment: ExternalFpsEmployment;
  validationMsg: string;
}

export interface ExternalFpsEmployeeDetails {
  nino: string;
  name: ExternalRtiEmployeeName;
  address: ExternalRtiEmployeeAddress;
  birthDate: string;
  gender: string;
  passportNumber: string;
  partnerDetails: ExternalFpsEmployeeDetailsPartnerDetails;
}

export interface ExternalFpsEmployeeDetailsPartnerDetails {
  nino: string;
  name: ExternalRtiEmployeeName;
}

export interface ExternalFpsEmployment {
  employeeCurrentNiLetter: string;
  offPayrollWorker: string;
  occPenInd: string;
  directorsNIC: string;
  taxWkOfApptOfDirector: string;
  starter: ExternalFpsEmployeeStarter;
  payId: string;
  payIdChgd: ExternalFpsEmployerPayIdChanged;
  paymentToANonIndividual: string;
  irrEmp: string;
  leavingDate: string;
  figuresToDate: ExternalFpsEmployeeFigsToDate;
  payment: ExternalFpsEmployeePayment;
  nIlettersAndValues: ExternalFpsEmployeeNIlettersAndValues[];
}

export interface ExternalFpsEmployeeStarter {
  startDate: string;
  startDec: string;
  studentLoan: string;
  postgradLoan: string;
  occPension: ExternalFpsEmployeeStarterOccPension;
}

export interface ExternalFpsEmployerPayIdChanged {
  payrollIdChangedIndicator: string;
  oldPayrollId: string;
}

export interface ExternalFpsEmployeeFigsToDate {
  taxablePay: string;
  totalTax: string;
  studentLoansTD: string;
  postgradLoansTD: string;
  benefitsTaxedViaPayrollYTD: string;
  empeePenContribnsPaidYTD: string;
  empeePenContribnsNotPaidYTD: string;
  smpytd: string;
  sppytd: string;
  sapytd: string;
  shppytd: string;
  spbpytd: string;
  sspytd: string;
  gross: string;
  netPay: string;
  additions: string;
  deductions: string;
  takeHomePay: string;
  adjustments: string;
  mapsMiles: string;
  pensionableEarnings: string;
  pensionablePay: string;
  employerPensionContribution: string;
  employeePensionContribution: string;
  employeePensionContributionAvc: string;
  paymentAfterLeaving: string;
  taxOnPaymentAfterLeaving: string;
  taxablePayPreviousEmployment: string;
  totalTaxPreviousEmployment: string;
}

export interface ExternalFpsEmployeePayment {
  bacsHashCode: string;
  payFreq: string;
  pmtDate: string;
  lateReason: string;
  weekNo: string;
  monthNo: string;
  periodsCovered: number;
  aggregatedEarnings: string;
  pmtAfterLeaving: string;
  hoursWorked: string;
  taxCode: ExternalFpsEmployeeTaxCode;
  taxablePay: string;
  nonTaxOrNICPmt: string;
  dednsFromNetPay: string;
  payAfterStatDedns: string;
  benefitsTaxedViaPayroll: string;
  class1ANICsYTD: string;
  benefits: ExternalFpsBenefit;
  empeePenContribnsPaid: string;
  itemsSubjectToClass1NIC: string;
  empeePenContribnsNotPaid: string;
  studentLoanRecovered: ExternalStudentLoanRecovered;
  postgradLoanRecovered: string;
  taxDeductedOrRefunded: string;
  onStrike: string;
  unpaidAbsence: string;
  smpytd: string;
  sppytd: string;
  sapytd: string;
  shPPYTD: string;
  spbpytd: string;
  trivialCommutationPayment: ExternalFpsEmployeeTrivialCommutationPayment[];
  flexibleDrawdown: ExternalFpsEmployeeFlexibleDrawdown;
}

export interface ExternalFpsEmployeeNIlettersAndValues {
  nIletter: string;
  grossEarningsForNICsInPd: string;
  grossEarningsForNICsYTD: string;
  atLELYTD: string;
  leLtoPTYTD: string;
  pTtoUELYTD: string;
  totalEmpNICInPd: string;
  totalEmpNICYTD: string;
  empeeContribnsInPd: string;
  empeeContribnsYTD: string;
}

export interface ExternalFpsEmployeeFlexibleDrawdown {
  flexiblyAccessingPensionRights: string;
  pensionDeathBenefit: string;
  taxablePayment: string;
  nontaxablePayment: string;
}

export interface ExternalFpsEmployeeTrivialCommutationPayment {
  type: string;
  value: string;
}

export interface ExternalStudentLoanRecovered {
  planType: string;
  value: string;
}

export interface ExternalCar {
  make: string;
  firstRegd: string;
  cO2: string;
  zeroEmissionsMileage: string;
  fuel: string;
  id: string;
  amendment: string;
  price: string;
  availFrom: string;
  cashEquiv: string;
  availTo: string;
  freeFuel: ExternalCarFuel;
}

export interface ExternalCarFuel {
  provided: string;
  cashEquiv: string;
  withdrawn: string;
}

export interface ExternalFpsBenefit {
  car: ExternalCar[];
}

export interface ExternalFpsEmployeeTaxCode {
  basisNonCumulative: string;
  taxRegime: string;
  taxCode: string;
}

export interface ExternalFpsEmployeeStarterOccPension {
  bereaved: string;
  amount: string;
}

export interface ExternalFullPaymentSubmission {
  employee: ExternalFpsEmployee[];
  relatedTaxYear: string;
}

export interface RecurringPayline {
  id: number;
  userId: number;
  companyId: number;
  code: string;
  amount: number;
  description: string;
  startDate: string;
  endDate: string;
}
