import { CompanyPayrollOverview } from '@shared/modules/payroll/company-payroll';
import { NewCompanyPayCode, StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';
import axios from 'axios';

import type { CompanyPayrollAccountingConfig, NominalCodeDto } from '@/v2/feature/payroll/payroll.interface';
import type { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import type {
  AddToPayrollResult,
  CompanyPayroll,
  CompanyPayrollsDto,
  EditableUserPayrollDto,
  Payroll,
  PayrunEntry,
  RemoveFromPayrollResult,
  UpdateGeneralSettings,
  UserCompanyPayrollConfig,
  UserPayrollDto,
} from '@shared/modules/payroll/payroll.types';

import { AccountingFieldPaycodeMapping, NominalCode } from '@/v2/feature/payroll/payroll.interface';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

export class PayrollAPI {
  static async addUserPayrollRecord(userId: number, payrollRecord: EditableUserPayrollDto) {
    await axios.post(`/apiv2/users/${userId}/payroll`, payrollRecord);
  }

  static async updateUserPayrollRecord(userId: number, updatedPayrollRecord: EditableUserPayrollDto) {
    await axios.patch(`/apiv2/users/${userId}/payroll`, updatedPayrollRecord);
  }

  static async getActiveUserPayroll(userId: number): Promise<UserPayrollDto | null> {
    return (await axios.get(`/apiv2/users/${userId}/payroll`)).data || null;
  }

  static async getUserPayrollSchema(userId: number): Promise<PayrollSchema | null> {
    return (await axios.get(`/apiv2/users/${userId}/payroll/schema`)).data || null;
  }

  static async getPayrollSchemaForCountry(countryCode: string): Promise<PayrollSchema | null> {
    return (await axios.get(`/apiv2/company/payroll/schemas/${countryCode}`)).data || null;
  }

  static async getUserPayrollRecords(userId: number): Promise<EntriesAndEffectiveRecord<UserPayrollDto>> {
    return (await axios.get(`/apiv2/users/${userId}/payrolls`)).data;
  }

  /**
   * Fetch the list of payrun entries for this user directly from Staffology
   */
  static getUserPayruns = async (userId: number): Promise<readonly PayrunEntry[]> =>
    (await axios.get(`/apiv2/users/${userId}/payroll/payruns`)).data;

  static async updateAccounting(
    payrollId: number,
    accounting: AccountingFieldPaycodeMapping[]
  ): Promise<{ fields: AccountingFieldPaycodeMapping[] }> {
    return (await axios.put(`/apiv2/company/payroll/accounting?payrollId=${payrollId}`, accounting)).data;
  }

  static async getAccounting(payrollId: number): Promise<CompanyPayrollAccountingConfig> {
    return (await axios.get(`/apiv2/company/payroll/accounting?payrollId=${payrollId}`)).data;
  }

  static initiateAccountingAppConnection(appStub: string, payrollId: number) {
    return `/apiv2/company/payroll/accounting/app/${appStub}/initiate?payrollId=${payrollId}`;
  }

  static async assignAccountingAppToPayroll(payrollId: number, config: { stub: 'xero'; tenantId: string }) {
    await axios.put(`/apiv2/company/payroll/accounting/app/link?payrollId=${payrollId}`, config);
  }

  static async disconnectAccountingApp(payrollId: number): Promise<void> {
    await axios.delete(`/apiv2/company/payroll/accounting/app?payrollId=${payrollId}`);
  }

  static async getPayrollNominalCodes(payrollId: number): Promise<NominalCodeDto[]> {
    return (await axios.get(`/apiv2/company/payroll/nominal-code?payrollId=${payrollId}`)).data;
  }

  static async refreshNominalCodes(payrollId: number): Promise<NominalCode[]> {
    return (await axios.post(`/apiv2/company/payroll/nominal-code/refresh?payrollId=${payrollId}`)).data;
  }

  static async getPayroll(): Promise<Payroll | undefined> {
    return (await axios.get('/apiv2/company/payroll')).data || undefined;
  }

  static async getCompanyPayrolls(): Promise<CompanyPayrollsDto> {
    return (await axios.get('/apiv2/company/payroll/all')).data;
  }

  static async getCompanyPayrollsAsSuperAdmin(companyId: number): Promise<CompanyPayrollsDto> {
    return (await axios.get(`/apiv2/company/payroll/superadmin/companies/${companyId}/all`)).data;
  }

  static async setPayrollTestMode(payrollId: number, testMode: boolean): Promise<void> {
    await axios.patch(`/apiv2/company/payroll/test-mode?payrollId=${payrollId}`, { testMode });
  }

  static async setPayrollPayslipPreview(payrollId: number, payslipPreview: boolean): Promise<void> {
    await axios.patch(`/apiv2/company/payroll/payslip-preview?payrollId=${payrollId}`, { payslipPreview });
  }

  static async getEmploymentAllowance(payrollId: number): Promise<EmploymentAllowanceStatus> {
    return (await axios.get(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`)).data;
  }

  static async requestEmploymentAllowance(payrollId: number, allowance: number): Promise<void> {
    return axios.post(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`, { allowance });
  }

  static async adjustEmploymentAllowance(payrollId: number, allowance: number): Promise<void> {
    return axios.patch(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`, { allowance });
  }

  static async createCompanyPaycode(payrollId: number, code: NewCompanyPayCode): Promise<StaffologyPayCode> {
    return (await axios.post(`/apiv2/company/payroll/paycodes/${payrollId}`, { code })).data;
  }

  static async updateCompanyPaycodes(payrollId: number, codes: StaffologyPayCode[]): Promise<void> {
    await axios.patch(`/apiv2/company/payroll/paycodes/${payrollId}`, { codes });
  }

  static async deleteCompanyPaycode(payrollId: number, code: string): Promise<void> {
    await axios.delete(`/apiv2/company/payroll/paycodes/${payrollId}?code=${code}`);
  }

  // static async addUserToPayroll(userId: number): Promise<void> {
  //   await axios.post(`/apiv2/company/payroll/member`, { userId });
  // }

  // static async removeUserFromPayroll(userId: number): Promise<void> {
  //   await axios.delete(`/apiv2/company/payroll/member/${userId}`);
  // }

  static async addUsersToPayroll(payrollId: number, userIds: number[]): Promise<AddToPayrollResult> {
    return (await axios.post(`/apiv2/company/payroll/${payrollId}/member/add`, { userIds })).data;
  }

  static async removeUsersFromPayroll(payrollId: number, userIds: number[]): Promise<RemoveFromPayrollResult> {
    return (await axios.post(`/apiv2/company/payroll/${payrollId}/member/remove`, { userIds })).data;
  }

  static async setGeneralSettings(payrollId: number, values: UpdateGeneralSettings): Promise<void> {
    return (await axios.patch(`/apiv2/company/payroll/settings/general?payrollId=${payrollId}`, values)).data;
  }
}

export class PayrollEndpoints {
  static getActiveUserPayroll(userId: number): Endpoint<UserPayrollDto | null> {
    return {
      url: `/apiv2/users/${userId}/payroll`,
    };
  }

  static getUserPayrolls(userId: number): Endpoint<EntriesAndEffectiveRecord<UserPayrollDto>> {
    return {
      url: `/apiv2/users/${userId}/payrolls`,
    };
  }

  static requestEmploymentAllowanceProgress(payrollId: number): { url: string } {
    return {
      url: `/apiv2/company/payroll/employment-allowance/progress?payrollId=${payrollId}`,
    };
  }

  static getCompanyPaycodes(payrollId: number, excludeSystemAndControl: boolean): Endpoint<StaffologyPayCode[]> {
    return {
      url: `/apiv2/company/payroll/paycodes/${payrollId}?excludeSystemAndControl=${excludeSystemAndControl}`,
    };
  }

  static countPayrunsByPayrollId(payrollId: number): Endpoint<number> {
    return {
      url: `/apiv2/payroll/payruns/payrolls/${payrollId}/count`,
    };
  }

  // Returns config for payroll the current user is enrolled in
  static getCurrentUserPayrollConfig(): Endpoint<UserCompanyPayrollConfig> {
    return {
      url: '/apiv2/company/payroll/current-user-payroll/config',
    };
  }

  static getAccounting(payrollId: number): Endpoint<CompanyPayrollAccountingConfig> {
    return {
      url: `/apiv2/company/payroll/accounting?payrollId=${payrollId}`,
    };
  }

  static getNominalCodes(payrollId: number): Endpoint<NominalCodeDto[]> {
    return {
      url: `/apiv2/company/payroll/nominal-code?payrollId=${payrollId}`,
    };
  }

  static getPayrollSchemaForCountry(countryCode: string): Endpoint<PayrollSchema | null> {
    return {
      url: `/apiv2/company/payroll/schemas/${countryCode}`,
    };
  }

  static getCompanyPayroll(payrollId: number): Endpoint<CompanyPayroll> {
    return {
      url: `/apiv2/company/payroll?payrollId=${payrollId}`,
    };
  }

  static getCompanyPayrolls(): Endpoint<CompanyPayrollsDto> {
    return {
      url: '/apiv2/company/payroll/all',
    };
  }

  static getCompanyCurrentPayrollId(): Endpoint<number> {
    return {
      url: '/apiv2/company/payroll/current',
    };
  }

  static getCompanyPayrollOverview(): Endpoint<CompanyPayrollOverview> {
    return {
      url: '/apiv2/company/payroll/overview',
    };
  }
}
