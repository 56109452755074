import { Fragment, useCallback, useContext } from 'react';

import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE,
  REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE,
  REVIEWS_COMPANY_ONGOING_ROUTE,
} from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { CurrentUser } from '@/models';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { RCUpsertGeneralPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-general/rc-upsert-general.page';
import { RCUpsertLaunchPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.page';
import { RCUpsertParticipantsPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/rc-upsert-participants.page';
import { RCUpsertTimelinePage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page';
import { ReviewCycleCreationNotificationsPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-notifications/review-cycle-creation-notifications.page';
import { ReviewCycleCreationQuestionPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/review-cycle-creation-question.page';
import { ReviewCycleCreationVisibilitySettingsPage } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-visibility-settings/review-cycle-creation-visibility-settings.page';
import { ReviewCycleDetailOverviewPage } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-overview/review-cycle-detail-overview.page';
import { ReviewCycleDetailResultsCompanyRouter } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-router/company/review-cycle-detail-results-company.router';
import { ReviewCycleDetailResultsPage } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results.page';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

const getPageConfig = (
  cycleId: string,
  reviewCycle: ReviewCycle | null | undefined,
  polyglot: Polyglot,
  user: CurrentUser,
  isAdmin: boolean,
  isManager: boolean
): readonly NavConfigItem[] => {
  return [
    ...(reviewCycle?.state !== CycleState.Draft
      ? [
          {
            title: 'General',
            stub: '',
            isHidden: false,
            hasChildren: true,
            subItems: [
              {
                title: 'Overview',
                stub: 'overview',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              ...(reviewCycle?.state === CycleState.Ongoing ||
              reviewCycle?.state === CycleState.Completed ||
              reviewCycle?.state === CycleState.Paused
                ? [
                    {
                      title: 'Results',
                      stub: 'results',
                      path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE, { cycleId }),
                      isHidden: false,
                      hasChildren: false,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin || (isManager && user.userId === reviewCycle?.owner)
      ? [
          {
            title: 'Settings',
            stub: 'settings',
            isHidden: false,
            hasChildren: true,
            subItems: [
              {
                title: polyglot.t('CycleSettingsRouter.general'),
                stub: 'general',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Questions',
                stub: 'question',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Participants',
                stub: 'participants',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Visibility',
                stub: 'visibility',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Timeline',
                stub: 'timeline',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Notifications',
                stub: 'notifications',
                path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              ...(reviewCycle?.state === CycleState.Draft || reviewCycle?.state === CycleState.Scheduled
                ? [
                    {
                      title: 'Start review cycle',
                      stub: 'launch',
                      path: generatePath(REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE, { cycleId }),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ] as NavConfigItem[];
};

export const ReviewCycleDetailCompanyRouter = () => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);

  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;
  const { data: reviewCycle, mutate: refreshReviewCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, ReachType.Company),
    {
      suspense: false,
    }
  );

  const refresh = useCallback(async () => {
    try {
      await refreshReviewCycle?.();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), 'error');
    }
  }, [refreshReviewCycle, polyglot, showMessage]);

  if (!reviewCycle) return <></>;

  return (
    <Fragment>
      <DomainSideMenuContent
        title="Reviews"
        pageConfig={getPageConfig(cycleId, reviewCycle, polyglot, user, isAdmin, isManager)}
        backPath={generatePath(REVIEWS_COMPANY_ONGOING_ROUTE, {
          cycleId,
        })}
        showBack
        type="Domain"
      />
      <Switch>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE}>
          <ReviewCycleDetailOverviewPage
            reviewCycle={reviewCycle}
            refresh={refresh}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE}>
          <ReviewCycleDetailResultsCompanyRouter
            reviewCycle={reviewCycle}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE}>
          <ReviewCycleDetailResultsPage
            reviewCycle={reviewCycle}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>

        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE}>
          <RCUpsertGeneralPage
            reviewCycle={reviewCycle}
            refresh={refresh}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE}>
          <ReviewCycleCreationQuestionPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE}>
          <RCUpsertParticipantsPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE}>
          <ReviewCycleCreationVisibilitySettingsPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE}>
          <RCUpsertTimelinePage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE}>
          <ReviewCycleCreationNotificationsPage reviewCycle={reviewCycle} refresh={refresh} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:all']} path={REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE}>
          <RCUpsertLaunchPage cycleId={cycleId} reviewCycle={reviewCycle} reach={ReachType.Company} />
        </RouteScopesControl>

        <Redirect
          to={
            reviewCycle?.state !== CycleState.Draft
              ? REVIEWS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE
              : REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE
          }
          exact
        />
      </Switch>
    </Fragment>
  );
};
