import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { getRegularTimeFromScheduleData } from '@v2/feature/absence/absence.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { TableEntryProps } from '@/v2/feature/calendar/features/calendar.page';
import { OverlappingCounter } from '@/v2/feature/calendar/features/components/overlapping-counter.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly schedule: {
    id: number;
    name: string;
    startHour: string | undefined;
    endHour: string | undefined;
    isScheduleToday: boolean;
  };
  readonly activeView: 'Month' | 'Week';
  readonly overlappingEventsCounter: number;
  readonly setSelectedEventOnDate: React.Dispatch<React.SetStateAction<TableEntryProps | undefined>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly entry: TableEntryProps;
}

export const CalendarScheduleEvent = ({
  schedule,
  activeView,
  overlappingEventsCounter,
  setSelectedEventOnDate,
  setIsOpen,
  entry,
}: Props) => {
  const { polyglot } = usePolyglot();

  const regularTime = useMemo(() => getRegularTimeFromScheduleData(schedule.startHour, schedule.endHour, polyglot), [
    polyglot,
    schedule,
  ]);

  if (!schedule)
    return (
      <Box>
        <Typography variant="caption">No schedule found</Typography>
      </Box>
    );

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', height: '48px' }}
        onClick={() => {
          setSelectedEventOnDate(entry);
          setIsOpen(true);
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxSizing: 'border-box',
            opacity: 1,
            '&:hover::before': {
              cursor: 'pointer',
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 1,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, px: spacing.p5 }}>
            {schedule && activeView === 'Week' && schedule.isScheduleToday && (
              <div style={{ width: '100%', overflow: 'hidden' }}>
                {(schedule.startHour || schedule.endHour) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                    <Typography variant="captionSmall" color="DarkGrey">
                      {regularTime}
                    </Typography>
                  </Box>
                )}

                {schedule && schedule.isScheduleToday && (
                  <Typography
                    variant="captionSmall"
                    color="Grey"
                    sx={{
                      textTransform: 'capitalize',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: activeView === 'Week' ? '140px' : '40px',
                    }}
                  >
                    {schedule.name}
                  </Typography>
                )}
              </div>
            )}
          </Box>
        </Box>

        {overlappingEventsCounter > 0 && (
          <OverlappingCounter overlappingEventsCounter={overlappingEventsCounter} activeView={activeView} />
        )}
      </Box>
    </>
  );
};
