import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { AttendanceScheduleSelect } from '@v2/components/attendance-schedule-select.component';
import { HolidayCalendarEndpoints } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { CustomCountryEnum } from '@v2/infrastructure/country/country.interface';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { ContractsAPI } from '@/api-client/contracts.api';
import {
  CreateUserContract,
  UpdateUserContract,
  UserContract,
  UserContractKinds,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ContractEndDatePickerComponent } from '@/v2/components/contract-end-date-picker.component';
import { EntitySelect } from '@/v2/components/entity-select.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { getPublicHolidayCalendarOptions } from '@/v2/feature/absence/absence.util';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import {
  getEmploymentTypeOptions,
  getUnitTypeOptions,
} from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type NewEmployeeContractSectionProps = {
  contract?: UserContract | null;
  onNext: () => void;
  sx?: SxProps<Theme>;
  startDate: string;
  userId: number;
};

export const NewEmployeeContractSection = ({
  contract,
  onNext,
  startDate,
  sx,
  userId,
}: NewEmployeeContractSectionProps) => {
  const { data: holidayCalendars } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });

  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = contract ? 'edit' : 'add';

  const NewEmployeeContractSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string()
          .trim()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.employmentTypeRequired')),
        contract: Yup.string()
          .trim()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.contractTypeRequired')),
        entityId: Yup.number().nullable().optional(),
        attendanceScheduleId: Yup.number().nullable().optional(),
        publicHolidays: Yup.string().required(
          polyglot.t('NewEmployeeContractSection.errorMessages.publicCalendarRequired')
        ),
        probationPeriodUnit: Yup.string()
          .trim()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.probationValueRequired')),
        probationPeriodLength: Yup.number()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.probationValueRequired'))
          .integer(),
        noticePeriodUnit: Yup.string()
          .trim()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.noticeValueRequired')),
        noticePeriodLength: Yup.number()
          .required(polyglot.t('NewEmployeeContractSection.errorMessages.noticeValueRequired'))
          .integer(),
        effectiveDate: Yup.string().test(dateFieldTest).required('Required'),
        contractEndDate: Yup.date()
          .nullable()
          .when(['effectiveDate'], (effectiveDate, schema) => {
            return schema
              .nullable()
              .min(effectiveDate, 'Invalid end date. Please chose a date equal or greater than the effective date.')
              .notRequired();
          }),
      }),
    [polyglot]
  );

  const customHolidayCalendarsOptions = useMemo(() => {
    if (!holidayCalendars) return [];
    return holidayCalendars.map((hc) => ({ label: hc.name, value: hc.id }));
  }, [holidayCalendars]);

  const defaultUserContractValues = useMemo<CreateUserContract>(
    () => ({
      effectiveDate: new LocalDate(startDate).toDateString(),
      contract: 'Full-time',
      type: 'Employee',
      ftePercent: 100,
      publicHolidays: 'uk',
      changeReason: undefined,
      noticePeriodLength: 1,
      noticePeriodUnit: 'Months',
      probationPeriodLength: 1,
      probationPeriodUnit: 'Months',
      entityId: null,
      attendanceScheduleId: null,
      contractEndDate: null,
      customUpdates: [],
    }),
    [startDate]
  );

  const formik = useFormik<CreateUserContract | UserContract>({
    initialValues: contract ?? defaultUserContractValues,
    validateOnMount: true,
    validationSchema: NewEmployeeContractSchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        if ('id' in values) {
          const update: UpdateUserContract = {
            id: Number(values.id),
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates,
          };
          await ContractsAPI.updateById(userId, update);
        } else {
          const create: CreateUserContract = {
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates,
          };
          await ContractsAPI.createUserContract(userId, create);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t('NewEmployeeContractSection.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('NewEmployeeContractSection.contract')}
          </Typography>
          <SelectComponent
            name="type"
            label={polyglot.t('NewEmployeeContractSection.type')}
            options={getEmploymentTypeOptions(polyglot)}
            value={formik.values.type}
            onChange={formik.handleChange}
            disabled={saving}
            helperText={hasSubmitted && formik.errors.type}
            error={hasSubmitted && !!formik.errors.type}
          />
          <SelectComponent
            name="contract"
            label={polyglot.t('NewEmployeeContractSection.contractLabel')}
            options={UserContractKinds(polyglot)}
            value={formik.values.contract}
            onChange={formik.handleChange}
            disabled={saving}
            helperText={hasSubmitted && formik.errors.contract}
            error={hasSubmitted && !!formik.errors.contract}
          />
          <EntitySelect
            name="entityId"
            label={polyglot.t('NewEmployeeContractSection.entityId')}
            value={formik.values.entityId}
            disabled={saving}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.errors.entityId}
            helperText={hasSubmitted && formik.errors.entityId}
            autoSelectSingleEntity={(entityId) => formik.setFieldValue('entityId', entityId)}
          />
          <AttendanceScheduleSelect
            name="attendanceScheduleId"
            label={polyglot.t('NewEmployeeContractSection.attendanceScheduleId')}
            value={formik.values.attendanceScheduleId}
            disabled={saving}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.errors.attendanceScheduleId}
            helperText={hasSubmitted && formik.errors.attendanceScheduleId}
            autoSelectSingleEntity={(attendanceScheduleId) =>
              formik.setFieldValue('attendanceScheduleId', attendanceScheduleId)
            }
          />
          <SelectComponent
            name="publicHolidays"
            label={polyglot.t('NewEmployeeContractSection.publicHolidays')}
            options={getPublicHolidayCalendarOptions({ includeCustom: true })}
            value={formik.values.publicHolidays}
            disabled={saving}
            onChange={formik.handleChange}
            helperText={hasSubmitted && formik.errors.publicHolidays}
            error={hasSubmitted && !!formik.errors.publicHolidays}
          />
          {formik.values.publicHolidays === CustomCountryEnum.code && (
            <SelectComponent
              name="holidayCalendarId"
              label={polyglot.t('HolidayCalendarModule.customHolidayCalendar')}
              options={customHolidayCalendarsOptions}
              value={formik.values.holidayCalendarId}
              disabled={saving}
              onChange={formik.handleChange}
              helperText={hasSubmitted && formik.errors.holidayCalendarId}
              error={hasSubmitted && !!formik.errors.holidayCalendarId}
            />
          )}
          <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
            <TextfieldComponent
              name="probationPeriodLength"
              label={polyglot.t('NewEmployeeContractSection.probationPeriodLength')}
              type="tel"
              value={formik.values.probationPeriodLength?.toString() ?? ''}
              disabled={saving}
              onChange={(e) => {
                const newValue = Number(e.target.value.match(/^\d{1,2}/)?.[0] ?? 'nan');
                formik.setFieldValue('probationPeriodLength', Number.isInteger(newValue) ? newValue : undefined);
              }}
              clearText={() => formik.setFieldValue('probationPeriodLength', 0)}
              helperText={hasSubmitted && formik.errors.probationPeriodLength}
              error={hasSubmitted && !!formik.errors.probationPeriodLength}
            />
            <SelectComponent
              name="probationPeriodUnit"
              label={polyglot.t('NewEmployeeContractSection.probationPeriodUnit')}
              options={getUnitTypeOptions(polyglot)}
              value={formik.values.probationPeriodUnit}
              disabled={saving}
              onChange={formik.handleChange}
              helperText={hasSubmitted && formik.errors.probationPeriodUnit}
              error={hasSubmitted && !!formik.errors.probationPeriodUnit}
            />
          </Stack>
          <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
            <TextfieldComponent
              name="noticePeriodLength"
              label={polyglot.t('NewEmployeeContractSection.noticePeriodUnit')}
              type="tel"
              value={formik.values.noticePeriodLength?.toString() ?? ''}
              disabled={saving}
              onChange={(e) => {
                const newValue = Number(e.target.value.match(/^\d{1,2}/)?.[0] ?? 'nan');
                formik.setFieldValue('noticePeriodLength', Number.isInteger(newValue) ? newValue : undefined);
              }}
              clearText={() => formik.setFieldValue('noticePeriodLength', 0)}
              helperText={hasSubmitted && formik.errors.noticePeriodLength}
              error={hasSubmitted && !!formik.errors.noticePeriodLength}
            />
            <SelectComponent
              name="noticePeriodUnit"
              label={polyglot.t('NewEmployeeContractSection.noticePeriodMeasure')}
              options={getUnitTypeOptions(polyglot)}
              value={formik.values.noticePeriodUnit}
              disabled={saving}
              onChange={formik.handleChange}
              helperText={hasSubmitted && formik.errors.noticePeriodUnit}
              error={hasSubmitted && !!formik.errors.noticePeriodUnit}
            />
          </Stack>
          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            rowModalMode={rowModalMode}
            formName={CustomProfileFormType.Contract}
            fieldSx={{}}
          />
          <ContractEndDatePickerComponent
            name="contractEndDate"
            label="End date"
            value={formik.values.contractEndDate}
            onChange={(value) => formik.setFieldValue('contractEndDate', value)}
            effectiveDate={formik.values.effectiveDate}
            error={!!formik.errors.contractEndDate}
            helperText={formik.errors.contractEndDate}
          />
          {formik.isValid && (
            <LoaderButton
              name={polyglot.t('General.continue')}
              loading={saving}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
