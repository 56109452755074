import React from 'react';

import Box from '@mui/material/Box';
import { format } from 'date-fns';

import { ReactComponent as NewWindow } from '@/images/new-theme-icon/NewWindow.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getRegularTimeFromScheduleData } from '@/v2/feature/absence/absence.util';
import { TableEntryProps } from '@/v2/feature/calendar/features/calendar.page';
import { EventKind } from '@/v2/feature/calendar/features/components/calendar-special-events.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const AllEventModal = ({
  isOpen,
  setIsOpen,
  selectedEventOnDate,
  userId,
  setScheduledIsViewOpen,
  setSpecialIsViewOpen,
  setAbsenceIsViewOpen,
  setPublicIsViewOpen,
  setSpecialKind,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEventOnDate: TableEntryProps | undefined;
  userId: number;
  setScheduledIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSpecialIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAbsenceIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSpecialKind: React.Dispatch<React.SetStateAction<EventKind | undefined>>;
}) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
        setScheduledIsViewOpen(false);
        setSpecialIsViewOpen(false);
        setSpecialKind(undefined);
        setAbsenceIsViewOpen(false);
        setPublicIsViewOpen(false);
      }}
    >
      <AllEventModalContent
        selectedEventOnDate={selectedEventOnDate}
        userId={userId}
        setScheduledIsViewOpen={setScheduledIsViewOpen}
        setSpecialIsViewOpen={setSpecialIsViewOpen}
        setAbsenceIsViewOpen={setAbsenceIsViewOpen}
        setPublicIsViewOpen={setPublicIsViewOpen}
        setSpecialKind={setSpecialKind}
      />
    </DrawerModal>
  );
};

const AllEventModalContent = ({
  selectedEventOnDate,
  userId,
  setScheduledIsViewOpen,
  setSpecialIsViewOpen,
  setAbsenceIsViewOpen,
  setPublicIsViewOpen,
  setSpecialKind,
}: {
  selectedEventOnDate: TableEntryProps | undefined;
  userId: number;
  setScheduledIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSpecialIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAbsenceIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPublicIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSpecialKind: React.Dispatch<React.SetStateAction<EventKind | undefined>>;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{`${format(
        new LocalDate(selectedEventOnDate?.day).getDate(),
        'EEEE, do MMM'
      )}`}</Typography>

      <ViewBox
        title={polyglot.t('CalendarPage.employee')}
        value={<UserCell userId={userId} nameVariant="caption" nameColor="DarkGrey" />}
      />
      {selectedEventOnDate?.events.absence && selectedEventOnDate?.events.absence.length > 0 && (
        <EventDetail
          title={polyglot.t('CalendarPage.absencePolicy')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
              <Box
                sx={{
                  width: '14px',
                  height: '14px',
                  borderRadius: radius.br16,
                  backgroundColor: selectedEventOnDate?.events.absence[0]?.policy?.color || themeColors.Grey,
                }}
              />
              <Typography variant="caption">
                {selectedEventOnDate?.events.absence[0].policyName || 'Holiday'}
              </Typography>
            </Box>
          }
          onClick={() => setAbsenceIsViewOpen(true)}
        />
      )}

      {selectedEventOnDate && selectedEventOnDate.events.isDob && (
        <EventDetail
          title={polyglot.t('CalendarPage.event')}
          value={polyglot.t('CalendarPage.birthday')}
          onClick={() => {
            setSpecialIsViewOpen(true);
            setSpecialKind('birthday');
          }}
        />
      )}
      {selectedEventOnDate && selectedEventOnDate.events.isFirstDay && (
        <EventDetail
          title={polyglot.t('CalendarPage.event')}
          value={polyglot.t('CalendarPage.firstDay')}
          onClick={() => {
            setSpecialIsViewOpen(true);
            setSpecialKind('first-day');
          }}
        />
      )}
      {selectedEventOnDate && selectedEventOnDate.events.isWorkAnniversary && (
        <EventDetail
          title={polyglot.t('CalendarPage.event')}
          value={polyglot.t('CalendarPage.workAnniversary')}
          onClick={() => {
            setSpecialIsViewOpen(true);
            setSpecialKind('work-anniversary');
          }}
        />
      )}
      {selectedEventOnDate && selectedEventOnDate.events.isLastDay && (
        <EventDetail
          title={polyglot.t('CalendarPage.event')}
          value={polyglot.t('CalendarPage.lastDay')}
          onClick={() => {
            setSpecialIsViewOpen(true);
            setSpecialKind('last-day');
          }}
        />
      )}

      {selectedEventOnDate &&
        selectedEventOnDate.events.schedule &&
        selectedEventOnDate.events.schedule.isScheduleToday && (
          <EventDetail
            title={polyglot.t('CalendarPage.workSchedule')}
            value={
              getRegularTimeFromScheduleData(
                selectedEventOnDate.events.schedule.startHour,
                selectedEventOnDate.events.schedule.endHour,
                polyglot
              ) || '–'
            }
            onClick={() => setScheduledIsViewOpen(true)}
          />
        )}

      {selectedEventOnDate &&
        selectedEventOnDate.events.publicHolidays &&
        selectedEventOnDate.events.publicHolidays.length > 0 && (
          <EventDetail
            title={polyglot.t('CalendarPage.publicHoliday')}
            value={selectedEventOnDate.events.publicHolidays[0].name}
            onClick={() => setPublicIsViewOpen(true)}
          />
        )}
    </Box>
  );
};

const EventDetail = ({
  title,
  value,
  onClick,
}: {
  title: string;
  value: JSX.Element | string;
  onClick: () => void;
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <ViewBox title={title} value={value} />
      <IconButton colorVariant="secondary" sizeVariant="small" onClick={onClick}>
        <NewWindow />
      </IconButton>
    </Box>
  );
};

const ViewBox = ({ title, value }: { title: string; value: JSX.Element | string }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
      <Typography variant="caption" color="Grey">
        {title}
      </Typography>
      <Typography variant="caption" color="DarkGrey">
        {value}
      </Typography>
    </Box>
  );
};
