import { useCallback, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { OverlapsRule } from '@v2/feature/absence/absence.interface';
import { AbsenceGeneralSettingsReadonly } from '@v2/feature/absence/subfeatures/settings/components/absence-general-settings-readonly.component';
import { AbsenceSettingsEditDrawer } from '@v2/feature/absence/subfeatures/settings/components/absence-settings-edit-drawer.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';

export const getOverlapsRuleOptions = (polyglot: Polyglot) => [
  { value: OverlapsRule.Everyone, label: polyglot.t('AbsenceGeneralSettings.everyone') },
  { value: OverlapsRule.DirectReports, label: polyglot.t('AbsenceGeneralSettings.directReports') },
  { value: OverlapsRule.Department, label: polyglot.t('AbsenceGeneralSettings.department') },
];

export const AbsenceCompanySettingsGeneralPage = () => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);

  const {
    data: absenceSettings,
    isLoading,
    mutate: refreshSettings,
  } = useApiClient(AbsenceEndpoints.getCompanyAbsenceSettings(), { suspense: false });

  const refreshAbsenceSettings = useCallback(async () => {
    if (refreshSettings) await refreshSettings();
  }, [refreshSettings]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ width: '600px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="title2">{polyglot.t('AbsenceCompanySettingsGeneralPage.other')}</Typography>
            <IconButton sx={tableIconButtonSx} onClick={() => setEdit((prev) => !prev)}>
              <EditIcon {...actionIconSize} />
            </IconButton>
          </Box>
        }
      />
      <ContentWrapper loading={isLoading}>
        <Box sx={{ width: '600px' }}>
          {absenceSettings && <AbsenceGeneralSettingsReadonly absenceSettings={absenceSettings} />}

          {absenceSettings && (
            <AbsenceSettingsEditDrawer
              isOpen={edit}
              setIsOpen={setEdit}
              absenceSettings={absenceSettings}
              refresh={refreshAbsenceSettings}
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
