import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IconButton, Stack } from '@mui/material';
import { OnboardingAPI } from '@v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { OnboardingStateInit } from '@v2/feature/user-onboarding/by-admin/interface/onboarding.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ONBOARDING_ROUTE, ONBOARDING_USER_STATUS_ROUTE } from '@/lib/routes';
import { StepperComponent } from '@/v2/components/stepper.component';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { OnboardingUserInvite } from '@/v2/feature/onboarding/user-setup/onboarding-user-invite.page';
import { NewEmployeeCompensationSection } from '@/v2/feature/onboarding/user-setup/sections/new-employee-compensation.section';
import { NewEmployeeContractSection } from '@/v2/feature/onboarding/user-setup/sections/new-employee-contract.section';
import { NewEmployeeEquitySection } from '@/v2/feature/onboarding/user-setup/sections/new-employee-equity.section';
import { NewEmployeeRoleSection } from '@/v2/feature/onboarding/user-setup/sections/new-employee-role.section';
import { NewEmployeeSection } from '@/v2/feature/onboarding/user-setup/sections/new-employee.section';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const OnboardingStepperSteps = ['Employee', 'Role', 'Contract', 'Compensation', 'Equity'] as const;

export type OnboardingStepperStep = typeof OnboardingStepperSteps[number];

export const iconSize = { width: 14, height: 14 } as const;

export const OnboardingUserSetup = () => {
  const { state } = useLocation<{ newUserId: number; templateId: number } | undefined>();
  const { refreshCachedUsers } = useCachedUsers();
  const [activeStep, setActiveStep] = useState<OnboardingStepperStep>(
    // if we have a userId, skip the first step
    OnboardingStepperSteps[state?.newUserId ? 1 : 0]
  );

  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });

  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const departments = useMemo<readonly OptionObj[]>(() => {
    return deptsData?.map((dept) => ({ label: dept.name, value: dept.id })) ?? [];
  }, [deptsData]);

  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo<readonly OptionObj[]>(() => {
    return siteData?.map((site) => ({ label: site.name, value: site.id })) ?? [];
  }, [siteData]);

  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false,
  });

  const nextPossibleId = useMemo(() => {
    if (!jobPositionData) return '';
    const lastJobPosition = jobPositionData?.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositionData.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositionData]);

  const DEFAULT_NEW_JOB_POSITION: JobPositionForTable = {
    id: undefined,
    internalCode: nextPossibleId,
    title: '',
    description: '',
  };
  const jobPositions: OptionObj[] = useMemo(() => {
    if (!jobPositionData) return [] as OptionObj[];
    return jobPositionData.map((eachJobPosition: JobPosition) => {
      return {
        label: eachJobPosition.title,
        value: eachJobPosition.id as number,
      };
    });
  }, [jobPositionData]);

  const [onboardingState, setOnboardingState] = useState<OnboardingStateInit | null>(null);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const [showInviteConfirmation, setShowInviteConfirmation] = useState(false);

  const returnToOnboardingOverview = useCallback(() => {
    routerHistory.push(ONBOARDING_ROUTE);
  }, [routerHistory]);

  const routeToOnboardingUserStatus = useCallback(() => {
    if (onboardingState?.user.userId) {
      refreshCachedUsers();
      routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboardingState.user.userId }));
    }
  }, [onboardingState, refreshCachedUsers, routerHistory]);

  const isFirstStep = useCallback(() => {
    return OnboardingStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep]);

  const handleNext = useCallback(() => {
    const currentStepIdx = OnboardingStepperSteps.indexOf(activeStep);
    if (currentStepIdx >= OnboardingStepperSteps.length - 1) {
      returnToOnboardingOverview();
      return;
    }
    setActiveStep(OnboardingStepperSteps[currentStepIdx + 1]);
  }, [activeStep, returnToOnboardingOverview]);

  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = OnboardingStepperSteps.indexOf(currentStep);
      return OnboardingStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, []);

  const getOnboardingInfo = useCallback(
    async (userId: number) => {
      try {
        const state = await OnboardingAPI.getInitOnboardingState(userId);
        setOnboardingState(state);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), 'error');
        setOnboardingState(null);
      }
    },
    [showMessage, polyglot]
  );

  useEffect(() => {
    if (!state?.newUserId) return;
    // initialise the onboarding state if we are passed a user id
    getOnboardingInfo(state.newUserId);
  }, [getOnboardingInfo, state?.newUserId]);

  if (showInviteConfirmation) {
    return onboardingState ? (
      <OnboardingUserInvite
        onBackClick={() => setShowInviteConfirmation(false)}
        onFinish={() => routeToOnboardingUserStatus()}
        user={onboardingState.user}
      />
    ) : (
      <></>
    );
  }

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <Stack sx={{ flexFlow: 'row', width: '70%', mx: 'auto' }}>
        {!isFirstStep() && (
          <IconButton sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }} onClick={handleBack}>
            <Back {...iconSize} />
          </IconButton>
        )}
        <StepperComponent sx={{ flex: 1 }} steps={OnboardingStepperSteps} activeStep={activeStep} />
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }}
          onClick={() => returnToOnboardingOverview()}
        >
          <Close {...iconSize} stroke={themeColors.DarkGrey} />
        </IconButton>
      </Stack>
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>
        {{
          Employee: () => (
            <NewEmployeeSection
              onNext={async (userId) => {
                if (userId) {
                  await getOnboardingInfo(userId);
                  handleNext();
                  return;
                }
                handleNext();
              }}
              sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
              templateId={state?.templateId}
              user={onboardingState?.user}
            />
          ),
          Role: () =>
            onboardingState && (
              <NewEmployeeRoleSection
                userId={onboardingState.user.userId}
                role={onboardingState.role}
                departments={departments}
                sites={sites}
                jobPositions={jobPositions}
                startDate={onboardingState.user.startDate}
                onNext={async () => {
                  await getOnboardingInfo(onboardingState.user.userId);
                  handleNext();
                }}
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
                refreshSites={refreshSites}
                refreshDept={refreshDept}
                refreshJobPosition={refreshJobPosition}
                defaultJobPosition={DEFAULT_NEW_JOB_POSITION}
              />
            ),
          Contract: () =>
            onboardingState && (
              <NewEmployeeContractSection
                contract={onboardingState.contract}
                onNext={async () => {
                  await getOnboardingInfo(onboardingState.user.userId);
                  handleNext();
                }}
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
                startDate={onboardingState.user.startDate}
                userId={onboardingState.user.userId}
              />
            ),
          Compensation: () =>
            onboardingState && (
              <NewEmployeeCompensationSection
                compensation={onboardingState.compensation}
                onNext={async () => {
                  await getOnboardingInfo(onboardingState.user.userId);
                  handleNext();
                }}
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
                userId={onboardingState.user.userId}
                startDate={onboardingState.user.startDate}
              />
            ),
          Equity: () =>
            onboardingState && (
              <NewEmployeeEquitySection
                currentUserId={globalState.user.userId}
                equity={onboardingState.equity[0]}
                onNext={async () => {
                  await getOnboardingInfo(onboardingState.user.userId);
                  setShowInviteConfirmation(true);
                }}
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
                newUserId={onboardingState.user.userId}
                companyId={globalState.user.company.companyId}
              />
            ),
        }[activeStep]()}
      </Stack>
    </Stack>
  );
};
