import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { identity, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { UserRoleAPI } from '@/api-client/user-role.api';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { UserRoleFormInfo } from '@/models/user-role.model';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { OptionObj, OptionsProps } from '@/v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  RoleForm,
  RoleSchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component';
import { displayUserName } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { buildColumnsForCustomFields } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const SCOPE = 'user.role' as const;

const skeletonHeight = calculateSkeletonHeight(RoleSchema);
const iconSize = { width: 14, height: 14 } as const;

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: UserRoleFormInfo) => void;
}

export const RoleCard = ({ userId, onSubmit }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const { nonTerminatedCachedUsers: companyUsers, getCachedUserById, refreshCachedUsers } = useCachedUsers({
    refresh: true,
  });

  const [data, setData] = useState<EntriesAndEffectiveRecord<UserRoleFormInfo>>();

  const [formMode, setFormMode] = useState<UserRoleFormInfo | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { data: overview } = useApiClient(UserEndpoints.getUserSummaryById(userId), { suspense: false });
  const directReport = useMemo(() => {
    return overview?.directReports ?? [];
  }, [overview]);

  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });
  const departments = useMemo<readonly OptionObj[]>(() => {
    return deptsData?.map((dept) => ({ label: dept.name, value: dept.id })) ?? [];
  }, [deptsData]);

  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo<readonly OptionObj[]>(() => {
    return siteData?.map((site) => ({ label: site.name, value: site.id })) ?? [];
  }, [siteData]);

  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false,
  });

  const nextPossibleId = useMemo(() => {
    if (!jobPositionData) return '';
    const lastJobPosition = jobPositionData?.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositionData.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositionData]);

  const DEFAULT_NEW_JOB_POSITION: JobPositionForTable = {
    id: undefined,
    internalCode: nextPossibleId,
    title: '',
    description: '',
  };
  const jobPositions: OptionObj[] = useMemo(() => {
    if (!jobPositionData) return [] as OptionObj[];
    return jobPositionData
      ? jobPositionData?.map((eachJobPosition: JobPosition) => {
          return {
            label: eachJobPosition.title,
            value: eachJobPosition.id as number,
          };
        })
      : [];
  }, [jobPositionData]);

  const managers = useMemo<OptionsProps[]>(
    () =>
      companyUsers
        .filter((u) => u.userId !== userId)
        ?.map((rec) => {
          return { label: rec.displayName, value: rec.userId, ...rec };
        }),
    [companyUsers, userId]
  );
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const refreshRole = useCallback(async () => {
    try {
      const role = await UserRoleAPI.getUserRoleInfo(userId);
      refreshCachedUsers?.();
      setData(role);
      if (!data?.entries?.length) setShowDetails(false);
    } catch {
      showMessage('Role information could not be loaded. Please try again.', 'error');
    }
  }, [data?.entries?.length, refreshCachedUsers, showMessage, userId]);

  useEffect(() => {
    refreshRole();
  }, [refreshRole]);

  const columnData = useMemo<ColumnDef<UserRoleFormInfo, UserRoleFormInfo>[]>(
    () => [
      {
        header: () => polyglot.t('RoleCard.jobTitle'),
        accessorFn: identity,
        id: 'jobTitle',
        enableSorting: false,
        size: 80,
        cell: ({
          row: {
            original: { jobTitle },
          },
        }) => (jobTitle ? <div>{polyglot.t(jobTitle)}</div> : <EmptyCell />),
      },
      {
        header: () => polyglot.t('RoleCard.department'),
        accessorFn: identity,
        id: 'department',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { departmentId },
          },
        }) => {
          const department = departmentId && departments.find((d) => d.value === departmentId);
          return department ? <div>{polyglot.t(department.label)}</div> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('RoleCard.site'),
        accessorFn: identity,
        id: 'site',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { siteId },
          },
        }) => {
          const site = sites.find((s) => s.value === siteId);
          return site ? <div style={{ textTransform: 'capitalize' }}>{polyglot.t(site.label)}</div> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('RoleCard.reportsTo'),
        accessorFn: (row) => row.managerId,
        id: 'reportsTo',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { managerId },
          },
        }) => {
          const reportsTo = managerId ? getCachedUserById(managerId) : undefined;
          return reportsTo ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UserAvatar userId={reportsTo.userId} size="xxsmall" />
              <Typography variant="caption" sx={{ marginLeft: spacing.m10 }}>
                {polyglot.t(displayUserName(reportsTo))}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => polyglot.t('RoleCard.directReports'),
        accessorFn: identity,
        id: 'directReports',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { id },
          },
        }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: spacing.m5,
                visibility: id === data?.effectiveRecord?.id ? 'visible' : 'hidden',
              }}
            >
              {directReport && directReport.length > 0 ? (
                directReport.map((user) => <UserAvatar userId={user.userId} size="xxsmall" />)
              ) : (
                <EmptyCell />
              )}
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('RoleCard.lastChangeType'),
        accessorFn: identity,
        id: 'lastChangeType',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { lastChangeType },
          },
        }) => (lastChangeType ? <div style={{ textTransform: 'capitalize' }}>{lastChangeType}</div> : <EmptyCell />),
      },
      {
        header: () => polyglot.t('RoleCard.lastChangeReason'),
        accessorFn: identity,
        id: 'lastChangeReason',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { lastChangeReason },
          },
        }) =>
          lastChangeReason ? (
            <div style={{ textTransform: 'capitalize' }}>{polyglot.t(lastChangeReason)}</div>
          ) : (
            <EmptyCell />
          ),
      },
      ...buildColumnsForCustomFields(data?.entries, { size: 80 }),
      {
        header: () => polyglot.t('RoleCard.effectiveDate'),
        accessorFn: identity,
        id: 'effectiveDate',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        accessorFn: identity,
        header: () => '',
        id: 'actions',
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title="edit"
                onClick={() => {
                  setIsOpen(true);
                  setFormMode(original);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ],
    [
      polyglot,
      data?.effectiveRecord?.id,
      data?.entries,
      departments,
      directReport,
      getCachedUserById,
      scopesContext,
      sites,
    ]
  );

  const handleSubmit = useCallback(
    (x: UserRoleFormInfo) => {
      refreshRole();
      setFormMode(null);
      setIsOpen(false);
      onSubmit(x);
    },
    [onSubmit, refreshRole]
  );

  const onRefresh = useCallback(() => {
    refreshRole();
    setFormMode(null);
  }, [refreshRole]);

  return pipe(
    data ? O.some(data) : O.none,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      ({ entries, effectiveRecord }) => {
        const manager = effectiveRecord?.managerId ? getCachedUserById(effectiveRecord?.managerId) : undefined;
        return (
          <CardStructure
            key={effectiveRecord?.id}
            cardTitle={polyglot.t('RoleCard.cardTitle')}
            cardScope={[SCOPE]}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            userId={userId}
            addButtonTitle={polyglot.t('RoleCard.newRole')}
            addAction={() => {
              setIsOpen(true);
              setFormMode(null);
            }}
            editAction={
              effectiveRecord &&
              (() => {
                setIsOpen(true);
                setFormMode(effectiveRecord);
              })
            }
            showMissingInfo={!effectiveRecord}
            cardFieldCustomUpdates={effectiveRecord?.customUpdates}
            cardFieldStubs={['role.jobPosition', 'role.department', 'role.site', 'role.manager']}
            cardFieldDetails={
              effectiveRecord && (
                <Box component="dl" sx={definitionListSx}>
                  <FieldStructure
                    fieldTitle={polyglot.t('RoleCard.jobTitle')}
                    fieldValue={effectiveRecord.jobTitle ? polyglot.t(effectiveRecord.jobTitle) : ''}
                    fieldStub="role.jobTitle"
                  />

                  <FieldStructure
                    fieldTitle={polyglot.t('RoleCard.department')}
                    fieldValue={polyglot.t(
                      effectiveRecord?.departmentId
                        ? departments.find((d) => d.value === effectiveRecord?.departmentId)?.label ?? ''
                        : ''
                    )}
                    fieldStub="role.department"
                  />

                  <FieldStructure
                    fieldTitle={polyglot.t('RoleCard.site')}
                    fieldValue={polyglot.t(
                      effectiveRecord?.siteId ? sites.find((s) => s.value === effectiveRecord?.siteId)?.label ?? '' : ''
                    )}
                    fieldStub="role.site"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('RoleCard.reportsTo')}
                    fieldValue={
                      manager ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <UserAvatar userId={manager.userId} size="xxsmall" />
                          <Typography variant="title4" sx={{ marginLeft: spacing.m5 }}>
                            {polyglot.t(manager.displayName ?? '')}
                          </Typography>
                        </Box>
                      ) : null
                    }
                    fieldStub="role.manager"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('RoleCard.directReports')}
                    fieldValue={
                      !!directReport?.length ? (
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: spacing.m5 }}>
                          {directReport.map((user) => (
                            <UserAvatar userId={user.userId} size="xxsmall" />
                          ))}
                        </Box>
                      ) : null
                    }
                    fieldStub="role.manager"
                  />
                  {sortCustomFields(effectiveRecord.customUpdates).map(
                    (f) =>
                      !f.field.isHidden && (
                        <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                      )
                  )}
                </Box>
              )
            }
            tableColumn={columnData}
            tableRowData={[...entries]}
            drawerDetails={
              <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
                  <RoleForm
                    userId={userId}
                    departments={departments}
                    managers={managers}
                    sites={sites}
                    jobPositions={jobPositions}
                    initialValues={formMode}
                    onSubmit={handleSubmit}
                    onRefresh={onRefresh}
                    onClose={() => setIsOpen(false)}
                    refreshSites={refreshSites}
                    refreshDept={refreshDept}
                    refreshJobPosition={refreshJobPosition}
                    defaultJobPosition={DEFAULT_NEW_JOB_POSITION}
                  />
                </DrawerModal>
              </ScopesControl>
            }
          />
        );
      }
    )
  );
};
