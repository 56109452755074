import axios from 'axios';
import { BillingInformation } from '@/models/subscription.model';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import { Invoice } from '@/models/invoice.model';
import { EnhancedCompanySubscription } from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';

export class BillingAPI {
  static async getBillingInformation(): Promise<BillingInformation> {
    return (await axios.get('/apiv2/billing/info')).data;
  }

  static async manuallyChargeCustomerForUnpaidInvoice(
    invoice: Invoice,
    companySubscription: EnhancedCompanySubscription
  ): Promise<void> {
    return await axios.post('/apiv2/billing/manually-charge-invoice', { invoice, companySubscription });
  }
}

export class BillingEndpoints {
  static getBillingInfo(): Endpoint<BillingInformation> {
    return { url: '/apiv2/billing/info' };
  }
}
