import React, { useCallback, useContext, useMemo, useState } from 'react';

import { FormControlLabel, RadioGroup, Stack, SxProps, Theme, Typography } from '@mui/material';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { APPS_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { OptionObject } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AppTenant } from '@/v2/feature/app-integration/app-integration.dto';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

interface Props {
  readonly payrollId: number;
  readonly setAccountEdit: React.Dispatch<React.SetStateAction<boolean>>;
  readonly accountEdit: boolean;
  sx?: SxProps<Theme>;
}

export const PayrollAccountingSettings = ({ payrollId, setAccountEdit, accountEdit, sx }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const scopesContext = getScopesContext({ userId: user.userId });
  const hasAppsScope = hasScopes(['apps'], scopesContext);
  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null);

  const {
    data: accountingConfig,
    mutate: refreshAccountingState,
    isValidating: loading,
  } = useApiClient(PayrollEndpoints.getAccounting(payrollId), { suspense: false });
  const accountingDefaultApp = 'xero';

  const { data: accountingApp } = useApiClient(
    hasAppsScope ? AppDetailsEndpoints.getAppDetails(accountingDefaultApp) : { url: undefined },
    {
      suspense: false,
    }
  );

  useEscapeKey(() => setAccountEdit(false));

  // const connectAccountingApp = useCallback(
  //   (appStub: 'xero') => {
  //     window.location.href = PayrollAPI.initiateAccountingAppConnection(appStub, payrollId);
  //   },
  //   [payrollId]
  // );

  // const disconnectAccountingApp = useCallback(async () => {
  //   try {
  //     await PayrollAPI.disconnectAccountingApp(payrollId);
  //     setAccountEdit(false);
  //     showMessage(polyglot.t('PayrollAccountingSettings.accountingDisconnected'), 'success');
  //   } catch (e) {
  //     showMessage(
  //       polyglot.t('PayrollAccountingSettings.accountingDisconnectionFailed', { reason: nestErrorMessage(e) }),
  //       'error'
  //     );
  //   }
  //   refreshAccountingState?.();
  // }, [payrollId, refreshAccountingState, setAccountEdit, showMessage, polyglot]);

  const assignAccountingTenantToPayroll = useCallback(
    async (tenantId: string) => {
      try {
        await PayrollAPI.assignAccountingAppToPayroll(payrollId, {
          stub: 'xero',
          tenantId,
        });
        setAccountEdit(false);
        showMessage(polyglot.t('PayrollAccountingSettings.accountingConnected'), 'success');
      } catch (e) {
        showMessage(
          polyglot.t('PayrollAccountingSettings.accountingConnectionFailed', { reason: nestErrorMessage(e) }),
          'error'
        );
      }
      refreshAccountingState?.();
    },
    [payrollId, refreshAccountingState, setAccountEdit, showMessage, polyglot]
  );

  const tenantOptions = useMemo(() => {
    if (!accountingApp || !accountingApp?.tenants || accountingApp?.tenants?.length === 0) return null;
    return [...accountingApp.tenants]
      .sort((a, b) => caseInsensitiveSort(a, b, (item) => item.tenantName))
      .map<OptionObject>((e: AppTenant) => ({
        label: e.tenantName,
        value: e.tenantId,
      }));
  }, [accountingApp]);

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader
        showEditButton={!loading && !accountEdit && accountingConfig?.appInstalled && !accountingConfig?.invalidatedAt}
        onEditClick={() => {
          setSelectedTenantId(accountingConfig?.organisation?.id ?? null);
          setAccountEdit(true);
        }}
      >
        {polyglot.t('PayrollAccountingSettings.title')}
      </PayrollSettingSectionHeader>
      {!loading && accountingConfig && accountEdit && (
        <>
          <Typography sx={{ font: themeFonts.caption }}>
            {polyglot.t('PayrollAccountingSettings.selectOrganisation')}
          </Typography>
          <Stack direction="column">
            <RadioGroup
              name="external-sync-organisation-group"
              onChange={(event) => {
                if (!event.target.value) return;
                const matchingTenant = (accountingApp?.tenants ?? []).find((t) => t.tenantId === event.target.value);
                if (!matchingTenant) return;
                const { tenantId } = matchingTenant;
                setSelectedTenantId(tenantId);
              }}
            >
              {tenantOptions?.map((option: OptionObject) => (
                <FormControlLabel
                  key={option.value}
                  labelPlacement="end"
                  value={option.value}
                  checked={selectedTenantId ? selectedTenantId === option.value : false}
                  control={<StyledRadio />}
                  label={<Typography sx={{ font: themeFonts.caption }}>{option.label}</Typography>}
                  sx={{ mb: spacing.m5 }}
                />
              ))}
            </RadioGroup>
            <Stack sx={{ flexFlow: 'row', gap: spacing.g10, mt: spacing.m15, alignItems: 'center' }}>
              <ButtonComponent
                onClick={() => {
                  if (selectedTenantId) assignAccountingTenantToPayroll(selectedTenantId);
                }}
                sizeVariant="medium"
                colorVariant="primary"
                disabled={!selectedTenantId || selectedTenantId === accountingConfig?.organisation?.id}
              >
                {polyglot.t('General.save')}
              </ButtonComponent>
              <ButtonComponent onClick={() => setAccountEdit(false)} sizeVariant="medium" colorVariant="secondary">
                {polyglot.t('General.cancel')}
              </ButtonComponent>
            </Stack>
          </Stack>
        </>
      )}
      {!loading && accountingConfig && !accountEdit && (
        <Stack sx={{ gap: spacing.g5, ...sx }}>
          {accountingConfig?.organisation?.name ? (
            <>
              <Typography sx={themeFonts.caption}>
                {polyglot.t('PayrollAccountingSettings.organisationLabel')}
              </Typography>
              <Typography sx={themeFonts.title4}>{accountingConfig?.organisation?.name}</Typography>
            </>
          ) : accountingConfig?.appInstalled && accountingConfig?.invalidatedAt ? (
            <Typography sx={themeFonts.caption}>
              {polyglot.t('PayrollAccountingSettings.accountingAppInvalidatedFirstPart')}
              <ButtonComponent
                sizeVariant="small"
                colorVariant="text"
                onClick={() => {
                  window.open(APPS_COMPANY_OVERVIEW_ROUTE, '_blank');
                }}
                style={{
                  margin: 0,
                  padding: 0,
                  color: themeColors.black,
                  display: 'inline',
                  textDecoration: 'underline',
                }}
              >
                here
              </ButtonComponent>
              {polyglot.t('PayrollAccountingSettings.accountingAppInvalidatedSecondPart')}
            </Typography>
          ) : (
            <Typography sx={themeFonts.caption}>{polyglot.t('PayrollAccountingSettings.payrollNotLinked')}</Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
