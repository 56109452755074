import React from 'react';

import { Box } from '@mui/material';
import { RichTextField } from '@v2/components/forms/rich-text/rich-text-field.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export const DeviceNotesOverviewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  selectedNote,
  devicePossession,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  selectedNote: string | undefined;
  devicePossession: DevicePossessionDto;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <OverviewNotesContent onClose={onClose} selectedNote={selectedNote} devicePossession={devicePossession} />
    </DrawerModal>
  );
};

const useCommentForm = (
  selectedNote: string | undefined,
  devicePossession: DevicePossessionDto,
  onClose: () => void
) => {
  const [showMessage] = useMessage();

  return useFormik({
    initialValues: {
      notes: selectedNote ? selectedNote : '',
    },
    validationSchema: yup.object({
      notes: yup.string().notRequired(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await DeviceAPI.updateDeviceCustomerNotes(devicePossession.deviceId, {
          customerNotes: values.notes,
        });
        showMessage('Successfully update the notes', 'success');
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });
};

const OverviewNotesContent = ({
  onClose,
  selectedNote,
  devicePossession,
}: {
  onClose: () => void;
  selectedNote: string | undefined;
  devicePossession: DevicePossessionDto;
}) => {
  const { polyglot } = usePolyglot();

  const formik = useCommentForm(selectedNote, devicePossession, onClose);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Add note</Typography>
        <RichTextField
          label="Notes text"
          value={formik.values.notes}
          onChange={(value: string) => formik.setFieldValue('notes', value)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
