import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DevicesTransitsTableSuperAdmin } from '@v2/feature/device/components/devices-list-overview/devices-transits-table-superadmin.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceTransitDto } from '@v2/feature/device/device.dto';
import { getCompanyNamesKeyedByCompanyIds, getUserNamesKeyedByUserIds } from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { keyBy } from 'lodash';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface SuperAdminDevicesTransitsPageProps {
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly pageConfig: PageConfig;
}

export const SuperAdminDevicesTransitsPage = ({
  users,
  companies,
  pageConfig,
}: SuperAdminDevicesTransitsPageProps): JSX.Element => {
  const [zeltDeviceTransits, setZeltDeviceTransits] = useState<readonly DeviceTransitDto[]>([]);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);

  const [sites, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();

  const refreshZeltTransits = useCallback(async () => {
    try {
      setLoading(true);
      const deviceTransits = await DeviceAPI.getZeltTransitsAsSuperadmin();
      const sitesData = await SiteAPI.listSitesAsSuperAdmin();
      setZeltDeviceTransits(deviceTransits);
      setSitesById(keyBy(sitesData, 'id'));
    } catch (error) {
      showMessage(`Could not retrieve Zelt devices transits. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshZeltTransits();
  }, [refreshZeltTransits]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>}
        views={<></>}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {Boolean(zeltDeviceTransits && zeltDeviceTransits.length > 0) ? (
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="83%"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            }
          >
            <DevicesTransitsTableSuperAdmin
              userNames={userNames}
              companiesNames={companiesNames}
              deviceTransits={zeltDeviceTransits}
              refresh={refreshZeltTransits}
              sites={sites}
            />
          </Suspense>
        ) : (
          <Typography variant="subtitle1">No active transits.</Typography>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
