import { useContext, useMemo, useState } from 'react';

import { Backdrop, Box, Fade, Modal } from '@mui/material';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as DownloadIcon } from '@/images/fields/Download.svg';
import { ReactComponent as Eye } from '@/images/new-theme-icon/Eye.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const PublicImageViewer = ({
  fileName,
  uploadName,
  hasView,
  hasDownload,
}: {
  fileName: string;
  uploadName: string;
  hasView: boolean;
  hasDownload: boolean;
}) => {
  const [state] = useContext(GlobalContext);
  const [showPreview, setShowPreview] = useState(false);

  const publicUrl = useMemo(() => {
    if (!state.publicURL || !fileName) return undefined;

    return `${state.publicURL}/${fileName}`;
  }, [state.publicURL, fileName]);

  const handlePreviewClick = () => {
    setShowPreview(true);
  };

  const handleClose = () => {
    setShowPreview(false);
  };

  const handleDownloadClick = async () => {
    if (!publicUrl) return;

    try {
      const response = await fetch(publicUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
          <DocumentIcon {...iconSize} />
          <Typography variant="title4">{uploadName}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
          {hasView && (
            <IconButton sizeVariant="small" colorVariant="secondary" onClick={handlePreviewClick}>
              <Eye {...iconSize} />
            </IconButton>
          )}

          {hasDownload && (
            <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => handleDownloadClick()}>
              <DownloadIcon {...iconSize} />
            </IconButton>
          )}
        </Box>

        <PublicImageView publicUrl={publicUrl} showPreview={showPreview} handleClose={handleClose} />
      </Box>
    </Box>
  );
};

export const PublicImageView = ({
  publicUrl,
  showPreview,
  handleClose,
}: {
  publicUrl: string | undefined;
  showPreview: boolean;
  handleClose: () => void;
}) => {
  return (
    <Modal
      open={showPreview}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showPreview}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '900px',
            height: 'auto',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: { xs: spacing.s1, sm: spacing.s2 },
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.s2,
          }}
        >
          {publicUrl ? (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '56.25%',
                overflow: 'hidden',
              }}
            >
              <iframe
                src={publicUrl}
                title="Preview"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
              />
            </Box>
          ) : (
            <Typography variant="caption" color="darkRed">
              Failed to load the document.
            </Typography>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
