import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';

import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { UserImportDto } from '@/v2/feature/user/features/user-import/user-import.dto';

export function camelCaseToCapitalCase(inputString: string): string {
  return inputString.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

export function getDisplayName(user: Partial<UserSummaryDto> | Partial<UserImportDto>) {
  return (user && (user.displayName ?? `${user.firstName} ${user.lastName}`)) ?? '';
}

export function undefinedForEmptyString(inputString: string): string | null {
  const trimmedVal = inputString.trim();
  return typeof trimmedVal !== 'string' || trimmedVal.length > 0 ? trimmedVal : null;
}

export function formatAsGBPCurrency(inputNumber: number, decimals?: number): string {
  return '£ '.concat(new Intl.NumberFormat('en-GB', { minimumFractionDigits: decimals ?? 0 }).format(inputNumber));
}

export function formatAsCompensationCurrency(currency: string | undefined | null, inputNumber: number): string {
  const cf = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency ?? DEFAULT_CURRENCY,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return cf
    .format(inputNumber)
    .replace(/^(\D+)/, '$1 ')
    .replace(/\s+/, ' '); // fix for https://github.com/andyearnshaw/Intl.js/issues/221
}

export function capitalize(inputString: string): string {
  if (!inputString) return '';
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
