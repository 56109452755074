import { Box, SxProps, Theme, Typography } from '@mui/material';

import type { SystemStyleObject } from '@mui/system';

import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const OverflowSx: SystemStyleObject = {
  maxWidth: '70px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export interface AvatarCardProps {
  readonly title?: string;
  readonly titleSx?: SxProps<Theme>;
  readonly subTitle?: string;
  readonly subTitleSx?: SxProps<Theme>;
  readonly caption?: string;
  readonly captionSx?: SxProps<Theme>;
  readonly onClick?: () => void;
  readonly size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  readonly userId: number;
  readonly freeWidth?: boolean;
  readonly publicURL?: string | null;
}

export const AvatarCardWidth = 110;

export const AvatarCard = ({
  title,
  titleSx = { ...themeFonts.caption, color: themeColors.DarkGrey, ...OverflowSx },
  caption,
  captionSx = { ...themeFonts.captionSmall, color: themeColors.Grey, ...OverflowSx },
  onClick,
  subTitle,
  subTitleSx = { ...themeFonts.captionSmall, color: themeColors.Grey, ...OverflowSx },
  size = 'medium',
  userId,
  freeWidth,
  publicURL,
}: AvatarCardProps): JSX.Element => {
  return (
    <Box
      sx={{
        bgcolor: themeColors.Background,
        overflow: 'hidden',
        py: spacing.p15,
        borderRadius: radius.br15,
        width: freeWidth ? 'auto' : `${AvatarCardWidth}px`,
        cursor: onClick && 'pointer',
        minHeight: '130px',
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <UserAvatar userId={userId} size={size} publicURL={publicURL} />
      </Box>

      {title && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              ...titleSx,
              marginTop: spacing.m10,
            }}
          >
            {title}
          </Typography>
        </Box>
      )}

      {subTitle && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              ...subTitleSx,
            }}
          >
            {subTitle}
          </Typography>
        </Box>
      )}

      {caption && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              ...captionSx,
            }}
          >
            {caption}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
