import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@v2/components/typography/typography.component';
import {
  BelongsTo,
  documentBelongsToEveryone,
  DocumentFormModalAction,
  DocumentFormModalState,
  documentHasOwner,
  DocumentsDomain,
  DocumentType,
  getDefaultNameForSingleUserDocument,
  getDocumentTypeListBasedOnAudience,
  getOwnersUserIds,
  getTypeState,
  OTHER_TYPE,
} from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize, iconSize } from '@v2/styles/table.styles';
import dayjs from 'dayjs';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import { useParams } from 'react-router-dom';
import { Subject } from 'rxjs';

import { UploadInput } from '@/component/forms/UploadInput';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as InfoGrey } from '@/images/side-bar-icons/InfoGrey.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Subtract.svg';
import { BELONGS_TO_EVERYONE, ZeltDocumentType, ZeltDocumentUser } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { checkScopes } from '@/lib/scopes';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { MultiUserSelect } from '@/v2/components/forms/user-select/multi-user-select.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { ClearIconButton } from '@/v2/components/theme-components/clear-icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { BulkUploadAction } from '@/v2/feature/documents/bulk-upload-documents.context';
import { DocumentSettingsNewTypeForm } from '@/v2/feature/documents/components/document-settings-new-type-drawer.component';
import { DocumentAPI, DocumentEndpoints } from '@/v2/feature/documents/document.api';
import {
  DocumentAction,
  DocumentUploadInputFile,
  DocumentUploadResponse,
  DocumentUploadStatus,
} from '@/v2/feature/documents/documents.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

interface DocumentFormModalProps {
  readonly mode: DocumentAction;
  readonly state: DocumentFormModalState;
  readonly currentUserIsAdmin: boolean;
  readonly dispatch: React.Dispatch<DocumentFormModalAction>;
  readonly newDocumentTypes: readonly ZeltDocumentType[];
  readonly onClose?: () => void;
  readonly afterClose?: () => void;
  readonly onSubmit: () => Promise<void>;
  readonly users?: readonly CachedUser[];
  readonly canChangeOwnership?: boolean;
  readonly canSelectEveryone?: boolean;
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly getDocuments?: () => Promise<void>;
  readonly setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  readonly filterValue: string;
  readonly bulkUpload?: boolean;
  readonly bulkUploadDispatch?: React.Dispatch<BulkUploadAction>;
}

/**
 * Modal component for handling additions and delitions of documents
 */

export const DocumentFormModal = ({
  mode,
  state,
  currentUserIsAdmin,
  dispatch,
  newDocumentTypes,
  onSubmit,
  users = [],
  canChangeOwnership = false,
  canSelectEveryone = false,
  setOpen,
  open,
  setLoading,
  getDocuments,
  setFilterValue,
  filterValue,
  onClose,
  afterClose,
  bulkUpload = false,
  bulkUploadDispatch,
}: DocumentFormModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const { data: allTypes, mutate: refreshAllTypes } = useApiClient(
    open ? DocumentEndpoints.getTypesData() : { url: undefined },
    {
      suspense: false,
    }
  );
  const Views = [
    { name: polyglot.t('DocumentFormModal.companyDocuments'), value: 'company' },
    { name: polyglot.t('DocumentFormModal.personalDocuments'), value: 'personal' },
  ];
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const [removalCandidateId, setRemovalCandidateId] = useState<number | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();

  const optionObject = useMemo(() => {
    return allTypes
      ? getDocumentTypeListBasedOnAudience(allTypes, currentUserIsAdmin, filterValue as 'company' | 'personal')
      : [];
  }, [allTypes, currentUserIsAdmin, filterValue]);

  const hasDocumentsAllScope = checkScopes(globalState.user, ['documents:all'], { userId: globalState.user.userId });
  const { userId: userIdParam } = useParams<{ readonly userId: string }>();
  const currentUserId = !!userIdParam ? Number(userIdParam) : undefined;

  const [showMessage] = useMessage();

  const belongsToOnlyYou =
    mode === 'edit' &&
    state &&
    state.owners &&
    state.owners.length === 1 &&
    state.owners[0].value === globalState.user.userId;

  // might be brought back later, based on customer requests - currently not required
  // as document deletion needs to be reserved ONLY for documents:all
  // const stdUserCan = hasDocumentsAllScope
  //   ? true
  //   : mode === 'edit' && state && globalState.user.userId === state.uploadedBy;

  // TODO Don't compare label
  const isEditableOrRemovable =
    (canSelectEveryone || !documentBelongsToEveryone(state)) &&
    state.type &&
    newDocumentTypes?.find((t) => t.value === state.type?.value)?.label !== 'Payslip';

  const [changeName$] = useState(() => new Subject<string>());
  useEffect(
    () =>
      pipe(
        changeName$,
        // TODO change this so that it's not a full debounce, and instead it accepts a value every {DEBOUNCE_TIME_MS}
        ($) => $.subscribe((value) => dispatch({ kind: 'change_name', value })),
        (s) => () => s.unsubscribe()
      ),
    [changeName$, dispatch]
  );
  const [changeNote$] = useState(() => new Subject<string>());

  useEffect(
    () =>
      pipe(
        changeNote$,
        ($) =>
          $.subscribe((value) => {
            dispatch({ kind: 'change_note', value });
            if (bulkUpload && bulkUploadDispatch && state.fileUuid)
              bulkUploadDispatch({
                type: 'UPDATE_DOCUMENT_PENDING',
                payload: { documentId: state.fileUuid, updatedData: { note: value } },
              });
          }),
        (s) => () => s.unsubscribe()
      ),
    [changeNote$, dispatch, bulkUploadDispatch, bulkUpload, state.fileUuid]
  );

  const [changeTypes$] = useState(() => new Subject<DocumentType>());
  useEffect(
    () =>
      pipe(
        changeTypes$,
        // TODO change this so that it's not a full debounce, and instead it accepts a value every {DEBOUNCE_TIME_MS}
        ($) =>
          $.subscribe((value) =>
            dispatch({ kind: 'change_type', type: value as DocumentType, keepOwnership: !canChangeOwnership })
          ),
        (s) => () => s.unsubscribe()
      ),
    [changeTypes$, dispatch, canChangeOwnership]
  );
  const allSelectedUserIds = getOwnersUserIds(state);
  const belongsToUserOptions = pipe(
    users,
    RA.filter((u) => !allSelectedUserIds.has(u.userId)),
    RA.map((u) => ({ value: u.userId, label: u.displayName, ...u } as const))
  );
  const documentId = state.id;

  const hasDocumentsManagerScope = useMemo(() => {
    if (currentUserId) return checkScopes(globalState.user, ['documents:manager'], { userId: currentUserId });
    if (allSelectedUserIds.size > 0) {
      return Array.from(allSelectedUserIds).every((eachUserId) =>
        checkScopes(globalState.user, ['documents:manager'], { userId: eachUserId })
      );
    }
  }, [currentUserId, allSelectedUserIds, globalState.user]);

  const managerOrAdmin = useMemo(() => {
    return hasDocumentsAllScope || hasDocumentsManagerScope;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);

  function handleRemoveClick(documentId: number) {
    setRemovalCandidateId(documentId);
    setIsRemovalModalOpen(true);
  }

  const removeDocument = async () => {
    if (removalCandidateId) {
      setLoading?.(true);
      try {
        await DocumentAPI.deleteDocumentById(removalCandidateId);
        await getDocuments?.();
        setOpen(false);
        showMessage(polyglot.t('DocumentFormModal.successMessages.remove'), 'success');
      } catch (error) {
        showMessage(
          polyglot.t('DocumentFormModal.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading?.(false);
        setIsRemovalModalOpen(false);
        setOpen(false);
      }
    }
  };

  const commitChangesForDocument = () => {
    if (state.fileUuid && bulkUploadDispatch)
      bulkUploadDispatch({
        type: 'COMMIT_DOCUMENT_UPDATE',
        payload: state.fileUuid,
      });
  };

  const cancelPendingChangesForDocument = () => {
    if (state.fileUuid && bulkUploadDispatch)
      bulkUploadDispatch({
        type: 'CANCEL_DOCUMENT_UPDATE',
        payload: state.fileUuid,
      });
  };

  const updateSingleOwnershipForDocument = (v: BelongsTo) => {
    const userForV = getCachedUserById(v.value);
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      const ownershipList = [
        {
          documentId: 0,
          User: {
            userId: v.value,
            firstName: userForV?.firstName,
            lastName: userForV?.lastName,
            displayName: userForV?.displayName,
          },
        },
      ] as ZeltDocumentUser[];
      bulkUploadDispatch({
        type: 'UPDATE_DOCUMENT_PENDING',
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            assignee: ownershipList,
            state: DocumentUploadStatus.MAPPED,
          },
        },
      });
    }
  };

  const updateVisibilityForDocument = (hidden: boolean) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      bulkUploadDispatch({
        type: 'UPDATE_DOCUMENT_PENDING',
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            visibilitySettings: { [DocumentsDomain.employee]: { hideDocument: hidden } },
          },
        },
      });
    }
  };

  const updateExpiryForDocument = (expires: boolean, expiryDate: string) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      bulkUploadDispatch({
        type: 'UPDATE_DOCUMENT_PENDING',
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            expirySettings: { expiryDate, documentExpires: expires },
          },
        },
      });
    }
  };

  const updateDocumentType = (x: DocumentType) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid)
      bulkUploadDispatch({
        type: 'UPDATE_DOCUMENT_PENDING',
        payload: {
          documentId: state.fileUuid,
          updatedData: { documentType: x, state: DocumentUploadStatus.MAPPED },
        },
      });
  };

  return (
    <>
      <DrawerModal isOpen={open} setIsOpen={setOpen} onClose={onClose} afterClose={afterClose}>
        <form
          onSubmit={async (event) => {
            commitChangesForDocument();
            event.preventDefault();
            setIsLoading(true);
            await onSubmit();
            // TODO this could present an issue on component unmount
            setIsLoading(false);
            setOpen(false);
          }}
          style={drawerContentSx}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="title2">
              {mode === 'request'
                ? polyglot.t('DocumentFormModal.requestDocument')
                : mode === 'edit'
                ? polyglot.t('DocumentFormModal.editDocument')
                : polyglot.t('DocumentFormModal.newDocument')}
            </Typography>
            {mode === 'edit' && !bulkUpload ? (
              documentId === undefined || !isEditableOrRemovable || !hasDocumentsAllScope ? (
                <IconButton sx={iconButtonSx} disabled>
                  <TrashIcon {...actionIconSize} />
                </IconButton>
              ) : (
                <IconButton
                  sx={iconButtonSx}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    handleRemoveClick(documentId);
                  }}
                >
                  <TrashIcon {...actionIconSize} />
                </IconButton>
              )
            ) : (
              <></>
            )}
          </Box>
          {currentUserIsAdmin && (
            <TabFilterButtons
              filters={mode === 'request' ? Views.filter((v) => v.value === 'personal') : Views}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              disabled={mode === 'edit'}
              onFilterChange={({ filterValue }) => {
                getTypeState(state, changeTypes$, filterValue as 'company' | 'personal');
              }}
            />
          )}
          {canChangeOwnership ? (
            <Box>
              {state.multiownership ? (
                <MultiUserSelect
                  id="multiownership"
                  limitTags={5}
                  options={[
                    ...(canSelectEveryone && state.canBelongToEveryone ? [BELONGS_TO_EVERYONE] : []),
                    ...belongsToUserOptions,
                  ]}
                  value={
                    state.canBelongToEveryone && state.belongsToEveryone ? [BELONGS_TO_EVERYONE] : [...state.owners]
                  }
                  onChange={(_, values) => {
                    const vs = pipe(
                      values,
                      RA.filterMap((v) => (v.value !== 'public-org' ? O.some(v) : O.none))
                    );
                    if (vs.length === values.length || values[values.length - 1]?.value !== 'public-org') {
                      dispatch({
                        kind: 'select_owners',
                        value: vs as BelongsTo[],
                      });
                    } else {
                      dispatch({ kind: 'select_everyone' });
                    }
                  }}
                  isOptionEqualToValue={(x, y) => x.value === y.value}
                  getOptionLabel={({ label }: { label: string }): string => label}
                  label={polyglot.t('DocumentFormModal.belongs')}
                />
              ) : (
                <SingleUserSelect
                  name="singleownership"
                  options={belongsToUserOptions}
                  onChange={(_, value) => {
                    if (value) {
                      updateSingleOwnershipForDocument(value);
                      dispatch({
                        kind: 'select_owner',
                        value: value as BelongsTo,
                      });
                    } else {
                      dispatch({
                        kind: 'unselect_owners',
                      });
                    }
                  }}
                  value={state.owner?.value}
                  label={polyglot.t('DocumentFormModal.belongs')}
                  error={false}
                  helperText={false}
                />
              )}
            </Box>
          ) : canChangeOwnership ? (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {state.multiownership ? (
                state.canBelongToEveryone && state.belongsToEveryone ? (
                  <StyledTextfield
                    label={polyglot.t('DocumentFormModal.belongs')}
                    disabled={true}
                    fullWidth
                    value="Everyone"
                    sx={{ ...themeFonts.caption }}
                    variant="standard"
                  />
                ) : (
                  <StyledTextfield
                    label={polyglot.t('DocumentFormModal.belongs')}
                    disabled={true}
                    fullWidth
                    sx={{ ...themeFonts.caption }}
                    variant="standard"
                    InputProps={{
                      startAdornment: state.owners.map((option) => (
                        <InputAdornment key={option.value} position="start">
                          {option?.value && <UserAvatar userId={option.value} size="xxsmall" />}
                        </InputAdornment>
                      )),
                    }}
                  />
                )
              ) : state.owner ? (
                <StyledTextfield
                  label={polyglot.t('DocumentFormModal.belongs')}
                  disabled={true}
                  fullWidth
                  value={state.owner.label}
                  sx={{ ...themeFonts.caption }}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {state.owner?.value && (
                          <UserAvatar key={state.owner.value} userId={state.owner.value} size="xxsmall" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : null}
            </Box>
          ) : null}

          {filterValue === 'company' && (
            <AutocompleteComponent
              name="documentType"
              label={polyglot.t('DocumentFormModal.documentType')}
              options={optionObject}
              value={state.type}
              // @ts-ignore
              onChange={(_, x: DocumentType) => {
                if (x) {
                  updateDocumentType(x);
                  dispatch({
                    kind: 'change_type',
                    type: x,
                    keepOwnership: !canChangeOwnership,
                  });
                } else {
                  dispatch({
                    kind: 'change_type',
                    type: newDocumentTypes.find((type) => type.value === 'other') ?? OTHER_TYPE,
                    keepOwnership: !canChangeOwnership,
                  });
                }
              }}
              compareValue={state.type?.value ?? ''}
              editList={{
                handler: () => setNewTypeDrawerIsOpen(true),
                isHidden: !hasScopes(['documents:all'], { userId: user.userId }),
              }}
            />
          )}

          {filterValue === 'personal' && (
            <AutocompleteComponent
              name="documentType"
              label={polyglot.t('DocumentFormModal.documentType')}
              options={optionObject}
              value={state.type}
              // @ts-ignore
              onChange={(_, x: DocumentType) => {
                updateDocumentType(x);
                changeTypes$.next(x);
              }}
              compareValue={state.type?.value ?? ''}
              editList={{
                handler: () => setNewTypeDrawerIsOpen(true),
                isHidden: !hasScopes(['documents:all'], { userId: user.userId }),
              }}
            />
          )}

          <StyledTextfield
            label={polyglot.t('DocumentFormModal.name')}
            size="small"
            required
            value={state.name ?? getDefaultNameForSingleUserDocument(state, changeName$)}
            onChange={({ target: { value } }) => changeName$.next(value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {state.name && state.name?.length > 0 && <ClearIconButton onClick={() => changeName$.next('')} />}
                </InputAdornment>
              ),
            }}
          />

          <TextfieldComponent
            label={
              mode === 'request' ? polyglot.t('DocumentFormModal.description') : polyglot.t('DocumentFormModal.note')
            }
            value={state.note}
            onChange={({ target: { value } }) => changeNote$.next(value)}
            fullWidth
            clearText={() => changeNote$.next('')}
          />

          {filterValue === 'company' && (
            <CheckboxComponent
              name="isPinned"
              label={polyglot.t('DocumentFormModal.isPinned')}
              checked={state.pinned}
              onChange={() => {
                dispatch({
                  kind: 'set_pinned',
                  value: !state.pinned,
                });
              }}
            />
          )}

          {managerOrAdmin && (
            <CheckboxComponent
              name="visibilitySettings"
              label={polyglot.t('DocumentFormModal.visibilitySettings')}
              checked={state.visibility?.employee?.hideDocument ?? false}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                updateVisibilityForDocument(target.checked);
                dispatch({
                  kind: 'set_visibility',
                  value: {
                    employee: { hideDocument: target.checked },
                    private: state.visibility?.private ?? undefined,
                  },
                });
              }}
            />
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <CheckboxComponent
              name="privateDocument"
              label={polyglot.t('DocumentFormModal.privateDocument')}
              checked={state.visibility?.private ?? false}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                updateVisibilityForDocument(target.checked);
                dispatch({
                  kind: 'set_visibility',
                  value: {
                    employee: { hideDocument: state.visibility?.employee?.hideDocument ?? false },
                    private: target.checked,
                  },
                });
              }}
            />
            <StyledTooltip title={polyglot.t('DocumentFormModal.privateHelperText')}>
              <InfoGrey {...iconSize} />
            </StyledTooltip>
          </Box>

          <CheckboxComponent
            name="expirySettings"
            label={polyglot.t('DocumentFormModal.expirySettings')}
            checked={!!state?.expirySettings?.documentExpires}
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              updateExpiryForDocument(target.checked, '');
              dispatch({
                kind: 'set_expiry',
                value: { documentExpires: target.checked, expiryDate: '' },
              });
            }}
          />

          {state?.expirySettings?.documentExpires && (
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              label={polyglot.t('DocumentFormModal.expiryDate')}
              name="expiryDate"
              value={state.expirySettings?.expiryDate ? state.expirySettings?.expiryDate : ''}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  updateExpiryForDocument(true, value);
                  dispatch({
                    kind: 'set_expiry',
                    value: { documentExpires: true, expiryDate: value },
                  });
                }
              }}
              error={undefined}
              helperText={undefined}
              sx={{ mt: spacing.mt30 }}
            />
          )}

          {state.fileUuid ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                component="li"
                key={state.fileUuid}
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DocumentIcon {...iconSize} />
                  <Box component="span" sx={{ ...themeFonts.title4, marginLeft: spacing.m10 }}>
                    {pipe(
                      (state.fileName ?? 'placeholder').split('/'),
                      RA.reverse,
                      RA.head,
                      O.getOrElse(() => '')
                    )}
                  </Box>
                </Box>

                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() => dispatch({ kind: 'clear_file' })}
                  title={polyglot.t('General.remove')}
                  disabled={(mode === 'edit' && !hasDocumentsAllScope && !belongsToOnlyYou) || bulkUpload}
                >
                  <TrashIcon {...iconSize} />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: state.fileUuid || mode === 'request' ? 'none' : 'block' }}>
              <UploadInput
                sx={{
                  display:
                    (state?.documentLink && state?.documentLink?.length > 0) || mode === 'request' ? 'none' : 'block',
                }}
                onChange={(
                  response?: DocumentUploadResponse,
                  file?: DocumentUploadInputFile | File | undefined,
                  status?: string
                ) => {
                  if (file && response && response.uuid && response.fileName) {
                    // upload complete
                    dispatch({
                      kind: 'upload_file',
                      value: {
                        fileUuid: response.uuid,
                        fileName: response.fileName,
                      },
                    });
                    dispatch({
                      kind: 'currently_uploading',
                      value: false,
                    });
                  } else if (status === 'preparing') {
                    dispatch({
                      kind: 'prepare_file_for_upload',
                      value: {
                        ...(state.owner ? { owner: state.owner } : {}),
                        ...(state.owners && state.owners?.length > 0 ? { owner: state.owners[0] } : {}),
                      },
                    });
                  } else if (status === 'uploading' || status === 'getting_upload_params') {
                    dispatch({
                      kind: 'currently_uploading',
                      value: true,
                    });
                  } else {
                    dispatch({ kind: 'clear_file' });
                    dispatch({
                      kind: 'currently_uploading',
                      value: false,
                    });
                  }
                }}
                fileOwner={
                  state.owner
                    ? state.owner?.value
                    : state.owners && state.owners?.length > 0
                    ? state.owners[0].value
                    : undefined
                }
              />
            </Box>
          )}

          <Box sx={buttonBoxDrawerSx}>
            {mode === 'edit' ? (
              <Box sx={{ display: state.currentlyUploading ? 'none' : 'flex', gap: 1, width: '100%' }}>
                <ButtonComponent
                  fullWidth
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => {
                    cancelPendingChangesForDocument();
                    setOpen(false);
                  }}
                >
                  {polyglot.t('General.cancel')}
                </ButtonComponent>
                <LoaderButton
                  name={polyglot.t('General.save')}
                  colorVariant="primary"
                  sizeVariant="medium"
                  fullWidth={true}
                  loading={isLoading}
                  disabled={!documentHasOwner(state) || !state?.type}
                />
              </Box>
            ) : (
              <Box
                sx={{ display: state.currentlyUploading ? 'none' : 'flex', width: '100%', justifyContent: 'center' }}
              >
                <LoaderButton
                  fullWidth={true}
                  colorVariant="primary"
                  sizeVariant="medium"
                  name={mode === 'request' ? polyglot.t('DocumentFormModal.request') : polyglot.t('General.save')}
                  loading={isLoading}
                  disabled={mode !== 'request' ? !documentHasOwner(state) || !state?.type : !state?.type}
                />
              </Box>
            )}
          </Box>
        </form>
      </DrawerModal>

      <DrawerModal isOpen={newTypeDrawerIsOpen} setIsOpen={setNewTypeDrawerIsOpen}>
        <DocumentSettingsNewTypeForm
          initialValues={undefined}
          onClose={() => {
            setNewTypeDrawerIsOpen(false);
          }}
          refreshDocumentTypes={() => refreshAllTypes?.()}
          mode="create"
        />
      </DrawerModal>
      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => setIsRemovalModalOpen(false)}
        anchorEl={anchorEl}
        takeAction={removeDocument}
        message={polyglot.t('DocumentFormModal.confirmDelete')}
        callToAction={polyglot.t('General.yes')}
      />
    </>
  );
};
