import axios from 'axios';

import {
  AppCreateUserParams,
  AppIntegrationUserDto,
  AppIntegrationUsersListDto,
  AppsDto,
  GroupMembership,
  InstalledAppDto,
  LaunchpadBarAppDto,
  SuperAdminAppsPageResult,
  UserAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  AppIntegrationStub,
  AppsOverviewStats,
  CalendarDomainConfig,
  CurrentlyDelayedJobs,
  PasswordResetResult,
} from '@/v2/feature/app-integration/app-integration.interface';
import { RequestAlertDto } from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { MappedUserFromAzureAD } from '@/v2/feature/entity-import/wizard/import-wizard.interface';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

const APPS_URL_V1 = '/api';
const APPS_URL_V2 = '/apiv2/apps';

// Add here migrated AppIntegrations that should target the new nest API
const v2Apps = [
  'docusign',
  'github',
  'google-workspace',
  'hubspot',
  'intercom',
  'jumpcloud',
  'jira',
  'microsoft365',
  'lastpass',
  'monday',
  'notion',
  'pipedrive',
  'quickbooks',
  'remote',
  'slack',
  'workable',
  'teamtailor',
  'greenhouse',
  'xero',
  'zoom',
  'deel',
  'azure-ad',
  'oysterhr',
  'lever',
];

const isV2App = (appStub: string): boolean => {
  return v2Apps.includes(appStub);
};

const getApiPrefix = (appStub: string): string => (isV2App(appStub) ? APPS_URL_V2 : APPS_URL_V1);

const getApiUrl = (appStub: string, url: string, params?: Record<string, string>): string => {
  const searchParams = new URLSearchParams(params).toString();
  const path = `${getApiPrefix(appStub)}/${appStub}/${url}`;
  return searchParams ? `${path}?${searchParams}` : path;
};

type OAuthExtraState = {
  okRedirect?: `/${string}`;
};

export class AppIntegrationAPI {
  static getHandleCodeUrl(appStub: string, code: string): string {
    return getApiUrl(appStub, `handle-code${code}`);
  }

  static async getInstalledAppsAsSuperadmin(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    shouldLimitResult?: string;
  }): Promise<SuperAdminAppsPageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/apps/superadmin/installed-apps?${searchParams.toString()}`)).data;
  }

  static async getInstalledApps(): Promise<InstalledAppDto[]> {
    return (await axios.get('/apiv2/apps/install')).data;
  }

  static async getCompanyDomainForApp(appStub: string): Promise<string> {
    return (await axios.get(`/apiv2/apps/${appStub}/company-domain`)).data;
  }

  static async availableApps(): Promise<InstalledAppDto[]> {
    return (await axios.get('/apiv2/apps')).data;
  }

  static async installApps(appStubs: readonly string[]): Promise<void> {
    return (await axios.post('/apiv2/apps/install', appStubs)).data;
  }

  static async expiryCheck(): Promise<void> {
    return (await axios.get('/apiv2/apps/expiryCheck')).data;
  }

  static getInitiateUrl(appStub: string, extraState?: OAuthExtraState): string {
    return getApiUrl(appStub, 'initiate', extraState);
  }

  static getSignUpInitiateUrl(): string {
    return 'apiv2/apps/xero/signup';
  }

  static async listAppUsers(appStub: string): Promise<AppIntegrationUsersListDto> {
    return (await axios.get<AppIntegrationUsersListDto>(getApiUrl(appStub, 'users'))).data;
  }

  static async listAppTeamUsersWithPagination(appStub: string): Promise<AppIntegrationUsersListDto> {
    return (await axios.get<AppIntegrationUsersListDto>(getApiUrl(appStub, 'team-users'))).data;
  }

  static async listAppExternalUsersWithPagination(appStub: string): Promise<AppIntegrationUsersListDto> {
    return (await axios.get<AppIntegrationUsersListDto>(getApiUrl(appStub, 'external-users'))).data;
  }

  static async getGroupMemberships(appStub: string): Promise<GroupMembership[]> {
    return (await axios.get<GroupMembership[]>(getApiUrl(appStub, 'groups'))).data;
  }

  static async getEmployments(appStub: string): Promise<AppIntegrationUsersListDto> {
    return (await axios.get<AppIntegrationUsersListDto>(getApiUrl(appStub, 'employments'))).data;
  }

  static async suspendAppUser(
    appId: string,
    appUserId: string,
    userId: number,
    actionDate?: string,
    cronHour?: number
  ): Promise<void> {
    await axios.post(getApiUrl(appId, `users/${appUserId}/suspend`), { userId, actionDate, cronHour });
  }

  static async resetPasswordForAppUser(
    appId: string,
    appUserId: string,
    userId: number,
    appUserEmail: string,
    appUserName: string
  ): Promise<PasswordResetResult> {
    return (
      await axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/resetPassword`), { appUserEmail, appUserName })
    ).data;
  }

  static async dataTransferThenDeleteForAppUser(
    appId: string,
    localUserId: number | string,
    appUserId: string,
    newAppUserId: string,
    actionDate?: string
  ): Promise<void> {
    await axios.post(getApiUrl(appId, `users/${localUserId}/${appUserId}/${newAppUserId}/dataTransferThenDelete`), {
      actionDate,
    });
  }

  static async activateAppUser(appId: string, appUserId: number, userId: number, actionDate?: Date): Promise<void> {
    await axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/activate`), { actionDate });
  }

  static async deleteAppUser(appId: string, appUserId: string, userId: number, actionDate?: string): Promise<void> {
    await axios.delete(getApiUrl(appId, `users/${userId}/${appUserId}`), {
      data: {
        actionDate,
      },
    });
  }

  static async deleteAppUserFromGroup(
    appId: string,
    appUserId: string,
    userId: number,
    groupId: string
  ): Promise<void> {
    await axios.delete(getApiUrl(appId, `users/${userId}/${appUserId}/group/${groupId}`));
  }

  static async addAppUserToGroup(appId: string, appUserId: string, userId: number, groupId: string): Promise<void> {
    await axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/group/${groupId}`));
  }

  static async revokeToken(appId: string): Promise<void> {
    await axios.post(getApiUrl(appId, `revoke`));
  }

  static async invalidateApp(appId: string): Promise<void> {
    await axios.post(getApiUrl(appId, `invalidateApp`));
  }

  static async assignUser(appId: string, userId: number, emailAddress: string): Promise<void> {
    await axios.patch(getApiUrl(appId, `users/${userId}/assignuser`), { emailAddress });
  }

  static async unassignUser(appId: string, userId: number, emailAddress: string): Promise<void> {
    await axios.patch(getApiUrl(appId, `users/${userId}/unassign-user`), { emailAddress });
  }

  static async importUser(appId: string, appUserId: string): Promise<boolean> {
    return (await axios.post(getApiUrl(appId, `users/${appUserId}/import`)))?.data;
  }

  static async getJobDetailForCandidate(appStub: string, externalId: string): Promise<string> {
    return (await axios.get(`/apiv2/apps/${appStub}/candidate-job-detail/${externalId}`)).data;
  }

  static async createAppUser(
    appId: string,
    userId: number,
    params?: AppCreateUserParams,
    actionDate?: string
  ): Promise<AppIntegrationUserDto> {
    return (
      await axios.post<AppIntegrationUserDto>(getApiUrl(appId, 'users'), {
        userId,
        actionDate,
        params,
      })
    ).data;
  }

  static async createEmployment(appId: string, userId: number, country: string): Promise<AppIntegrationUserDto> {
    return (
      await axios.post<AppIntegrationUserDto>(getApiUrl(appId, 'employments'), {
        userId,
        country,
      })
    ).data;
  }

  static async getLaunchpadApps(): Promise<readonly LaunchpadBarAppDto[]> {
    return (await axios.get<readonly LaunchpadBarAppDto[]>('/apiv2/apps/launchpad')).data;
  }

  static async getUserApps(userId: number): Promise<UserAppDto[]> {
    return (await axios.get<UserAppDto[]>(`/apiv2/apps/users/${userId}`)).data;
  }

  static async cancelScheduledAction(appId: string, jobId: string, userId: number, action: string): Promise<boolean> {
    return (
      await axios.post(getApiUrl(appId, `actions/delayed/cancel`), {
        jobId,
        userId,
        action,
      })
    ).data;
  }

  static async getAlerts(): Promise<Alert<RequestAlertDto>> {
    return (await axios.get('/apiv2/apps/user-alerts')).data;
  }

  static async saveCCEmailList(appStub: string, ccEmailList: string): Promise<void> {
    return await axios.post(getApiUrl(appStub, 'cc-email-list'), { ccEmailList });
  }

  static async getCCEmailList(appStub: string): Promise<string[]> {
    return (await axios.get(getApiUrl(appStub, 'cc-email-list')))?.data;
  }

  static async saveCalendarDomainConfig(selectedDomain: string): Promise<CalendarDomainConfig> {
    return (await axios.patch('/apiv2/google-calendar/domain-name-config', { selectedDomain }))?.data;
  }

  static async getCalendarDomainConfig(): Promise<CalendarDomainConfig> {
    return (await axios.get('/apiv2/google-calendar/domain-name-config'))?.data;
  }

  static async generateHubSpotIdentificationToken(
    email?: string,
    firstName?: string,
    lastName?: string
  ): Promise<string> {
    return (
      await axios.post(getApiUrl('hubspot', 'visitor-id-token'), {
        email,
        firstName,
        lastName,
      })
    )?.data;
  }

  static async createIdMatchingForNewCandidate(
    appStub: AppIntegrationStub,
    newUserId: number,
    externalId: string
  ): Promise<void> {
    await axios.post(getApiUrl(appStub, 'candidate-import'), {
      newUserId,
      externalId,
    });
  }

  static async getCandidateList(appStub: string): Promise<AppIntegrationUsersListDto> {
    return (await axios.get(getApiUrl(appStub, 'candidates')))?.data;
  }

  static async getAppIntegrationDetails(appStub: AppIntegrationStub): Promise<InstalledAppDto> {
    return (await axios.get(`/apiv2/apps/${appStub}`)).data;
  }

  static getUserListForImport = async (appStub: string): Promise<MappedUserFromAzureAD[]> => {
    return (await axios.get(`/apiv2/apps/${appStub}/user-list-for-import`)).data;
  };

  static async disconnectXeroTenant(tenantId: string) {
    return (await axios.delete(`/apiv2/apps/xero/disconnect-tenant/${tenantId}`)).data;
  }

  static accountingAppInitiateTenantConnection(appStub: string) {
    return `/apiv2/apps/${appStub}/accounting-app-initiate`;
  }

  static async appConnectAllowed() {
    return (await axios.get(`/apiv2/apps/allowed`)).data;
  }
}

export class AppIntegrationEndpoints {
  static getSidebarApps(): Endpoint<readonly LaunchpadBarAppDto[]> {
    return {
      url: '/apiv2/apps/launchpad',
    };
  }

  static getAppUsers(appStub: string): Endpoint<AppIntegrationUsersListDto> {
    return {
      url: getApiUrl(appStub, 'users'),
    };
  }

  static getPersonalEmailForAppsByUserId(userId: number, appStub?: string): Endpoint<string> {
    return {
      url: appStub
        ? `/apiv2/apps/user/${userId}/personal-email?appStub=${appStub}`
        : `/apiv2/apps/user/${userId}/personal-email`,
    };
  }

  static getUserApps(userId: number): Endpoint<readonly UserAppDto[]> {
    return {
      url: `/apiv2/apps/users/${userId}`,
    };
  }

  static getInstalledApps(): Endpoint<readonly InstalledAppDto[]> {
    return {
      url: '/apiv2/apps/install',
    };
  }

  static getCalendarApps(hasAppsAll?: boolean): Endpoint<readonly InstalledAppDto[]> {
    return {
      url: hasAppsAll ? '/apiv2/apps/install' : undefined,
    };
  }

  static getAvailableApps(): Endpoint<readonly InstalledAppDto[]> {
    return {
      url: '/apiv2/apps',
    };
  }

  static getAppsOverviewStats(): Endpoint<AppsOverviewStats> {
    return {
      url: '/apiv2/apps/overview/stats',
    };
  }

  static getDelayedAppActionsByStub(appStub: string): Endpoint<CurrentlyDelayedJobs<CreateAppQueue>> {
    return {
      url: `/apiv2/apps/actions/delayed?appStub=${appStub}`,
    };
  }

  static getDelayedAppActionsByUserId(userId: number): Endpoint<CurrentlyDelayedJobs<CreateAppQueue>> {
    return {
      url: `/apiv2/apps/actions/delayed?userId=${userId}`,
    };
  }

  static getAllDelayedAppActions(): Endpoint<CurrentlyDelayedJobs<CreateAppQueue>> {
    return {
      url: `/apiv2/apps/actions/delayed`,
    };
  }

  static getLatestApps(): Endpoint<AppsDto[]> {
    return { url: '/apiv2/apps/:companyId/latest-apps' };
  }

  static getCompanyDomainForApp(appStub: string): Endpoint<string> {
    return { url: `/apiv2/apps/${appStub}/company-domain` };
  }
}
