import { SuperAdminUsersPageResult } from '@v2/feature/user/dtos/user-superadmin.dto';
import {
  EditableUserFamilyMemberDto,
  UserFamilyMemberDto,
} from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { OsUpdateEmailBody } from '@v2/feature/user/features/user-forms/user-os-update-notification/os-update-notification.dto';
import { PeopleDirectoryUserDto, UserInviteResult } from '@v2/feature/user/user-directory.interface';
import axios, { AxiosResponse } from 'axios';

import {
  CurrentUser,
  UserAboutInfo,
  UserAccountInfo,
  UserBasicsInfo,
  UserEmergencyContactInfo,
  UserFamilyInfo,
  UserLifecycleInfo,
  UserOverviewConfig,
  UserWorkContactInfo,
} from '@/models';
import { extractFilters } from '@/v2/feature/super-admin/features/super-admin-billing/helper/extract-filters.helper';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { UpdateUserBasicsInfoDtoAndUserCustomDataDto } from '@/v2/feature/user/features/user-forms/user-basic-info/user-basic-info.dto';
import {
  UpdateUserPersonalInfoDto,
  UserPersonalInfoDto,
} from '@/v2/feature/user/features/user-forms/user-personal-info/user-personal-info.dto';
import { OrgChartProps } from '@/v2/feature/user/pages/components/people-org-view.component';
import {
  LocaleProps,
  MissingField,
  MissingFieldsByDomain,
  PersonalSettingsProps,
  PrivacyProps,
  TableStructure,
  UserBenefitsData,
} from '@/v2/feature/user/user.interface';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import { convertDateFields } from '@/v2/util/date-format.util';

export interface InvitableUser {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export class UserAPI {
  static userCacheResponse: Promise<AxiosResponse<any>> | null = null;

  static getUserAvatarURL(userId: number): string {
    return `/apiv2/users/${userId}/avatar`;
  }

  static async createBulk(bulkUserList: InvitableUser[]): Promise<void> {
    await axios.post('/apiv2/users/bulk', bulkUserList);
  }

  static async getUserAvatar(userId: number): Promise<string> {
    return (await axios.get(this.getUserAvatarURL(userId))).data;
  }

  static async deleteUserAvatar(userId: number): Promise<void> {
    await axios.delete(this.getUserAvatarURL(userId));
  }

  static async patchUserBasicInfo(
    userId: number,
    userBasicsUpdate: UpdateUserBasicsInfoDtoAndUserCustomDataDto
  ): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/basic`, userBasicsUpdate);
  }

  static async getUserBasicInfo(userId: number): Promise<UserBasicsInfo> {
    return (await axios.get(`/apiv2/users/${userId}/basic`)).data;
  }

  static async patchUserPersonalInfo(
    userId: number,
    userPersonalUpdate: Partial<UpdateUserPersonalInfoDto>
  ): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/personal`, userPersonalUpdate);
  }

  static async patchUserFamilyInfo(userId: number, userPersonalFamilyUpdate: UserFamilyInfo): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/family`, userPersonalFamilyUpdate);
  }

  static async patchUserAboutInfo(userId: number, userAboutUpdate: Partial<UserAboutInfo>): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/about`, userAboutUpdate);
  }

  static async patchUserEmergencyContactInfo(
    userId: number,
    userEmergencyContactUpdate: Partial<UserEmergencyContactInfo>
  ): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/emergency-contact`, userEmergencyContactUpdate);
  }

  static async patchUserWorkContactInfo(userId: number, userWorkContactUpdate: UserWorkContactInfo): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/work-contact`, userWorkContactUpdate);
  }

  static async getUserLifecycleInfo(userId: number): Promise<UserLifecycleInfo> {
    return (await axios.get(`/apiv2/users/${userId}/lifecycle`)).data;
  }

  static async updateEmployeeId(
    userId: number,
    employeeIdUpdate: Pick<UserLifecycleInfo, 'employeeId'>
  ): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/lifecycle/employeeId`, employeeIdUpdate);
  }

  static async getAllUsersAsSuperAdmin(param?: {
    page?: string;
    pageSize?: string;
    searchQuery?: string;
    shouldLimitResult?: string;
    filter?: string;
  }): Promise<SuperAdminUsersPageResult> {
    const filters = extractFilters(param?.filter || '');
    let searchParams;

    if (param?.filter && filters) {
      // Use the extracted filters instead
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filter, ...otherParams } = param;
      searchParams = new URLSearchParams({ ...otherParams, ...filters });
    } else {
      searchParams = new URLSearchParams(param);
    }
    return (await axios.get(`/apiv2/users/superadmin?${searchParams.toString()}`)).data;
  }

  static async getUserSuperadminBasicByCompany(companyId: number): Promise<UserBasicsInfo[]> {
    return (await axios.get(`/apiv2/users/superadmin/${companyId}/basic`)).data;
  }

  // TODO: @user-endpoint - this should be entirely replaced
  static async inviteUser(
    userId: number,
    mode: string
  ): Promise<{ success: boolean; message: string; emailAddresses?: string[] }> {
    return (await axios.post(`/apiv2/users/${userId}/invite?mode=${mode}`)).data;
  }

  static async getUserPersonalInfo(userId: number): Promise<UserPersonalInfoDto> {
    const { data } = await axios.get(`/apiv2/users/${userId}/personal`);
    data.dob = data.dob && data.dob.split('T')[0];
    return data;
  }

  static async getUserAbout(userId: number): Promise<UserAboutInfo> {
    return (await axios.get(`/apiv2/users/${userId}/about`)).data;
  }

  /**
   * Fetches all users to cache.
   * @param useCache whether to bypass the cached version of the result of this api.
   * @param shouldRethrow Whether to rethrow errors, default true. As this api call is now used asynchronously,
   * errors can be unhandled. Only set false if you want to run in the background.
   */
  static async fetchUserCache(useCache = true, shouldRethrow = true): Promise<CachedUser[]> {
    try {
      if (!this.userCacheResponse || !useCache) {
        this.userCacheResponse = axios.get('/apiv2/users/cache');
        this.userCacheResponse.catch(() => {
          this.userCacheResponse = null;
        });
      }

      return (await this.userCacheResponse).data;
    } catch (error) {
      this.userCacheResponse = null;
      if (shouldRethrow) throw error;
      return [];
    }
  }

  static async getUserEmergencyContact(userId: number): Promise<UserEmergencyContactInfo> {
    return (await axios.get(`/apiv2/users/${userId}/emergency-contact`)).data;
  }

  static async getUserFamilyInfo(userId: number): Promise<UserFamilyInfo> {
    return (await axios.get(`/apiv2/users/${userId}/family`)).data;
  }

  static async getUserWorkContactInfo(userId: number): Promise<UserWorkContactInfo> {
    return (await axios.get(`/apiv2/users/${userId}/work-contact`)).data;
  }

  static async addUserFamilyMember(userId: number, data: EditableUserFamilyMemberDto): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/family/members`, data);
  }

  static async updateUserFamilyMemberById(
    userId: number,
    memberId: number,
    update: EditableUserFamilyMemberDto
  ): Promise<void> {
    await axios.put(`/apiv2/users/${userId}/family/members/${memberId}`, update);
  }

  static async deleteUserFamilyMemberById(userId: number, memberId: number): Promise<void> {
    await axios.delete(`/apiv2/users/${userId}/family/members/${memberId}`);
  }

  static async getUserAccountInfo(userId: number): Promise<UserAccountInfo> {
    const { data } = await axios.get<UserAccountInfo>(`/apiv2/users/${userId}/account-info`);
    return convertDateFields(data, ['createdAt', 'updatedAt', 'lastTimeOnline']);
  }

  static async getUserSummaryForOnboardingById(
    userId: number,
    includeReports = true
  ): Promise<
    UserSummaryDto & {
      personalEmail: string | null;
    }
  > {
    const opts = { params: { reports: includeReports } };
    return (await axios.get(`/apiv2/users/${userId}/summary-for-onboarding`, opts)).data;
  }

  static async getAlerts(userId: number): Promise<Alert<MissingField>> {
    return (await axios.get(`/apiv2/users/${userId}/alerts`)).data;
  }

  // WARNING: Don't forget to update the global user each time you use this
  static async updateOwnUserFeatures(
    domain: string,
    subdomain: string,
    featureName: string,
    value: boolean | string[] | string | undefined
  ): Promise<CurrentUser> {
    return (await axios.patch(`/apiv2/users/features/${domain}/${subdomain}/${featureName}`, { value })).data;
  }

  static exportPeopleDirectory(): string {
    return '/apiv2/users/filtered';
  }

  static async exportSelectedPeopleDirectory(selectedUserIds: number[]): Promise<string> {
    return (await axios.post('/apiv2/users/export-selected', { selectedUserIds })).data;
  }

  static async inviteSelectedPeople(selectedUserIds: number[]): Promise<UserInviteResult> {
    return (await axios.post('/apiv2/users/invite-selected', { selectedUserIds })).data;
  }

  static async getUserLocaleSettings(): Promise<LocaleProps> {
    return (await axios.get(`/apiv2/users/locale`)).data;
  }

  static async patchUserLocaleSettings(userUpdate: LocaleProps): Promise<void> {
    await axios.patch(`/apiv2/users/locale`, userUpdate);
  }

  static async patchUserPersonalSettings(userUpdate: PrivacyProps): Promise<void> {
    await axios.patch(`/apiv2/users/privacy`, userUpdate);
  }

  static async exportReportById(): Promise<any> {
    return (await axios.get(`/apiv2/users/stats/orgchart-csv`)).data;
  }

  static async sendOsVersionUpdateNotification(update: OsUpdateEmailBody): Promise<void> {
    return (await axios.post(`/apiv2/users/notifications/os-update-notification`, update))?.data;
  }
}

export class UserEndpoints {
  static getUserAvatarURL(userId: number): Endpoint<string> {
    return {
      url: `/apiv2/users/${userId}/avatar`,
    };
  }

  static getAllUsersDirectoryV2(filterString: string): Endpoint<PeopleDirectoryUserDto[]> {
    return {
      url: `/apiv2/users/user-directory-v2?${filterString}`,
    };
  }

  static getUserSummaryById(userId: number, includeReports = true): Endpoint<UserSummaryDto> {
    return {
      url: `/apiv2/users/${userId}/summary?reports=${includeReports}`,
    };
  }

  static getUserPersonalInfo(userId: number): Endpoint<UserPersonalInfoDto> {
    return {
      url: `/apiv2/users/${userId}/personal`,
    };
  }

  static getUserBasicInfo(userId: number): Endpoint<UserBasicsInfo> {
    return {
      url: `/apiv2/users/${userId}/basic`,
    };
  }

  static getOrgChartUsers(): Endpoint<OrgChartProps[]> {
    return {
      url: '/apiv2/users/stats/orgchart',
    };
  }

  static getUserOverviewConfig(userId: number): Endpoint<UserOverviewConfig> {
    return {
      url: `/apiv2/users/${userId}/overview/config`,
    };
  }

  static getUserMissingFields(userId: number): Endpoint<MissingFieldsByDomain> {
    return {
      url: `/apiv2/users/${userId}/missing-fields`,
    };
  }

  static getBulkDirectReports(listOfUserIds: number[]): Endpoint<any> {
    return {
      url: `/apiv2/users/bulk-direct-reports?userList=${listOfUserIds?.join(',')}`,
    };
  }

  static getUserAbout(userId: number, hasScope: boolean): Endpoint<UserAboutInfo> {
    return {
      url: hasScope ? `/apiv2/users/${userId}/about` : undefined,
    };
  }

  static getUserFamily(userId: number): Endpoint<UserFamilyInfo> {
    return {
      url: `/apiv2/users/${userId}/family`,
    };
  }

  static getUserFamilyMembers(userId: number): Endpoint<UserFamilyMemberDto[]> {
    return {
      url: `/apiv2/users/${userId}/family/members`,
    };
  }

  static getUserFamilyMembersAsSuperAdmin(userId: number): Endpoint<UserFamilyMemberDto[]> {
    return {
      url: `/apiv2/users/${userId}/family/superadmin/members`,
    };
  }

  static getUserBenefitsPeopleData(): Endpoint<{
    usersData: UserBenefitsData[];
    tableStructure: TableStructure;
  }> {
    return {
      url: '/apiv2/user-benefits',
    };
  }

  static getUserLifecycleInfo(userId: number): Endpoint<UserLifecycleInfo> {
    return {
      url: `/apiv2/users/${userId}/lifecycle`,
    };
  }

  static getPersonalSettings(): Endpoint<PersonalSettingsProps> {
    return {
      url: '/apiv2/users/personal-settings',
    };
  }

  static getUserWorkContactInfo(userId: number): Endpoint<UserWorkContactInfo> {
    return {
      url: `/apiv2/users/${userId}/work-contact`,
    };
  }

  static getUserEmergencyContact(userId: number): Endpoint<UserEmergencyContactInfo> {
    return {
      url: `/apiv2/users/${userId}//emergency-contact`,
    };
  }
}
