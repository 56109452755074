import { useEffect, useState } from 'react';

import { Box, IconButton, useMediaQuery } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { PaginationState } from '@tanstack/react-table';
import { DEFAULT_PAGE_SIZE } from '@v2/components/table/server-side-table.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ReactComponent as Next } from '@/images/side-bar-icons/NextBtn.svg';
import { iconSize } from '@/v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/scss/components/pagination-detail.scss';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const PaginationDetail = ({
  totalPageCount,
  totalRecordCount,
  current,
  onNextAction,
  nextDisabled,
  onPreviousAction,
  previousDisabled,
  paginationState,
  setPaginationState,
  handleState,
  showCompact = false,
  paginationSx,
}: {
  readonly totalPageCount: number;
  readonly totalRecordCount: number;
  readonly current: number;
  readonly onNextAction: () => void;
  readonly nextDisabled: boolean;
  readonly onPreviousAction: () => void;
  readonly previousDisabled: boolean;
  readonly paginationState: PaginationState;
  readonly setPaginationState: (pageSize: number, pageIndex: number) => void;
  readonly handleState: (page: number) => void;
  readonly showCompact?: boolean;
  readonly paginationSx?: SxProps<Theme> | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [currenPageIndex, setCurrentPageIndex] = useState(current);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pageValidation =
      Number(e.target.value) > totalPageCount
        ? totalPageCount
        : Number(e.target.value) < 1
        ? 1
        : Number(e.target.value);
    setCurrentPageIndex(pageValidation);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is Enter
    if (e.key === 'Enter') {
      const page = currenPageIndex ? Number(currenPageIndex) : 0;
      handleState(page);
    }
  };

  useEffect(() => {
    setCurrentPageIndex(current);
  }, [current]);

  const currentPageEndPageSize = (current - 1) * paginationState.pageSize + paginationState.pageSize;
  const currentPageRangeEnd = currentPageEndPageSize > totalRecordCount ? totalRecordCount : currentPageEndPageSize;
  const currentPageStartPageSize = (current - 1) * paginationState.pageSize;
  const currentPageRangeStart = currentPageStartPageSize + 1;

  const getNewPageIndex = (pageSize: number) => {
    const newPageIndex = Math.ceil(currentPageRangeStart / pageSize);
    const totalPages = Math.ceil(totalRecordCount / pageSize);
    return newPageIndex >= totalPages ? totalPages : newPageIndex <= 1 ? 1 : newPageIndex;
  };

  const getActions = () => {
    return [
      {
        handler: () => setPaginationState(20, getNewPageIndex(20)),
        label: polyglot.has('PaginationDetail.20') ? polyglot.t('PaginationDetail.20') : '20',
        disabled: false,
      },
      {
        label: polyglot.has('PaginationDetail.50') ? polyglot.t('PaginationDetail.50') : '50',
        handler: () => setPaginationState(50, getNewPageIndex(50)),
        disabled: false,
      },
      {
        label: polyglot.has('PaginationDetail.100') ? polyglot.t('PaginationDetail.100') : '100',
        handler: () => setPaginationState(100, getNewPageIndex(100)),
        disabled: false,
      },
    ];
  };

  return totalRecordCount > 0 ? (
    totalRecordCount > DEFAULT_PAGE_SIZE ? (
      <Box
        className="pagination-sticky-strip"
        sx={{
          gap: showCompact || isMobileScreen ? spacing.g10 : spacing.g20,
          ...paginationSx,
        }}
      >
        {!(showCompact || isMobileScreen) && (
          <Typography variant="caption">
            {currentPageRangeStart} - {currentPageRangeEnd}{' '}
            {polyglot.has('PaginationDetail.of') ? polyglot.t('PaginationDetail.of') : 'of'} {totalRecordCount}
          </Typography>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          {!(showCompact || isMobileScreen) && (
            <Typography variant="caption">
              {' '}
              {polyglot.has('PaginationDetail.itemsPerPage')
                ? polyglot.t('PaginationDetail.itemsPerPage')
                : 'objects per page'}
            </Typography>
          )}
          <StyledMenuComponent
            options={getActions()}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: paginationState.pageSize.toString(),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: themeColors.Background,
            color: themeColors.DarkGrey,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: radius.br15,
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={onPreviousAction}
            title="Previous"
            disabled={previousDisabled}
            sx={{ ...iconCTAButtonSx, borderTopLeftRadius: radius.br15, borderRadius: radius.br15 }}
          >
            <Back {...iconSize} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: spacing.g5 }}>
            <Typography variant="caption">
              {polyglot.has('PaginationDetail.page') ? polyglot.t('PaginationDetail.page') : 'Page'}
            </Typography>
            <input
              type="number"
              value={currenPageIndex}
              onKeyDown={onEnterPress}
              onChange={onChange}
              className="pagination-input"
            />
            <Typography variant="caption">
              {' '}
              {polyglot.has('PaginationDetail.of') ? polyglot.t('PaginationDetail.of') : 'of'} {totalPageCount}
            </Typography>
          </Box>
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={onNextAction}
            disabled={nextDisabled}
            title="Next"
            sx={{ ...iconCTAButtonSx, borderTopRightRadius: radius.br15, borderRadius: radius.br15 }}
          >
            <Next {...iconSize} />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box
        className="pagination-sticky-strip"
        sx={{
          gap: showCompact ? spacing.g10 : spacing.g20,
          ...paginationSx,
        }}
      >
        <Typography variant="caption">
          {currentPageRangeStart} - {currentPageRangeEnd}{' '}
          {polyglot && polyglot.has('PaginationDetail.of') ? polyglot.t('PaginationDetail.of') : 'of'}{' '}
          {totalRecordCount}{' '}
          {polyglot && polyglot.has('PaginationDetail.records') ? polyglot.t('PaginationDetail.records') : 'records'}
        </Typography>
      </Box>
    )
  ) : (
    <></>
  );
};
