import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ChecklistDto } from '@v2/feature/task/subfeature/checklist/checklist.dto';
import { emojiOptions } from '@v2/feature/task/subfeature/checklist.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ChecklistFormDrawer } from '@/v2/feature/task/subfeature/checklist/components/checklist-form-drawer.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ChecklistsPage = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();

  const [checkLists, setChecklists] = useState<readonly ChecklistDto[]>([]);
  const [newChecklistDrawer, setNewChecklistDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const checkLists = await TaskAPI.getChecklists();
      setChecklists(checkLists);
    } catch (error) {
      showMessage(`${polyglot.t('ChecklistsPage.errorMessages.load')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    trackPage('Company checklists');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={polyglot.t('ChecklistsPage.checklists')}
        showAction={true}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setNewChecklistDrawer(true)}>
            {polyglot.t('ChecklistsPage.new')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={loading}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexFlow: { sm: 'column', md: 'column', lg: 'row' },
            justifyContent: 'space-between',
            gap: spacing.g40,
          }}
        >
          <Box sx={{ maxWidth: '600px', width: '100%', display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
            {checkLists.length > 0 ? (
              checkLists.map((checklist, i) => (
                <IconContentActionCard
                  key={checklist.id}
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                      <Typography variant="title4">
                        {emojiOptions.find((emo) => emo.value === checklist.bgImg)?.label ?? checklist.bgImg ?? '🗒️'}{' '}
                        {checklist?.name ?? ''}
                      </Typography>
                      <Box
                        sx={{
                          background: themeColors.Background,
                          paddingY: spacing.p5,
                          paddingX: spacing.p10,
                          borderRadius: radius.br15,
                          flexShrink: 0,
                        }}
                      >
                        <Typography variant="caption">{`${checklist?.checklistItems?.length ?? 0} task${
                          checklist?.checklistItems?.length === 1 ? '' : 's'
                        }`}</Typography>
                      </Box>
                    </Box>
                  }
                  action={
                    <ButtonComponent
                      fullWidth
                      sizeVariant="small"
                      colorVariant="secondary"
                      onClick={() =>
                        routerHistory.push(
                          generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: checklist.id })
                        )
                      }
                    >
                      {polyglot.t('ChecklistsPage.details')}
                    </ButtonComponent>
                  }
                  showDivider={i < checkLists.length - 1}
                />
              ))
            ) : (
              <Typography variant="caption">{polyglot.t('ChecklistsPage.noChecklists')}</Typography>
            )}
          </Box>
        </Box>

        <DrawerModal isOpen={newChecklistDrawer} setIsOpen={setNewChecklistDrawer}>
          <ChecklistFormDrawer
            checklist={null}
            onClose={async () => {
              setNewChecklistDrawer(false);
              await refresh();
            }}
          />
        </DrawerModal>
      </ContentWrapper>
    </RootStyle>
  );
};
