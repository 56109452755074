import { ReviewerTypes, TimelineSettings } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SurveyTimelineSettings } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';

export function checkDeadlines(timelineSettings: TimelineSettings, reviewerSelect: ReviewerTypes[]): boolean {
  for (const reviewerType of reviewerSelect) {
    switch (reviewerType) {
      case ReviewerTypes.Manager:
        if (!timelineSettings.managerReviewDeadline) return false;
        break;
      case ReviewerTypes.Self:
        if (!timelineSettings.selfReviewDeadline) return false;
        break;
      case ReviewerTypes.Peer:
        if (!timelineSettings.peerReviewDeadline) return false;
        break;
      case ReviewerTypes.Upward:
        if (!timelineSettings.upwardReviewDeadline) return false;
        break;
      default:
        console.warn(`Unhandled reviewer type: ${reviewerType}`);
        return false;
    }
  }
  return true;
}

export function isTimingCorrectlySet(timelineSettings: SurveyTimelineSettings): boolean {
  if (!timelineSettings.startNow) {
    return (
      Boolean(timelineSettings.startTime) &&
      Boolean(timelineSettings.startDate) &&
      Boolean(timelineSettings.startTimeMeridiem)
    );
  }
  return true;
}

export function isCurrentDateTimeLessThanOrEqualToSpecified(dateTimeSpec: SurveyTimelineSettings) {
  const { startDate, startTime, startTimeMeridiem } = dateTimeSpec;
  if (!startDate || !startTime || !startTimeMeridiem) return false;
  let hour24 = parseInt(startTime, 10);
  if (startTimeMeridiem.toUpperCase() === 'PM' && hour24 < 12) {
    hour24 += 12;
  } else if (startTimeMeridiem.toUpperCase() === 'AM' && hour24 === 12) {
    hour24 = 0;
  }
  const specificDateTime = new Date(
    Date.UTC(
      new Date(startDate).getUTCFullYear(),
      new Date(startDate).getUTCMonth(),
      new Date(startDate).getUTCDate(),
      hour24,
      0,
      0
    )
  );
  const currentDateTime = new Date().getTime();
  return currentDateTime <= specificDateTime.getTime();
}
