import { useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { CategoryFilters } from '@v2/components/table/category-filters.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortString } from '@v2/components/table/table-sorting.util';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { DeferEnrollmentDrawer } from '@v2/feature/benefits/subfeature/pension/components/defer-enrollment-drawer.component';
import { ManageEmployeeMembershipDrawer } from '@v2/feature/benefits/subfeature/pension/components/manage-employee-membership-drawer.component';
import { PensionSchemeDto, UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { EmployeePensionEnrollmentStatus } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import { getLastActionFromReport } from '@v2/feature/benefits/subfeature/pension/pension.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx, tablePrimaryIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as WaitingFull } from '@/images/side-bar-icons/WaitingFull.svg';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { PensionProviderCell } from '@/v2/feature/benefits/subfeature/pension/components/pension-provider-cell.component';
import { themeColors } from '@/v2/styles/colors.styles';

export function isLessThanXDaysInFuture(referenceDate: Date, targetDate: Date, Xdays: number): boolean {
  const XDaysInMs = Xdays * 24 * 60 * 60 * 1000;
  const timestampXDaysInFuture = new Date(referenceDate).getTime() + XDaysInMs;
  return timestampXDaysInFuture > targetDate.getTime();
}

// TODO: @polyglot-later-staffology-pension
const TabFilter = [
  { name: 'All', value: 'All' },
  { name: 'Enrolled', value: 'Enrolled' },
  { name: 'Not enrolled', value: 'NotEnrolled' },
];

interface EmployeeMembershipsProps {
  readonly userPensions: readonly UserPensionDto[];
  readonly companyPensions: readonly PensionSchemeDto[];
  readonly refreshMemberships: () => Promise<void>;
}

export const EmployeeMemberships = ({
  userPensions,
  companyPensions,
  refreshMemberships,
}: EmployeeMembershipsProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeferralDrawerOpen, setIsDeferralDrawerOpen] = useState(false);
  const [selectedUserPension, setSelectedUserPension] = useState<UserPensionDto | null>(null);

  const params = useParams<{ pensionSchemeId: string }>();

  const pensionScheme = useMemo(() => {
    return params.pensionSchemeId ? companyPensions.find((p) => p.id === Number(params.pensionSchemeId)) ?? null : null;
  }, [params, companyPensions]);

  const [filterValue, setFilterValue] = useState<string>('All');
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  const pensionsByExternalId = useMemo(() => {
    return keyBy(companyPensions, 'externalId');
  }, [companyPensions]);

  const entityFilterOptions = useMemo(() => {
    const payrollIds = [...new Set(companyPensions.map((p) => p.payrollId))];
    // !! this is a mapping for payrollId => CompanyEntity name, don't use it as entityId => CompanyEntity name
    return payrollIds.map((payrollId) => {
      const pension = companyPensions.find((p) => p.payrollId === payrollId);
      return {
        label: pension?.payroll?.entity?.legalName ?? `PayrollID ${payrollId}`,
        value: payrollId,
      };
    });
  }, [companyPensions]);

  const { getCachedUserById } = useCachedUsers();

  const filteredMemberships = useMemo(() => {
    const payrollsWithPensions = new Set(companyPensions.map((p) => p.payrollId));
    let filteredMemberships = [...userPensions].filter(
      (p) =>
        payrollsWithPensions.has(p.payrollId) &&
        (!pensionScheme || !p.inPension || p.pensionProviderId === pensionScheme.externalId)
    );

    if (filterValue !== 'All') {
      filteredMemberships = filteredMemberships.filter((membership) => {
        if (filterValue === 'Enrolled') return membership?.inPension;
        if (filterValue === 'NotEnrolled') return !membership?.inPension;
        return true;
      });
    }

    if (entityFilterOptions && filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions.entity) {
        filteredMemberships = filteredMemberships.filter(
          (m) => m.payrollId && filterOptions.entity.includes(String(m.payrollId))
        );
      }
    }

    if (searchInput) {
      filteredMemberships = filteredMemberships.filter((membership) => {
        const search = searchInput.toLowerCase();
        const user = getCachedUserById(membership.userId);

        const pensionProvider = membership.pensionProviderId
          ? pensionsByExternalId[membership.pensionProviderId]
          : null;

        return (
          (membership?.pensionProviderName && membership.pensionProviderName.toLowerCase().includes(search)) ||
          (membership?.payroll?.entity?.legalName &&
            membership?.payroll?.entity?.legalName.toLowerCase().includes(search)) ||
          (user && user.displayName.toLowerCase().includes(search)) ||
          (pensionProvider?.displayName && pensionProvider?.displayName.toLowerCase().includes(search))
        );
      });
    }

    return filteredMemberships;
  }, [
    companyPensions,
    userPensions,
    filterValue,
    entityFilterOptions,
    filterString,
    searchInput,
    pensionScheme,
    getCachedUserById,
    pensionsByExternalId,
  ]);

  const currentUserColumn = useMemo<ColumnDef<UserPensionDto, UserPensionDto>[]>(
    () => [
      {
        header: () => 'Name',
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pensionProviderName),
        cell: ({ row: { original } }) => {
          const user = original.userId ? getCachedUserById(original.userId) : null;
          return user ? <div>{polyglot.t(user.displayName)}</div> : <EmptyCell />;
        },
      },
      {
        header: () => 'Scheme',
        id: 'enrolledIn',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pensionProviderName),
        cell: ({ row: { original } }) =>
          original.pensionProviderName ? (
            <PensionProviderCell providerName={original.pensionProviderName} />
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Display name',
        id: 'displayName',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => {
            const pensionProvider = item.pensionProviderId ? pensionsByExternalId[item.pensionProviderId] : null;
            return pensionProvider?.displayName ? pensionProvider.displayName : null;
          }),
        cell: ({ row: { original } }) => {
          const pensionProvider = original.pensionProviderId ? pensionsByExternalId[original.pensionProviderId] : null;
          return pensionProvider?.displayName ? <Box>{pensionProvider.displayName}</Box> : <EmptyCell />;
        },
      },
      {
        header: () => 'Entity',
        id: 'entity',
        cell: ({ row: { original } }) =>
          original.payrollId ? (
            <div>
              {original?.payroll?.entity?.legalName
                ? polyglot.t(original?.payroll?.entity?.legalName)
                : `PayrollID: ${original.payrollId}`}
            </div>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Auto-enrollment State',
        id: 'aeState',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.lastAssessment?.employee?.metadata.aeState),
        cell: ({ row: { original } }) => (
          <div>{original?.lastAssessment?.employee?.metadata.aeState ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Eligibility',
        id: 'eligibility',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.lastAssessment?.status),
        cell: ({ row: { original } }) => <div>{original?.lastAssessment?.status ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Last Action',
        id: 'lastAction',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getLastActionFromReport(item?.lastAssessment)),
        cell: ({ row: { original } }) => {
          const lastAction = getLastActionFromReport(original?.lastAssessment);
          return lastAction ? (
            <StyledTooltip title={lastAction} placement="top">
              <Box sx={{ width: '150px' }}>{lastAction}</Box>
            </StyledTooltip>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Status',
        id: 'status',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) =>
            item?.inPension ? EmployeePensionEnrollmentStatus.Enrolled : EmployeePensionEnrollmentStatus.NotEnrolled
          ),
        cell: ({ row: { original } }) => {
          const isInPension = !!original?.inPension;
          const isScheduledForRemoval =
            !isInPension &&
            original?.lastAssessment &&
            new Date(original.lastAssessment.assessmentDate).getTime() > Date.now();

          return (
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
              {isInPension ? (
                <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
              ) : isScheduledForRemoval ? (
                <WaitingFull {...iconSize} style={{ fill: themeColors.YellowPress }} />
              ) : (
                <WaitingFull {...iconSize} />
              )}
              {isInPension
                ? EmployeePensionEnrollmentStatus.Enrolled
                : isScheduledForRemoval
                ? `Leaving on ${new LocalDate(original!.lastAssessment!.assessmentDate).toDateString()}`
                : EmployeePensionEnrollmentStatus.NotEnrolled}
            </Box>
          );
        },
      },
      {
        header: () => 'Worker Group',
        id: 'workerGroupName',
        cell: ({ row: { original } }) => {
          return original.workerGroupName !== null ? <div>{original.workerGroupName}</div> : <EmptyCell />;
        },
      },
      {
        header: () => 'Employer',
        id: 'employerContribution',
        cell: ({ row: { original } }) => {
          return original.employerContribution !== null ? <div>{original.employerContribution}%</div> : <EmptyCell />;
        },
      },
      {
        header: () => 'Employee',
        id: 'employeeContribution',
        cell: ({ row: { original } }) => {
          return original.employeeContribution !== null ? <div>{original.employeeContribution}%</div> : <EmptyCell />;
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        size: 75,
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.userId);
          const userStartDate = user?.startDate ? new Date(user.startDate) : new Date();
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                alignItems: 'center',
                gap: spacing.gap5,
              }}
            >
              {!original.inPension && (
                <StyledMenuComponent
                  options={[
                    {
                      handler: () => {
                        setSelectedUserPension(original);
                        setIsDeferralDrawerOpen(true);
                      },
                      label: 'Defer enrollment',
                      // disabled if 90 days passed from the start date or if the user was already deferred/enrolled once
                      disabled: Boolean(
                        !isLessThanXDaysInFuture(userStartDate, new Date(), 90) ||
                          original.pensionProviderId ||
                          original.pensionProviderName ||
                          original.startDate ||
                          original.scheduledForEnrollment
                      ),
                    },
                  ]}
                  actionButtonDetails={{
                    type: 'iconButton',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: 'actions',
                    icon: <ActionsSmall {...iconSize} />,
                  }}
                />
              )}

              <IconButton
                disableRipple
                sx={!original.inPension ? tablePrimaryIconButtonSx : tableIconButtonSx}
                onClick={() => {
                  setSelectedUserPension(original);
                  setIsDrawerOpen(true);
                }}
              >
                {!original.inPension ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [getCachedUserById, pensionsByExternalId, polyglot]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
          <TabFilterButtons
            filters={TabFilter}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            onFilterChange={({ filterValue }) => {
              setFilterValue(filterValue);
            }}
          />

          <CategoryFilters
            filterTypes={{ entity: entityFilterOptions }}
            setFilterString={setFilterString}
            filterString={filterString}
          />
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
      </Box>

      <Box sx={{ marginTop: spacing.m15 }}>
        <BasicTable rowData={filteredMemberships} columnData={currentUserColumn} hidePagination />
      </Box>

      {isDrawerOpen && selectedUserPension && (
        <ManageEmployeeMembershipDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          userPension={selectedUserPension}
          setUserPension={setSelectedUserPension}
          pensionSchemes={companyPensions}
          onClose={() => setSelectedUserPension(null)}
          refresh={refreshMemberships}
        />
      )}

      {isDeferralDrawerOpen && selectedUserPension && (
        <DeferEnrollmentDrawer
          isOpen={isDeferralDrawerOpen}
          setIsOpen={setIsDeferralDrawerOpen}
          userPension={selectedUserPension}
          onClose={() => setSelectedUserPension(null)}
          refresh={refreshMemberships}
        />
      )}
    </>
  );
};
