import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { DEBOUNCE_500_MS } from '@v2/feature/documents/documents.util';
import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';

import { CompensationAPI, CompensationEndpoints } from '@/api-client/compensation.api';
import { UserContractEndpoints } from '@/api-client/contracts.api';
import { getUserCompensationSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { displayRateLabel, PaySchedules, SalaryBasis } from '@/lib/employment';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { payScheduleUnit } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import {
  CompensationBreakdown,
  PayScheduleEnum,
  SalaryBasisEnum,
  UserCompensationDto,
  UserCompensationForBreakdown,
  UserCompensationWithBreakdown,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { originalValueOrUndefined } from '@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { popularCurrencyShortOptions } from '@/v2/infrastructure/currency/currency.interface';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatAsCompensationCurrency } from '@/v2/util/string-format.util';

type NewEmployeeCompensationSectionProps = {
  compensation?: UserCompensationWithBreakdown | null;
  onNext: () => void;
  sx?: SxProps<Theme>;
  userId: number;
  startDate: string;
};

export const NewEmployeeCompensationSection = ({
  compensation,
  onNext,
  sx,
  userId,
  startDate,
}: NewEmployeeCompensationSectionProps) => {
  const { polyglot } = usePolyglot();

  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = compensation ? 'edit' : 'add';
  const { data: userFtePercentage } = useApiClient(CompensationEndpoints.getFTEPercentageForUser(userId), {
    suspense: false,
  });
  const { data: currentContract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), {
    suspense: false,
  });

  const availableUserFtePercentage = useMemo(() => {
    if (userFtePercentage && !isNaN(userFtePercentage)) return userFtePercentage;
    return 0;
  }, [userFtePercentage]);

  const initialValues = useMemo(() => {
    return {
      effectiveDate: compensation?.effectiveDate ?? new LocalDate(startDate).toDateString(),
      paySchedule: compensation?.paySchedule ?? PayScheduleEnum.Monthly,
      salaryBasis: compensation?.salaryBasis ?? SalaryBasisEnum.Annual,
      currency: compensation?.currency ?? currentContract?.entity?.currency ?? DEFAULT_CURRENCY,
      rate: compensation?.prorateSalaryByFte ? compensation.nonProratedRate ?? 0 : compensation?.rate ?? 0,
      units: compensation?.units,
      prorateSalaryByFte: compensation?.prorateSalaryByFte ?? false,
      proratedRate: compensation ? (compensation?.prorateSalaryByFte ? compensation?.rate ?? 0 : 0) : 0,
      nonProratedRate: compensation?.nonProratedRate ?? undefined,
      annualSalary: compensation?.compensationBreakdown?.fixedRates?.annual,
      salaryPerPaySchedule: compensation?.compensationBreakdown?.payScheduleRate,
      customUpdates: compensation?.customUpdates ?? [],
    };
  }, [compensation, startDate, currentContract]);

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: getUserCompensationSchema(polyglot),
    onSubmit: async (values) => {
      setSaving(true);
      try {
        const updatedValues = {
          ...values,
          units: [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(formik.values?.salaryBasis!)
            ? values.units
            : 0,
          rate: formik.values.prorateSalaryByFte ? formik.values.proratedRate ?? null : formik.values.rate,
          nonProratedRate: formik.values.rate ?? undefined,
        } as UserCompensationDto;
        delete updatedValues.proratedRate;

        if (compensation) {
          await CompensationAPI.updateById(userId, {
            ...updatedValues,
            id: compensation.id,
          });
        } else {
          await CompensationAPI.create(userId, {
            ...updatedValues,
          });
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t('NewEmployeeCompensationSection.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const onChangeRate = useCallback(
    (objToCheck: Partial<UserCompensationDto>) => {
      if (objToCheck) {
        const { rate, prorateSalaryByFte } = objToCheck;
        const rateValue = rate ?? 0;
        const proratedRate =
          prorateSalaryByFte && availableUserFtePercentage ? availableUserFtePercentage * rateValue : rateValue;

        formik.setFieldValue('rate', rate, true);
        formik.setFieldValue('proratedRate', originalValueOrUndefined(proratedRate), true);
      }
    },
    [availableUserFtePercentage, formik]
  );

  const proratedSuffix = useMemo(() => {
    return formik.values.prorateSalaryByFte && availableUserFtePercentage
      ? '- Prorated'.concat(' (' + availableUserFtePercentage * 100 + '%)')
      : '';
  }, [formik.values.prorateSalaryByFte, availableUserFtePercentage]);

  const hasSubmitted = formik.submitCount > 0;
  const modalUnitLabel = (objToCheck: Pick<UserCompensationDto, 'salaryBasis'>) => {
    return objToCheck?.salaryBasis === 'Daily'
      ? polyglot.t('NewEmployeeCompensationSection.days')
      : polyglot.t('NewEmployeeCompensationSection.hours');
  };

  const modalSalaryPerLabel = (objToCheck: Pick<UserCompensationDto, 'paySchedule'>): string => {
    return objToCheck?.paySchedule === 'Monthly'
      ? polyglot.t('NewEmployeeCompensationSection.month')
      : polyglot.t('NewEmployeeCompensationSection.week');
  };

  const getCalculatedSalaryRates = useCallback(
    async (compensation: Omit<UserCompensationForBreakdown, 'currency'>): Promise<CompensationBreakdown | null> => {
      try {
        return CompensationAPI.calculateSalaryFixedRates(userId, {
          ...compensation,
          currency: formik.values.currency ?? DEFAULT_CURRENCY,
        });
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), 'error');
        return null;
      }
    },
    [showMessage, polyglot, userId, formik.values.currency]
  );

  const debouncedCall = useCallback(async () => {
    if (!getCalculatedSalaryRates || !formik.values.rate || !formik.values.paySchedule || !formik.values.salaryBasis)
      return;

    getCalculatedSalaryRates({
      rate: formik.values.rate,
      paySchedule: formik.values.paySchedule,
      salaryBasis: formik.values.salaryBasis,
      units: formik.values.units,
    })
      .then((compBreakdown) => {
        formik.setFieldValue('annualSalary', compBreakdown?.fixedRates.annual ?? '');
        formik.setFieldValue('salaryPerPaySchedule', compBreakdown?.payScheduleRate ?? '');
      })
      .catch((error) => {
        showMessage(nestErrorMessage(error, polyglot), 'error');
      });
    // do not add formik here!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showMessage,
    polyglot,
    getCalculatedSalaryRates,
    formik.values.rate,
    formik.values.paySchedule,
    formik.values.salaryBasis,
    formik.values.units,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedCall();
    }, DEBOUNCE_500_MS);

    // Cleanup function
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCall]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('CompensationForm.compensation')}</Typography>
          <SelectComponent
            name="paySchedule"
            label={polyglot.t('CompensationForm.paySchedule')}
            options={PaySchedules(polyglot)}
            value={formik.values.paySchedule}
            onChange={formik.handleChange}
            disabled={saving}
            helperText={hasSubmitted && formik.errors.paySchedule}
            error={hasSubmitted && !!formik.errors.paySchedule}
          />
          <SelectComponent
            name="salaryBasis"
            label={polyglot.t('CompensationForm.salaryBasis')}
            options={SalaryBasis(polyglot)}
            value={formik.values.salaryBasis}
            onChange={formik.handleChange}
            disabled={saving}
            helperText={hasSubmitted && formik.errors.salaryBasis}
            error={hasSubmitted && !!formik.errors.salaryBasis}
          />
          <CheckboxComponent
            label={polyglot.t('CompensationForm.prorateSalaryByFte')}
            name="prorateSalaryByFte"
            checked={formik.values.prorateSalaryByFte}
            onChange={(_, value) => {
              formik.setFieldValue('prorateSalaryByFte', value);
              onChangeRate({
                ...formik.values,
                prorateSalaryByFte: value,
              });
            }}
          />
          {formik.values.prorateSalaryByFte && currentContract?.attendanceSchedule ? (
            <Typography variant="caption" sx={{ mt: spacing.m10 }}>
              {polyglot.t('CompensationForm.prorateSalaryByFteDescription', {
                percentage: currentContract.attendanceSchedule.ftePercent,
                workHours: convertMinutesToClockHours(currentContract.attendanceSchedule.totalTime, polyglot),
                fteHours: convertMinutesToClockHours(currentContract.attendanceSchedule.fteEquivalent, polyglot),
              })}
            </Typography>
          ) : formik.values.prorateSalaryByFte && !currentContract?.attendanceSchedule ? (
            <Typography variant="caption" color="RedDark" sx={{ mt: spacing.m10 }}>
              {polyglot.t('CompensationForm.noScheduleMessage')}
            </Typography>
          ) : null}
          <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
            <SelectComponent
              name="currency"
              label={polyglot.t('CompensationForm.currency')}
              options={popularCurrencyShortOptions}
              value={formik.values.currency}
              onChange={formik.handleChange}
              disabled={saving}
              helperText={hasSubmitted && formik.errors.currency}
              error={hasSubmitted && !!formik.errors.currency}
              sx={{ flexBasis: '50%' }}
            />
            <MoneyTextfieldComponent
              name="rate"
              label={displayRateLabel(formik.values.salaryBasis!, polyglot)}
              value={formik.values.rate}
              onChange={(newValue: number | undefined) => {
                formik.setFieldValue('rate', newValue);
                onChangeRate({
                  ...formik.values,
                  rate: newValue ? Number(newValue) : undefined,
                });
              }}
              autoFocus
            />
          </Stack>
          {[SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(formik.values?.salaryBasis) && (
            <TextfieldComponent
              name="units"
              label={polyglot.t('NewEmployeeCompensationSection.units', {
                unitLabel: modalUnitLabel(formik.values),
                salary: modalSalaryPerLabel(formik.values),
              })}
              value={formik.values.units}
              type="number"
              onChange={formik.handleChange}
              error={formik.touched.units && !!formik.errors.units}
              helperText={(formik.touched.units && formik.errors.units) ?? ' '}
              clearText={() => formik.setFieldValue('units', '')}
            />
          )}
          {formik.values.salaryPerPaySchedule && (
            <Stack sx={{ gap: spacing.g5 }}>
              <Typography variant="caption" color="Grey">
                {polyglot.t('NewEmployeeCompensationSection.salaryPer', {
                  paySchedule: payScheduleUnit(formik.values.paySchedule),
                  proRated: proratedSuffix,
                })}
              </Typography>

              {formik.values.salaryPerPaySchedule && !Number.isNaN(formik.values.salaryPerPaySchedule) ? (
                <Typography variant="title4">
                  {formatAsCompensationCurrency(formik.values.currency, formik.values.salaryPerPaySchedule)}
                </Typography>
              ) : (
                <Box></Box>
              )}
            </Stack>
          )}
          {formik.values.annualSalary && ['Monthly', 'Annual'].includes(formik.values.salaryBasis) && (
            <Stack sx={{ gap: spacing.g5 }}>
              <Typography variant="caption" color="Grey">
                {polyglot.t('NewEmployeeCompensationSection.annualSalaryGross', { salary: proratedSuffix })}
              </Typography>

              {formik.values.annualSalary && !Number.isNaN(formik.values.annualSalary) ? (
                <Typography variant="title4">
                  {formatAsCompensationCurrency(formik.values.currency, formik.values.annualSalary)}
                </Typography>
              ) : (
                <Box></Box>
              )}
            </Stack>
          )}
          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            rowModalMode={rowModalMode}
            formName={CustomProfileFormType.Salary}
          />
          {formik.isValid && (
            <LoaderButton
              name={polyglot.t('General.continue')}
              loading={saving}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
