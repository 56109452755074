import { UserCompensationWithBreakdown } from '@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { UserRoleDto } from '@v2/feature/user/features/user-forms/user-role/user-role.dto';

import { UserContract, UserEquity } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { User } from '@/models';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';

export enum EmploymentTypeEnum {
  Employee = 'Employee',
  Contractor = 'Contractor',
}

export interface CreateUserOnboarding extends Omit<Partial<User>, 'accountType'> {
  templateId?: number;
  appSource?: AppIntegrationStub;
  externalId?: string;
}

export type OnboardingStateInit = {
  user: Pick<
    User,
    'userId' | 'firstName' | 'lastName' | 'displayName' | 'emailAddress' | 'personalEmail' | 'startDate'
  >;
  role: UserRoleDto | null;
  contract: UserContract | null;
  compensation: UserCompensationWithBreakdown | null;
  equity: UserEquity[];
};

export interface UpdateUserOnboarding extends Partial<User> {
  userId: number;
}

export interface PasswordSetResult {
  userId: number;
}

//list of apps excluded by Polina from onboarding & offboarding
export const MANUALLY_EXCLUDED_BOARDING_APPS = ['slack', 'monday', 'xero'];

export interface AppUsedInOnboardingResult {
  users: number;
  templates: number;
  used: boolean;
}
