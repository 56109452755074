import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { spacing } from '@v2/styles/spacing.styles';

import { SuperAdminBillingInvoiceTableHelper } from '../../super-admin/features/super-admin-billing/helper/super-admin-billing-invoices-table-helper';

import { BillingFailedPaymentAPI } from '@/api-client/billing-failed-payment.api';
import { BillingInvoiceAPI } from '@/api-client/billing-invoice.api';
import { BillingAPI } from '@/api-client/billing.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Invoice, InvoiceStatus } from '@/models/invoice.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { CompanySubscriptionDetails } from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface Props {
  subscriptionDetailsForCompany: CompanySubscriptionDetails | null;
}

export const BillingInvoices = ({ subscriptionDetailsForCompany }: Props) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isRetryingPayments, setIsRetryingPayments] = useState(false);
  const [payingInvoice, setPayingInvoice] = useState(false);
  const [currentInvoiceIdBeingProcessed, setCurrentInvoiceIdBeingProcessed] = useState<string | null>(null);

  const fetchInvoices = useCallback(
    async (params?: { page: string; pageSize: string }) => {
      try {
        setIsLoadingInvoices(true);
        const { invoices, pagination: paginationInfo } = await BillingInvoiceAPI.getAllInvoicesForCompany(params);
        setInvoices(invoices || []);

        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingInvoices(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const refresh = useCallback(async () => {
    await fetchInvoices({
      page: pageIndex.toString(),
      pageSize: pageSize.toString(), // Use the current pageSize instead of DEFAULT_PAGE_SIZE
    });
  }, [fetchInvoices, pageIndex, pageSize]);

  const payInvoice = useCallback(
    async (invoice: Invoice) => {
      try {
        if (!subscriptionDetailsForCompany) return;
        const { companySubscription } = subscriptionDetailsForCompany;
        setCurrentInvoiceIdBeingProcessed(invoice.id);

        await BillingAPI.manuallyChargeCustomerForUnpaidInvoice(invoice, companySubscription);
        showMessage('Initiated invoice payment...', 'info');
      } catch (error) {
        showMessage(`Failed to initiate invoice payment: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setPayingInvoice(false);
        setCurrentInvoiceIdBeingProcessed(null);
      }
    },
    [showMessage, subscriptionDetailsForCompany]
  );

  const handleDownloadClick = useCallback(
    async (invoiceId: string) => {
      try {
        const PDFBlob = await BillingInvoiceAPI.downloadInvoice(invoiceId);

        const file = new Blob([PDFBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.download = `${invoiceId}-${new Date()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (error) {
        console.error('::Download error', error);
        showMessage(`Failed to download invoice. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const retryFailedPayment = useCallback(
    async (params: { failedPaymentId: number; invoiceId: string }) => {
      try {
        setIsRetryingPayments(true);

        const { failedPaymentId, invoiceId } = params;
        setCurrentInvoiceIdBeingProcessed(invoiceId);
        await BillingFailedPaymentAPI.retryFailedPayment({ failedPaymentId });
        refresh();

        showMessage(`Charge successful for invoice No. ${invoiceId}`, 'success');
      } catch (error) {
        const defaultErrorMessage = 'Failed to retry payment';
        showMessage(`${nestErrorMessage(error) || defaultErrorMessage}`, 'error');
      } finally {
        setIsRetryingPayments(false);
        setCurrentInvoiceIdBeingProcessed(null);
      }
    },
    [refresh, showMessage]
  );

  useEffect(() => {
    refresh();
  }, [pageIndex, pageSize, refresh]);

  const invoiceTableColumns = useMemo<ColumnDef<Invoice, unknown>[]>(
    () => [
      SuperAdminBillingInvoiceTableHelper.getInvoiceIdColumn({
        header: 'Invoice Id',
        id: 'id',
        size: 145,
      }),
      SuperAdminBillingInvoiceTableHelper.getPeriodStartDateColumn({
        header: 'Period Start',
        id: 'periodStart',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getPeriodEndDateColumn({
        header: 'Period End',
        id: 'periodEnd',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getInvoiceDateColumn({
        header: 'Invoice Date',
        id: 'invoiceDate',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getInvoiceStatusColumn({
        header: 'Status',
        id: 'status',
        size: 100,
      }),
      SuperAdminBillingInvoiceTableHelper.getTotalAmountColumn({
        header: 'Total Amount',
        id: 'totalAmount',
        size: 100,
      }),

      {
        id: 'action-buttons',
        header: () => '',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          return (
            <Stack flexDirection="row" justifyContent="flex-end" gap="5px" whiteSpace="nowrap">
              {original.id && (
                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() => (original.id ? handleDownloadClick(original.id!) : undefined)}
                  title="Download"
                >
                  <DownloadIcon {...iconSize} />
                </IconButton>
              )}
              {original.status === InvoiceStatus.Unpaid && original?.billingFailedPayment?.id && (
                <ButtonComponent
                  onClick={() =>
                    original.billingFailedPayment?.id
                      ? retryFailedPayment({
                          failedPaymentId: original.billingFailedPayment.id,
                          invoiceId: original.id!,
                        })
                      : undefined
                  }
                  sizeVariant={'small'}
                  colorVariant={'primary'}
                  disabled={isRetryingPayments}
                  loading={original.id === currentInvoiceIdBeingProcessed && isRetryingPayments}
                >
                  Pay
                </ButtonComponent>
              )}
              {original.status === InvoiceStatus.Unpaid && !original?.billingFailedPayment?.id && (
                <ButtonComponent
                  onClick={() => payInvoice(original)}
                  sizeVariant={'small'}
                  colorVariant={'primary'}
                  loading={original.id === currentInvoiceIdBeingProcessed && payingInvoice}
                  disabled={payingInvoice}
                >
                  Pay
                </ButtonComponent>
              )}
            </Stack>
          );
        },
      },
    ],
    [
      currentInvoiceIdBeingProcessed,
      handleDownloadClick,
      isRetryingPayments,
      payInvoice,
      payingInvoice,
      retryFailedPayment,
    ]
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  return invoices && invoices.length > 0 ? (
    <>
      <Box>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Invoices</Typography>
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        {!isLoadingInvoices && (
          <>
            <BasicServerTable<Invoice>
              rowData={[...invoices]}
              columnData={invoiceTableColumns}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          </>
        )}
      </Box>
    </>
  ) : null;
};
