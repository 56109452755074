import axios from 'axios';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import {
  CompensationBreakdown,
  UserCompensationDto,
  UserCompensationForBreakdown,
  UserCompensationWithBreakdown,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class CompensationAPI {
  static async findByUserId(userId: number): Promise<EntriesAndEffectiveRecord<UserCompensationWithBreakdown>> {
    return (await axios.get(`/apiv2/users/${userId}/compensation`)).data;
  }

  static async calculateSalaryFixedRates(
    userId: number,
    data: UserCompensationForBreakdown
  ): Promise<CompensationBreakdown | null> {
    const query = `salaryBasis=${data.salaryBasis}&rate=${data.rate}&paySchedule=${data.paySchedule}&units=${data.units}&currency=${data.currency}`;

    return (await axios.get(`/apiv2/users/${userId}/compensation-breakdown?${query}`)).data;
  }

  static async findCurrentByUserId(userId: number): Promise<UserCompensationDto> {
    return (await axios.get(`/apiv2/users/${userId}/compensation/current`)).data;
  }

  static async create(userId: number, userCompensation: UserCompensationDto): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/compensation`, userCompensation);
  }

  static async updateById(userId: number, compensationUpdate: UserCompensationDto): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/compensation/${compensationUpdate.id}`, compensationUpdate);
  }

  static async deleteById(userId: number, compensationId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/compensation/${compensationId}`);
  }

  static async getCompensationByCompanyId(companyId: number): Promise<UserCompensationDto[]> {
    return (await axios.get(`/apiv2/superadmin/company/${companyId}/compensation`)).data;
  }

  static async bulkDeleteCompensationByIds(companyId: number, compensationIds: number[]): Promise<void> {
    await axios.delete(`/apiv2/superadmin/company/${companyId}/compensation`, { data: compensationIds });
  }
}

export class CompensationEndpoints {
  static getFTEPercentageForUser(userId: number): Endpoint<number> {
    return {
      url: `/apiv2/users/${userId}/ftepercentage`,
    };
  }

  static findCurrentByUserId(userId: number): Endpoint<UserCompensationDto> {
    return {
      url: `/apiv2/users/${userId}/compensation/current`,
    };
  }
}
