import { DownloadInvoice, SuperAdminChargePageResult, InvoicePageResult } from '@/models/invoice.model';
import { CreateCharge } from '@/v2/feature/super-admin/features/super-admin-billing-v2/one-off-charge.interface';
import axios from 'axios';

export class BillingInvoiceAPI {
  static async getAllInvoices(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    shouldLimitResult?: string;
  }): Promise<InvoicePageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/billing/invoice?${searchParams.toString()}`)).data;
  }

  static async getAllInvoicesForCompany(param?: { page?: string; pageSize?: string }): Promise<InvoicePageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/billing/invoice/all?${searchParams.toString()}`)).data;
  }

  static async getAllCharges(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    shouldLimitResult?: string;
  }): Promise<SuperAdminChargePageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/billing/one-off-charges?${searchParams.toString()}`)).data;
  }

  static async createNewChargeAsSuperadmin(data: CreateCharge): Promise<void> {
    await axios.post(`/apiv2/billing/one-off-charges`, data);
  }

  static async updateExistingChargeAsSuperadmin(originalCompanyId: number, data: CreateCharge): Promise<void> {
    await axios.patch(`/apiv2/billing/one-off-charges/${originalCompanyId}/${data.id}`, data);
  }

  static async deleteChargeAsSuperadmin(companyId: number, chargeId: number): Promise<void> {
    await axios.delete(`/apiv2/billing/one-off-charges/${companyId}/${chargeId}`);
  }

  static async downloadInvoiceAsSuperAdmin(payload: DownloadInvoice): Promise<any> {
    return (
      await axios.get(`/apiv2/billing/invoice/superadmin/download/${payload.companyId}/${payload.id}`, {
        responseType: 'blob',
      })
    ).data;
  }

  static async deleteInvoiceAsSuperAdmin(companyId: number, invoiceId: string): Promise<void> {
    await axios.delete(`/apiv2/billing/invoice/superadmin/${invoiceId}/company/${companyId}`);
  }

  static async deleteFailiedBillingPaymentAsSuperAdmin(
    companyId: number,
    invoiceId: string,
    failedPaymentId: number
  ): Promise<void> {
    await axios.delete(
      `/apiv2/billing/invoice/superadmin/${invoiceId}/company/${companyId}/failed-payment/${failedPaymentId}`
    );
  }

  static async generateExternalInvoiceAsSuperAdmin(invoiceId: string): Promise<void> {
    await axios.post(`/apiv2/billing/invoice/superadmin/${invoiceId}/generate-external`);
  }

  static async sendEmailReminderOfUnpaidInvoiceAsSuperAdmin(companyId: number, invoiceId: string): Promise<void> {
    await axios.post(`/apiv2/billing/invoice/superadmin/${companyId}/${invoiceId}/send-email-reminder`);
  }

  static async downloadInvoice(invoiceId: string): Promise<any> {
    return (
      await axios.get(`/apiv2/billing/invoice/download/${invoiceId}`, {
        responseType: 'blob',
      })
    ).data;
  }
}
