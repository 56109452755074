import { useMemo } from 'react';

import { SxProps, Theme } from '@mui/material';
import { StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';

import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ignoredPayLines } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';

const iconSize = { width: 14, height: 14 } as const;
type PayLineTypeMenuProps = {
  disabled?: boolean;
  kind: 'addition' | 'deduction';
  payCodes: StaffologyPayCode[];
  onMenuItemClick: (paycode: StaffologyPayCode) => void;
  sx?: SxProps<Theme>;
};

export const PayLineTypeMenu = ({ disabled, kind, payCodes, onMenuItemClick, sx }: PayLineTypeMenuProps) => {
  const payrunMenuOptions = useMemo(() => {
    const isDeduction = kind === 'deduction';

    return payCodes
      .filter(
        (payCode) => !payCode.isControlCode && payCode.isDeduction === isDeduction && !ignoredPayLines.has(payCode.code)
      )
      .sort((a, b) => a.code.localeCompare(b.code, undefined, { sensitivity: 'base' }))
      .map<OptionProps>((item) => ({
        handler: () => onMenuItemClick(item),
        label: item.code,
      }));
  }, [kind, payCodes, onMenuItemClick]);

  return (
    <StyledMenuComponent
      options={payrunMenuOptions}
      actionButtonDetails={{
        type: 'button',
        colorVariant: 'secondary',
        sizeVariant: 'small',
        title: { addition: 'Add income', deduction: 'Add deduction' }[kind],
        icon: <Plus {...iconSize} />,
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      disabled={disabled || payrunMenuOptions.length === 0}
      sx={sx}
    />
  );
};
