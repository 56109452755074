import React, { useCallback, useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ZeltText } from '@/images/zelt-text.svg';
import { ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { DASHBOARD_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { AccountStatus } from '@/lib/users';
import { CurrentUser, UserLifecycleInfo } from '@/models';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { TaskDto } from '@/v2/feature/task/task.dto';
import { CachedUsersProvider } from '@/v2/feature/user/context/cached-users.context';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';
import { UserAPI } from '@/v2/feature/user/user.api';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { CloseButton } from '@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component';
import { OnboardingOverview } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/onboarding-overview.page';
import {
  filterPendingOnboardingContracts,
  filterUserOnboardingDocuments,
} from '@/v2/feature/user-onboarding/user-onboarding.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

const DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS = [
  'documents',
  'contracts',
  'contracts_step2',
  'contracts_step3',
  'contracts_step4',
  'contracts_step5',
];

const CURRENTLY_IMPLEMENTED_ONBOARDING_STEPS = [
  'basic',
  'payroll',
  'tasks',
  ...DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS,
];

export const UserOnboardingSummaryPage = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  // const [globalState] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [state, setState] = useState<{
    lifecycle: UserLifecycleInfo;
    requiredSteps: OnboardingStep[];
    user: CurrentUser;
    missingFields: OnboardingMissingFields;
    pendingContracts: ZeltDocument[];
    documents: ZeltDocument[];
    documentTypes: ZeltDocumentType[];
    incompleteTasks: TaskDto[];
    checklistNames: string[];
    taskCount: number;
    progressPercent: number;
    publicURL: string | null;
  }>();
  const today = todaysDateShortISOString();

  const getProgress = useCallback(
    (
      requiredSteps: OnboardingStep[],
      missingFields: OnboardingMissingFields,
      missingDocumentCount: number,
      incompleteTaskCount: number,
      missingContractCount: number
    ) => {
      let total = 0;
      let completed = 0;
      if (requiredSteps.includes('basic')) {
        total += 1;
        if (missingFields.basic.length === 0) {
          completed += 1;
        }
      }
      if (requiredSteps.includes('payroll')) {
        total += 1;
        if (missingFields.payroll.length === 0) {
          completed += 1;
        }
      }
      if (requiredSteps.includes('documents')) {
        total += 1;
        if (missingDocumentCount === 0) {
          completed += 1;
        }
      }
      if (requiredSteps.includes('tasks')) {
        total += 1;
        if (incompleteTaskCount === 0) {
          completed += 1;
        }
      }
      if (requiredSteps.some((step) => step.includes('contracts'))) {
        total += 1;
        if (missingContractCount === 0) {
          completed += 1;
        }
      }
      return {
        total,
        pending: total - completed,
        percentComplete: total && Math.trunc((100 * completed) / total),
      };
    },
    []
  );

  const finishOnboarding = useCallback(
    async (userId: number) => {
      await UserProfileActionAPI.activateUser(userId, 'onboarding');
      routerHistory.push(DASHBOARD_ROUTE);
    },
    [routerHistory]
  );

  const refreshState = useCallback(async () => {
    try {
      const { user, publicURL } = await AuthAPI.getAuthMe();
      if (user.accountStatus !== AccountStatus.InvitedToOnboard) {
        routerHistory.replace(DASHBOARD_ROUTE);
        return;
      }
      const hasTaskScope = checkScopes(user, ['task'], { userId: user.userId });
      const hasDocumentScope = checkScopes(user, ['documents'], { userId: user.userId });
      const hasPayrollScope = checkScopes(user, ['payroll:read'], { userId: user.userId });
      const [requiredSteps, missingFields, lifecycle, allDocuments, documentTypes, tasks] = await Promise.all([
        OnboardingAPI.onboardingSteps().then((steps) =>
          // remove steps not yet implemented
          steps
            .filter((step) => CURRENTLY_IMPLEMENTED_ONBOARDING_STEPS.includes(step))
            .filter((step) => !DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS.includes(step) || hasDocumentScope)
            .filter((step) => step !== 'tasks' || hasTaskScope)
            .filter((step) => step !== 'payroll' || hasPayrollScope)
        ),
        OnboardingAPI.onboardingMissingFields(),
        UserAPI.getUserLifecycleInfo(user.userId),
        hasDocumentScope ? DocumentAPI.listUserDocuments(user.userId) : Promise.resolve([]),
        DocumentAPI.listDocumentTypes(),
        hasTaskScope ? TaskAPI.listTasks(user.userId) : Promise.resolve([]),
      ]);

      const validDocuments = filterUserOnboardingDocuments(allDocuments);
      const pendingContracts = filterPendingOnboardingContracts(
        allDocuments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      );
      const missingDocuments = validDocuments.filter((d) => !d.attachments?.length);
      const missingContract = pendingContracts.filter((d) => !d.attachments?.length);

      const incompleteTasks = tasks.filter((t) => t.status !== 'Complete');
      const checklistNames = [...new Set(tasks.map((t) => t.checklist?.name ?? '').filter(Boolean))];
      const { pending, percentComplete } = getProgress(
        requiredSteps,
        missingFields,
        missingDocuments.length,
        incompleteTasks.length,
        missingContract.length
      );
      const userHasStarted = !lifecycle.startDate || lifecycle.startDate <= todaysDateShortISOString();
      if (pending <= 0 && userHasStarted) {
        finishOnboarding(user.userId);
        return;
      }
      setState({
        lifecycle,
        requiredSteps,
        user,
        missingFields,
        documents: validDocuments,
        pendingContracts,
        documentTypes,
        incompleteTasks,
        checklistNames,
        taskCount: pending,
        progressPercent: percentComplete,
        publicURL,
      });
    } catch (error) {
      showMessage(
        polyglot.t('OnboardingByUser.errors.accountStateRetrieveFailed', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, finishOnboarding, getProgress, routerHistory, showMessage]);

  useEffect(() => {
    refreshState();
  }, [refreshState]);

  if (!state) {
    return <></>;
  }

  const {
    lifecycle,
    progressPercent,
    missingFields,
    documents,
    documentTypes,
    pendingContracts,
    incompleteTasks,
    checklistNames,
    requiredSteps,
    taskCount,
    user,
    publicURL,
  } = state;

  return (
    // <GlobalStateProvider initialState={{ ...globalState, user }}>
    <CachedUsersProvider>
      <Stack sx={{ mt: spacing.mt40, mx: spacing.mx40 }}>
        <ZeltText />
        <OnboardingOverview
          companyName={user.company.name}
          taskCount={taskCount}
          progressPercent={progressPercent}
          requiredSteps={requiredSteps}
          missingFields={missingFields}
          documents={documents}
          documentTypes={documentTypes}
          pendingContracts={pendingContracts}
          incompleteTasks={incompleteTasks}
          checklistNames={checklistNames}
          startDate={lifecycle.startDate}
          sx={{ mt: '100px' }}
          userId={user.userId}
          publicURL={publicURL}
        />
        {(!lifecycle.startDate || today >= lifecycle.startDate) && (
          <CloseButton
            onClick={async () => {
              await finishOnboarding(user.userId);
            }}
          />
        )}
      </Stack>
    </CachedUsersProvider>
    // </GlobalStateProvider>
  );
};
