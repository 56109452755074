import { useContext, useMemo, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { CycleCreationNotificationsModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-notifications/components/cycle-creation-notifications-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import {
  ReachType,
  ReminderFrequencyDict,
  ReviewCycle,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewCycleCreationNotificationsPage = ({
  reviewCycle,
  refresh,
  reach,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  refresh: () => Promise<void>;
  reach: ReachType;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);

  const isEditable = useMemo(
    () =>
      Boolean(
        reviewCycle &&
          (reviewCycle.state === CycleState.Draft ||
            reviewCycle.state === CycleState.Scheduled ||
            reviewCycle.state === CycleState.Ongoing) &&
          Boolean(isAdmin || (isManager && reviewCycle.owner === user.userId))
      ),
    [reviewCycle, isAdmin, isManager, user]
  );

  if (!reviewCycle) return <></>;
  const { notificationSettings } = reviewCycle;

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={false}
    >
      {notificationSettings && (
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              headerWidth: '100%',
              title: 'Notifications',
              onEdit: () => setIsOpen(true),
              hideEdit: !isEditable,
              items: [
                {
                  type: SectionItemType.Pair,
                  label: 'Reminder',
                  value: ReminderFrequencyDict[notificationSettings.reminderFrequency],
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Invite message',
                  value: 'Use default message',
                },
              ],
            },
          ]}
        />
      )}
      <CycleCreationNotificationsModal
        reviewCycle={reviewCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refresh?.();
        }}
      />
    </SettingsSectionContent>
  );
};
