import React, { useCallback, useContext, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ApproversList } from '@v2/feature/approval-rule/components/approvers-list.component';
import { ExpenseApprovalButtons } from '@v2/feature/payments/pages/components/expense-approval-buttons.component';
import { DEFAULT_CURRENCY, Expense, ExpenseStatus } from '@v2/feature/payments/payments.interface';
import { getContractorInvoiceStatusComponent } from '@v2/feature/payments/utils/get-contractor-invoice-status.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import moment from 'moment/moment';
import { generatePath, useHistory } from 'react-router-dom';

import { ExpenseAPI } from '@/api-client/expense.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { PublicImageViewer } from '@/v2/components/upload/public-image-viewer.component';
import { LineItemInDrawerComponent } from '@/v2/feature/payments/components/line-item-in-drawer.component';
import { ViewerItem } from '@/v2/feature/payments/components/payment-details-drawer.component';
import { getExpenseTotalsBasedOnLineItems, getTaxRateForTotalSection } from '@/v2/feature/payments/expenses.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface ExpenseModalProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedExpense: Expense | undefined | null;
  readonly onClose: () => void;
  readonly afterClose?: () => void;
  readonly onActionPerformed: () => Promise<void>;
  readonly currentUserIsAdmin: boolean;
  readonly reach?: 'me' | 'team' | 'company';
}

export const ExpenseModal = ({
  isOpen,
  setIsOpen,
  selectedExpense,
  onClose,
  afterClose,
  onActionPerformed,
  currentUserIsAdmin,
  reach = 'me',
}: ExpenseModalProps) => {
  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose} afterClose={afterClose}>
      <ExpenseModalContent
        selectedExpense={selectedExpense}
        currentUserIsAdmin={currentUserIsAdmin}
        onActionPerformed={onActionPerformed}
        onClose={onClose}
        reach={reach}
      />
    </DrawerModal>
  );
};

const ExpenseModalContent = ({
  selectedExpense,
  currentUserIsAdmin,
  onActionPerformed,
  onClose,
  reach,
}: {
  readonly selectedExpense: Expense | undefined | null;
  readonly currentUserIsAdmin: boolean;
  readonly onActionPerformed: () => Promise<void>;
  readonly onClose: () => void;
  readonly reach?: 'me' | 'team' | 'company';
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const routerHistory = useHistory();
  const { getScopesContext, hasScopes } = useScopes();

  const { polyglot } = usePolyglot();
  const [isUpdatingApproval, setIsUpdatingApproval] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const currentUserIsExpenseAdmin = hasScopes(
    ['expenses:all', 'expenses:manager'],
    getScopesContext({ userId: user?.userId })
  );

  const currentUserIsManagerOfExpenseOwner = selectedExpense?.from
    ? checkScopes(user, ['expenses:manager'], { userId: selectedExpense.from })
    : false;

  const hasRights = currentUserIsManagerOfExpenseOwner || currentUserIsExpenseAdmin;

  const draftExpense = selectedExpense?.status === ExpenseStatus.Draft;
  const expensePending = selectedExpense?.status === ExpenseStatus.Pending;

  const canEditExpense =
    hasRights && (draftExpense || expensePending)
      ? true
      : selectedExpense && selectedExpense.createdBy && user.userId === selectedExpense.createdBy && draftExpense
      ? true
      : selectedExpense && selectedExpense.from
      ? hasScopes(['expenses'], getScopesContext({ userId: selectedExpense.from })) &&
        user.userId === selectedExpense?.from
      : false;

  const [loading, setLoading] = useState<boolean>(false);

  const currentUserIsBeneficiaryOfExpense = user.userId === selectedExpense?.from;

  const deleteExpense = useCallback(
    async (expenseId: string) => {
      try {
        setIsUpdatingApproval(true);

        await ExpenseAPI.deleteExpense(expenseId);

        showMessage(polyglot.t('ExpenseModal.successMessages.delete'), 'success');
        setIsUpdatingApproval(false);

        await onActionPerformed();
        onClose();
      } catch (e) {
        showMessage(polyglot.t('ExpenseModal.errorMessages.delete', { errorMessage: nestErrorMessage(e) }), 'error');
        setIsUpdatingApproval(false);
      }
    },
    [showMessage, polyglot, onClose, onActionPerformed]
  );

  const syncExpenseWithAccountingProvider = async (expenseId: string) => {
    try {
      setLoading(true);
      await ExpenseAPI.syncExpenseWithExternalProvider(expenseId);
      showMessage(polyglot.t('ExpenseModal.successMessages.sync'), 'success');
    } catch (error) {
      showMessage(polyglot.t('ExpenseModal.errorMessages.sync', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  const navigateToExpensePageToEdit = useCallback(() => {
    if (
      !selectedExpense ||
      !selectedExpense?.id ||
      (selectedExpense?.status !== ExpenseStatus.Draft && selectedExpense?.status !== ExpenseStatus.Pending)
    )
      return;
    if (reach === 'me') {
      routerHistory.push(generatePath(EXPENSE_ME_EDIT_EXPENSE_ROUTE, { id: selectedExpense?.id }), {
        expenseToEdit: selectedExpense,
      });
    } else if (reach === 'team') {
      routerHistory.push(generatePath(EXPENSE_TEAM_EDIT_EXPENSE_ROUTE, { id: selectedExpense?.id }), {
        expenseToEdit: selectedExpense,
      });
    } else if (reach === 'company') {
      routerHistory.push(generatePath(EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE, { id: selectedExpense?.id }), {
        expenseToEdit: selectedExpense,
      });
    }
  }, [reach, routerHistory, selectedExpense]);

  const { totalGross, totalAmount, totalTaxAmount } =
    selectedExpense && selectedExpense.lineItems
      ? getExpenseTotalsBasedOnLineItems(selectedExpense.lineItems)
      : { totalGross: 0, totalAmount: 0, totalTaxAmount: 0 };

  const hasMoreThanOneLineItem =
    selectedExpense?.lineItems?.length && selectedExpense?.lineItems?.length > 1 ? true : false;

  const canDeleteOrEditExpense =
    selectedExpense?.status &&
    [ExpenseStatus.Draft, ExpenseStatus.Pending, ExpenseStatus.Rejected, ExpenseStatus.Voided].includes(
      selectedExpense?.status
    );

  return (
    <Box sx={drawerContentSx}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="title2">{polyglot.t('ExpenseModal.title')}</Typography>

        {((canDeleteOrEditExpense && currentUserIsAdmin) ||
          (expensePending && currentUserIsBeneficiaryOfExpense) ||
          (draftExpense && canEditExpense)) && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
            {(expensePending || draftExpense) && canEditExpense && (
              <IconButton
                disabled={isUpdatingApproval}
                fullWidth
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => {
                  navigateToExpensePageToEdit();
                }}
                name={polyglot.t('General.edit')}
              >
                <EditIcon {...iconSize} />
              </IconButton>
            )}
            <IconButton
              disabled={isUpdatingApproval}
              fullWidth
              sizeVariant="small"
              colorVariant="secondary"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setIsRemovalModalOpen(true);
              }}
              name={polyglot.t('General.delete')}
            >
              <TrashIcon {...iconSize} />
            </IconButton>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="caption">{polyglot.t('ExpenseModal.paidTo')}</Typography>
        </Box>
        {selectedExpense?.from && (
          <Box>
            <UserCell userId={selectedExpense.from} nameVariant="title4" />
          </Box>
        )}
      </Box>

      {selectedExpense && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, mb: spacing.mb20 }}>
          <ViewerItem
            title={polyglot.t('ExpenseModal.policy')}
            value={selectedExpense?.lineItems?.length === 1 ? 'Single' : 'Multiple'}
          />
          {selectedExpense.notes && (
            <ViewerItem
              title={polyglot.t('ContractorInvoiceModal.description')}
              value={
                selectedExpense?.notes && selectedExpense.notes.length > 40 ? (
                  <StyledTooltip title={selectedExpense.notes} placement="top">
                    <span>{truncateWithEllipses(selectedExpense.notes, 40)}</span>
                  </StyledTooltip>
                ) : (
                  <div>{truncateWithEllipses(selectedExpense.notes, 40)}</div>
                )
              }
            />
          )}
          {selectedExpense.date && (
            <ViewerItem
              title={polyglot.t('ExpenseModal.dateSpent')}
              value={moment(selectedExpense.date).format('DD MMM YYYY')}
            />
          )}

          <ViewerItem
            title={polyglot.t('ExpenseModal.type')}
            value={selectedExpense?.lineItems?.length === 1 ? 'Single' : 'Multiple'}
          />

          {selectedExpense.updatedBy && (
            <ViewerItem
              title={polyglot.t('ExpenseModal.lastUpdatedBy')}
              value={<UserCell userId={selectedExpense.updatedBy} nameVariant="title4" />}
            />
          )}

          {selectedExpense?.lineItems?.map((item, index) => (
            <LineItemInDrawerComponent key={item.id} item={item} index={index} currency={selectedExpense.currency} />
          ))}
          <ViewerItem
            title={polyglot.t('ExpenseModal.status')}
            value={getContractorInvoiceStatusComponent(selectedExpense.status, themeFonts.title4)}
          />

          {!selectedExpense.amount ? (
            <ViewerItem
              title={polyglot.t('ContractorInvoiceModal.amount')}
              value={formatCurrency(selectedExpense.amount, undefined, selectedExpense.currency) as string}
            />
          ) : null}

          {selectedExpense.gross && selectedExpense.amount && totalAmount ? (
            <ViewerItem
              title={polyglot.t('NewInvoicePage.amount')}
              value={
                formatCurrency(totalAmount ?? 0, undefined, selectedExpense.currency ?? DEFAULT_CURRENCY) as string
              }
            />
          ) : null}

          {selectedExpense.taxRate || selectedExpense.taxRate === 0 ? (
            <ViewerItem
              title={`${polyglot.t('NewInvoicePage.taxAmount')} ${
                !hasMoreThanOneLineItem ? getTaxRateForTotalSection(selectedExpense.taxRate) : ''
              }`}
              value={formatCurrency(totalTaxAmount, undefined, selectedExpense.currency) as string}
            />
          ) : null}

          {!selectedExpense.taxRate && (selectedExpense.taxAmount || totalTaxAmount) ? (
            <ViewerItem
              title={`${polyglot.t('NewInvoicePage.taxAmount')}`}
              value={
                formatCurrency(
                  totalTaxAmount ?? selectedExpense.taxAmount,
                  undefined,
                  selectedExpense.currency
                ) as string
              }
            />
          ) : null}

          {selectedExpense.amount && selectedExpense.taxRate !== null && selectedExpense.taxRate >= 0 ? (
            <ViewerItem
              title={polyglot.t('NewInvoicePage.gross')}
              value={formatCurrency(totalGross ?? 0, undefined, selectedExpense.currency ?? DEFAULT_CURRENCY) as string}
            />
          ) : null}

          {selectedExpense.amount && !selectedExpense.taxRate && selectedExpense.taxAmount && (
            <ViewerItem
              title={polyglot.t('NewInvoicePage.gross')}
              value={formatCurrency(totalGross ?? 0, undefined, selectedExpense.currency ?? DEFAULT_CURRENCY) as string}
            />
          )}

          {!selectedExpense.approvedOnTimestamp && (
            <ApproversList approverSteps={selectedExpense.approverSteps} layout="horizontal" rowAvatarsLimit={4} />
          )}

          {selectedExpense.approvedByIds && selectedExpense?.approvedByIds.length > 0 && (
            <ViewerItem
              title={polyglot.t('ExpenseModal.approvedBy')}
              value={<MultiUserAvatar userIds={selectedExpense.approvedByIds} />}
            />
          )}

          {selectedExpense.rejectedByIds && selectedExpense?.rejectedByIds.length > 0 && (
            <ViewerItem
              title={polyglot.t('ExpenseModal.rejectedBy')}
              value={<MultiUserAvatar userIds={selectedExpense.rejectedByIds} />}
            />
          )}

          {selectedExpense.approvedOnTimestamp && selectedExpense.status === ExpenseStatus.Approved ? (
            <ViewerItem
              title={polyglot.t('ExpenseModal.approvedOn')}
              value={new Date(selectedExpense.approvedOnTimestamp).toLocaleString()}
            />
          ) : selectedExpense.approvedOnTimestamp && selectedExpense.status === ExpenseStatus.Approved ? (
            <ViewerItem
              title={polyglot.t('ExpenseModal.rejectedOn')}
              value={new Date(selectedExpense.approvedOnTimestamp).toLocaleString()}
            />
          ) : null}

          {selectedExpense.status === ExpenseStatus.Rejected &&
            selectedExpense.approvalNotes &&
            selectedExpense.approvalNotes?.length > 0 && (
              <ViewerItem title={polyglot.t('ContractorInvoiceModal.notes')} value={selectedExpense.approvalNotes} />
            )}

          {selectedExpense.attachment && (
            <PublicImageViewer
              fileName={selectedExpense.attachment}
              uploadName="Uploaded receipt"
              hasView
              hasDownload
            />
          )}
        </Box>
      )}

      <AccountingIntegrationSection
        selectedExpense={selectedExpense}
        syncExpenseWithAccountingProvider={syncExpenseWithAccountingProvider}
        loading={loading}
      />

      {selectedExpense && (
        <ExpenseApprovalButtons
          selectedExpense={selectedExpense}
          onClose={onClose}
          onActionPerformed={onActionPerformed}
        />
      )}

      {/* Admin can delete / reject / void expenses
    user can delete pending expenses if they are the beneficiary */}

      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => setIsRemovalModalOpen(false)}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (selectedExpense) await deleteExpense(selectedExpense.id);
        }}
        message={`Are you sure you want to delete this expense?`}
        callToAction="Yes"
      />
    </Box>
  );
};

const AccountingIntegrationSection = ({
  selectedExpense,
  syncExpenseWithAccountingProvider,
  loading,
}: {
  readonly selectedExpense: Expense | undefined | null;
  readonly syncExpenseWithAccountingProvider: (expenseId: string) => Promise<void>;
  readonly loading: boolean;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15 }}>
      <Typography variant="title4" sx={{ mbb: spacing.s2 }}>
        {polyglot.t('ContractorInvoiceModal.accountingIntegration')}
      </Typography>
      {selectedExpense?.externalId && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">External invoice</Typography>
          <a
            href={`https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${selectedExpense.externalId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Box component="span" sx={themeFonts.caption}>
              See here
            </Box>
          </a>
        </Box>
      )}
      {selectedExpense &&
      selectedExpense.lineItems &&
      selectedExpense.lineItems?.some((item) => item.accountingCode !== null) ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            {Array.from(new Set(selectedExpense.lineItems.map((item) => item.accountingCode).filter(Boolean))).map(
              (accountingCode) => {
                const item = selectedExpense.lineItems.find((i) => i.accountingCode === accountingCode);
                return (
                  <Typography variant="caption" key={accountingCode}>
                    {`${polyglot.t('PaymentSettingsPage.tableColumns.accountingCode')} (${accountingCode}${
                      item?.accountingCodeDescription
                        ? truncateWithEllipses(' - '.concat(item?.accountingCodeDescription), 15)
                        : ''
                    })`}
                  </Typography>
                );
              }
            )}
          </Box>
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            disabled={loading}
            onClick={async () => {
              await syncExpenseWithAccountingProvider(selectedExpense.id);
            }}
          >
            <RefreshIcon sx={{ ...iconSize }} />
            {polyglot.t(
              selectedExpense?.externalId ? 'ContractorInvoiceModal.syncAgain' : 'ContractorInvoiceModal.syncExternally'
            )}
          </ButtonComponent>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">{polyglot.t('ExpenseModal.noAccountingCode')}</Typography>
        </Box>
      )}
    </Box>
  );
};
