import React, { useMemo, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { NotEnrolledInPension } from '@/v2/feature/payroll/features/payroll-uk/components/not-enrolled-in-pension.component';
import { EditEmployerCostPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-employer-cost-page.component';
import { EditPayrunEntryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component';
import {
  CurrencyWithDiff,
  ValueWithDiff,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { sum } from '@/v2/util/array.util';

type PayrunEmployerCostTableProps = {
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  payCodes?: StaffologyPayCode[] | null;
  payrunClosed: boolean;
  sx?: SxProps<Theme>;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
};

export const PayrunEmployerCostTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx,
}: PayrunEmployerCostTableProps) => {
  const [editEmployerCost, setEditEmployerCost] = useState<PayRunEntryDto>();

  const [empCostTotals, prevEmpCostTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => ({
      income: sum(entries, (e) => e.income ?? 0),
      employerNi: sum(entries, (e) => e.employerNi ?? 0),
      employerPension: sum(entries, (e) => e.employerPension ?? 0),
      employerCost: sum(entries, (e) => e.employerCost ?? 0),
    });
    return [
      calculateTotals(entries),
      (previousEntries.length ? calculateTotals(previousEntries) : {}) as Record<
        keyof ReturnType<typeof calculateTotals>,
        number | undefined
      >,
    ];
  }, [entries, previousEntries]);

  const columnData = useMemo<ColumnDef<PayRunEntryDto, PayRunEntryDto>[]>(() => {
    const previousById = new Map<string, PayRunEntryDto>(previousEntries.map((entry) => [entry.employee.id, entry]));
    return [
      {
        id: 'employee',
        header: () => 'Employee',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (entry) => entry.employee.name),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => 'Total',
        size: 100,
      },
      {
        id: 'income',
        header: () => 'Income',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => entry.income ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(entry) => entry.income ?? 0}
          />
        ),
        footer: () => <CurrencyWithDiff currentValue={empCostTotals.income} previousValue={prevEmpCostTotals.income} />,
        size: 60,
      },
      {
        id: 'ni',
        header: () => 'National Insurance',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => entry.employerNi ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(entry) => entry.employerNi ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={empCostTotals.employerNi} previousValue={prevEmpCostTotals.employerNi} />
        ),
        size: 80,
      },
      {
        id: 'pension',
        header: () => 'Pension',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => entry.employerPension ?? 0),
        cell: (c) =>
          'employerPensionContribution' in c.getValue().totals ? (
            <ValueWithDiff
              current={c.getValue()}
              previous={previousById.get(c.getValue().employee.id)}
              getValue={(entry) => entry.employerPension ?? 0}
            />
          ) : (
            <NotEnrolledInPension userId={c.row.original.userId} />
          ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={empCostTotals.employerPension}
            previousValue={prevEmpCostTotals.employerPension}
          />
        ),
        size: 80,
      },
      {
        id: 'total-employer-cost',
        header: () => 'Total employer cost',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => entry.employerCost ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(entry) => entry.employerCost ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={empCostTotals.employerCost} previousValue={prevEmpCostTotals.employerCost} />
        ),
        size: 100,
      },
    ];
  }, [empCostTotals, previousEntries, prevEmpCostTotals]);

  return (
    <Box sx={sx}>
      <BasicTable
        rowData={entries}
        columnData={columnData}
        hidePagination
        showFooter
        rowClick={(row) => setEditEmployerCost(row.original)}
      />
      <EditPayrunEntryDrawer
        userId={editEmployerCost?.userId}
        payrunEntry={editEmployerCost}
        payCodes={payCodes}
        payrunClosed={payrunClosed}
        onClose={() => setEditEmployerCost(undefined)}
        saveIncomeUpdates={saveIncomeUpdates}
        Content={EditEmployerCostPage}
      />
    </Box>
  );
};
