import React, { useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { CompanyDepartmentAPI } from '@/api-client/company-department.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BasicUser, CompanyDepartmentWithMemberDetailsDto } from '@/models/company-department.model';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface IDepartmentFormModal {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedDepartment?: CompanyDepartmentWithMemberDetailsDto;
  readonly refreshDepartments: () => Promise<void>;
  readonly closePage: () => void;
}

interface DepartmentManagementFormData {
  name: string;
  employees: BasicUser[];
}

const DepartmentFormSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9 ]*$/, 'Please avoid using space or any special character')
    .required('Department name is required'),
});
export const DepartmentFormModal = ({
  isOpen,
  setIsOpen,
  selectedDepartment,
  refreshDepartments,
  closePage,
}: IDepartmentFormModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={closePage}>
      <DepartmentFormModalContent
        selectedDepartment={selectedDepartment}
        refreshDepartments={refreshDepartments}
        closePage={closePage}
      />
    </DrawerModal>
  );
};

interface IDepartmentFormModalContent {
  readonly selectedDepartment?: CompanyDepartmentWithMemberDetailsDto;
  readonly refreshDepartments: () => Promise<void>;
  readonly closePage: () => void;
}

const DepartmentFormModalContent = ({
  selectedDepartment,
  refreshDepartments,
  closePage,
}: IDepartmentFormModalContent) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [departmentToDelete, setDepartmentToDelete] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const deleteDepartment = async () => {
    try {
      if (departmentToDelete) await CompanyDepartmentAPI.deleteCompanyDepartment(departmentToDelete);
      else throw new Error('No department to delete');
      showMessage('Successfully deleted department', 'success');
      await refreshDepartments();
    } catch (error) {
      showMessage(`Error encountered when trying to delete department: ${nestErrorMessage(error)}`, 'error');
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, departmentId: number) => {
    if (event) setAnchorEl(event.currentTarget);
    setDepartmentToDelete(departmentId);
    setIsConfirmModalOpen(true);
  };

  const formik = useFormik<DepartmentManagementFormData>({
    initialValues: selectedDepartment
      ? { name: selectedDepartment?.name ?? '', employees: selectedDepartment?.users ?? [] }
      : { name: '', employees: [] },
    validationSchema: DepartmentFormSchema,
    onSubmit: async (values): Promise<void> => {
      try {
        setIsSubmitting(true);
        if (selectedDepartment && selectedDepartment.id) {
          await CompanyDepartmentAPI.updateCompanyDepartment(selectedDepartment.id, {
            name: values.name,
          });
          showMessage(`Successfully updated department`, 'success');
        } else {
          await CompanyDepartmentAPI.addCompanyDepartment({ name: values.name });
          showMessage(`Successfully added new department`, 'success');
        }
        refreshDepartments();
        closePage();
      } catch (error) {
        showMessage(
          `Failed to ${selectedDepartment ? 'update' : 'add'} department: ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="title2">{selectedDepartment ? 'Edit department' : 'Add department'}</Typography>
          {selectedDepartment && (
            <IconButton
              key="delete-ph"
              colorVariant="secondary"
              sizeVariant="small"
              onClick={(event) => confirmDelete(event, selectedDepartment.id)}
            >
              <Trash {...iconSize} />
            </IconButton>
          )}
        </Box>
        <TextfieldComponent
          name="name"
          label={polyglot.t('DepartmentManagementDrawerPage.departmentNameLabel')}
          value={formik.values.name}
          type="text"
          onChange={(e) => {
            const updatedValue = e.currentTarget.value;
            formik.setFieldValue('name', updatedValue);
          }}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) ?? ' '}
          clearText={() => formik.setFieldValue('name', '')}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            fullWidth
            type="submit"
            loading={isSubmitting}
            colorVariant="primary"
            sizeVariant="medium"
            disabled={isSubmitting || Object.keys(formik.errors).length > 0}
            name={polyglot.t('General.save')}
          />
        </Box>

        <NotificationModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
          }}
          anchorEl={anchorEl}
          takeAction={async () => {
            if (departmentToDelete) {
              setIsConfirmModalOpen(false);
              closePage();
              await deleteDepartment();
            } else showMessage('No department selected', 'error');
          }}
          message={polyglot.t('DepartmentManagementDrawerPage.confirmDeleteMessage')}
          callToAction="Yes"
        />
      </Form>
    </FormikProvider>
  );
};
