import {
  PayScheduleEnum,
  SalaryBasisEnum,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { OptionObject } from '@v2/components/forms/select.component';
import Polyglot from 'node-polyglot';

export const MonthlyPayScheduleOption = (polyglot: Polyglot | null): { value: PayScheduleEnum; label: string } => ({
  value: PayScheduleEnum.Monthly,
  label: polyglot ? polyglot.t('PaySchedules.monthly') : 'Monthly',
});
export const WeeklyPayScheduleOption = (polyglot: Polyglot | null): { value: PayScheduleEnum; label: string } => ({
  value: PayScheduleEnum.Weekly,
  label: polyglot ? polyglot.t('PaySchedules.weekly') : 'Weekly',
});

export const MonthlySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Monthly,
  label: polyglot ? polyglot.t('PaySchedules.monthly') : 'Monthly',
});
export const WeeklySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Weekly,
  label: polyglot ? polyglot.t('PaySchedules.weekly') : 'Weekly',
});
export const DailySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Daily,
  label: polyglot ? polyglot.t('PaySchedules.daily') : 'Daily',
});
export const HourlySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Hourly,
  label: polyglot ? polyglot.t('PaySchedules.hourly') : 'Hourly',
});
export const AnnualSalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Annual,
  label: polyglot ? polyglot.t('PaySchedules.annual') : 'Annual',
});

export const PaySchedules = (polyglot: Polyglot | null) => [
  MonthlyPayScheduleOption(polyglot),
  WeeklyPayScheduleOption(polyglot),
];

export const SalaryBasis = (polyglot: Polyglot | null) => [
  AnnualSalaryBasisOption(polyglot),
  MonthlySalaryBasisOption(polyglot),
  WeeklySalaryBasisOption(polyglot),
  DailySalaryBasisOption(polyglot),
  HourlySalaryBasisOption(polyglot),
];

export const displayRateLabel = (salaryBasis: SalaryBasisEnum, polyglot: Polyglot) => {
  switch (salaryBasis) {
    case SalaryBasisEnum.Annual:
      return polyglot.t('CompensationForm.annualSalary');
    case SalaryBasisEnum.Monthly:
      return polyglot.t('CompensationForm.monthlySalary');
    case SalaryBasisEnum.Daily:
      return polyglot.t('CompensationForm.dailyRate');
    case SalaryBasisEnum.Hourly:
      return polyglot.t('CompensationForm.hourlyRate');
    default:
      return polyglot.t('CompensationForm.rate');
  }
};

export const equityTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'Share', label: polyglot.t('equityTypeOptions.share') },
    { value: 'Option', label: polyglot.t('equityTypeOptions.option') },
    { value: 'RSU', label: polyglot.t('equityTypeOptions.rsu') },
  ];
};
