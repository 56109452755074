import React from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

type RowDataType = { id: number };

interface SelectDeselectRowsProps {
  readonly setSelectionModel: React.Dispatch<React.SetStateAction<number[]>>;
  readonly selectionModel: number[];
  readonly rows: readonly RowDataType[];
  readonly hideSelectAll?: boolean;
}

export const SelectDeselectRows = ({
  setSelectionModel,
  selectionModel,
  rows,
  hideSelectAll = false,
}: SelectDeselectRowsProps): React.JSX.Element | null => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g15 }}>
      {selectionModel.length > 0 && (
        <>
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="filter"
            key={selectionModel.length}
            onClick={() => {
              setSelectionModel([]);
            }}
          >
            {`${selectionModel?.length} selected`}
            &nbsp;
            <Close {...iconSize} stroke={themeColors.DarkGrey} fill={themeColors.DarkGrey} />
          </ButtonComponent>
          {!hideSelectAll && selectionModel.length < rows.length && (
            <ButtonComponent
              sizeVariant="small"
              colorVariant="text"
              onClick={() => {
                setSelectionModel(rows.map((r) => r.id));
              }}
            >
              {polyglot.t('SelectDeselectRows.selectAll')}
            </ButtonComponent>
          )}
        </>
      )}
    </Box>
  );
};
