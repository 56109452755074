import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { getPeriodFromAbsence } from '@v2/feature/absence/absence.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { isMonday, isSunday } from 'date-fns';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Right } from '@/images/fields/Right.svg';
import { nestErrorMessage } from '@/lib/errors';
import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { AbsenceStatus } from '@/v2/feature/absence/absence.interface';
import { AbsenceDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { AbsenceViewDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';
import { TableEntryProps } from '@/v2/feature/calendar/features/calendar.page';
import { OverlappingCounter } from '@/v2/feature/calendar/features/components/overlapping-counter.component';
import { borders } from '@/v2/styles/borders.styles';
import { DARK_CONTRAST_COLOR, themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly absence: AbsenceDto;
  readonly date: string;
  readonly refreshAbsences: () => Promise<void>;
  readonly activeView: 'Month' | 'Week';
  readonly overlappingEventsCounter: number;
  readonly setSelectedEventOnDate: React.Dispatch<React.SetStateAction<TableEntryProps | undefined>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly entry: TableEntryProps;
  readonly setAbsenceIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isAbsenceViewOpen: boolean;
}

const getWeekWidth = (
  absence: AbsenceDto,
  dateIsStartDate: boolean,
  dateIsEndDate: boolean | '' | null | undefined
): string => {
  return (absence.morningOnly && dateIsEndDate) || (absence.afternoonOnly && dateIsStartDate) ? '100%' : '100%';
};

// TODO: @polyglot-later
export const CalendarAbsenceEvent = ({
  absence,
  date,
  refreshAbsences,
  activeView,
  overlappingEventsCounter,
  setSelectedEventOnDate,
  setIsOpen,
  entry,
  setAbsenceIsViewOpen,
  isAbsenceViewOpen,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [state, globalDispatch] = useContext(GlobalContext);
  const { user } = state;

  const { hasScopes, getScopesContext } = useScopes();

  const [showMessage] = useMessage();
  const dateIsEndDate = absence.end && absence.end === date;
  const dateIsStartDate = absence.start === date;

  const [drawerAbsence, setDrawerAbsence] = useState<AbsenceDto | undefined>(undefined);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState<boolean>(false);

  const isAdmin = hasScopes(['absence:all'], getScopesContext(user));

  const isPending = useMemo(
    () =>
      absence.canApprove ||
      absence.canForceApprove ||
      absence.canReject ||
      absence.canRejectCancellation ||
      absence.canApproveCancellation ||
      absence.canRejectCancellation,
    [absence]
  );

  const approveAbsence = useCallback(
    async (a: AbsenceDto): Promise<AbsenceDto | undefined> => {
      try {
        const [updatedAbsence] = await AbsenceAPI.approveAllAbsences([a.absenceId]);
        showMessage('Time entry approved successfully.', 'success');
        await refreshAbsences();
        return updatedAbsence;
      } catch (err) {
        showMessage('Something went wrong.', 'error');
      }
    },
    [refreshAbsences, showMessage]
  );

  const rejectAbsence = useCallback(
    async (absence: AbsenceDto, rejectionNotes?: string): Promise<AbsenceDto | undefined> => {
      try {
        const [updatedAbsence] = await AbsenceAPI.rejectAllAbsences([absence.absenceId], rejectionNotes);
        showMessage('Time entry rejected successfully.', 'success');
        await refreshAbsences();
        return updatedAbsence;
      } catch (err) {
        showMessage('Something went wrong.', 'error');
      }
    },
    [refreshAbsences, showMessage]
  );

  const removeRequestHelper = useCallback(
    async (absence: AbsenceDto) => {
      if (absence) {
        try {
          await AbsenceAPI.deleteAbsenceRecord(absence.absenceId);
          showMessage('Time entry removed successfully.', 'success');

          await refreshAbsences();
        } catch (error) {
          showMessage(`The time entry could not be removed. Please try again. ${nestErrorMessage(error)}`, 'error');
        }
      }
    },
    [refreshAbsences, showMessage]
  );

  const onForceApproval = useCallback(
    async (absence: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => {
      try {
        await AbsenceAPI.forceApprovalByAbsencesIds([absence.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved
              ? 'PersonalAbsence.successMessages.approve'
              : 'PersonalAbsence.successMessages.reject'
          ),
          'success'
        );
        await refreshAbsences();
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.cancel', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [refreshAbsences, showMessage, polyglot]
  );

  const onRequestCancellation = useCallback(
    async (absence: AbsenceDto) => {
      try {
        await AbsenceAPI.requestTimeRequestCancellation(absence.absenceId);
        showMessage('Requested cancellation successfully.', 'success');
        await refreshAbsences();
      } catch (error) {
        showMessage(`Could not request cancellation of time request. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAbsences, showMessage]
  );

  const handleRejectCancellation = useCallback(
    async (abs: AbsenceDto) => {
      try {
        await AbsenceAPI.rejectTimeCancellationRequests([abs.absenceId], abs.userId);
        showMessage('Time cancellation request rejected successfully.', 'success');
        await refreshAbsences();
        const alertsForAbsences = await AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences },
        });
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage, refreshAbsences, user.userId, globalDispatch]
  );

  return (
    <>
      {!!absence.end ? (
        <Box
          onClick={() => {
            setSelectedEventOnDate(entry);
            setIsOpen(true);
          }}
          sx={{
            display: 'flex',
            width: '100%',
            height: isPending ? '46px' : '48px',
            justifyContent: dateIsEndDate ? 'flex-start' : dateIsStartDate ? 'flex-end' : 'center',
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              position: 'relative',
              display: 'flex',
              backgroundColor: isPending ? 'none' : absence.policy?.color ?? themeColors.VioletMiddle,
              overflow: 'hidden',
              padding: '0px',
              width: getWeekWidth(absence, dateIsStartDate, dateIsEndDate),
              borderTop: isPending ? '1px dashed #9E9E9E' : 'none', //pending
              borderBottom: isPending ? '1px dashed #9E9E9E' : 'none', //pending
              borderLeft: isPending && dateIsStartDate ? '1px dashed #9E9E9E' : 'none', //pending
              borderRight: isPending && dateIsEndDate ? '1px dashed #9E9E9E' : 'none', //pending
              '&:hover::before': {
                cursor: 'pointer',
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 1,
              },
              '& > *': {
                position: 'relative',
                zIndex: 1,
              },
            }}
          >
            {dateIsStartDate && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g5,
                  px: spacing.p5,
                }}
              >
                <div style={{ display: activeView === 'Week' ? 'inherit' : 'none' }}>
                  {isPending && (
                    <Typography
                      variant="captionSmall"
                      sx={{
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {absence.status === AbsenceStatus.Approved && absence.cancellationRequested
                        ? 'Cancellation requested'
                        : 'Pending'}
                    </Typography>
                  )}
                  {!isPending && (
                    <Box sx={{ display: 'grid' }}>
                      <Typography
                        variant="captionSmall"
                        color={
                          DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle)
                            ? 'white'
                            : 'DarkGrey'
                        }
                        sx={{
                          maxWidth: '100%',
                          display: 'block',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {getPeriodFromAbsence(absence)}
                      </Typography>
                      {absence.policy?.name && (
                        <Typography
                          variant="captionSmall"
                          color={
                            DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle)
                              ? 'white'
                              : 'Grey'
                          }
                        >
                          {absence.policy.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                </div>
              </Box>
            )}

            {isMonday(new LocalDate(date).getDate()) && date > absence.start && activeView === 'Week' && (
              <Box sx={{ display: 'block', px: spacing.p5 }}>
                <Typography
                  variant="captionSmall"
                  color={
                    DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle)
                      ? 'white'
                      : 'DarkGrey'
                  }
                >
                  {getPeriodFromAbsence(absence)}
                </Typography>
                <Typography
                  variant="captionSmall"
                  color={
                    DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle) ? 'white' : 'Grey'
                  }
                >
                  {isPending ? 'Pending' : absence.policy?.name ?? 'Time request'}
                </Typography>
              </Box>
            )}

            {isSunday(new LocalDate(date).getDate()) && date > absence.start && activeView === 'Week' && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'flex-end',
                  px: spacing.p5,
                }}
              >
                <Right {...iconSize} />
              </Box>
            )}
          </Box>
          {overlappingEventsCounter > 0 && (
            <OverlappingCounter overlappingEventsCounter={overlappingEventsCounter} activeView={activeView} />
          )}
        </Box>
      ) : (
        <Box
          onClick={() => {
            setSelectedEventOnDate(entry);
            setIsOpen(true);
          }}
          sx={{
            display: 'flex',
            width: '100%',
            height: isPending ? '46px' : '48px',
            justifyContent: absence.morningOnly ? 'flex-start' : absence.afternoonOnly ? 'flex-end' : 'center',
            cursor: 'pointer',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              backgroundColor: isPending ? themeColors.white : absence.policy?.color ?? themeColors.VioletMiddle,
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
              outline: isPending ? borders.dash : 'none',
              '&:hover::before': {
                cursor: 'pointer',
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 1,
              },
              '& > *': {
                position: 'relative',
                zIndex: 1,
              },
            }}
          >
            {dateIsStartDate && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: spacing.p5,
                  overflowX: 'hidden',
                }}
              >
                <div style={{ maxWidth: 'inherit', display: activeView === 'Week' ? 'inherit' : 'none' }}>
                  {isPending && (
                    <Typography
                      variant="captionSmall"
                      sx={{
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {absence.status === AbsenceStatus.Approved && absence.cancellationRequested
                        ? 'Cancellation requested'
                        : 'Pending'}
                    </Typography>
                  )}
                  {!isPending && (
                    <Box sx={{ display: 'block' }}>
                      <Typography
                        variant="captionSmall"
                        color={
                          DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle)
                            ? 'white'
                            : 'DarkGrey'
                        }
                      >
                        {absence.morningOnly || absence.afternoonOnly ? 'Half day' : 'All day'}
                      </Typography>
                      {absence.policy?.name && (
                        <Typography
                          variant="captionSmall"
                          color={
                            DARK_CONTRAST_COLOR.includes(absence.policy?.color ?? themeColors.VioletMiddle)
                              ? 'white'
                              : 'Grey'
                          }
                        >
                          {absence.policy.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                </div>
              </Box>
            )}
          </Box>

          {overlappingEventsCounter > 0 && (
            <OverlappingCounter overlappingEventsCounter={overlappingEventsCounter} activeView={activeView} />
          )}
        </Box>
      )}

      <AbsenceDrawer
        isOpen={isAbsenceDrawerOpen}
        onClose={() => {
          setIsAbsenceDrawerOpen(false);
          setDrawerAbsence(undefined);
        }}
        refresh={refreshAbsences}
        absence={drawerAbsence}
        setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
        reach={isAdmin ? 'company' : 'team'}
      />
      {absence && (
        <AbsenceViewDrawer
          isOpen={isAbsenceViewOpen}
          absence={absence}
          setIsAbsenceDrawerViewOpen={setAbsenceIsViewOpen}
          handleApprove={isPending ? approveAbsence : undefined}
          handleReject={isPending ? rejectAbsence : undefined}
          onRequestCancellation={onRequestCancellation}
          onRejectCancellation={handleRejectCancellation}
          onDelete={removeRequestHelper}
          onForceApproval={onForceApproval}
          onEdit={(absence: AbsenceDto) => {
            setDrawerAbsence(absence);
            setIsAbsenceDrawerOpen(true);
            setAbsenceIsViewOpen(false);
          }}
          showCalendarLink={false}
          refresh={refreshAbsences}
        />
      )}
    </>
  );
};
