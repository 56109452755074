import { useMemo, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  FilterTypeOption,
  ReportFilterCategory,
  ReportFilterSelected,
  ReportSQLOperator,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { capitalize } from 'lodash';
import Polyglot from 'node-polyglot';

import { ReactComponent as Close } from '@/images/fields/Close.svg';

export enum ThisFilterOptions {
  Day = 'D',
  Week = 'W',
  Month = 'M',
  Quarter = 'Q',
  Year = 'Y',
  WeekSoFar = 'WsF',
  MonthSoFar = 'MsF',
  QuarterSoFar = 'QsF',
  YearSoFar = 'YsF',
}

export enum LastFilterOptions {
  Last1D = 'D1',
  Last2D = 'D2',
  Last7D = 'D7',
  Last14D = 'D14',
  Last30D = 'D30',
  Last60D = 'D60',
  Last90D = 'D90',
  Last365D = 'D365',
  Week = 'W',
  Month = 'M',
  Quarter = 'Q',
  Year = 'Y',
}

export enum NextFilterOptions {
  Next1D = 'D1',
  Next2D = 'D2',
  Next7D = 'D7',
  Next14D = 'D14',
  Next30D = 'D30',
  Next60D = 'D60',
  Next90D = 'D90',
  Next365D = 'D365',
  Week = 'W',
  Month = 'M',
  Quarter = 'Q',
  Year = 'Y',
}

interface FilterItem {
  stub: string;
  category: string;
  filterKey: string;
  label: string;
  op: ReportSQLOperator;
  value: string | number | string[] | number[];
  valueLabels: string[];
  type: FilterTypeOption;
  disabled: boolean;
}

interface SectionProps {
  readonly selectedFilters: SelectedFiltersRequest;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFiltersRequest>>;
  readonly editFilter: (selectedFilter: ReportFilterSelected) => void;
  readonly reportFilters: readonly ReportFilterCategory[];
}

export const FiltersListSection = ({
  selectedFilters,
  setSelectedFilters,
  editFilter,
  reportFilters,
}: SectionProps) => {
  const filters: FilterItem[] = useMemo(() => {
    return Object.keys(selectedFilters).flatMap((stub) => {
      return Object.keys(selectedFilters[stub]).flatMap((filterKey) => {
        return (selectedFilters[stub][filterKey] ?? []).map((filterValue) => {
          const filterCat = reportFilters.find((f) => f.stub === stub);

          if (!filterCat?.filters || !filterCat.filters[filterKey])
            return {
              stub,
              filterKey,
              category: filterCat?.category ?? '',
              label: `Undefined filter (${filterCat?.category ?? 'Please delete this filter and try again'})`,
              op: filterValue.op,
              value: filterValue.value,
              valueLabels: [''],
              type: FilterTypeOption.string,
              disabled: Boolean(filterValue.isMandatory),
            };

          const valueLabels =
            // if option && op is '='
            filterCat?.filters[filterKey].type &&
            [FilterTypeOption.intOption, FilterTypeOption.stringOption].includes(filterCat?.filters[filterKey].type) &&
            filterValue.op === ReportSQLOperator.eq
              ? [
                  filterCat?.filters[filterKey].options.find((option) => option.value === filterValue.value)?.label ??
                    String(filterValue.value),
                ]
              : // if operator is between
              filterValue.op === ReportSQLOperator.between || filterValue.op === ReportSQLOperator.contains
              ? (filterValue.value as (string | number)[]).map(String)
              : // else if the value is object (op = 'in')
              typeof filterValue.value === 'object'
              ? (filterValue.value as (string | number)[]).map((v) =>
                  capitalize(
                    filterCat?.filters[filterKey]?.options?.find((option) => option.value === v)?.label ?? String(v)
                  )
                )
              : [];

          return {
            stub,
            filterKey,
            category: filterCat?.category ?? '',
            label: filterCat?.filters[filterKey].label ?? '',
            op: filterValue.op,
            value: filterValue.value,
            valueLabels,
            type:
              filterCat?.filters[filterKey].type ??
              (typeof filterValue.value === 'number' ? FilterTypeOption.number : FilterTypeOption.string),
            disabled: Boolean(filterValue.isMandatory),
          };
        });
      });
    });
  }, [reportFilters, selectedFilters]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.s1,
        overflowY: 'hidden',
      }}
    >
      <Typography variant="caption" color="Grey">
        Applied
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          height: '100%',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          paddingRight: '4px',
        }}
      >
        {filters.map((filter, index) => (
          <FilterDisplay
            key={`${index}`}
            filter={filter}
            setSelectedFilters={setSelectedFilters}
            editFilter={editFilter}
            isLast={index === filters.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
};

interface FilterDisplayProps {
  readonly filter: FilterItem;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFiltersRequest>>;
  readonly editFilter: (selectedFilter: ReportFilterSelected) => void;
  readonly isLast: boolean;
}

const FilterDisplay = ({ filter, setSelectedFilters, editFilter, isLast }: FilterDisplayProps) => {
  const { polyglot } = usePolyglot();
  const [isOverFilter, setIsOverFilter] = useState(false);
  const [isOverClose, setIsOverClose] = useState(false);

  function unselectFilter(filter: FilterItem): void {
    if (filter.disabled) return;
    setSelectedFilters((prev) => {
      const copy = { ...prev };
      const newFilters = copy[filter.stub][filter.filterKey].filter(
        (f) => f.value !== filter.value || f.op !== filter.op
      );
      if (newFilters.length > 0) {
        copy[filter.stub][filter.filterKey] = newFilters;
      } else {
        delete copy[filter.stub][filter.filterKey];
      }

      if (Object.keys(copy[filter.stub]).length === 0) delete copy[filter.stub];

      return copy;
    });
  }

  return (
    <Box>
      <Box
        sx={{
          bgcolor: themeColors.Background,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
        onMouseEnter={() => setIsOverFilter(true)}
        onMouseLeave={() => setIsOverFilter(false)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 0.5 }}>
          <Box sx={{ p: 0.5 }}>
            <Typography variant="title4">{filter.category}</Typography>
          </Box>
          {isOverFilter && !filter.disabled && (
            <Box
              sx={{ cursor: 'pointer', p: 0.5 }}
              onClick={!filter.disabled ? () => unselectFilter(filter) : undefined}
              onMouseEnter={!filter.disabled ? () => setIsOverClose(true) : undefined}
              onMouseLeave={!filter.disabled ? () => setIsOverClose(false) : undefined}
            >
              <Close {...iconSize} stroke={isOverClose ? themeColors.black : themeColors.Grey} />
            </Box>
          )}
        </Box>
        <Box
          onClick={
            !filter.disabled
              ? () =>
                  editFilter({
                    stub: filter.stub,
                    filterKey: filter.filterKey,
                    op: filter.op,
                    value: filter.value,
                    type: 'edit',
                  })
              : undefined
          }
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            p: 0.5,
            cursor: !filter.disabled ? 'pointer' : undefined,
            ':hover': {
              background: themeColors.GreyHover,
              borderRadius: 1,
            },
          }}
        >
          <Typography variant="caption">{getAppliedFilterLabel(filter, polyglot)}</Typography>
        </Box>
      </Box>
      {!isLast && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 2, px: 3 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography variant="caption">and</Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>
      )}
    </Box>
  );
};

const getDefaultLabel = (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`;

const ThisFilterLabels: Record<ThisFilterOptions, (filter: FilterItem) => string> = {
  [ThisFilterOptions.Day]: (filter: FilterItem) => `${filter.label} is today`,
  [ThisFilterOptions.Week]: (filter: FilterItem) => `${filter.label} is in this week`,
  [ThisFilterOptions.WeekSoFar]: (filter: FilterItem) => `${filter.label} is in this week so far`,
  [ThisFilterOptions.Month]: (filter: FilterItem) => `${filter.label} is in this month`,
  [ThisFilterOptions.MonthSoFar]: (filter: FilterItem) => `${filter.label} is in this month so far`,
  [ThisFilterOptions.Quarter]: (filter: FilterItem) => `${filter.label} is in this quarter`,
  [ThisFilterOptions.QuarterSoFar]: (filter: FilterItem) => `${filter.label} is in this quarter so far`,
  [ThisFilterOptions.Year]: (filter: FilterItem) => `${filter.label} is in this year`,
  [ThisFilterOptions.YearSoFar]: (filter: FilterItem) => `${filter.label} is in this year so far`,
};

const LastFilterLabels: Record<LastFilterOptions, (filter: FilterItem) => string> = {
  [LastFilterOptions.Last1D]: (filter: FilterItem) => `${filter.label} is yesterday`,
  [LastFilterOptions.Last2D]: (filter: FilterItem) => `${filter.label} is in last 2 days`,
  [LastFilterOptions.Last7D]: (filter: FilterItem) => `${filter.label} is in last 7 days`,
  [LastFilterOptions.Last14D]: (filter: FilterItem) => `${filter.label} is in last 14 days`,
  [LastFilterOptions.Last30D]: (filter: FilterItem) => `${filter.label} is in last 30 days`,
  [LastFilterOptions.Last60D]: (filter: FilterItem) => `${filter.label} is in last 60 days`,
  [LastFilterOptions.Last90D]: (filter: FilterItem) => `${filter.label} is in last 90 days`,
  [LastFilterOptions.Last365D]: (filter: FilterItem) => `${filter.label} is in last 365 days`,
  [LastFilterOptions.Week]: (filter: FilterItem) => `${filter.label} is in last week`,
  [LastFilterOptions.Month]: (filter: FilterItem) => `${filter.label} is in last month`,
  [LastFilterOptions.Quarter]: (filter: FilterItem) => `${filter.label} is in last quarter`,
  [LastFilterOptions.Year]: (filter: FilterItem) => `${filter.label} is in last year`,
};

const NextFilterLabels: Record<NextFilterOptions, (filter: FilterItem) => string> = {
  [NextFilterOptions.Next1D]: (filter: FilterItem) => `${filter.label} is tomorrow`,
  [NextFilterOptions.Next2D]: (filter: FilterItem) => `${filter.label} is in next 2 days`,
  [NextFilterOptions.Next7D]: (filter: FilterItem) => `${filter.label} is in next 7 days`,
  [NextFilterOptions.Next14D]: (filter: FilterItem) => `${filter.label} is in next 14 days`,
  [NextFilterOptions.Next30D]: (filter: FilterItem) => `${filter.label} is in next 30 days`,
  [NextFilterOptions.Next60D]: (filter: FilterItem) => `${filter.label} is in next 60 days`,
  [NextFilterOptions.Next90D]: (filter: FilterItem) => `${filter.label} is in next 90 days`,
  [NextFilterOptions.Next365D]: (filter: FilterItem) => `${filter.label} is in next 365 days`,
  [NextFilterOptions.Week]: (filter: FilterItem) => `${filter.label} is in next week`,
  [NextFilterOptions.Month]: (filter: FilterItem) => `${filter.label} is in next month`,
  [NextFilterOptions.Quarter]: (filter: FilterItem) => `${filter.label} is in next quarter`,
  [NextFilterOptions.Year]: (filter: FilterItem) => `${filter.label} is in next year`,
};

const AppliedFilterLabelSchema: Record<ReportSQLOperator, (filter: FilterItem, polyglot: Polyglot) => string> = {
  [ReportSQLOperator.this]: (filter: FilterItem) => {
    if (typeof filter.value !== 'string') return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = ThisFilterLabels[filter.value as ThisFilterOptions];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;

    return labelFunc(filter);
  },
  [ReportSQLOperator.last]: (filter: FilterItem) => {
    if (typeof filter.value !== 'string') return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = LastFilterLabels[filter.value as LastFilterOptions];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;

    return labelFunc(filter);
  },
  [ReportSQLOperator.next]: (filter: FilterItem) => {
    if (typeof filter.value !== 'string') return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = NextFilterLabels[filter.value as NextFilterOptions];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;

    return labelFunc(filter);
  },
  [ReportSQLOperator.eq]: (filter: FilterItem) => {
    if ([FilterTypeOption.intOption, FilterTypeOption.stringOption].includes(filter.type) && filter.valueLabels[0])
      return `${filter.label} ${filter.op} ${filter.valueLabels[0]}`;

    return `${filter.label} ${filter.op} ${filter.value}`;
  },
  [ReportSQLOperator.gte]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.lte]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.gt]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.lt]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.not]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.is]: (filter: FilterItem) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.between]: (filter: FilterItem) => `${filter.label} is between ${filter.valueLabels.join(' and ')}`,
  [ReportSQLOperator.isKnown]: (filter: FilterItem) => `${filter.label} is known`,
  [ReportSQLOperator.isUnknown]: (filter: FilterItem) => `${filter.label} is unknown`,

  [ReportSQLOperator.in]: (filter: FilterItem) => {
    if (filter.valueLabels.length === 0) return `${filter.label} is any of (?)`;
    if (filter.valueLabels.length === 1) return `${filter.label} is ${filter.valueLabels[0]}`;
    if (filter.valueLabels.length < 4) return `${filter.label} is any of ${filter.valueLabels.join(', ')}`;
    return `${filter.label} is ${filter.valueLabels.slice(0, 2).join(', ')} or ${filter.valueLabels.length - 2} others`;
  },
  [ReportSQLOperator.contains]: (filter: FilterItem) => {
    if (Array.isArray(filter.value)) {
      const displayS = filter.value.map((v) => `"${v}"`).join(', ');
      return `${filter.label} contains any of ${displayS}`;
    }
    return `${filter.label} contains "${filter.value}"`;
  },
  [ReportSQLOperator.startsWith]: (filter: FilterItem) => `${filter.label} starts with "${filter.value}"`,
  [ReportSQLOperator.endsWith]: (filter: FilterItem) => `${filter.label} ends with ${filter.value}`,

  // DATE PART
  [ReportSQLOperator.dayIs]: (filter: FilterItem) => `${filter.label}'s day is ${Number(filter.value)}`,
  [ReportSQLOperator.monthIs]: (filter: FilterItem, polyglot: Polyglot) =>
    `${filter.label}'s month is ${getMonthName(Number(filter.value), polyglot)}`,
  [ReportSQLOperator.yearIs]: (filter: FilterItem) => `${filter.label}'s year is ${Number(filter.value)}`,
};

function getAppliedFilterLabel(filter: FilterItem, polyglot: Polyglot): string {
  const getLabelFunction = AppliedFilterLabelSchema[filter.op] ?? getDefaultLabel;

  return getLabelFunction(filter, polyglot);
}

function getMonthName(monthNumber: number, polyglot: Polyglot): string {
  const months: { [key: number]: string } = {
    1: polyglot.t('getMonthOptions.january'),
    2: polyglot.t('getMonthOptions.february'),
    3: polyglot.t('getMonthOptions.march'),
    4: polyglot.t('getMonthOptions.april'),
    5: polyglot.t('getMonthOptions.may'),
    6: polyglot.t('getMonthOptions.june'),
    7: polyglot.t('getMonthOptions.july'),
    8: polyglot.t('getMonthOptions.august'),
    9: polyglot.t('getMonthOptions.september'),
    10: polyglot.t('getMonthOptions.october'),
    11: polyglot.t('getMonthOptions.november'),
    12: polyglot.t('getMonthOptions.december'),
  };

  return months[monthNumber] || 'Invalid month';
}
