import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { ContractorInvoiceModal } from '@v2/feature/payments/pages/components/contractor-invoice-modal.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { themeColors } from '@v2/styles/colors.styles';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { DisplayInvoiceUserAvatar } from '@/v2/feature/payments/pages/components/display-invoice-user-avatar.component';
import { ContractorInvoice, ContractorInvoiceStatus } from '@/v2/feature/payments/payments.interface';
import { getContractorInvoiceStatusComponent } from '@/v2/feature/payments/utils/get-contractor-invoice-status.util';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { SelectDeselectIdRows } from '@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

export type IncludableContractorInvoice = IIncludable & ContractorInvoice;
type BasicTableColumnType = ColumnDef<IncludableContractorInvoice, IncludableContractorInvoice>[];

const DATE_FORMAT = 'DD MMM YYYY';

const TabFilter = [
  { name: 'All', value: 'All' },
  { name: 'Draft', value: ContractorInvoiceStatus.Draft },
  { name: ContractorInvoiceStatus.Pending, value: ContractorInvoiceStatus.Pending },
  { name: 'Paid', value: 'Paid' },
  { name: 'Other', value: 'Other' },
];

interface ContractorInvoiceTableProps {
  invoices: ContractorInvoice[];
  onActionPerformed: () => Promise<void>;
  readonly setSelectionModel: Dispatch<SetStateAction<string[]>>;
  readonly selectionModel: string[];
  reach: 'me' | 'team' | 'company';
  loadingInvoices: boolean;
}

export function ContractorInvoiceTable({
  invoices,
  onActionPerformed,
  reach,
  setSelectionModel,
  selectionModel,
  loadingInvoices,
}: ContractorInvoiceTableProps): JSX.Element {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const location = useLocation();
  const queriedInvoiceId = new URLSearchParams(location.search).get('invoiceId');
  const queriedInvoice = invoices.find((invoice) => invoice.id === queriedInvoiceId);

  const { data: settingsAndAppConfig } = useApiClient(PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId(), {
    suspense: false,
  });

  const isAccountingAppConfigured = settingsAndAppConfig?.accountingAppConfigured;

  const [selectedInvoice, setSelectedInvoice] = useState<ContractorInvoice | undefined>(
    queriedInvoiceId ? queriedInvoice : undefined
  );
  const [filterValue, setFilterValue] = useState<string>(
    state.user.features?.invoice?.table?.selectedFilters ?? ContractorInvoiceStatus.Pending
  );
  const [searchInput, setSearchInput] = useState<string>('');

  const [showMessage] = useMessage();
  const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState<boolean>(!!queriedInvoice);
  const currentUserIsAdmin = hasScopes(['invoices:all'], getScopesContext(user));
  const { getCachedUserById } = useCachedUsers();

  const filteredInvoices = useMemo(() => {
    let filteredInvoices = [...invoices];

    // if (filterValue === 'All') do nothing;

    if (filterValue === 'Paid')
      filteredInvoices = invoices.filter(
        (i) =>
          i.status === ContractorInvoiceStatus.Approved &&
          (!i.payment || i.payment.transaction?.status === 'COMPLETED' || i.payment.markPaid)
      );

    if (filterValue === 'Awaiting payment')
      filteredInvoices = invoices.filter(
        (i) =>
          i.status === ContractorInvoiceStatus.Approved &&
          i.payment &&
          i.payment.transaction?.status !== 'COMPLETED' &&
          !i.payment.markPaid
      );

    if (filterValue === ContractorInvoiceStatus.Draft)
      filteredInvoices = invoices.filter((i) => [ContractorInvoiceStatus.Draft].includes(i.status));

    if (filterValue === 'Pending')
      filteredInvoices = invoices.filter((i) => [ContractorInvoiceStatus.Pending].includes(i.status));

    if (filterValue === 'Other')
      filteredInvoices = invoices.filter((i) =>
        [ContractorInvoiceStatus.Rejected, ContractorInvoiceStatus.Voided].includes(i.status)
      );

    // search on beneficiary name, invoice number and total amount
    if (searchInput) {
      const loweredCaseSearch = searchInput.toLowerCase();
      filteredInvoices = filteredInvoices.filter((i) => {
        const beneficiary = getCachedUserById(i.from);
        const name = beneficiary ? beneficiary.displayName : '';
        return (
          i.invoiceNumber?.toLowerCase().includes(loweredCaseSearch) ||
          name.toLowerCase().includes(loweredCaseSearch) ||
          i.totalAmount?.toString().includes(loweredCaseSearch)
        );
      });
    }
    return filteredInvoices;
  }, [invoices, filterValue, searchInput, getCachedUserById]);

  const bulkApproveInvoices = async () => {
    try {
      const invoiceCount = await ContractorInvoiceAPI.bulkApprove(selectionModel);
      setSelectionModel([]);
      await onActionPerformed();
      showMessage(polyglot.t('ContractorInvoiceModal.successMessages.bulkApproval', { invoiceCount }), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('ContractorInvoiceModal.errorMessages.bulkApproval', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const invoicesColumn = useMemo<ColumnDef<IncludableContractorInvoice, unknown>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const selectableInvoiceIds = new Set(filteredInvoices.map(({ id }) => id));
          const allSelected =
            selectionModel.length > 0 &&
            selectionModel.length === selectableInvoiceIds.size &&
            selectionModel.every((id) => selectableInvoiceIds.has(id));
          return (
            <Box onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name="allSelected"
                checked={allSelected}
                value="allSelected"
                onChange={(_, checked) => {
                  setSelectionModel(checked ? [...selectableInvoiceIds] : []);
                }}
              />
            </Box>
          );
        },
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
            <Box onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name={original.id?.toString() ?? ''}
                checked={selectionModel.includes(original.id)}
                value={original.id?.toString() ?? ''}
                onChange={() => {
                  let finalArray: string[];
                  if (selectionModel?.includes(original.id)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.id);
                  } else finalArray = [...selectionModel, original.id];
                  setSelectionModel(finalArray);
                }}
              />
            </Box>
          </Box>
        ),
      },
      ...(reach !== 'me'
        ? [
            new TableColumn<IncludableContractorInvoice>().define({
              header: polyglot.t('PaymentTableHeaders.beneficiary'),
              id: 'from',
              fieldName: 'from',
              enableSorting: true,
              sortingFn: (a, b) =>
                sortString(a, b, (item) => polyglot.t(`${item.fromUser.firstName} ${item.fromUser.lastName}`)),
              parseRow: (row: IncludableContractorInvoice) =>
                row.createdBy ? (
                  <DisplayInvoiceUserAvatar key={row.id} row={row} user={{ idField: 'from', userField: 'fromUser' }} />
                ) : (
                  <EmptyCell />
                ),
            }),
          ]
        : []),

      new TableColumn<IncludableContractorInvoice>().define({
        header: polyglot.t('PaymentTableHeaders.description'),
        id: 'notes',
        enableSorting: false,
        maxSize: 250,
        fieldName: 'notes',
        parseRow: (row: IncludableContractorInvoice) => {
          const truncateLimit = 35;
          const notesTruncated = row?.notes && row.notes.length > truncateLimit;
          return !!notesTruncated ? (
            <StyledTooltip title={row.notes} placement="right">
              <div style={{ paddingRight: '10px' }}>
                {row.notes ? truncateWithEllipses(row.notes, truncateLimit) : ''}
              </div>
            </StyledTooltip>
          ) : (
            <div style={{ paddingRight: '10px' }}>{row.notes}</div>
          );
        },
      }),

      new TableColumn<IncludableContractorInvoice>().define({
        header: polyglot.t('PaymentTableHeaders.dueDate'),
        id: 'dueDate',
        fieldName: 'dueDate',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => new Date(item.dueDate).getTime()),
        formatter: (date) => {
          return (
            <Box sx={{ color: new Date(date).getTime() < Date.now() ? themeColors.RedDark : themeColors.black }}>
              {moment(date).format(DATE_FORMAT)}
            </Box>
          );
        },
        parseRow: (row: IncludableContractorInvoice) => {
          const showAlert =
            [ContractorInvoiceStatus.Pending].includes(row.status) && new Date(row.dueDate).getTime() < Date.now();
          return (
            <Box
              sx={{
                color: showAlert ? themeColors.RedDark : themeColors.black,
              }}
            >
              {moment(row.dueDate).format(DATE_FORMAT)}
            </Box>
          );
        },
      }),

      new TableColumn<IncludableContractorInvoice>().define({
        header: polyglot.t('PaymentTableHeaders.status'),
        id: 'status',
        fieldName: 'status',
        enableSorting: true,
        sortingFn: (a, b) => sortString<IncludableContractorInvoice>(a, b, (item) => item.status),
        parseRow: (row: IncludableContractorInvoice) => {
          return (
            <Box>
              {getContractorInvoiceStatusComponent(
                row.status,
                { ...themeFonts.caption },
                !!isAccountingAppConfigured,
                !!row.externalId
              )}
            </Box>
          );
        },
      }),

      new TableColumn<IncludableContractorInvoice>().define({
        header: polyglot.t('PaymentTableHeaders.amount'),
        id: 'amount',
        fieldName: 'lineItems',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric<IncludableContractorInvoice>(a, b, (item) => item.totalAmount),
        parseRow: (row: IncludableContractorInvoice) => {
          return <div style={{ paddingRight: '10px' }}>{formatCurrency(row.totalAmount, undefined, row.currency)}</div>;
        },
      }),
    ],
    [reach, polyglot, filteredInvoices, selectionModel, setSelectionModel, isAccountingAppConfigured]
  );

  const allSelectedInvoicesArePending = useMemo(() => {
    const selectedInvoices = filteredInvoices.filter((invoice) => selectionModel.includes(invoice.id));
    return selectedInvoices.every((eachInvoice) => eachInvoice.status === ContractorInvoiceStatus.Pending);
  }, [filteredInvoices, selectionModel]);

  const getInvoiceBulkActionsOptions = () => {
    return [
      {
        icon: <Chose {...iconSize} />,
        handler: () => bulkApproveInvoices(),
        label: 'Approve selected',
        disabled: !allSelectedInvoicesArePending,
      },
    ];
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <TabFilterButtons
          filters={TabFilter}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          hasSearch
          onFilterChange={async ({ filterValue, searchInput }) => {
            try {
              setSelectionModel([]);
              setFilterValue(filterValue);
              setSearchInput(searchInput);
              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                'invoice',
                'table',
                'selectedFilters',
                filterValue
              );
              dispatch({
                type: GlobalStateActions.UPDATE_USER,
                payload: updatedGlobalUser,
              });
            } catch (error) {
              showMessage(
                polyglot.t('PaymentTableHeaders.errorMessages.filter', {
                  errorMessage: nestErrorMessage(error),
                }),
                'error'
              );
            }
          }}
        />
        {selectionModel.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: spacing.g5, marginLeft: spacing.ml10 }}>
            <SelectDeselectIdRows<string>
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              rows={filteredInvoices}
              hideSelectAll
            />
            <StyledMenuComponent
              options={getInvoiceBulkActionsOptions()}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'Actions',
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
              }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable<IncludableContractorInvoice>
          rowData={[...filteredInvoices]}
          columnData={(invoicesColumn as unknown) as BasicTableColumnType}
          rowClick={(row) => {
            setSelectedInvoice(row.original);
            setOpenInvoiceDetailsModal(true);
          }}
          initialSort={[{ id: 'dueDate', desc: true }]}
          loading={loadingInvoices}
        />
      </Box>
      {openInvoiceDetailsModal && selectedInvoice && (
        <ContractorInvoiceModal
          isOpen={openInvoiceDetailsModal}
          setIsOpen={setOpenInvoiceDetailsModal}
          selectedInvoice={selectedInvoice}
          onClose={() => setSelectedInvoice(undefined)}
          onActionPerformed={onActionPerformed}
          currentUserIsAdmin={currentUserIsAdmin}
          reach={reach}
        />
      )}
    </Box>
  );
}
