import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { TaskFormModal } from '@v2/feature/task/components/task-add-modal/task-form-modal.component';
import { TaskTable } from '@v2/feature/task/components/task-table/task-table.component';
import { TaskDto } from '@v2/feature/task/task.dto';
import { TaskStatuses, TaskTabFilter } from '@v2/feature/task/task.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { format } from 'date-fns';
import saveAs from 'file-saver';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { TaskLaunchModal } from '@/v2/feature/task/components/task-launch/task-launch-modal.component';
import { SelectDeselectRows } from '@/v2/feature/task/components/task-table/select-deselect-rows.component';
import {
  TaskAndChecklistItemCommon,
  TaskViewModal,
} from '@/v2/feature/task/components/task-view/task-view-modal.component';
import { emojiOptions } from '@/v2/feature/task/subfeature/checklist.interface';
import { TaskAPI, TaskEndpoints } from '@/v2/feature/task/task.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface TasksListPageProps {
  readonly reach: 'team' | 'company';
  readonly isManager: boolean;
}

const iconSize = { width: 14, height: 14 } as const;

export const TasksListPage = ({ reach, isManager }: TasksListPageProps) => {
  const { polyglot } = usePolyglot();
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t('TasksListPage.inSevenDays'), value: TaskTabFilter.InSevenDays },
      { name: polyglot.t('TasksListPage.inThirtyDays'), value: TaskTabFilter.InThirtyDays },
      { name: polyglot.t('TasksListPage.all'), value: TaskTabFilter.All },
      { name: polyglot.t('TasksListPage.done'), value: TaskTabFilter.Done },
    ],
    [polyglot]
  );
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const { user } = state;
  const context = getScopesContext(user);
  const [paginationState, setPaginationState] = useState<PaginationState>({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [tableState, setTableState] = useState<{ filterValue: string; searchInput: string; filterString: string }>({
    filterValue: TaskTabFilter.InSevenDays,
    searchInput: '',
    filterString: '',
  });
  const { filterValue, searchInput, filterString } = tableState;

  const { data: allTasks, isLoading, error, mutate: refreshTasks } = useApiClient(
    TaskEndpoints.listTasks(reach, paginationState, {
      sortField: sortingState[0],
      status: filterValue,
      search: searchInput,
      filterString,
    }),
    {
      suspense: false,
    }
  );

  const { data: allChecklists } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false,
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState<boolean>(false);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState<boolean>(false);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<TaskDto | null>(null);
  const [selectedTask, setSelectedTask] = useState<TaskDto | null>(null);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const hasTaskAll = hasScopes(['task:all'], context);

  const { trackPage } = useJune();

  const [showMessage] = useMessage();

  const updateTableState = useCallback((newState: Partial<typeof tableState>) => {
    setTableState((prevState) => {
      // if the state-change can result in a different set of results, reset the pagination
      if ('filterValue' in newState || 'searchInput' in newState || 'filterString' in newState) {
        setPaginationState((p) => ({ ...p, pageIndex: 1 }));
      }
      return { ...prevState, ...newState };
    });
  }, []);

  const refresh = useCallback(async () => {
    await refreshTasks?.();
  }, [refreshTasks]);

  useEffect(() => {
    if (!isLoading || error) setLoading(false);
  }, [error, isLoading]);

  useEffect(() => {
    if (error) {
      showMessage(polyglot.t('TasksListPage.errorMessages.badRequest', { errorMessage: error.data?.message }), 'error');
    }
  }, [polyglot, error, showMessage]);

  const closeTaskFormModal = (): void => {
    setTaskToEdit(null);
    setIsTaskFormModalOpen(false);
  };

  const deleteTask = useCallback(
    async (taskId: number) => {
      try {
        await TaskAPI.removeTask(taskId);
        showMessage(polyglot.t('TasksListPage.successMessages.delete'), 'success');
        await refresh();
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot, refresh]
  );

  const remindAboutTask = useCallback(
    async (taskId: number) => {
      try {
        await TaskAPI.remindAboutTask(taskId);
        showMessage(polyglot.t('PersonalTask.successMessages.remind'), 'success');
        await refresh();
      } catch (error) {
        showMessage(
          polyglot.t('PersonalTask.errorMessages.remind', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot, refresh]
  );

  const confirmDeleteTask = () => {
    setIsRemovalModalOpen(true);
  };

  const deleteTaskAfterConfirmation = useCallback(
    async (selectedTasks: number[]) => {
      try {
        setIsRemovalModalOpen(false);
        setAnchorEl(null);
        await TaskAPI.removeTasks(selectedTasks);
        showMessage(polyglot.t('TasksListPage.successMessages.deleteMult'), 'success');
        setSelectionModel([]);
        setIsTaskViewModalOpen(false);
        await refresh();
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot, refresh]
  );

  const updateTaskStatus = useCallback(
    async (taskId: number, assignedUserId: number | undefined | null, status: TaskStatuses) => {
      try {
        await TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
        showMessage(polyglot.t('TasksListPage.successMessages.updateStatus'), 'success');
        await refresh();
        setSelectedTask((prev) => (prev ? { ...prev, status } : null));
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.updateStatus', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot, refresh]
  );

  useEffect(() => {
    trackPage(`Tasks ${reach} overview`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportTasksCSV = useCallback(
    async (selectedList?: number[]) => {
      try {
        const csvData = await TaskAPI.exportTasks(selectedList);
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, `zelt-task-export${format(new Date(), 'ddMMyy')}.csv`);
      } catch (error) {
        showMessage(
          `${polyglot.t('PeopleDirectoryPage.errorMessages.smthWrong')}. ${nestErrorMessage(error)}`,
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const getTaskBulkActionsOptions = useMemo(() => {
    const actionOptions = [];
    if (hasTaskAll && selectionModel?.length > 0)
      actionOptions.push({
        icon: <Trash {...iconSize} />,
        handler: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (e) setAnchorEl((e as React.MouseEvent<HTMLButtonElement, MouseEvent>).currentTarget);
          confirmDeleteTask();
        },
        label: polyglot.t('TasksListPage.deleteSelected'),
        disabled: false,
      });
    if (hasTaskAll && selectionModel?.length > 0)
      actionOptions.push({
        icon: <Export {...iconSize} />,
        handler: () => exportTasksCSV(selectionModel),
        label: polyglot.t('TasksListPage.exportSelected'),
        disabled: false,
      });
    if (allTasks?.totalItems)
      // todo - fetch all tasks or export from backend
      actionOptions.push({
        icon: <Export {...iconSize} />,
        handler: () => exportTasksCSV(),
        label: polyglot.t('TasksListPage.export'),
        disabled: false,
      });
    return actionOptions;
  }, [allTasks?.totalItems, exportTasksCSV, hasTaskAll, polyglot, selectionModel]);

  const checklistOptions = useMemo(() => {
    let options: any = [];
    if (allChecklists) {
      const uniqueValues = new Set();
      options = allChecklists
        .map((t) => {
          const bgImgLabel = emojiOptions.find((emo) => emo.value === t?.bgImg)?.label;
          const label = t?.name ? `${bgImgLabel ?? t?.bgImg ?? '📝'} ${t?.name}` : undefined;
          return {
            value: t.id?.toString(),
            label: label,
          };
        })
        .filter((t) => t.value && t.label && !uniqueValues.has(t.value) && uniqueValues.add(t.value));

      options.unshift({
        value: 'null',
        label: 'No checklist',
      });
    }

    return options;
  }, [allChecklists]);

  return (
    <RootStyle>
      <TopHeader
        title={polyglot.t('TasksListPage.tasks')}
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => setIsLaunchModalOpen(true)}>
              Checklist
            </ButtonComponent>
            <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsTaskFormModalOpen(true)}>
              {polyglot.t('TasksListPage.newTask')}
            </ButtonComponent>
          </Box>
        }
      />
      <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
              <TabFilterButtons
                filters={TabFilter}
                setFilterValue={(filterValue) => updateTableState({ filterValue })}
                filterValue={filterValue}
                hasSearch
                onFilterChange={({ filterValue, searchInput }) => {
                  updateTableState({
                    filterValue,
                    searchInput,
                  });
                }}
              />

              <CategoryFilters
                filterTypes={{
                  checklist: checklistOptions,
                }}
                setFilterString={(filterString) => updateTableState({ filterString })}
                filterString={filterString}
              />
            </Box>

            {(hasTaskAll || isManager) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', gap: spacing.g15 }}>
                <SelectDeselectRows
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  rows={allTasks?.items ?? []}
                />
                {getTaskBulkActionsOptions.length > 0 && (
                  <StyledMenuComponent
                    options={getTaskBulkActionsOptions}
                    actionButtonDetails={{
                      type: 'button',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: polyglot.t('General.actions'),
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          {allTasks && (
            <Box sx={{ ...spacing.mt20 }}>
              <TaskTable
                tasks={allTasks}
                pagination={[paginationState, setPaginationState]}
                sorting={[sortingState, setSortingState]}
                openSelectedTaskModal={(task: TaskDto) => {
                  setSelectedTask(task);
                  setIsTaskViewModalOpen(true);
                }}
                openEditTaskModal={(task: TaskDto) => {
                  setTaskToEdit(task);
                  setIsTaskFormModalOpen(true);
                }}
                markAsComplete={async (task: TaskDto) => {
                  if (task.id) {
                    await updateTaskStatus(task.id, task?.assignedUserId, TaskStatuses.COMPLETE);
                  }
                }}
                showUserLink={true}
                loading={loading}
                setSelectionModel={setSelectionModel}
                selectionModel={selectionModel}
                currentUserId={user.userId}
                deleteAction={deleteTask}
                remindAction={remindAboutTask}
                reach={reach}
              />
            </Box>
          )}
        </Box>
        {/* TASK ADD AND EDIT MODAL */}
        <TaskFormModal
          isOpen={isTaskFormModalOpen}
          setIsOpen={setIsTaskFormModalOpen}
          onClose={closeTaskFormModal}
          reach={reach}
          userId={undefined} // showAllUsersTasks ? undefined : userId
          formData={taskToEdit}
          refresh={refresh}
        />

        {/* TASK VIEW MODAL */}
        {selectedTask && (
          <TaskViewModal
            isOpen={isTaskViewModalOpen}
            setIsOpen={setIsTaskViewModalOpen}
            onClose={() => {
              setSelectedTask(null);
              setIsTaskViewModalOpen(false);
            }}
            task={selectedTask as TaskAndChecklistItemCommon}
            action={updateTaskStatus}
            deleteTask={deleteTaskAfterConfirmation}
            editTask={() => {
              setTaskToEdit(selectedTask);
              setIsTaskFormModalOpen(true);
              setIsTaskViewModalOpen(false);
            }}
          />
        )}

        <TaskLaunchModal
          isOpen={isLaunchModalOpen}
          setIsOpen={setIsLaunchModalOpen}
          onClose={() => setIsLaunchModalOpen(false)}
          refresh={refresh}
          reach={reach}
        />
        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => setIsRemovalModalOpen(false)}
          anchorEl={anchorEl}
          takeAction={() => deleteTaskAfterConfirmation(selectionModel)}
          message={polyglot.t('TasksListPage.confirmDelete', { smart_count: selectionModel.length })}
          callToAction={polyglot.t('TasksListPage.deleteTasks', { smart_count: selectionModel.length })}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
