import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/DocumentFull.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

import 'react-dropzone-uploader/dist/styles.css';

interface Props {
  readonly value?: string;
  readonly uploadedFileName?: string;
  readonly onChange: (avatar?: string) => void;
  readonly disabled?: boolean;
  readonly url: string;
  readonly width?: string;
  readonly restrictedFormats?: string[];
  readonly refresh: () => Promise<void>;
  readonly handleDelete: () => Promise<void>;
}

export const UploadPublicImage = ({
  value,
  uploadedFileName = 'Uploaded file',
  onChange,
  disabled = false,
  url,
  width = '100%',
  handleDelete,
  restrictedFormats,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showMessage] = useMessage();
  const resetKey = 0;

  useEffect(() => {
    if (value) {
      setIsLoading(false);
    }
  }, [value]);

  const validateFile = useCallback(
    (file: IFileWithMeta) => {
      if (restrictedFormats && restrictedFormats.length > 0 && !restrictedFormats.includes(file.file.type)) {
        showMessage(`${polyglot.t('UploadPublicImage.errorMessages.type')}`, 'error');
      }
    },
    [showMessage, polyglot, restrictedFormats]
  );

  const handleChangeStatus = useCallback(
    (file: IFileWithMeta, status: StatusValue) => {
      try {
        validateFile(file);
        if (status === 'uploading') {
          setUploadProgress(file.meta.percent || 0);
        }
        if (status === 'done' && file.xhr) {
          onChange(file.xhr.response);
          setUploadProgress(0);
        } else if (status === 'removed') {
          onChange();
          setUploadProgress(0);
        } else if (status === 'preparing') {
          setIsLoading(true);
        }
      } catch (error) {
        showMessage(`${polyglot.t('UploadPublicImage.errorMessages.somethingWentWrong')}`, 'error');
        file.remove();
      }
    },
    [onChange, showMessage, validateFile, polyglot]
  );

  const initiateDelete = useCallback(async () => {
    setIsLoading(true);
    await handleDelete();
    setIsLoading(false);
  }, [handleDelete]);

  const transitionStyle = {
    transition: 'all 0.3s ease',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20, ...transitionStyle }}>
      {value && !isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '40px',
            boxSizing: 'border-box',
            ...transitionStyle,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <DocumentIcon {...iconSize} />
            <Typography variant="title4">{uploadedFileName}</Typography>
          </Box>
          <IconButton sizeVariant="small" colorVariant="secondary" onClick={initiateDelete}>
            <TrashIcon />
          </IconButton>
        </Box>
      ) : isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            ...transitionStyle,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            sx={{
              width: '100%',
              height: '4px',
              borderRadius: radius.br10,
            }}
          />
        </Box>
      ) : (
        <Dropzone
          styles={{
            dropzone: {
              borderRadius: '8px',
              width,
              border: `1px dashed ${themeColors.Grey}`,
              opacity: !disabled ? 1 : 0,
              height: !disabled ? '40px' : '0px',
              minHeight: '0px',
              boxSizing: 'border-box',
              overflow: 'hidden',
              ...transitionStyle,
            },
            inputLabel: {
              textAlign: 'center',
              ...themeFonts.caption,
              color: themeColors.DarkGrey,
            },
          }}
          key={resetKey}
          getUploadParams={() => ({ url })}
          onChangeStatus={handleChangeStatus}
          maxFiles={1}
          maxSizeBytes={10485760}
          canCancel={false}
          accept={restrictedFormats && restrictedFormats?.toString()}
          inputContent={polyglot.t('UploadPublicImage.dropFile')}
        />
      )}
    </Box>
  );
};
