import React, { useMemo, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { NotEnrolledInPension } from '@/v2/feature/payroll/features/payroll-uk/components/not-enrolled-in-pension.component';
import {
  calcPaycodeTotalForPayrunEntry,
  getOptionalPayCodesInUse,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { EditDeductionsPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-deductions-page.component';
import { EditPayrunEntryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component';
import {
  CurrencyWithDiff,
  ValueWithDiff,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { sum } from '@/v2/util/array.util';

type PayrunDeductionsTableProps = {
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  payCodes?: StaffologyPayCode[] | null;
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
  sx?: SxProps<Theme>;
};

export const PayrunDeductionsTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx,
}: PayrunDeductionsTableProps) => {
  const [editingDeductions, setEditingDeductions] = useState<PayRunEntryDto>();

  const optionalDeductionsInUse = useMemo(() => getOptionalPayCodesInUse(payCodes ?? [], entries, true), [
    entries,
    payCodes,
  ]);

  const [deductionTotals, prevDeductionTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => ({
      paye: sum(entries, (e) => e.paye ?? 0),
      employeeNi: sum(entries, (e) => e.employeeNi ?? 0),
      employeePension: sum(entries, (e) => e.employeePension ?? 0),
      studentLoan: sum(entries, (e) => e.studentLoan ?? 0),
      postgradLoan: sum(entries, (e) => e.postgradLoan ?? 0),
      deductions: sum(entries, (e) => e.deductions ?? 0),
    });
    return [
      calculateTotals(entries),
      (previousEntries.length ? calculateTotals(previousEntries) : {}) as Record<
        keyof ReturnType<typeof calculateTotals>,
        number | undefined
      >,
    ];
  }, [entries, previousEntries]);

  const [optionalDeductionsTotals, prevOptionalDeductionsTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => {
      const totals: Record<string, number> = {};
      for (const { code } of optionalDeductionsInUse) {
        totals[code] = sum(entries, (e) => calcPaycodeTotalForPayrunEntry(e, code));
      }
      return totals;
    };
    return [calculateTotals(entries), previousEntries.length ? calculateTotals(previousEntries) : {}];
  }, [entries, optionalDeductionsInUse, previousEntries]);

  const columnData = useMemo<ColumnDef<PayRunEntryDto, PayRunEntryDto>[]>(() => {
    const previousById = new Map<string, PayRunEntryDto>(previousEntries.map((item) => [item.employee.id, item]));
    const getPreviousPayrunEntry = (context: CellContext<PayRunEntryDto, PayRunEntryDto>) => {
      return previousById.get(context.getValue().employee.id);
    };
    // returns a zero column-size value if all entries are zero/undefined for a given payrun entry field
    const hideColumnIfNoValues = (visibleSize: number, getValue: (item: PayRunEntryDto) => number | undefined) => {
      return entries.some(getValue) || previousEntries.some(getValue) ? visibleSize : 0;
    };

    const columns: ColumnDef<PayRunEntryDto, PayRunEntryDto>[] = [
      {
        id: 'employee',
        header: () => 'Employee',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.employee.name),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => 'Total',
        size: 100,
      },
      {
        id: 'paye',
        header: () => <span title="Pay-as-you-earn">PAYE</span>,
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.paye ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.paye ?? 0}
          />
        ),
        footer: () => <CurrencyWithDiff currentValue={deductionTotals.paye} previousValue={prevDeductionTotals.paye} />,
        size: 70,
      },
      {
        id: 'employee-ni',
        header: () => <span title="National insurance">NI</span>,
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.employeeNi ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.employeeNi ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={deductionTotals.employeeNi} previousValue={prevDeductionTotals.employeeNi} />
        ),
        size: 70,
      },
      {
        id: 'pension',
        header: () => 'Pension',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.employeePension ?? 0),
        cell: (c) =>
          'employeePensionContribution' in c.getValue().totals ? (
            <ValueWithDiff
              current={c.getValue()}
              previous={previousById.get(c.getValue().employee.id)}
              getValue={(item) => item.employeePension ?? 0}
            />
          ) : (
            <NotEnrolledInPension userId={c.row.original.userId} />
          ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={deductionTotals.employeePension}
            previousValue={prevDeductionTotals.employeePension}
          />
        ),
        size: 70,
      },
      {
        id: 'student-loan',
        header: () => 'Student loan',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.studentLoan ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.studentLoan ?? 0}
            dimIfZero
          />
        ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={deductionTotals.studentLoan}
            previousValue={prevDeductionTotals.studentLoan}
          />
        ),
        size: hideColumnIfNoValues(90, (e) => e.studentLoan ?? 0),
      },
      {
        id: 'pg-loan',
        header: () => 'Postgrad loan',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.postgradLoan ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.postgradLoan ?? 0}
            dimIfZero
          />
        ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={deductionTotals.postgradLoan}
            previousValue={prevDeductionTotals.postgradLoan}
          />
        ),
        size: hideColumnIfNoValues(90, (e) => e.postgradLoan ?? 0),
      },
      ...optionalDeductionsInUse
        .sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
        .map<ColumnDef<PayRunEntryDto, PayRunEntryDto>>(({ code, title }) => ({
          id: code,
          header: () => title,
          accessorFn: (row) => row,
          enableSorting: true,
          sortingFn: (a, b) => sortNumeric(a, b, (item) => calcPaycodeTotalForPayrunEntry(item, code)),
          cell: (c) => (
            <ValueWithDiff
              current={c.getValue()}
              previous={getPreviousPayrunEntry(c)}
              getValue={(item) => calcPaycodeTotalForPayrunEntry(item, code)}
              dimIfZero
            />
          ),
          footer: () => (
            <CurrencyWithDiff
              currentValue={optionalDeductionsTotals[code]}
              previousValue={prevOptionalDeductionsTotals[code]}
            />
          ),
          size: 90,
        })),
      {
        id: 'total-deductions',
        header: () => 'Total deductions',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.deductions ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.deductions ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={deductionTotals.deductions} previousValue={prevDeductionTotals.deductions} />
        ),
        size: 110,
      },
    ];

    // remove any zero-sized columns
    return columns.filter((c) => c.size);
  }, [
    deductionTotals,
    entries,
    optionalDeductionsInUse,
    optionalDeductionsTotals,
    previousEntries,
    prevDeductionTotals,
    prevOptionalDeductionsTotals,
  ]);

  return (
    <Box sx={sx}>
      <BasicTable
        rowData={entries}
        columnData={columnData}
        hidePagination
        showFooter
        rowClick={(row) => setEditingDeductions(row.original)}
      />
      <EditPayrunEntryDrawer
        userId={editingDeductions?.userId}
        payrunEntry={editingDeductions}
        payCodes={payCodes}
        payrunClosed={payrunClosed}
        saveIncomeUpdates={saveIncomeUpdates}
        onClose={() => setEditingDeductions(undefined)}
        Content={EditDeductionsPage}
      />
    </Box>
  );
};
