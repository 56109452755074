import {
  StaffologyAeAssessment,
  StaffologyAeAssessmentAction,
  StaffologyAeExclusionCode,
} from '@v2/feature/payroll/payroll-external.dto';

export type PayrunPensionState =
  | PayrunPensionStates.notSubmitted
  | PayrunPensionStates.submitted
  | PayrunPensionStates.markedAsSent;

export enum PayrunPensionStates {
  notSubmitted = 'notSubmitted',
  submitted = 'submitted',
  markedAsSent = 'markedAsSent',
}

export enum EarningsType {
  qualifying = 'Qualifying Earnings',
  custom = 'Custom',
  unbanded = 'Unbanded',
}

export enum ExternalProviderConversationStatuses {
  Queued = 'Queued',
  Sent = 'Sent',
  Failed = 'Failed',
  Processing = 'Processing',
}

export enum EmployeePensionEnrollmentStatus {
  Enrolled = 'Enrolled',
  NotEnrolled = 'Not Enrolled',
}

export type PayPeriodStaffology = 'Custom' | 'Monthly' | 'FourWeekly' | 'Fortnightly' | 'Weekly' | 'Daily';

export enum StaffologyEmployeeStates {
  Automatic = 'Automatic',
  OptOut = 'OptOut',
  OptIn = 'OptIn',
  VoluntaryJoiner = 'VoluntaryJoiner',
  ContractualPension = 'ContractualPension',
  CeasedMembership = 'CeasedMembership',
  Leaver = 'Leaver',
  Excluded = 'Excluded',
  Enrol = 'Enrol',
  NonEligible = 'NonEligible',
  AgeMissing = 'AgeMissing',
  SalaryMissing = 'SalaryMissing',
}

export enum LeavingEmployeeStates {
  OptOut = StaffologyEmployeeStates.OptOut,
  CeasedMembership = StaffologyEmployeeStates.CeasedMembership,
  Leaver = StaffologyEmployeeStates.Leaver,
  Excluded = StaffologyEmployeeStates.Excluded,
}

export interface StaffologyAutoEnrolment {
  state: StaffologyEmployeeStates;
  readonly stateDate: string;
  ukWorker?: 'No' | 'Yes' | 'Ordinarily';
  daysToDeferAssessment?: number;
  postponementDate: string;
  deferByMonthsNotDays: boolean;
  exempt: boolean;
  aeExclusionCode?: StaffologyAeExclusionCode;
  aePostponementLetterSent?: boolean;
  lastAssessment: StaffologyAeAssessment;
  action: StaffologyAeAssessmentAction;
}
