import React, { useMemo } from 'react';

import { IconButton, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortBoolean, sortString } from '@/v2/components/table/table-sorting.util';
import { CompanyPayrollAccountingConfig } from '@/v2/feature/payroll/payroll.interface';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const getMode = (code: StaffologyPayCode) => (code.isControlCode ? 'Control' : code.isSystemCode ? 'System' : 'Custom');

type PayrollSettingsPayCodesTableProps = {
  payCodes?: StaffologyPayCode[] | null;
  accountingConfig?: CompanyPayrollAccountingConfig | null;
  onEditPayCodeClick: (paycode: StaffologyPayCode) => void;
};

export const PayrollSettingsPayCodesTable = ({
  payCodes,
  accountingConfig,
  onEditPayCodeClick,
}: PayrollSettingsPayCodesTableProps) => {
  const sortedPayCodes = useMemo(() => {
    return (
      payCodes
        // the initial sort is [custom-codes -> system-codes -> control-codes] with each group ordered by name
        ?.sort(
          (a, b) =>
            Number(a.isControlCode) - Number(b.isControlCode) ||
            Number(a.isSystemCode) - Number(b.isSystemCode) ||
            a.code.localeCompare(b.code)
        )
    );
  }, [payCodes]);

  const accountingCodes = useMemo(() => {
    // map pay code IDs to their accounting codes
    return new Map(accountingConfig?.accountingCodes.fields?.map((f) => [f.payCode, `${f.code} - ${f.nominalName}`]));
  }, [accountingConfig?.accountingCodes.fields]);

  const columns = useMemo<ColumnDef<StaffologyPayCode, StaffologyPayCode>[]>(
    () => [
      {
        id: 'code',
        header: () => 'Code',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.code),
        minSize: 50,
        cell: (c) => c.row.original.code,
      },
      {
        id: 'name',
        header: () => 'Name',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.title),
        maxSize: 300,
        minSize: 50,
        cell: (c) => c.row.original.title,
      },
      {
        id: 'type',
        header: () => 'Type',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isDeduction, true),
        minSize: 50,
        cell: (c) => (c.row.original.isDeduction ? 'Deduction' : 'Addition'),
      },
      {
        id: 'niable',
        header: () => 'NI',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isNiable),
        minSize: 50,
        cell: (c) => (c.row.original.isNiable ? 'Yes' : 'No'),
      },
      {
        id: 'taxable',
        header: () => 'Tax',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isTaxable),
        minSize: 50,
        cell: (c) => (c.row.original.isTaxable ? 'Yes' : 'No'),
      },
      {
        id: 'pensionable',
        header: () => 'Pension',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isPensionable),
        minSize: 50,
        cell: (c) => (c.row.original.isPensionable ? 'Yes' : 'No'),
      },
      {
        id: 'attachmentorderable',
        header: () => 'Attachment Orders',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isAttachable),
        minSize: 50,
        cell: (c) => (c.row.original.isAttachable ? 'Yes' : 'No'),
      },
      {
        id: 'kind',
        header: () => 'Kind',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getMode(item)),
        minSize: 50,
        cell: (c) => getMode(c.row.original),
      },
      {
        id: 'accounting-code',
        header: () => 'Accounting code',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => accountingCodes.get(item.code)),
        minSize: 50,
        cell: (c) => accountingCodes.get(c.row.original.code) ?? <EmptyCell />,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        minSize: 50,
        cell: (c) => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, justifyContent: 'flex-end' }}>
            <IconButton
              sx={tableIconButtonSx}
              title={`Edit ${c.row.original.code}`}
              onClick={() => onEditPayCodeClick(c.row.original)}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [accountingCodes, onEditPayCodeClick]
  );

  return <BasicTable loading={!sortedPayCodes} rowData={sortedPayCodes ?? []} columnData={columns} hidePagination />;
};
