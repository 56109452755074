import { useState } from 'react';

import { Box } from '@mui/material';
import { ChipComponent } from '@v2/components/chip/chip.component';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceNotesOverviewModal } from '@v2/feature/device/features/device-cards/components/device-notes-overview-modal.component';
import { GridDisplayBarV2 } from '@v2/feature/growth/shared/components/grid-display-bar.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

export const DeviceNotesComponent = ({
  devicePossession,
  refresh,
}: {
  devicePossession: DevicePossessionDto;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [showMessage] = useMessage();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16 }}>
      <Typography variant="caption" color="Grey">
        You can add,edit or delete notes here
      </Typography>
      {!devicePossession.device?.customerNotes && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }} className="hide-for-capture">
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add note
          </ButtonComponent>
        </Box>
      )}

      {devicePossession?.device?.customerNotes && (
        <GridDisplayBarV2
          cells={[
            {
              content: (
                <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: spacing.g8 }}>
                  <Typography
                    variant="caption"
                    dangerouslySetInnerHTML={{ __html: devicePossession?.device?.customerNotes ?? '' }}
                    sx={{ padding: 0, margin: 0, overflowWrap: 'break-word' }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
                    <ChipComponent name={'Note'} backgroundColor="white" border="background" textVariant="caption" />
                    <ChipComponent
                      name={<UserCell userId={devicePossession.possessionId} nameVariant="caption" nameColor="Grey" />}
                      backgroundColor="white"
                      border="background"
                    />
                  </Box>
                </Box>
              ),
              gridXs: 8,
            },
            {
              content: (
                <StyledMenuComponent
                  className="hide-for-capture"
                  options={[
                    {
                      icon: <Edit {...iconSize} />,
                      handler: () => {
                        setIsOpen(true);
                      },
                      label: polyglot.t('General.edit'),
                    },
                    {
                      icon: <Trash {...iconSize} />,
                      handler: async () => {
                        try {
                          await DeviceAPI.updateDeviceCustomerNotes(devicePossession.deviceId, {
                            customerNotes: null,
                          });
                          showMessage('Notes deleted successfully', 'success');
                          await refresh();
                        } catch (error) {
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                        }
                      },
                      label: polyglot.t('General.delete'),
                    },
                  ]}
                  actionButtonDetails={{
                    type: 'iconButton',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: 'actions',
                    icon: <ActionsSmall {...iconSize} />,
                  }}
                />
              ),
              gridXs: 4,
              sx: { display: 'flex', justifyContent: 'flex-end' },
            },
          ]}
        />
      )}

      <DeviceNotesOverviewModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={async () => {
          await refresh?.();
          setIsOpen(false);
        }}
        selectedNote={devicePossession.device?.customerNotes ?? undefined}
        devicePossession={devicePossession}
      />
    </Box>
  );
};
