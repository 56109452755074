import React, { Suspense, useEffect, useState } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DepUserDeviceDto, DeviceDto, DeviceModelDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus, DevicePossessionType } from '@v2/feature/device/device.interface';
import { getDeviceOwnerAsSuperadminByDevicePossession } from '@v2/feature/device/device.util';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { SiteDto } from '@v2/feature/site/site.dto';
import { DeviceOrderTrackingLinkForm } from '@v2/feature/super-admin/features/super-admin-devices/components/device-order-tracking-link-form.component';
import { SuperAdminEditOrderForm } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-edit-order-form.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Checked } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminDeviceOrderDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly deviceOrder: DeviceOrderDto;
  readonly setDeviceOrder: React.Dispatch<React.SetStateAction<DeviceOrderDto | undefined>>;
  readonly refresh: () => Promise<void>;
  readonly userNames: { [userId: number]: string };
  readonly companyNames: { [companyId: number]: string };
  readonly deviceModels: readonly DeviceModelDto[];
  readonly sites: { [siteId: number]: SiteDto };
}

export const SuperAdminDeviceOrderDrawer = ({
  isOpen,
  setIsOpen,
  deviceOrder,
  setDeviceOrder,
  companyNames,
  refresh,
  userNames,
  deviceModels,
  sites,
}: SuperAdminDeviceOrderDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminDeviceOrderDrawerContent
        deviceOrder={deviceOrder}
        setDeviceOrder={setDeviceOrder}
        setIsOpen={setIsOpen}
        companyNames={companyNames}
        refresh={refresh}
        userNames={userNames}
        deviceModels={deviceModels}
        sites={sites}
      />
    </Suspense>
  </DrawerModal>
);

interface SuperAdminDeviceOrderDrawerContentProps {
  readonly deviceOrder: DeviceOrderDto;
  readonly refresh: () => Promise<void>;
  readonly setDeviceOrder: React.Dispatch<React.SetStateAction<DeviceOrderDto | undefined>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userNames: { [userId: number]: string };
  readonly companyNames: { [companyId: number]: string };
  readonly deviceModels: readonly DeviceModelDto[];
  readonly sites: { [siteId: number]: SiteDto };
}

const SuperAdminDeviceOrderDrawerContent = ({
  deviceOrder,
  setDeviceOrder,
  setIsOpen,
  refresh,
  userNames,
  companyNames,
  deviceModels,
  sites,
}: SuperAdminDeviceOrderDrawerContentProps) => {
  const [isAcceptLoading, setIsAcceptLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);
  const [isShipping, setIsShipping] = useState<boolean>(false);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [isDeliveredLoading, setIsDeliveredLoading] = useState<boolean>(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState<boolean>(false);
  const yesterday = new LocalDate();
  yesterday.getDate().setDate(yesterday.getDate().getDate() - 1);
  yesterday.getDate().setHours(23);

  const [deliveryDate, setDeliveryDate] = useState<string | null>(
    deviceOrder.deliveryDate && yesterday.toDateString() < deviceOrder.deliveryDate
      ? deviceOrder.deliveryDate
      : new LocalDate().toDateString()
  );
  const [stockDevices, setStockDevices] = useState<DeviceDto[]>([]);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const [selectedStockDevice, setSelectedStockDevice] = useState<number | null>(null);

  const [isInHouseMdm, setIsInHouseMdm] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const devices = await DeviceAPI.getStockDevicesAsSuperadmin();
        setStockDevices(devices);
      } catch (error) {
        showMessage(`Could not get stock devices. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  const rejectOrder = async () => {
    try {
      setIsRejectLoading(true);
      const rejectedDeviceOrder = await DeviceAPI.rejectDeviceOrderAsSuperadmin(deviceOrder.id);
      setDeviceOrder(rejectedDeviceOrder);
      showMessage('Order rejected.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not reject device order. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsRejectLoading(false);
    }
  };

  const deleteOrder = async () => {
    try {
      setIsDeletingLoading(true);
      await DeviceAPI.deleteDeviceOrderAsSuperAdmin(deviceOrder.id);
      showMessage('Order deleted.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not delete device order. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDeletingLoading(false);
      setDeviceOrder(undefined);
      setIsOpen(false);
    }
  };

  const acceptOrder = async () => {
    try {
      if (!deliveryDate) {
        showMessage('Please select a delivery date', 'error');
        return;
      }
      const acceptedDeviceOrder = await DeviceAPI.acceptDeviceOrderAsSuperadmin(deviceOrder.id, deliveryDate);
      setDeviceOrder(acceptedDeviceOrder);
      showMessage('Order accepted.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not accept device order. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const setOrderInShipping = async () => {
    try {
      if (!deviceOrder.deviceId) {
        showMessage('Please select a stock device', 'error');
        return;
      }
      const inShippingDeviceOrder = await DeviceAPI.setDeviceOrderInShippingAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId
      );
      setDeviceOrder(inShippingDeviceOrder);
      showMessage('Order status updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not update device order. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const setSelectedDevice = async () => {
    try {
      if (!selectedStockDevice) {
        showMessage('Please select a stock device', 'error');
        return;
      }
      const updatedDeviceOrder = await DeviceAPI.assignDeviceToDeviceOrderAsSuperadmin(
        deviceOrder.id,
        selectedStockDevice
      );
      setDeviceOrder(updatedDeviceOrder);
      showMessage('Device assigned.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not assign device. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const setOrderInStorage = async () => {
    try {
      if (!deviceOrder.deviceId) {
        showMessage('Please select a stock device', 'error');
        return;
      }
      const inShippingDeviceOrder = await DeviceAPI.setDeviceOrderInShippingAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId
      );
      setDeviceOrder(inShippingDeviceOrder);
      showMessage('Order status updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not update device order. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const setOrderAsDelivered = async () => {
    try {
      if (!deliveryDate) {
        showMessage('Please confirm the delivery date', 'error');
        return;
      }
      if (!deviceOrder.deviceId) {
        showMessage('Could not set the device as delivered as no device is assigned to this order.', 'error');
        return;
      }
      const deliveredDeviceOrder = await DeviceAPI.setDeviceOrderAsDeliveredAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId,
        deliveryDate
      );
      setDeviceOrder(deliveredDeviceOrder);
      showMessage('Order status updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Could not update device order status. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const addDepDeviceAndUserToMdm = async () => {
    try {
      const userName = getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites);
      if (
        !deviceOrder.deviceId ||
        !deviceOrder.device?.serialNumber ||
        !userName ||
        !deviceOrder.possession?.companyId
      ) {
        showMessage(
          `Please provide all necessary data to add device to MDM, deviceId: ${deviceOrder.deviceId}, serialNumber:${deviceOrder.device?.serialNumber}, userName: ${userName}, companyId:${deviceOrder.possession?.companyId}`,
          'error'
        );
        return;
      }
      const depUserDeviceDto: DepUserDeviceDto = {
        zeltDeviceId: deviceOrder.deviceId,
        userFullName: userName,
        userShortName: userName,
        serialNumber: deviceOrder.device?.serialNumber,
        companyId: deviceOrder.possession?.companyId,
      };
      await InHouseMdmAPI.setDepDeviceAndUser(depUserDeviceDto);
      showMessage('Dep device added to MDM.', 'success');
    } catch (error) {
      showMessage(`Could not add DEP device and user to MDM. ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="title2">Order details</Typography>
        {[DeviceOrderStatus.Accepted, DeviceOrderStatus.Placed].includes(deviceOrder.status) && (
          <IconButton
            onClick={() => setIsEditable((previousState) => !previousState)}
            sx={{ display: 'flex', marginLeft: 'auto', height: 40, width: 40 }}
            title="Edit"
          >
            {isEditable ? (
              <Close {...iconSize} stroke={themeColors.DarkGrey} fill={themeColors.DarkGrey} />
            ) : (
              <Edit {...iconSize} />
            )}
          </IconButton>
        )}
      </Box>
      {isEditable ? (
        <SuperAdminEditOrderForm deviceOrder={deviceOrder} deviceModels={deviceModels} refresh={refresh} />
      ) : (
        <Box sx={drawerContentSx}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="title3">{deviceOrder.deviceModel?.modelName}</Typography>

            {deviceOrder.status === DeviceOrderStatus.Accepted && (
              <LoaderButton
                name="Reject Order"
                loading={isRejectLoading}
                onClick={rejectOrder}
                sizeVariant="small"
                colorVariant="danger"
              />
            )}
          </Box>
          <Typography variant="title4">Device Summary</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            {deviceOrder.deviceId && <Typography variant="caption">Selected device: {deviceOrder.deviceId}</Typography>}
            {deviceOrder.device?.serialNumber && (
              <Typography variant="caption">Serial number: {deviceOrder.device.serialNumber}</Typography>
            )}
            <Typography variant="caption">Model ID: {deviceOrder.deviceModelId}</Typography>
            {deviceOrder.deviceModel?.modelName && (
              <Typography variant="caption">Model Name: {deviceOrder.deviceModel.modelName}</Typography>
            )}
            {deviceOrder.deviceModel?.modelNumber && (
              <Typography variant="caption">Model Number: {deviceOrder.deviceModel.modelNumber}</Typography>
            )}
            {deviceOrder.deviceModel?.screenSize && (
              <Typography variant="caption">Screen Size: {deviceOrder.deviceModel.screenSize}"</Typography>
            )}
            {deviceOrder.deviceModel?.ram && (
              <Typography variant="caption">Memory: {deviceOrder.deviceModel.ram}GB</Typography>
            )}
            {deviceOrder.deviceModel?.storage && (
              <Typography variant="caption">Storage: {deviceOrder.deviceModel.storage}GB</Typography>
            )}
          </Box>

          <Typography variant="title4">Order Summary</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            <Typography variant="caption">
              Assigned to: {getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites)}
            </Typography>
            <Typography variant="caption">Ordered by: {userNames[deviceOrder.createdBy] ?? 'Unknown'}</Typography>
            <Typography variant="caption">Company: {companyNames[deviceOrder.companyId] ?? 'N/A'}</Typography>
            {deviceOrder.deliveryDate && (
              <Typography variant="caption">
                Delivery Date:{' '}
                {new Date(deviceOrder.deliveryDate).toLocaleDateString(undefined, { dateStyle: 'medium' })}
              </Typography>
            )}
            {deviceOrder.deliveryAddress && (
              <Typography variant="caption">Delivery Address: {deviceOrder.deliveryAddress}</Typography>
            )}
            {deviceOrder.phoneNumber && (
              <Typography variant="caption">Phone Number: {deviceOrder.phoneNumber}</Typography>
            )}
          </Box>

          <CheckboxComponent
            label="Assign to InHouse MDM, only Apple"
            name="required"
            checked={isInHouseMdm}
            value="required"
            onChange={(_, checked) => {
              setIsInHouseMdm(checked);
            }}
          />

          {isInHouseMdm && (
            <Box>
              <Typography variant="title4">DEP Settings</Typography>
              <Box sx={{ mb: '32px', mt: '16px' }}>
                <Typography variant="caption">
                  Full user name:{' '}
                  {getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites)}
                </Typography>
                <Typography variant="caption">
                  Short user name:{' '}
                  {getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites)}
                </Typography>
                <Typography variant="caption">DeviceId: {deviceOrder.deviceId}</Typography>
                <Typography variant="caption">Serial number: {deviceOrder.device?.serialNumber}</Typography>
                <Box sx={{ mb: '32px', mt: '16px' }}>
                  <ButtonComponent
                    onClick={async () => {
                      await addDepDeviceAndUserToMdm();
                    }}
                    fullWidth
                    sizeVariant="small"
                    colorVariant="primary"
                  >
                    Send to MDM
                  </ButtonComponent>
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ mb: 4 }}>
            {[DeviceOrderStatus.Rejected, DeviceOrderStatus.Cancelled].includes(deviceOrder.status) && (
              <Box>
                <LoaderButton
                  name="Delete Order"
                  loading={isDeletingLoading}
                  onClick={deleteOrder}
                  sizeVariant="small"
                  colorVariant="danger"
                  fullWidth
                />
              </Box>
            )}

            {[DeviceOrderStatus.Placed, DeviceOrderStatus.Shipping].includes(deviceOrder.status) && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  label="Delivery date"
                  className="datepicker"
                  inputFormat="dd/MM/yyyy"
                  value={deliveryDate}
                  // defaultCalendarMonth={tomorrow}
                  onChange={(newDate) => setDeliveryDate(newDate)}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" sx={{ width: '100%', mb: 2 }} size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
            {deviceOrder.status === DeviceOrderStatus.Placed && (
              <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center' }}>
                <LoaderButton
                  name="Accept Order"
                  loading={isAcceptLoading}
                  onClick={async () => {
                    setIsAcceptLoading(true);
                    await acceptOrder();
                    setIsAcceptLoading(false);
                  }}
                  disabled={!deliveryDate}
                  fullWidth
                  sizeVariant="medium"
                  colorVariant="primary"
                />
                <LoaderButton
                  name="Reject Order"
                  loading={isRejectLoading}
                  onClick={rejectOrder}
                  sizeVariant="medium"
                  colorVariant="danger"
                  fullWidth
                />
              </Box>
            )}

            {!deviceOrder.deviceId && (
              <Box sx={{ mt: spacing.m10 }}>
                <Typography variant="captionSmall">Select Device</Typography>
                <Box sx={{ width: '100%', mb: 2 }}>
                  <SelectComponent
                    name="Select device"
                    options={stockDevices
                      .filter((device) => device.deviceModelId === deviceOrder.deviceModelId)
                      .map((device) => {
                        const modelNumberLabel = device.modelNumber ? ` ${device.modelNumber}` : '';
                        const serialNumberLabel = device.serialNumber ? ` - ${device.serialNumber}` : '';
                        const label = `${device.deviceModelId} - ${device.modelName}${modelNumberLabel}${serialNumberLabel}`;
                        return {
                          value: device.id,
                          label,
                        };
                      })}
                    value={selectedStockDevice}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const deviceId = (event.target.value as unknown) as number;
                      setSelectedStockDevice(deviceId);
                    }}
                  />
                </Box>
                <Box>
                  <LoaderButton
                    name="Select device"
                    loading={isSelecting}
                    onClick={async () => {
                      setIsSelecting(true);
                      await setSelectedDevice();
                      setIsSelecting(false);
                    }}
                    disabled={!selectedStockDevice}
                    fullWidth
                    sizeVariant="medium"
                    colorVariant="primary"
                  />
                </Box>
              </Box>
            )}

            {deviceOrder.status === DeviceOrderStatus.Accepted && deviceOrder.deviceId && (
              <Box>
                {deviceOrder.possession?.possessionType &&
                  [DevicePossessionType.CompanySite, DevicePossessionType.User].includes(
                    deviceOrder.possession?.possessionType
                  ) && (
                    <LoaderButton
                      name="Set in Shipping"
                      loading={isShipping}
                      onClick={async () => {
                        setIsShipping(true);
                        await setOrderInShipping();
                        setIsShipping(false);
                      }}
                      disabled={!deviceOrder.deviceId}
                      fullWidth
                      sizeVariant="medium"
                      colorVariant="primary"
                    />
                  )}
                {/* WHEN DEVICE IS ORDERED TO BE KEPT IN STORAGE */}
                {deviceOrder.possession?.possessionType === DevicePossessionType.ZeltStorage && (
                  <LoaderButton
                    name="Set in Storage"
                    loading={isShipping}
                    onClick={async () => {
                      setIsShipping(true);
                      await setOrderInStorage();
                      setIsShipping(false);
                    }}
                    disabled={!selectedStockDevice}
                    fullWidth
                    sizeVariant="medium"
                    colorVariant="primary"
                  />
                )}
              </Box>
            )}

            {deviceOrder.status === DeviceOrderStatus.Shipping && (
              <LoaderButton
                name="Set as Delivered"
                loading={isDeliveredLoading}
                onClick={async () => {
                  setIsDeliveredLoading(true);
                  await setOrderAsDelivered();
                  setIsDeliveredLoading(false);
                }}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            )}

            {deviceOrder.status === DeviceOrderStatus.Delivered && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Checked {...iconSize} style={{ fill: themeColors.Green }} />
                <Typography variant="title4">{deviceOrder.status}</Typography>
              </Box>
            )}

            {deviceOrder.status === DeviceOrderStatus.Shipping && (
              <Box sx={{ mt: spacing.mt40 }}>
                <DeviceOrderTrackingLinkForm deviceOrder={deviceOrder} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
