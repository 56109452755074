import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';

export enum PayScheduleEnum {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export enum SalaryBasisEnum {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Daily = 'Daily',
  Hourly = 'Hourly',
}

export interface UserCompensationDto {
  id?: number;
  effectiveDate: string;
  paySchedule?: PayScheduleEnum;
  salaryBasis: SalaryBasisEnum | null;
  rate: number | null;
  units?: number;
  salaryPerPaySchedule?: number | undefined;
  annualSalary?: number | undefined;
  salary?: number | null;
  currency: CurrencyShort | null;
  prorateSalaryByFte?: boolean;
  proratedRate?: number;
  nonProratedRate?: number;
  userFteFromImport?: number;
  userId?: number;
  customUpdates: UserCustomDataDto[];
}

export type CompensationFixedRates = {
  annual: number;
  monthly: number;
  weekly: number;
  daily: number;
  hourly: number;
  byMinute: number;
};

export type CompensationWorkingUnits = {
  inWorkingUnits: boolean;
  daysInYear: number;
  monthsInYear: number;
  weeksInYear: number;
  weeksInMonth: number;
  daysInMonth: number;
  daysInWeek: number;
  hoursInDay: number;
  minutesInDay: number;
};

export type UserCompensationForBreakdown = Pick<
  UserCompensationDto,
  'salaryBasis' | 'rate' | 'paySchedule' | 'units' | 'currency'
>;

export type CompensationBreakdown = UserCompensationForBreakdown & {
  fixedRates: CompensationFixedRates;
  payScheduleRate: number;
  workingUnits: CompensationWorkingUnits;
  scheduleName: string | null;
};

export type UserCompensationWithBreakdown = UserCompensationDto & {
  compensationBreakdown: CompensationBreakdown | null;
};
