import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { LayoutRootStyle } from '@v2/feature/app-layout/app-layout.index';
import { SuperAdminSidebar } from '@v2/feature/app-layout/features/sidebar/super-admin-sidebar.index';
import { CachedUsersProvider } from '@v2/feature/user/context/cached-users.context';
import { useSetLocalTimeHeaderInterceptor } from '@v2/infrastructure/api-client/api-client-request-interceptor.hook';
import { useApiErrorInterceptor } from '@v2/infrastructure/api-error/api-error-interceptor.hook';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { MessageWrapper } from '@/component/widgets/Message';
import { GlobalStateProvider } from '@/GlobalState';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from '@/lib/routes';
import { CurrentUser } from '@/models';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { SuperAdminRouter } from '@/v2/feature/super-admin/super-admin.router';
import { ErrorNotificationProvider } from '@/v2/infrastructure/error-notification-provider.context';

export const SuperAdminDashboardLayout = (): JSX.Element => {
  const routerHistory = useHistory();

  const [user, setUser] = useState<CurrentUser | null>(null);
  const [showHiddenFeatures, setShowHiddenFeatures] = useState<boolean | null>(null); // This must remain initiated with null, if true or false it means the /me request received the response
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useApiErrorInterceptor();
  useSetLocalTimeHeaderInterceptor();

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const response = await AuthAPI.getAuthMe();
        const showHiddenFeatures = response.showHiddenFeatures;
        const isSuperAdmin = Boolean(response && response.isSuperAdmin) ?? false;
        setUser(response.user);
        setIsSuperAdmin(isSuperAdmin);
        // this should be set last for all the routes to work
        setShowHiddenFeatures(showHiddenFeatures);
        Sentry.setUser({
          id: `${response?.user?.userId}`,
          companyId: response?.user?.company?.companyId,
        });
      } catch (error: any) {
        if (error?.response?.status === 401) {
          routerHistory.push(LOGIN_ROUTE);
        } else {
          throw error;
        }
      }
      setLoading(false);
    })();
  }, [routerHistory]);

  if (!loading && !isSuperAdmin) {
    routerHistory.push(DASHBOARD_ROUTE);
    return <></>;
  }

  return (
    <LayoutRootStyle>
      {user && (
        <GlobalStateProvider
          initialState={{
            user,
            loggedOut: false,
            showHiddenFeatures,
            message: [],
            inspectorMode: false,
            paymentFailed: false,
            drawerState: false,
            isTestModeEnabled: false,
            trialMode: { enabled: false, lapsed: false },
            alerts: {},
            showSettingBar: false,
            publicURL: null,
          }}
        >
          <ErrorNotificationProvider axios={axios}>
            <CachedUsersProvider>
              <SuperAdminSidebar />
              <Box
                sx={{
                  height: '100vh',
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                <SuperAdminRouter />
              </Box>
            </CachedUsersProvider>
            <MessageWrapper />
          </ErrorNotificationProvider>
        </GlobalStateProvider>
      )}
    </LayoutRootStyle>
  );
};
