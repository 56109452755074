import { SiteDto } from '@v2/feature/site/site.dto';
import { CompensationBreakdown } from '@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { UserContractDto } from '@v2/feature/user/features/user-forms/user-contract/user-contract.dto';
import { UserRoleDto } from '@v2/feature/user/features/user-forms/user-role/user-role.dto';

import { UserEventDto } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { User } from '@/models';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { JobPosition } from '@/v2/feature/job-position/job-position-settings/job-position.interface';

export type PeopleDirectoryUserRole = Partial<
  Pick<UserRoleDto, 'jobTitle' | 'departmentId' | 'siteId' | 'managerId'> & {
    site?: Pick<SiteDto, 'id' | 'name'> | null;
  } & { department?: Pick<CompanyDepartmentDto, 'id' | 'name'> } & { manager: Pick<User, 'userId' | 'name'> } & {
    jobPosition?: Pick<JobPosition, 'id' | 'title'> | null;
  }
>;

export type PeopleDirectoryUserEvent = Partial<Pick<UserEventDto, 'status'>>;

export type PeopleDirectoryUserContract = Partial<
  Pick<UserContractDto, 'type' | 'contract' | 'ftePercent' | 'attendanceScheduleId' | 'attendanceSchedule'>
>;

export type PeopleDirectoryUserDto = Pick<
  User,
  'userId' | 'firstName' | 'lastName' | 'name' | 'displayName' | 'emailAddress' | 'avatarFileName'
> &
  Partial<
    Pick<User, 'startDate' | 'accountStatus' | 'gender' | 'phone' | 'personalEmail' | 'employeeId'> & {
      roles: PeopleDirectoryUserRole[];
      userCompensationBreakdown?: CompensationBreakdown;
      userEvents?: PeopleDirectoryUserEvent[];
      userContracts?: PeopleDirectoryUserContract[];
    }
  >;

export interface UserInviteResult {
  success: boolean;
  message: string;
  emailAddresses?: string[];
}

// FE should also have default if user / features does not have filter string defined, or FE does not show defaults
export const DEFAULT_LIFECYCLE_STATUS_FILTER = 'Lifecycle status=Hired,Employed,Leave';
