import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { StaffologyPayCode } from '@v2/feature/payroll/payroll-external.dto';
import { addMonths } from 'date-fns';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PayrunUserHeader } from '@/v2/feature/payroll/components/payrun-user-header.component';
import { extractPayLineEntriesFromPayRunEntry } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayLineTypeMenu } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payline-type-menu.component';
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from '@/v2/feature/payroll/payroll-external.interface';
import {
  PayLineEntry,
  PayRunEntryDto,
  PayrunEntryIncomeUpdateDto,
  RecurringPeriod,
} from '@/v2/feature/payroll/payroll.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { createShortDateAsUTC } from '@/v2/util/date-format.util';
import { setFocusToInput } from '@/v2/util/element.util';

type EditIncomeProps = {
  user: CachedUser;
  payrunEntry: PayRunEntryDto;
  payCodes: StaffologyPayCode[];
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
};

export const EditIncomePage = ({ user, payrunEntry, payCodes, payrunClosed, saveIncomeUpdates }: EditIncomeProps) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState('');

  const initialValues = useMemo(() => {
    return {
      salary: payrunEntry.payOptions.payAmount,
      multiplier: payrunEntry.payOptions.payAmountMultiplier,
      additions: extractPayLineEntriesFromPayRunEntry(payrunEntry, payCodes, 'addition'),
    };
  }, [payCodes, payrunEntry]);

  const formik = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: async (values) => {
      setSavingUpdate(true);
      await saveIncomeUpdates([
        {
          id: payrunEntry.id,
          userId: user.userId,
          additions: {
            salary: { amount: values.salary, multiplier: values.multiplier },
            paylines: values.additions,
          },
        },
      ]);
      setSavingUpdate(false);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);

  const updateAdditions = useCallback((f: typeof formik, entry: PayLineEntry) => {
    const idx = f.values.additions.findIndex(({ id }) => id === entry.id);
    const updatedAdditions = [...f.values.additions];
    if (idx >= 0) {
      updatedAdditions[idx] = entry;
    } else {
      updatedAdditions.push(entry);
    }
    f.setFieldValue('additions', updatedAdditions);
  }, []);

  const createAdditionPayLine = useCallback(
    (f: typeof formik, { code, title }: StaffologyPayCode) => {
      const newEntry: PayLineEntry = {
        id: uuidv4(),
        code,
        amount: 0,
        description: title,
        isDeduction: false,
        recurringId: null,
        recurring: null,
      };
      updateAdditions(f, newEntry);
      return newEntry;
    },
    [updateAdditions]
  );

  const updateRecurringRange = useCallback(
    (value: string, which: 'start' | 'end', recurring: RecurringPeriod): RecurringPeriod => {
      let { startDate, endDate } = recurring;
      const newDate = value;
      switch (which) {
        case 'start':
          startDate = newDate;
          endDate = newDate > endDate ? newDate : endDate;
          break;
        case 'end':
          endDate = newDate;
          startDate = newDate < startDate ? newDate : startDate;
          break;
      }
      return {
        startDate,
        endDate,
      };
    },
    []
  );

  return (
    <>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Edit income</Typography>
      <PayrunUserHeader user={user} sx={{ mt: spacing.m10 }} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Stack flex={1} sx={{ gap: spacing.g60, mt: spacing.m30 }}>
            {payrunEntry.payOptions.basis === 'Monthly' && (
              <Stack>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Salary</Typography>
                <MoneyTextfieldComponent
                  name="salary"
                  label="Salary"
                  value={formik.values.salary}
                  onChange={(n) => formik.setFieldValue('salary', n)}
                  disabled={savingUpdate || payrunClosed}
                  emptyIsZero
                  clearToZero
                  sx={{ mt: spacing.mt20 }}
                />
              </Stack>
            )}
            {payrunEntry.payOptions.basis !== 'Monthly' && (
              <Stack>
                <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Salary</Typography>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {formatCurrency(formik.values.salary * formik.values.multiplier)}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    gap: spacing.g10,
                    mt: spacing.mt20,
                  }}
                >
                  <MoneyTextfieldComponent
                    name="salary"
                    label="Rate"
                    value={formik.values.salary}
                    onChange={(n) => formik.setFieldValue('salary', n)}
                    disabled={savingUpdate || payrunClosed}
                    emptyIsZero
                    clearToZero
                  />
                  <MoneyTextfieldComponent
                    name="multiplier"
                    label={
                      {
                        Daily: 'Days worked',
                        Hourly: 'Hours worked',
                      }[payrunEntry.payOptions.basis]
                    }
                    value={formik.values.multiplier}
                    onChange={(n) => formik.setFieldValue('multiplier', n)}
                    disabled={savingUpdate || payrunClosed}
                    emptyIsZero
                    clearToZero
                  />
                </Stack>
              </Stack>
            )}
            {formik.values.additions
              .sort((a, b) => a.code.localeCompare(b.code) || a.id.localeCompare(b.id))
              .map((addition) => {
                const { id, code, description, amount, recurringId, recurring } = addition;
                return (
                  <React.Fragment key={id}>
                    <Stack>
                      <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ ...themeFonts.title2 }}>{code}</Typography>
                        <CheckboxComponent
                          checked={!!recurring}
                          onChange={(_, checked) =>
                            updateAdditions(formik, {
                              ...addition,
                              recurringId,
                              recurring: checked
                                ? {
                                    startDate: payrunEntry.startDate,
                                    endDate: createShortDateAsUTC(addMonths(Date.parse(payrunEntry.endDate), 2)),
                                  }
                                : null,
                            })
                          }
                          label="Recurring"
                          labelSx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}
                          disabled={savingUpdate || payrunClosed}
                        />
                      </Stack>
                      <MoneyTextfieldComponent
                        id={id}
                        name={id}
                        label="Amount"
                        value={amount}
                        onChange={(amount) =>
                          typeof amount === 'number' && updateAdditions(formik, { ...addition, amount })
                        }
                        disabled={savingUpdate || payrunClosed}
                        emptyIsZero
                        clearToZero
                        allowNegative
                        sx={{ mt: spacing.mt20 }}
                      />
                      <TextfieldComponent
                        name={`${id}Description`}
                        label="Description"
                        value={description}
                        maxLength={MAX_PAYLINE_DESCRIPTION_LENGTH}
                        onChange={(e) => updateAdditions(formik, { ...addition, description: e.target.value })}
                        clearText={() => updateAdditions(formik, { ...addition, description: '' })}
                        disabled={savingUpdate || payrunClosed}
                        sx={{ mt: spacing.m30 }}
                      />
                      {recurring && (
                        <Stack sx={{ flexFlow: 'row', gap: spacing.g20, mt: spacing.m30 }}>
                          <DatePickerComponent
                            name={`${id}StartDate`}
                            label="Effective start"
                            inputFormat="DD MMM YYYY"
                            minDate={payrunEntry.startDate}
                            maxDate={payrunEntry.endDate}
                            value={recurring.startDate}
                            onChange={(value) =>
                              updateAdditions(formik, {
                                ...addition,
                                recurring: updateRecurringRange(value, 'start', recurring),
                              })
                            }
                            disabled={savingUpdate || payrunClosed}
                          />
                          <DatePickerComponent
                            name={`${id}EndDate`}
                            label="Effective end"
                            inputFormat="DD MMM YYYY"
                            minDate={addMonths(Date.parse(payrunEntry.startDate), 1)}
                            value={recurring.endDate}
                            onChange={(value) =>
                              updateAdditions(formik, {
                                ...addition,
                                recurring: updateRecurringRange(value, 'end', recurring),
                              })
                            }
                            disabled={savingUpdate || payrunClosed}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </React.Fragment>
                );
              })}
          </Stack>
          {payrunClosed && (
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m30, color: themeColors.DarkGrey }}>
              Income cannot be changed because the payrun is closed.
            </Typography>
          )}
          {!payrunClosed && (
            <PayLineTypeMenu
              kind="addition"
              payCodes={payCodes}
              disabled={savingUpdate}
              onMenuItemClick={(payCode) => {
                const newEntry = createAdditionPayLine(formik, payCode);
                // set focus to the newly added item
                setFocusedTextEditId(newEntry.id);
              }}
              sx={{ mt: spacing.m30 }}
            />
          )}
          <Stack sx={{ mt: spacing.m30, flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>Total income</Typography>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              {formatCurrency(payrunEntry.totals.additions)}
            </Typography>
          </Stack>
          {!payrunClosed && (
            <LoaderButton
              fullWidth
              loading={savingUpdate}
              name="Save"
              sizeVariant="large"
              colorVariant="primary"
              style={{ marginTop: spacing.m30 }}
            />
          )}
        </Form>
      </FormikProvider>
    </>
  );
};
