import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/table-core';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { identity } from 'fp-ts/lib/function';
import Polyglot from 'node-polyglot';

import { CompensationAPI } from '@/api-client/compensation.api';
import { getUserCompensationSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { payScheduleUnit } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { useProfileFormUpdate } from '@/v2/feature/user/context/user-profile.context';
import {
  PayScheduleEnum,
  UserCompensationDto,
  UserCompensationWithBreakdown,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { CompensationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component';
import { buildColumnsForCustomFields } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { translatePaySchedule } from '@/v2/infrastructure/i18n/translate.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const SCOPE = 'user.compensation:all' as const;

const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(getUserCompensationSchema(polyglot));
const iconSize = { width: 14, height: 14 } as const;
const numberFormatter = Intl.NumberFormat('en-US');

export const CompensationCard = ({
  userId,
  handleSubmit = () => {},
  missingFields,
}: UserProfileCardProps): JSX.Element => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [data, setData] = useState<EntriesAndEffectiveRecord<UserCompensationWithBreakdown>>();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rowModalMode, setRowModalMode] = useState<'add' | 'edit'>('add');
  const [paySchedulesInRecords, setPaySchedulesInRecords] = useState<Set<PayScheduleEnum> | undefined>();

  const setFormCurrentlyEditing = useProfileFormUpdate();

  const [modalInitialValues, setModalInitialValues] = useState<UserCompensationDto | null>(null);

  const getUserCompensation = useCallback(async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const data = await CompensationAPI.findByUserId(userId);
      if (data?.entries?.length < 1) setShowDetails(false);

      setData(data);
      setPaySchedulesInRecords(new Set<PayScheduleEnum>(data.entries.map((e) => e.paySchedule as PayScheduleEnum)));
    } catch (error) {
      showMessage(`${polyglot.t('CompensationCard.errorMessages.load')}: ${error}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, userId, polyglot]);

  useEffect(() => {
    getUserCompensation();
  }, [getUserCompensation]);

  const editCompensationRow = useCallback((rowData: UserCompensationWithBreakdown) => {
    setModalInitialValues({
      ...rowData,
      salaryPerPaySchedule: rowData?.compensationBreakdown?.payScheduleRate,
      annualSalary: rowData?.compensationBreakdown?.fixedRates.annual,
    });
    setIsModalOpen(true);
  }, []);

  const addCompensationRow = () => {
    setRowModalMode('add');
    setModalInitialValues(null);
    setIsModalOpen(true);
  };

  const columnData = useMemo<ColumnDef<UserCompensationWithBreakdown, UserCompensationWithBreakdown>[]>(
    () => [
      {
        header: () => polyglot.t('CompensationCard.paySchedule'),
        accessorFn: identity,
        id: 'paySchedule',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.paySchedule ? original.paySchedule : ''}</div>,
      },
      {
        header: () => polyglot.t('CompensationCard.salaryBasis'),
        accessorFn: identity,
        id: 'salaryBasis',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.salaryBasis ? original.salaryBasis : ''}</div>,
      },
      {
        header: () => 'Rate',
        accessorFn: identity,
        id: 'rate',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>
            {original?.prorateSalaryByFte && original.nonProratedRate
              ? numberFormatter.format(original.nonProratedRate ?? 0)
              : numberFormatter.format(original.rate ?? 0)}
          </div>
        ),
      },
      {
        header: () => polyglot.t('CompensationCard.proRatedRate'),
        accessorFn: identity,
        id: 'proRatedRate',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>
            {original.rate && Number(original?.rate) > 0 && original?.prorateSalaryByFte
              ? numberFormatter.format(original.rate)
              : ''}
          </div>
        ),
      },
      {
        header: () => polyglot.t('CompensationCard.units'),
        accessorFn: identity,
        id: 'units',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.units ? original.units : ''}</div>,
      },
      {
        header: () => polyglot.t('CompensationCard.annualSalaryGross'),
        accessorFn: identity,
        id: 'annualSalary',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => {
          return <div>{original.compensationBreakdown?.fixedRates.annual ?? ''}</div>;
        },
      },
      ...(paySchedulesInRecords?.has(PayScheduleEnum.Weekly)
        ? ([
            {
              header: () => polyglot.t('CompensationCard.salaryPerWeek'),
              accessorFn: identity,
              id: 'salaryPerWeek',
              enableSorting: false,
              size: 80,
              cell: ({ row: { original } }) => {
                if (
                  !original.paySchedule ||
                  original.paySchedule !== PayScheduleEnum.Weekly ||
                  !original.compensationBreakdown
                )
                  return <EmptyCell />;
                return <div>{numberFormatter.format(original.compensationBreakdown.payScheduleRate)}</div>;
              },
            },
          ] as ColumnDef<UserCompensationWithBreakdown, UserCompensationWithBreakdown>[])
        : []),

      ...(paySchedulesInRecords?.has(PayScheduleEnum.Monthly)
        ? ([
            {
              header: () => polyglot.t('CompensationCard.salaryPerMonth'),
              accessorFn: identity,
              id: 'salaryPerMonth',
              enableSorting: false,
              size: 80,
              cell: ({ row: { original } }) => {
                if (
                  !original.paySchedule ||
                  original.paySchedule !== PayScheduleEnum.Monthly ||
                  !original.compensationBreakdown
                )
                  return <EmptyCell />;
                return <div>{numberFormatter.format(original.compensationBreakdown.payScheduleRate)}</div>;
              },
            },
          ] as ColumnDef<UserCompensationWithBreakdown, UserCompensationWithBreakdown>[])
        : []),
      ...buildColumnsForCustomFields<UserCompensationWithBreakdown>(data?.entries, { size: 80 }),
      {
        header: () => polyglot.t('CompensationCard.effectiveDate'),
        accessorFn: identity,
        id: 'effectiveDate',
        enableSorting: false,
        size: 80,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },

      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title={polyglot.t('CompensationCard.edit')}
                onClick={() => {
                  editCompensationRow(original);
                  setRowModalMode('edit');
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ],
    [paySchedulesInRecords, data?.entries, polyglot, scopesContext, editCompensationRow]
  );

  const effectiveRecord = useMemo(() => data?.effectiveRecord, [data]);

  return !data ? (
    <SkeletonLoader
      variant="rectangular"
      height={skeletonHeight(polyglot)}
      sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
    />
  ) : (
    <CardStructure
      cardTitle={polyglot.t('CompensationCard.salary')}
      cardScope={[SCOPE]}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      userId={userId}
      addAction={addCompensationRow}
      showMissingInfo={!effectiveRecord}
      cardFieldCustomUpdates={effectiveRecord?.customUpdates}
      cardFieldStubs={[
        'compensation.paySchedule',
        'compensation.salaryBasis',
        'compensation.rate',
        'compensation.currency',
      ]}
      cardFieldDetails={
        effectiveRecord && (
          <Box component="dl" sx={definitionListSx}>
            <FieldStructure
              fieldTitle={polyglot.t('CompensationCard.paySchedule')}
              fieldValue={translatePaySchedule(effectiveRecord.paySchedule ?? '', polyglot)}
              fieldStub="compensation.paySchedule"
            />
            <FieldStructure
              fieldTitle={polyglot.t('CompensationCard.salaryBasis')}
              fieldValue={translatePaySchedule(effectiveRecord.salaryBasis ?? '', polyglot)}
              fieldStub="compensation.salaryBasis"
            />
            {Boolean(effectiveRecord && effectiveRecord.nonProratedRate) && (
              <FieldStructure
                fieldTitle={polyglot.t('CompensationCard.rate')}
                fieldValue={
                  effectiveRecord.prorateSalaryByFte
                    ? numberFormatter.format(effectiveRecord.nonProratedRate ?? 0)
                    : numberFormatter.format(effectiveRecord.rate ?? 0)
                }
                fieldStub="compensation.rate"
              />
            )}
            {Boolean(!!effectiveRecord?.rate && effectiveRecord.prorateSalaryByFte) && (
              <FieldStructure
                fieldTitle={polyglot.t('CompensationCard.proRatedRate')}
                fieldValue={numberFormatter.format(effectiveRecord.rate!)}
                fieldStub="compensation.rate"
              />
            )}
            {Boolean(effectiveRecord.paySchedule) && (
              <FieldStructure
                fieldTitle={`${polyglot.t('CompensationCard.salaryPer')} ${payScheduleUnit(
                  effectiveRecord.paySchedule!
                )} (${polyglot.t('CompensationCard.gross')})`}
                fieldValue={(() => {
                  if (!effectiveRecord?.compensationBreakdown?.payScheduleRate) return '';
                  return numberFormatter.format(effectiveRecord.compensationBreakdown.payScheduleRate);
                })()}
                fieldStub="compensation.rate"
              />
            )}
            <FieldStructure
              fieldTitle={polyglot.t('CompensationCard.salaryYearGross')}
              fieldValue={(() => {
                if (!effectiveRecord?.compensationBreakdown?.fixedRates?.annual) return '';
                return numberFormatter.format(effectiveRecord?.compensationBreakdown?.fixedRates.annual);
              })()}
              fieldStub="compensation.rate"
            />
            <FieldStructure
              fieldTitle={polyglot.t('CompensationCard.currency')}
              fieldValue={effectiveRecord.currency}
              fieldStub="compensation.currency"
            />
            {sortCustomFields(effectiveRecord.customUpdates).map(
              (f) =>
                !f.field.isHidden && (
                  <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                )
            )}
          </Box>
        )
      }
      drawerDetails={
        <ScopesControl scopes={[SCOPE]} context={scopesContext}>
          <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <CompensationForm
              initialValues={modalInitialValues}
              userId={userId}
              getUserCompensation={getUserCompensation}
              rowModalMode={rowModalMode}
              handleSubmit={handleSubmit}
              setFormCurrentlyEditing={setFormCurrentlyEditing}
              setIsModalOpen={setIsModalOpen}
              setLoading={setLoading}
              loading={loading}
              onClose={() => setIsModalOpen(false)}
            />
          </DrawerModal>
        </ScopesControl>
      }
      addButtonTitle={polyglot.t('CompensationCard.new')}
      editAction={
        effectiveRecord &&
        (() => {
          editCompensationRow(effectiveRecord);
          setRowModalMode('edit');
        })
      }
      tableRowData={[...data.entries]}
      tableColumn={columnData}
      missingInfoIcon={
        <ScopesControl scopes={['user:all']} context={scopesContext}>
          {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.Salary) && (
            <Box sx={hazardMissingField}>
              <Hazard {...hazardSize} />
            </Box>
          )}
        </ScopesControl>
      }
    />
  );
};
