import React, { ReactNode } from 'react';

import Savings from '@mui/icons-material/Savings';
import {
  EmployeeAeAssessment,
  PensionSchemeDto,
  PensionWorkerGroupDto,
  UserPensionDto,
} from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { LocalDate } from '@v2/util/local-date';

import NestLogo from '@/images/pension/nest.svg';
import CushonLogo from '@/images/vendors/cushon.png';
import SmartPensionLogo from '@/images/vendors/SmartPension.svg';
import ThePeoplesPensionLogo from '@/images/vendors/ThePeoplesPension.svg';
import { PensionProviders } from '@/lib/pensions';

export const getPensionLogoByProviderName = (provider: string, width = 25): ReactNode => {
  switch (provider) {
    case PensionProviders.Nest:
      return <img src={NestLogo} alt="Smart Pension" width={width} />;
    case PensionProviders.SmartPension:
      return <img src={SmartPensionLogo} alt="Smart Pension" width={width} />;
    case PensionProviders.PeoplesPension:
      return <img src={ThePeoplesPensionLogo} alt="People's pension" width={width} />;
    case PensionProviders.Cushon:
      return <img src={CushonLogo} alt="Cushon" width={width} />;
    case PensionProviders.OtherProvider:
    default:
      return <Savings fontSize="small" width={width} />;
  }
};

export const PensionProvidersValueToLabel: Record<string, string | undefined> = {
  [PensionProviders.Nest]: 'Nest',
  [PensionProviders.SmartPension]: 'Smart Pension',
  [PensionProviders.PeoplesPension]: "People's pension",
  [PensionProviders.Cushon]: 'Cushon',
  [PensionProviders.OtherProvider]: 'Other Provider',
};

export const PensionProvidersOptions = [
  {
    label: 'Nest',
    value: PensionProviders.Nest,
    icon: getPensionLogoByProviderName(PensionProviders.Nest),
  },
  {
    label: 'Smart pension',
    value: PensionProviders.SmartPension,
    icon: getPensionLogoByProviderName(PensionProviders.SmartPension),
  },
  {
    label: "People's pension",
    value: PensionProviders.PeoplesPension,
    icon: getPensionLogoByProviderName(PensionProviders.PeoplesPension),
  },
  {
    label: 'Cushon',
    value: PensionProviders.Cushon,
    icon: getPensionLogoByProviderName(PensionProviders.Cushon),
  },
  {
    label: 'Other Provider',
    value: PensionProviders.OtherProvider,
    icon: getPensionLogoByProviderName(PensionProviders.OtherProvider),
  },
];

export const getActionRequiredFromLastAssessment = (lastAssessment: EmployeeAeAssessment | null): string => {
  if (!lastAssessment) return 'Assess';
  return !lastAssessment?.action.actionCompleted ? lastAssessment?.action.action : 'No change';
};

export const getLastActionFromReport = (lastAssessment: EmployeeAeAssessment | null): string => {
  if (lastAssessment?.action?.actionCompleted) {
    return `${
      lastAssessment?.action?.actionCompletedMessage ||
      lastAssessment?.action?.employeeState ||
      lastAssessment?.action?.action
    } on ${new LocalDate(lastAssessment.assessmentDate).toDateString()}`;
  }
  return '';
};

export const getPensionProviderFromUserPensionAndDefaultSchema = (
  userPension: UserPensionDto,
  defaultPensionScheme: PensionSchemeDto | null
): string => {
  if (userPension.pensionProviderId) return userPension.pensionProviderId;

  if (defaultPensionScheme?.externalId) return defaultPensionScheme.externalId;

  return '';
};

export const getWorkerGroupFromUserPensionAndDefaultSchema = (
  userPension: UserPensionDto,
  defaultWorkerGroup: PensionWorkerGroupDto | null
): string => {
  if (userPension.workerGroupId) return userPension.workerGroupId;

  if (defaultWorkerGroup?.externalId) return defaultWorkerGroup.externalId;

  return '';
};
