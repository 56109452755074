import { useContext, useMemo, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { OptionObj } from '@v2/components/forms/user-select/multi-user-select.component';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import { UserRoleAPI } from '@/api-client/user-role.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { checkScopes } from '@/lib/scopes';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import {
  AutocompleteComponent,
  AutocompleteOptionObj,
  OptionObject,
} from '@/v2/components/forms/autocomplete.component';
import { OptionsProps, SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DepartmentFormModal } from '@/v2/feature/department/department-settings/components/department-form-modal.component';
import { JobPositionAddEditFormDrawer } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { SiteFormModal } from '@/v2/feature/site/site-settings/features/components/site-form-modal.component';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { CreateUserRoleDto, UserRoleDto } from '@/v2/feature/user/features/user-forms/user-role/user-role.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

type NewEmployeeRoleSectionProps = {
  userId: number;
  role?: UserRoleDto | null;
  readonly departments: readonly OptionObject[];
  readonly sites: readonly OptionObject[];
  readonly jobPositions: OptionObject[];
  startDate: string;
  onNext: () => void;
  sx?: SxProps<Theme>;
  readonly refreshSites: KeyedMutator<SiteDto[]> | undefined;
  readonly refreshDept: KeyedMutator<CompanyDepartmentDto[]> | undefined;
  readonly refreshJobPosition: KeyedMutator<JobPosition[]> | undefined;
  readonly defaultJobPosition: JobPositionForTable;
};

export const NewEmployeeRoleSection = ({
  role,
  departments,
  sites,
  jobPositions,
  onNext,
  startDate,
  sx,
  userId,
  refreshSites,
  refreshDept,
  refreshJobPosition,
  defaultJobPosition,
}: NewEmployeeRoleSectionProps) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [siteDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState<boolean>(false);

  const NewEmployeeRoleSchema = useMemo(
    () =>
      Yup.object().shape({
        jobPositionId: Yup.string()
          .trim()
          .required(polyglot.t('NewEmployeeRoleSection.errorMessages.jobTitleRequired')),
        departmentId: Yup.number().optional().nullable().integer(),
        siteId: Yup.number().optional().nullable().integer(),
        managerId: Yup.number().optional().nullable().integer(),
      }),
    [polyglot]
  );
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = role ? 'edit' : 'add';

  const { nonTerminatedCachedUsers } = useCachedUsers();
  const users = useMemo(() => {
    return nonTerminatedCachedUsers
      .filter((u) => u.userId !== userId) // exclude the user being onboarded
      .map<OptionsProps>((u) => ({
        label: u.displayName,
        value: u.userId,
        ...u,
      }));
  }, [nonTerminatedCachedUsers, userId]);

  const formik = useFormik<UserRoleDto | CreateUserRoleDto>({
    initialValues: {
      effectiveDate: new LocalDate(startDate).toDateString(),
      jobTitle: '',
      departmentId: null,
      siteId: null,
      managerId: null,
      jobPositionId: null,
      customUpdates: [],
      ...role,
    },
    validateOnMount: true,
    validationSchema: NewEmployeeRoleSchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        if ('id' in values) {
          await UserRoleAPI.patch(userId, values.id, values);
        } else {
          await UserRoleAPI.create(userId, values);
          await OnboardingAPI.assignTemplateTasksToUser(userId);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t('NewEmployeeRoleSection.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  const currentJobPositionValue = useMemo(() => {
    return jobPositions.find((option) => option.value === Number(formik.values.jobPositionId));
  }, [formik.values.jobPositionId, jobPositions]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('NewEmployeeRoleSection.role')}
          </Typography>

          <AutocompleteComponent
            name="jobPositionId"
            label={polyglot.t('RoleForm.jobTitle')}
            options={jobPositions}
            value={currentJobPositionValue}
            compareValue={formik.values.jobPositionId}
            // @ts-ignore
            onChange={(_, x: OptionObj) => {
              formik.setFieldValue('jobPositionId', x?.value ?? null);
            }}
            helperText={hasSubmitted && formik.errors.jobPositionId}
            error={hasSubmitted && !!formik.errors.jobPositionId}
            editList={{
              handler: () => setJobDrawerOpen(true),
              isHidden: false,
            }}
          />

          <AutocompleteComponent
            name="departmentId"
            label={polyglot.t('NewEmployeeRoleSection.departmentId')}
            options={departments}
            value={departments.find(({ value }: { value: number | string }) => value === formik.values.departmentId)}
            compareValue={formik.values.departmentId ?? ''}
            // @ts-ignore
            onChange={(_, x: OptionObj) => {
              formik.setFieldValue('departmentId', x?.value ?? null);
            }}
            helperText={hasSubmitted && formik.errors.departmentId}
            error={hasSubmitted && !!formik.errors.departmentId}
            editList={{
              handler: () => setDepartmentDrawerOpen(true),
              isHidden: !checkScopes(user, ['company.settings:all'], scopesContext),
            }}
          />

          <AutocompleteComponent
            name="siteId"
            label={polyglot.t('NewEmployeeRoleSection.siteId')}
            options={sites}
            value={sites.find(({ value }) => value === formik.values.siteId)}
            compareValue={formik.values.siteId ?? ''}
            // @ts-ignore
            onChange={(_, x: AutocompleteOptionObj) => {
              formik.setFieldValue('siteId', x?.value ?? null);
            }}
            helperText={hasSubmitted && formik.errors.siteId}
            error={hasSubmitted && !!formik.errors.siteId}
            editList={{
              handler: () => setSiteDrawerOpen(true),
              isHidden: !checkScopes(user, ['company.settings:all'], scopesContext),
            }}
          />

          <SingleUserSelect
            name="managerId"
            label={polyglot.t('NewEmployeeRoleSection.managerId')}
            options={users}
            disabled={saving}
            onChange={(_, selection) => {
              formik.setFieldValue('managerId', selection?.value ?? null);
            }}
            value={formik.values.managerId}
            error={hasSubmitted && Boolean(formik.errors.managerId)}
            helperText={hasSubmitted && formik.errors.managerId}
          />

          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            rowModalMode={rowModalMode}
            formName={CustomProfileFormType.Role}
          />

          {formik.isValid && (
            <LoaderButton
              name={polyglot.t('General.continue')}
              loading={saving}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
            />
          )}
        </Stack>

        <SiteFormModal
          isOpen={siteDrawerOpen}
          setIsOpen={setSiteDrawerOpen}
          selectedSite={undefined}
          refreshSites={async () => {
            if (refreshSites) await refreshSites();
          }}
          closePage={() => setSiteDrawerOpen(false)}
        />

        <DepartmentFormModal
          isOpen={departmentDrawerOpen}
          setIsOpen={setDepartmentDrawerOpen}
          selectedDepartment={undefined}
          refreshDepartments={async () => {
            if (refreshDept) await refreshDept();
          }}
          closePage={() => setDepartmentDrawerOpen(false)}
        />

        <JobPositionAddEditFormDrawer
          isOpen={jobDrawerOpen}
          setIsOpen={setJobDrawerOpen}
          jobPositionToEdit={defaultJobPosition}
          onClose={() => {
            setJobDrawerOpen(false);
          }}
          refreshJobPositions={async () => {
            if (refreshJobPosition) await refreshJobPosition();
          }}
        />
      </Form>
    </FormikProvider>
  );
};
