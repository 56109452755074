import React, { useCallback, useContext } from 'react';

import { Box, Fade, styled as MUIStyled, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { format, isBefore, isToday, isWithinInterval } from 'date-fns';
import { generatePath, useHistory } from 'react-router-dom';

import type { TimelineEventProps } from '@/v2/feature/calendar/features/calendar.page';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { USER_PERSONAL_TAB } from '@/lib/routes';
import { PaginationDetail } from '@/v2/components/table/pagination-detail.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AttendanceSettingsDto } from '@/v2/feature/attendance/attendance.dto';
import { CompanyEvent, FormData } from '@/v2/feature/calendar/calendar.interface';
import {
  getBoxBackgroundColor,
  getEndFullOrHalfDayType,
  getStartFullOrHalfDayType,
  getTextColor,
  getWeekAlignment,
  getWeekWidth,
} from '@/v2/feature/calendar/calendar.util';
import { CalendarEvent } from '@/v2/feature/calendar/features/components/calendar-event.component';
import '@/v2/feature/calendar/features/style/calendar-timeline.css';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { convertUTCDateToLocalDate, isISODateSameAsLocaleDate } from '@/v2/infrastructure/date/date-format.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { Table, TableBody, TableHead, TableHeadRow } from '@/v2/styles/table.styles';

interface TimelineCalendarProps {
  readonly timelineDays: readonly Date[];
  readonly usersCalendarEvents: readonly TimelineEventProps[];
  readonly calendarEvents: readonly CompanyEvent[];
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSelectedCalendarEvent: React.Dispatch<React.SetStateAction<FormData>>;
  readonly refreshAbsences: () => Promise<void>;
  readonly handleStatePaginationState: (page: number, pageSize: number) => void;
  readonly setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly showPagination: boolean;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly activeView: 'Month' | 'Week';
  readonly loading: boolean;
  readonly attendanceSettings: AttendanceSettingsDto | null | undefined;
}

const StyleTR = MUIStyled('tr')(() => ({
  backgroundColor: 'transparent',
}));

const StyleTH = MUIStyled('th')(() => ({
  color: themeColors.Grey,
  ...themeFonts.captionSmall,
  height: '16px',
  paddingBottom: spacing.p10,
  paddingRight: spacing.p5,
  paddingLeft: spacing.p5,
  textAlign: 'left',
}));

const StickyStyleTH = MUIStyled(StyleTH)(() => ({
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  zIndex: 2,
}));

const StyleHolidayHeader = MUIStyled('th')(() => ({
  color: themeColors.Grey,
  ...themeFonts.captionSmall,
  height: '16px',
  paddingTop: spacing.p4,
  paddingBottom: spacing.p4,
  paddingRight: spacing.p5,
  paddingLeft: spacing.p5,
  textAlign: 'left',
}));

const StyleTD = MUIStyled('td')(() => ({
  position: 'relative',
  color: themeColors.DarkGrey,
  ...themeFonts.caption,
  textAlign: 'left',
  height: '47px', // = 40px - 1px row dividing line
}));

const StickyStyleTD = MUIStyled(StyleTD)(() => ({
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  zIndex: 2,
}));

export const CalendarTimeline = ({
  timelineDays,
  usersCalendarEvents,
  calendarEvents,
  setIsOpen,
  setSelectedCalendarEvent,
  refreshAbsences,
  handleStatePaginationState,
  setPageNumber,
  pageNumber,
  totalCount,
  showPagination,
  pageSize,
  totalItems,
  activeView,
  loading,
  attendanceSettings,
}: TimelineCalendarProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const context = getScopesContext(state.user);
  const hasCalendarAll = hasScopes(['calendar:all'], context);

  const isEventExist = calendarEvents.some((holiday) => {
    return timelineDays.some((day) =>
      holiday.endDate
        ? isWithinInterval(day, {
            start: new Date(holiday.startDate),
            end: new Date(holiday.endDate),
          }) ||
          isISODateSameAsLocaleDate(holiday.startDate, day) ||
          isISODateSameAsLocaleDate(holiday.endDate, day)
        : isISODateSameAsLocaleDate(holiday.startDate, day)
    );
  });

  const routerHistory = useHistory();
  const today = new Date();

  const handleUserClick = useCallback(
    (user: TimelineEventProps) => {
      routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: user?.userId ?? '' }));
    },
    [routerHistory]
  );

  return (
    <div>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {loading ? (
          <TableLoadingState timelineDays={timelineDays} today={today} activeView={activeView} />
        ) : (
          <Table>
            {/* date header */}
            <TableHead style={{ position: 'sticky', top: 0, zIndex: 3 }}>
              <TableHeadRow>
                <StickyStyleTH className="styled-th" style={{ minWidth: '130px', maxWidth: '130px', width: '130px' }}>
                  {polyglot.t('CalendarTimeline.name')}
                </StickyStyleTH>

                {timelineDays.map((day, idx) => {
                  const isTodayDate = isToday(day);
                  const isBeforeTodayDate = isBefore(day, today);
                  return (
                    <StyleTH
                      key={`${idx}-names-people`}
                      className="timeline-days-styled-th"
                      style={{
                        minWidth: activeView === 'Month' ? '40px' : '130px',
                        maxWidth: activeView === 'Month' ? '40px' : '130px',
                        width: activeView === 'Month' ? '40px' : '130px',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          sx={{
                            borderRadius: radius.br10,
                            padding: '0px 5px 0px 5px',
                            ...(isTodayDate
                              ? { backgroundColor: themeColors.DarkGrey, color: themeColors.white }
                              : isBeforeTodayDate
                              ? { color: themeColors.Grey }
                              : { backgroundColor: themeColors.white, color: themeColors.Grey }),
                          }}
                        >
                          {activeView === 'Month' ? format(day, 'EEE d') : format(day, 'EEE, dd MMM')}
                        </Box>
                      </Box>
                    </StyleTH>
                  );
                })}
              </TableHeadRow>
            </TableHead>

            {/* calendar events header */}
            <TableHead style={{ display: !isEventExist ? 'none' : '', position: 'sticky', top: 26, zIndex: 3 }}>
              <TableHeadRow style={{ borderBottom: isEventExist ? 'none' : borders.middle }}>
                <StickyStyleTH
                  className="styled-th"
                  style={{
                    minWidth: '130px',
                    maxWidth: '130px',
                    width: '130px',
                  }}
                />
                {timelineDays.map((day, idx) => {
                  const convertedDateUTC = convertUTCDateToLocalDate(new Date(day));

                  const filteredEvents: CompanyEvent[] = calendarEvents.filter((c) => {
                    return c.endDate
                      ? isWithinInterval(day, {
                          start: new Date(c.startDate),
                          end: new Date(c.endDate),
                        }) ||
                          isISODateSameAsLocaleDate(c.startDate, day) ||
                          isISODateSameAsLocaleDate(c.endDate, day)
                      : isISODateSameAsLocaleDate(c.startDate, day);
                  });
                  return (
                    <StyleHolidayHeader
                      key={`${idx}-header`}
                      className="timeline-days-styled-th"
                      style={{ paddingTop: spacing.p4, minWidth: '40px' }}
                    >
                      <Box
                        className="holiday-header"
                        sx={{
                          gap: spacing.g5,
                          marginTop: spacing.m2,
                        }}
                      >
                        {filteredEvents.slice(0, 2).map((event, idx) => (
                          <StyledTooltip title={event.name}>
                            <Box
                              key={`${idx}-event`}
                              sx={{
                                display: 'flex',
                                justifyContent: getWeekAlignment(event, convertedDateUTC),
                                width: '100%',
                                position: 'relative',
                                cursor: hasCalendarAll ? 'pointer' : 'default',
                              }}
                              onClick={() => {
                                if (hasCalendarAll) {
                                  setIsOpen(true);
                                  const calendarEvent = {
                                    ...event,
                                    startFullOrHalfDay: getStartFullOrHalfDayType(polyglot, event),
                                    endFullOrHalfDay: getEndFullOrHalfDayType(polyglot, event),
                                    multipleDays: !!event.endDate,
                                  };
                                  setSelectedCalendarEvent(calendarEvent);
                                }
                              }}
                            >
                              <Box
                                className="holiday-box"
                                sx={{
                                  backgroundColor: event.color ? event.color : themeColors.GreyLight,
                                  alignItems: 'center',
                                  width: getWeekWidth(event, convertedDateUTC),
                                }}
                              >
                                <Typography
                                  className="textOverflow"
                                  sx={{
                                    color: getTextColor(event),
                                    display: activeView === 'Week' ? 'flex' : 'none',
                                    ...themeFonts.captionSmall,
                                  }}
                                >
                                  {event.name}
                                </Typography>
                              </Box>
                            </Box>
                          </StyledTooltip>
                        ))}

                        {filteredEvents.length > 2 && (
                          <StyledTooltip
                            title={filteredEvents
                              .slice(2)
                              .map((event) => event.name)
                              .join(', ')}
                          >
                            <Box className="holiday-box">
                              <Typography
                                sx={{
                                  ...themeFonts.captionSmall,
                                }}
                              >
                                {polyglot.t('CalendarTimeline.events', { len: filteredEvents.length - 2 })}
                              </Typography>
                            </Box>
                          </StyledTooltip>
                        )}
                      </Box>
                    </StyleHolidayHeader>
                  );
                })}
              </TableHeadRow>
            </TableHead>

            <Fade in={Boolean(usersCalendarEvents)} timeout={{ enter: 700, exit: 700 }}>
              <TableBody>
                {usersCalendarEvents.map((user, userIndex) => {
                  return (
                    <StyleTR key={user?.userId ?? userIndex}>
                      <StickyStyleTD className="data-row-user">
                        <Box
                          sx={{
                            gap: spacing.g5,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          className="user-name-avatar"
                          onClick={() => handleUserClick(user)}
                        >
                          <UserAvatar userId={user?.userId} size="xxsmall" />
                          <Typography
                            sx={{
                              ...themeFonts.caption,
                              display: 'block',
                              cursor: 'pointer',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: '100%',
                            }}
                          >
                            {polyglot.t(user?.name ?? '')}
                          </Typography>
                        </Box>
                      </StickyStyleTD>
                      {user?.entries?.map((entry, entryIndex) => {
                        return (
                          <StyleTD
                            key={`${userIndex}-${entryIndex}-entry`}
                            className="data-row-entry"
                            style={{
                              minWidth: '48px',
                              ...getBoxBackgroundColor(entry, attendanceSettings),
                            }}
                          >
                            <CalendarEvent
                              activeView={activeView}
                              entry={entry}
                              userId={user.userId}
                              refreshAbsences={refreshAbsences}
                            />
                          </StyleTD>
                        );
                      })}
                    </StyleTR>
                  );
                })}
              </TableBody>
            </Fade>
          </Table>
        )}
      </div>
      {showPagination && (
        <PaginationDetail
          totalPageCount={totalCount}
          totalRecordCount={totalItems}
          current={pageNumber}
          onNextAction={() => {
            setPageNumber((prevState: number) => prevState + 1);
          }}
          nextDisabled={pageNumber === totalCount}
          onPreviousAction={() => {
            setPageNumber((prevState: number) => prevState - 1);
          }}
          previousDisabled={pageNumber === 1}
          handleState={(page: number) => setPageNumber(page)}
          paginationState={{ pageIndex: pageNumber, pageSize }}
          setPaginationState={(pageSize: number, pageIndex: number) => {
            handleStatePaginationState(pageIndex, pageSize);
          }}
        />
      )}
    </div>
  );
};

export const TableLoadingState = ({
  timelineDays,
  today,
  activeView,
}: {
  readonly timelineDays: readonly Date[];
  today: Date;
  readonly activeView: 'Month' | 'Week';
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          <StickyStyleTH
            className="styled-th"
            style={{
              minWidth: '130px',
              maxWidth: '130px',
              width: '130px',
            }}
          >
            {polyglot.t('CalendarTimeline.name')}
          </StickyStyleTH>

          {timelineDays.map((day, idx) => {
            const isTodayDate = isToday(day);
            const isBeforeTodayDate = isBefore(day, today);
            return (
              <StyleTH
                key={`${idx}-names-people`}
                className="timeline-days-styled-th"
                style={{
                  minWidth: activeView === 'Month' ? '48px' : '130px',
                  maxWidth: activeView === 'Month' ? '48px' : '130px',
                  width: activeView === 'Month' ? '48px' : '130px',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      borderRadius: radius.br10,
                      padding: '0px 5px 0px 5px',
                      ...(isTodayDate
                        ? { backgroundColor: themeColors.DarkGrey, color: themeColors.white }
                        : isBeforeTodayDate
                        ? { color: themeColors.Grey }
                        : { backgroundColor: themeColors.white, color: themeColors.Grey }),
                    }}
                  >
                    {activeView === 'Month' ? format(day, 'EEE d') : format(day, 'EEE, dd MMM')}
                  </Box>
                </Box>
              </StyleTH>
            );
          })}
        </TableHeadRow>
      </TableHead>

      <TableBody>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((user, index) => {
          return (
            <StyleTR key={index}>
              <StickyStyleTD
                className="data-row-user"
                style={{
                  minWidth: activeView === 'Month' ? '48px' : '130px',
                  maxWidth: activeView === 'Month' ? '48px' : '130px',
                  width: activeView === 'Month' ? '48px' : '130px',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <SkeletonLoader
                    variant="rectangular"
                    height="48px"
                    width="130px"
                    sx={{ background: themeColors.Background, display: 'flex' }}
                  />
                </Box>
              </StickyStyleTD>
              {timelineDays?.map((entry, idx) => {
                return (
                  <StyleTD
                    key={`${idx}-entry`}
                    className="data-row-entry"
                    style={{
                      minWidth: activeView === 'Month' ? '48px' : '130px',
                      maxWidth: activeView === 'Month' ? '48px' : '130px',
                      width: activeView === 'Month' ? '48px' : '130px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <SkeletonLoader
                        variant="rectangular"
                        height="48px"
                        width={activeView === 'Month' ? '40px' : '130px'}
                        sx={{ background: themeColors.Background, display: 'flex' }}
                      />
                    </Box>
                  </StyleTD>
                );
              })}
            </StyleTR>
          );
        })}
      </TableBody>
    </Table>
  );
};
