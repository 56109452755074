import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DevicePossessionDto,
  DeviceUpdateInHouseMdmSuperadminDto,
} from '@v2/feature/device/device.dto';
import { EnrolmentType } from '@v2/feature/device/device.interface';
import { TechSpecsViewModal } from '@v2/feature/device/features/device-cards/components/tech-specs/tech-specs-view-modal.component';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { SimpleInHouseMdmDeviceDialog } from '@v2/feature/super-admin/features/super-admin-devices/components/simple-inhouse-mdm-device-dialog.component';
import { MdmMigratorAPI } from '@v2/feature/user/features/mdm-migration/mdm-migrator.api';
import { underlinedLinkDark } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminOverviewInHouseMdmDeviceDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  devicePossession: DevicePossessionDto;
  readonly refresh: () => void;
  readonly companyName: string;
}

export const SuperAdminOverviewInHouseMdmDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  companyName,
}: SuperAdminOverviewInHouseMdmDeviceDrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminOverviewInHouseMdmDeviceDrawerContent devicePossession={devicePossession} companyName={companyName} />
    </Suspense>
  </DrawerModal>
);

interface SuperAdminOverviewInHouseMdmDeviceDrawerContentProps {
  devicePossession: DevicePossessionDto;
  companyName: string;
}

export const SuperAdminOverviewInHouseMdmDeviceDrawerContent = ({
  devicePossession,
  companyName,
}: SuperAdminOverviewInHouseMdmDeviceDrawerContentProps): JSX.Element => {
  const [, setLoading] = useState<boolean>(false);
  const [, setSyncLoading] = useState<boolean>(false);
  const [isDeviceDetailsOpen, setIsDeviceDetailsOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [appliedPolicies, setAppliedPolicies] = useState<
    AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined
  >();
  const [devicePossessionHelper, setDevicePossessionHelper] = useState<DevicePossessionDto>(devicePossession);

  const refreshDevice = async (): Promise<void> => {
    let devicePossessionUpdated = await DeviceAPI.getRefreshedDevicePossessionDetails(devicePossession.id);
    if (devicePossessionUpdated) {
      setDevicePossessionHelper(devicePossessionUpdated);
    }
  };
  const [showMessage] = useMessage();

  const initialValues: DeviceUpdateInHouseMdmSuperadminDto = {
    deviceModel: devicePossession.device?.modelName ?? null,
    serialNumber: devicePossession.device?.serialNumber ?? null,
    enrolmentType: devicePossession.device?.enrolmentType ?? null,
    enrolmentStatus: devicePossession.device?.enrollmentStatus ?? null,
  };

  const validationSchema = yup.object({
    deviceModel: yup.string().required('Device model is required'),
    serialNumber: yup.string().required('Serial number is required'),
  });

  const patchDeviceEntity = async () => {
    try {
      setLoading(true);
      await DeviceAPI.scanDeviceBySuperadmin(devicePossession.id, devicePossession.companyId ?? 0);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await refreshDevice();
      showMessage('Device successfully updated.', 'success');
    } catch (error) {
      showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<DeviceUpdateInHouseMdmSuperadminDto>({
    initialValues,
    validationSchema,
    onSubmit: async () => patchDeviceEntity(),
  });

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDeviceDetailsDialogOpen = async () => {
    await refreshDevice();
    setIsDeviceDetailsOpen(true);
  };
  const syncDeviceById = async () => {
    try {
      setSyncLoading(true);
      if (!devicePossession.device) {
        new Error('No device');
      }
      Promise.all([
        InHouseMdmAPI.syncDeviceAgainstDep({
          zeltDeviceId: devicePossession.deviceId,
          serialNumber: devicePossession.device?.serialNumber,
        }),
        DeviceAPI.scanDeviceBySuperadmin(devicePossession.id, devicePossession.companyId ?? 0),
      ]);

      showMessage('Start synchronizing device ', 'success');
    } catch (error: any) {
      showMessage('Could not sync device. Something went wrong.', 'error');
    } finally {
      setSyncLoading(false);
    }
  };

  const setCompanyPolicies = async () => {
    try {
      setSyncLoading(true);
      await InHouseMdmAPI.syncDevicePolicy({ zeltDeviceId: devicePossession.deviceId });

      showMessage('Start synchronizing device policy ', 'success');
    } catch (error: any) {
      showMessage('Could not sync device. Something went wrong.', 'error');
    } finally {
      setSyncLoading(false);
    }
  };

  const getDeviceAppliedPolicyPolicies = async () => {
    try {
      setSyncLoading(true);
      if (devicePossession.device && devicePossession.device.inHouseMdm) {
        if (['ipados', 'ios'].includes(devicePossession.device.os ?? '')) {
          setAppliedPolicies(
            await DeviceAPI.getSuperadminAppliedCompanyDevicePoliciesByZeltMobileMdm(devicePossession.companyId ?? 0)
          );
        } else {
          setAppliedPolicies(
            await DeviceAPI.getSuperadminAppliedCompanyDevicePoliciesByZeltMdm(devicePossession.companyId ?? 0)
          );
        }
      }
      setOpenDialog(true);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        showMessage(`Device's policies not found.`, 'warning');
      } else {
        showMessage(`Could not show device's policies. Something went wrong.`, 'error');
      }
    } finally {
      setSyncLoading(false);
    }
  };

  const addDepDeviceToInHouseMdm = async () => {
    try {
      setSyncLoading(true);
      await MdmMigratorAPI.startMigration({
        companyId: devicePossession.companyId ?? 0,
        deviceIds: [devicePossession.deviceId],
      });

      showMessage('Start synchronizing device ', 'success');
    } catch (error: any) {
      showMessage('Could not sync device. Something went wrong.', 'error');
    } finally {
      setSyncLoading(false);
    }
  };

  const syncEnrolmentStatus = async () => {
    try {
      setSyncLoading(true);
      await InHouseMdmAPI.syncEnrolmentStatus(devicePossession.deviceId);

      showMessage('Start synchronizing enrolment status ', 'success');
    } catch (error: any) {
      showMessage('Could not sync enrolment status. Something went wrong.', 'error');
    } finally {
      setSyncLoading(false);
    }
  };
  return (
    <>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
        {devicePossession.device?.modelName} {devicePossession.device?.id}
      </Typography>
      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, alignItems: 'left' }}>
          <TextfieldComponent
            label="Device Model"
            name="deviceModel"
            value={devicePossession.device?.modelName}
            size="small"
            endAdornment="none"
            disabled
          />
          <TextfieldComponent
            label="Serial number"
            name="serialNumber"
            value={devicePossession.device?.serialNumber}
            size="small"
            endAdornment="none"
            disabled
          />
          <TextfieldComponent
            label="Company name"
            name="companyName"
            value={companyName}
            size="small"
            endAdornment="none"
            disabled
          />
          <TextfieldComponent
            label="Enrolment type"
            name="enrolmentType"
            value={devicePossession.device?.enrolmentType}
            size="small"
            endAdornment="none"
            disabled
          />
          <TextfieldComponent
            label="Enrolment status"
            name="enrolmentStatus"
            value={devicePossession.device?.enrollmentStatus}
            size="small"
            endAdornment="none"
            disabled
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: 3,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {devicePossession.device?.enrollmentStatus === 'ENROLMENT_FINISHED' && (
              <Link
                component="button"
                sx={underlinedLinkDark}
                onClick={() => {
                  setCompanyPolicies();
                }}
              >
                Migrate company policies
              </Link>
            )}
            {devicePossession.device?.inHouseMdm &&
              devicePossession.device?.enrolmentType !== EnrolmentType.OPEN_ENROLMENT && (
                <Link
                  component="button"
                  sx={underlinedLinkDark}
                  onClick={() => {
                    addDepDeviceToInHouseMdm();
                  }}
                >
                  Add to in-house MDM database - DEP
                </Link>
              )}
            {devicePossession.device?.inHouseMdm && (
              <Link
                component="button"
                sx={underlinedLinkDark}
                onClick={() => {
                  syncEnrolmentStatus();
                }}
              >
                Sync enrolment status
              </Link>
            )}
            <Link
              component="button"
              sx={underlinedLinkDark}
              onClick={() => {
                getDeviceAppliedPolicyPolicies();
              }}
            >
              See company policies
            </Link>
            {devicePossession.device?.enrollmentStatus === 'ENROLMENT_FINISHED' && (
              <Link
                component="button"
                sx={underlinedLinkDark}
                onClick={() => {
                  handleDeviceDetailsDialogOpen();
                }}
              >
                See devices details
              </Link>
            )}
            {devicePossession.device?.enrollmentStatus === 'ENROLMENT_FINISHED' && (
              <Link
                component="button"
                sx={underlinedLinkDark}
                onClick={() => {
                  syncDeviceById();
                }}
              >
                Sync device
              </Link>
            )}
          </Box>
        </Box>
      </FormikProvider>
      <TechSpecsViewModal
        setIsOpen={setIsDeviceDetailsOpen}
        isOpen={isDeviceDetailsOpen}
        devicePossession={devicePossessionHelper}
        refresh={refreshDevice}
        activeTransit={null}
      />
      <SimpleInHouseMdmDeviceDialog open={openDialog} onClose={handleCloseDialog} appliedPolicies={appliedPolicies} />
    </>
  );
};
