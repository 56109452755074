export enum PayrunStates {
  draft = 'DRAFT',
  inProgress = 'IN_PROGRESS',
  partiallyCompleted = 'PARTIALLY_COMPLETED',
  completed = 'COMPLETED',
}

export interface ExternalDataProvider {
  name: string;
  id: string;
  authScheme: 'OAuth1' | 'OAuth2' | 'Basic' | 'ApiKeyInHeader' | 'Hmac' | 'OAuth2Cc' | 'OAuth2Password';
  logoUrl: string;
  iconUrl: string;
  connected: boolean;
  connectedAs: string;
  requiresConfig: boolean;
  types: ExternalDataProviderType[];
}

export interface NominalCodeDto {
  description: string;
  name: string;
  provider: string;
  code: string;
}

export interface NominalCode {
  code: string;
  name: string;
}

export type ExternalDataProviderType =
  | 'PensionSchemes'
  | 'Accounting'
  | 'Hr'
  | 'Payments'
  | 'TimeAndAttendance'
  | 'EmployeePortal';

export interface PayrollHMRCSetting {
  officeNumber: string;
  payeReference: string;
  accountsOfficeReference: string;
  smallEmployersRelief: boolean;
  govGatewayId: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  password: string | null;
}

export interface PayrunAccountingLine {
  payCode: string;
  payCodeTitle: string;
  description: string;
  value: number;
  nominalCode: string | null;
  departmentCode: string | null;
  qty: number | null;
}

export interface ExternalJournalDataLine {
  AccountCode: number | string;
  LineAmount: number;
  Description: string;
}

export interface FormattedAccountJournalLine {
  AccountCode: string | number;
  Amount: number;
  Date: string;
  Narration: string;
  TaxRate: string;
  Description: string;
}

export interface AccountingFieldPaycodeMapping {
  code: string;
  nominalName: string;
  payCode: string;
  description: string;
}

type XeroTenant = {
  type: 'xero';
  name: string;
  id: string;
};

type MinimalAccountingOrganisation = {
  name: string;
  id: string;
};

export type AccountingOrganisation = XeroTenant;

export interface CompanyPayrollAccountingConfig {
  appInstalled: boolean;
  organisation: AccountingOrganisation | null;
  accountingCodes: { fields?: AccountingFieldPaycodeMapping[] };
  invalidatedAt?: Date;
}

export interface ExpenseAccountingOrganisationConfig {
  appInstalled: boolean;
  organisation: MinimalAccountingOrganisation | null;
}

export const PayrollProviders = {
  zelt: { name: 'Zelt' },
};

export enum CompanyPayrollType {
  UKPayroll = 'uk-payroll',
  GlobalPayroll = 'global-payroll',
}
