import { useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { TableEntryProps } from '@/v2/feature/calendar/features/calendar.page';
import { AllEventModal } from '@/v2/feature/calendar/features/components/all-event-modal.component';
import { CalendarAbsenceEvent } from '@/v2/feature/calendar/features/components/calendar-absence-event.component';
import { CalendarAttendanceContentDrawer } from '@/v2/feature/calendar/features/components/calendar-attendance-content-drawer.component';
import { CalendarPublicHolidayDrawerContent } from '@/v2/feature/calendar/features/components/calendar-public-holiday-drawer-content.component';
import { CalendarPublicHoliday } from '@/v2/feature/calendar/features/components/calendar-public-holiday-event.component';
import { CalendarScheduleEvent } from '@/v2/feature/calendar/features/components/calendar-schedule-event.component';
import {
  CalendarSpecialEvent,
  EventKind,
  SpecialEvents,
} from '@/v2/feature/calendar/features/components/calendar-special-events.component';
import { UserEventDetailDrawer } from '@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-event-detail-drawer.component';
import { LocalDate } from '@/v2/util/local-date';

interface Props {
  readonly activeView: 'Month' | 'Week';
  readonly entry: TableEntryProps;
  readonly userId: number;
  readonly refreshAbsences: () => Promise<void>;
}

export const CalendarEvent = ({ activeView, entry, userId, refreshAbsences }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedEventOnDate, setSelectedEventOnDate] = useState<TableEntryProps | undefined>(undefined);
  const [isScheduledViewOpen, setScheduledIsViewOpen] = useState<boolean>(false);
  const [isSpecialViewOpen, setSpecialIsViewOpen] = useState<boolean>(false);
  const [specialKind, setSpecialKind] = useState<EventKind | undefined>(undefined);
  const [isAbsenceViewOpen, setAbsenceIsViewOpen] = useState<boolean>(false);
  const [isPublicViewOpen, setPublicIsViewOpen] = useState<boolean>(false);

  const overlappingEventsCounter = useMemo(() => {
    const eventCount =
      (entry.events.schedule ? 0 : 0) +
      (entry.events.absence?.length ? 1 : 0) +
      (entry.events.isDob ? 1 : 0) +
      (entry.events.isFirstDay ? 1 : 0) +
      (entry.events.isLastDay ? 1 : 0) +
      (entry.events.isWorkAnniversary ? 1 : 0) +
      (entry.events.publicHolidays?.length ? 1 : 0);

    return eventCount - 1;
  }, [entry]);
  const eventToShow = useMemo(() => {
    if (entry.events.absence && entry.events.absence.length > 0) {
      return (
        <CalendarAbsenceEvent
          key={`${userId}-${entry.day}-abs`}
          absence={entry.events.absence[0]}
          date={entry.day}
          refreshAbsences={refreshAbsences}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
          setAbsenceIsViewOpen={setAbsenceIsViewOpen}
          isAbsenceViewOpen={isAbsenceViewOpen}
        />
      );
    }

    if (entry.events.isDob) {
      return (
        <CalendarSpecialEvent
          key={`${userId}-${entry.day}-dob`}
          kind="birthday"
          eventDate={entry.userEventDate}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    if (entry.events.isFirstDay) {
      return (
        <CalendarSpecialEvent
          key={`${userId}-${entry.day}-first-day`}
          kind="first-day"
          eventDate={entry.userEventDate}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    if (entry.events.isLastDay) {
      return (
        <CalendarSpecialEvent
          key={`${userId}-${entry.day}-last-day`}
          kind="last-day"
          eventDate={entry.userEventDate}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    if (entry.events.isWorkAnniversary) {
      return (
        <CalendarSpecialEvent
          key={`${userId}-${entry.day}-anniversary`}
          kind="work-anniversary"
          eventDate={entry.userEventDate}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    if (entry.events.publicHolidays && entry.events.publicHolidays.length > 0) {
      return (
        <CalendarPublicHoliday
          key={`${userId}-${entry.day}-public`}
          publicHoliday={entry.events.publicHolidays[0]}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    if (entry.events.schedule && entry.events.schedule.isScheduleToday) {
      return (
        <CalendarScheduleEvent
          schedule={entry.events.schedule}
          activeView={activeView}
          overlappingEventsCounter={overlappingEventsCounter}
          setSelectedEventOnDate={setSelectedEventOnDate}
          setIsOpen={setIsOpen}
          entry={entry}
        />
      );
    }

    return null;
  }, [
    userId,
    entry,
    activeView,
    refreshAbsences,
    overlappingEventsCounter,
    setSelectedEventOnDate,
    setIsOpen,
    isAbsenceViewOpen,
  ]);

  return (
    <Box>
      {eventToShow}
      <AllEventModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        selectedEventOnDate={selectedEventOnDate}
        userId={userId}
        setScheduledIsViewOpen={setScheduledIsViewOpen}
        setSpecialIsViewOpen={setSpecialIsViewOpen}
        setAbsenceIsViewOpen={setAbsenceIsViewOpen}
        setPublicIsViewOpen={setPublicIsViewOpen}
        setSpecialKind={setSpecialKind}
      />

      <UserEventDetailDrawer
        selectedEvent={{
          type: specialKind ? SpecialEvents[specialKind].title : 'NA',
          startDate: entry.userEventDate,
          endDate: null,
          userId,
        }}
        setIsOpen={setSpecialIsViewOpen}
        isOpen={isSpecialViewOpen}
      />

      <DrawerModal isOpen={isScheduledViewOpen} setIsOpen={setScheduledIsViewOpen}>
        <CalendarAttendanceContentDrawer
          userId={userId}
          selectedDate={new LocalDate(entry.day).getDate()}
          schedule={entry.events.schedule}
        />
      </DrawerModal>

      <DrawerModal
        isOpen={isPublicViewOpen}
        setIsOpen={setPublicIsViewOpen}
        onClose={() => {
          setPublicIsViewOpen(false);
        }}
      >
        <CalendarPublicHolidayDrawerContent publicHoliday={entry.events.publicHolidays[0]} userId={userId} />
      </DrawerModal>
    </Box>
  );
};
