import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CompanyPayrollsDto } from '@shared/modules/payroll/payroll.types';
import { StaffologyPaySchedule } from '@v2/feature/payroll/payroll-external.dto';

import useMessage from '@/hooks/notification.hook';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

type Props = {
  companies: readonly SuperAdminCompanyInfo[];
  readonly pageConfig: PageConfig;
};

export const SuperAdminSalaryPage = ({ companies, pageConfig }: Props): JSX.Element => {
  const [companyId, setCompanyId] = useState<number>();
  const [payrolls, setPayrolls] = useState<CompanyPayrollsDto>();
  const [payrollId, setPayrollId] = useState<number>();
  const [schedules, setSchedules] = useState<StaffologyPaySchedule[]>([]);
  const [selectedScheduleIdx, setSelectedScheduleIdx] = useState(-1);
  const [movingUsers, setMovingUsers] = useState(false);
  const [showMessage] = useMessage();

  useEffect(() => {
    setPayrolls(undefined);
    setPayrollId(undefined);
    if (!companyId) return;
    PayrollAPI.getCompanyPayrollsAsSuperAdmin(companyId).then(setPayrolls);
  }, [companyId]);

  const companyList = useMemo(() => {
    return [...companies]
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      .map<OptionObject>((c) => ({ label: c.name, value: c.companyId }));
  }, [companies]);

  const scheduleList = useMemo(() => {
    return schedules.map<OptionObject>((a, idx) => ({
      label: a.name,
      value: idx,
    }));
  }, [schedules]);

  const fetchCompanyPayrollInfo = useCallback(async () => {
    setSchedules([]);
    setSelectedScheduleIdx(-1);
    if (!companyId || !payrollId) {
      return;
    }
    try {
      const [schedules] = await Promise.all([
        PayrollExternalApi.getPaySchedulesAsSuperadmin(companyId, payrollId, 'Year2024'),
      ]);
      setSchedules(schedules.filter((s) => s.isConfigured));
    } catch (err) {
      showMessage(err, 'error');
    }
  }, [companyId, payrollId, showMessage]);

  useEffect(() => {
    fetchCompanyPayrollInfo();
  }, [fetchCompanyPayrollInfo]);

  const selectedSchedule = schedules?.[selectedScheduleIdx];

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Salary</Typography>}
        views={<></>}
      />
      <SecondaryHeaderMenu tabs={pageConfig.header.tabs!} />
      <ContentWrapper secondLevel>
        <Stack sx={{ width: '500px', gap: '20px' }}>
          Company:
          <SelectComponent
            name="companies"
            options={companyList}
            value={companyId ?? 0}
            onChange={(e) => setCompanyId(e.target.value)}
            disabled={movingUsers}
          />
          Payroll:
          <SelectComponent
            name="payrolls"
            options={
              payrolls?.items.map((p) => ({
                label: p.entity?.legalName ?? `PayrollID ${p.id}`,
                value: p.id,
              })) || []
            }
            value={payrollId ?? 0}
            onChange={(e) => setPayrollId(e.target.value)}
            disabled={movingUsers}
          />
          {scheduleList && (
            <>
              Move users to a different pay schedule:
              <SelectComponent
                name="schedules"
                options={scheduleList}
                value={selectedScheduleIdx ?? -1}
                onChange={(e) => setSelectedScheduleIdx(e.target.value)}
                disabled={movingUsers}
              />
              {selectedSchedule && companyId && payrollId && (
                <LoaderButton
                  name="Move users"
                  type="button"
                  sizeVariant="large"
                  colorVariant="primary"
                  loading={movingUsers}
                  onClick={async () => {
                    setMovingUsers(true);
                    const { payPeriod } = selectedSchedule;
                    if (payPeriod !== 'Monthly' && payPeriod !== 'Weekly') {
                      throw new Error('Pay period not supported');
                    }
                    try {
                      const res = await PayrollExternalApi.moveUsersToPaySchedule(
                        companyId,
                        payrollId,
                        payPeriod,
                        selectedSchedule.ordinal
                      );
                      showMessage(`${res.count} users moved to new pay schedule`, 'info');
                      fetchCompanyPayrollInfo();
                    } catch (err) {
                      showMessage(err, 'error');
                    }
                    setMovingUsers(false);
                  }}
                />
              )}
              <div style={{ overflow: 'auto', height: '400px' }}>
                <Typography sx={{ whiteSpace: 'pre' }}>
                  {schedules[selectedScheduleIdx] && JSON.stringify(schedules[selectedScheduleIdx], undefined, ' ')}
                </Typography>
              </div>
            </>
          )}
        </Stack>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
