import axios from 'axios';
import { CreateExpense, Expense, UpdateExpenseApprovalDto } from '@v2/feature/payments/payments.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';

export class ExpenseAPI {
  static async getApprovalListForNewExpense(userId: number, type: number): Promise<ApproverStep[]> {
    return (await axios.get(`/apiv2/expense/approver-list-for-new-expense/${userId}/${type}`)).data;
  }

  static async createExpense(payload: CreateExpense): Promise<Expense> {
    return (await axios.post('/apiv2/expense', payload)).data;
  }

  static async updateExpense(expenseId: string, payload: Expense): Promise<Expense> {
    return (await axios.patch(`/apiv2/expense/${expenseId}`, payload)).data;
  }

  static async updateExpenseApproval(
    expenseId: string,
    userId: number,
    payload: UpdateExpenseApprovalDto
  ): Promise<Expense> {
    return (await axios.patch(`/apiv2/expense/${expenseId}/users/${userId}/approval`, payload)).data;
  }

  static async updateExpenseForceApproval(
    expenseId: string,
    userId: number,
    payload: UpdateExpenseApprovalDto
  ): Promise<Expense> {
    return (await axios.patch(`/apiv2/expense/${expenseId}/users/${userId}/force-approval`, payload)).data;
  }

  static async bulkApprove(expenseIds: string[]): Promise<number> {
    return (await axios.patch('/apiv2/expense/bulk-approve', expenseIds)).data;
  }

  static async deleteExpense(expenseId: string): Promise<Expense> {
    return (await axios.delete(`/apiv2/expense/${expenseId}`)).data;
  }

  static async getExpenseForPaymentId(userId: number, paymentId: number): Promise<Expense> {
    return (await axios.get(`/apiv2/expense/users/${userId}/payments/${paymentId}`)).data;
  }

  static async getExpenseForOneOffPaymentId(userId: number, oneOffPaymentId: string): Promise<Expense> {
    return (await axios.get(`/apiv2/expense/users/${userId}/one-off-payments/${oneOffPaymentId}`)).data;
  }

  static async syncExpenseWithExternalProvider(expenseId: string): Promise<Expense> {
    return (await axios.post(`/apiv2/expense/${expenseId}/sync-with-accounting-provider`)).data;
  }

  static async deleteUploadReceipt(key: string): Promise<void> {
    await axios.post('/apiv2/expense/delete-receipt', { remoteFileName: key });
  }

  static getExpenseUploadReceiptURL(): string {
    return `/apiv2/expense/upload-receipt`;
  }
}

export class ExpenseEndpoints {
  static getEventSourceUrlForExternalExpenseSyncSuccess(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-expense-external-sync-success`,
    };
  }

  static getCompanyExpenses(): Endpoint<Expense[]> {
    return {
      url: '/apiv2/expense',
    };
  }

  static getTeamExpenses(): Endpoint<Expense[]> {
    return {
      url: '/apiv2/expense/team',
    };
  }

  static getUserExpenses(): Endpoint<Expense[]> {
    return {
      url: '/apiv2/expense/user',
    };
  }

  static getSpecificUserExpenses(userId: number): Endpoint<Expense[]> {
    return {
      url: `/apiv2/expense/user/${userId}`,
    };
  }

  static getExpensesById(id: string, userId: number): Endpoint<Expense> {
    return {
      url: `/apiv2/expense/${id}/users/${userId}`,
    };
  }
}
