import { useState } from 'react';

import { PensionSummaryPage } from '@v2/feature/benefits/subfeature/pension/pension-summary.page';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PensionSchemeDto, UserPensionDto } from './pension.dto';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE,
  PENSION_COMPANY_ROUTE,
  PENSION_COMPANY_SUMMARY_ROUTE,
} from '@/lib/routes';

interface PensionCompanyRouterProps {
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly loading: boolean;
}

export const PensionCompanyRouter = ({ pensionSchemes, loading }: PensionCompanyRouterProps) => {
  const userPensionState = useState<readonly UserPensionDto[]>([]);

  return (
    <Switch>
      <RouteScopesControl scopes={['pension:all']} path={PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE}>
        <PensionSummaryPage
          pensionSchemes={pensionSchemes}
          userPensionState={userPensionState}
          loadingParent={loading}
        />
      </RouteScopesControl>
      <Route path={PENSION_COMPANY_ROUTE}>
        <Redirect to={PENSION_COMPANY_SUMMARY_ROUTE} />
      </Route>
    </Switch>
  );
};
