import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { OptionObject, SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyAttachmentType, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyGeneralFormData } from '@v2/feature/absence/absence.interface';
import { COLOR_OPTIONS, getMonthOptions } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyGeneralEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refreshPolicy: () => Promise<void>;
  readonly refreshPolicies: () => Promise<void>;
  readonly refreshArchivedPolicies: () => Promise<void>;
}

export const PolicyGeneralEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refreshPolicy,
  refreshPolicies,
}: PolicyGeneralEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyGeneralFormData) => {
      const policyData: PolicyGeneralFormData = {
        color: values.color,
        name: values.name,
        fullName: values.fullName,
        isPublic: values.isPublic,
        attachmentType: values.attachmentType ? values.attachmentType : null,
        cycleStartDay: Number(values.cycleStartDay),
        cycleStartMonth: Number(values.cycleStartMonth),
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyGeneral(absencePolicy.id, policyData);
        await Promise.all([refreshPolicy(), refreshPolicies()]);
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [absencePolicy.id, refreshPolicy, refreshPolicies, setIsOpen, showMessage, polyglot]
  );

  const formik = useFormik<PolicyGeneralFormData>({
    initialValues: {
      name: absencePolicy.name,
      color: absencePolicy.color,
      fullName: absencePolicy.fullName,
      isPublic: absencePolicy.isPublic,
      attachmentType: absencePolicy.attachmentType ?? null,
      cycleStartDay: absencePolicy.cycleStartDay,
      cycleStartMonth: absencePolicy.cycleStartMonth,
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
      color: yup.string().required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
      fullName: yup.string().required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
      isPublic: yup
        .boolean()
        .typeError(polyglot.t('validation.selectValid'))
        .required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
      attachmentType: yup
        .string()
        .nullable()
        .oneOf([null, ...Object.values(AbsencePolicyAttachmentType)], polyglot.t('validation.selectValid'))
        .strict(true),
      cycleStartDay: yup
        .number()
        .integer(polyglot.t('validation.selectValid'))
        .min(1, polyglot.t('validation.selectValid'))
        .max(31, polyglot.t('validation.selectValid'))
        .typeError(polyglot.t('validation.selectValid'))
        .when('cycleStartMonth', (cycleStartMonth, schema) => {
          const daysInMonth = new Date(new Date().getFullYear(), cycleStartMonth, 0).getDate();
          return schema.max(daysInMonth, polyglot.t('AbsenceGeneralSettings.thisMonthOnlyHas', { daysInMonth }));
        })
        .required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
      cycleStartMonth: yup
        .number()
        .integer(polyglot.t('validation.selectValid'))
        .min(1, polyglot.t('validation.selectValid'))
        .max(12, polyglot.t('validation.selectValid'))
        .typeError(polyglot.t('validation.selectValid'))
        .required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
    }),
    onSubmit,
  });

  const monthsOptions = useMemo((): readonly OptionObject[] => {
    return getMonthOptions(polyglot);
  }, [polyglot]);

  const attachmentOptions = useMemo((): readonly OptionObject[] => {
    return [
      { value: '', label: polyglot.t('General.none') },
      { value: AbsencePolicyAttachmentType.Optional, label: polyglot.t('General.optional') },
      { value: AbsencePolicyAttachmentType.Required, label: polyglot.t('General.required') },
    ];
  }, [polyglot]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="title2">{polyglot.t('AbsenceUtil.editGeneral')}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
            <Box sx={{ width: '60px' }}>
              <SelectComponent
                name="color"
                label={polyglot.t('NewTimePolicyGeneralStep.color')}
                options={COLOR_OPTIONS}
                value={formik.values.color}
                onChange={formik.handleChange}
                compareValue={formik.values.color}
                error={!!formik.errors.color && formik.touched.color}
                helperText={formik.touched.color && (formik.errors.color as string)}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextfieldComponent
                name="fullName"
                label={polyglot.t('NewTimePolicyGeneralStep.fullName')}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={formik.touched.fullName && !!formik.errors.fullName}
                helperText={formik.touched.fullName && (formik.errors.fullName as string)}
                endAdornment="none"
                fullWidth
              />
            </Box>
          </Box>
          <TextfieldComponent
            name="name"
            label={polyglot.t('NewTimePolicyGeneralStep.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && (formik.errors.name as string)}
            endAdornment="none"
            fullWidth
          />
          <Box>
            <CheckboxComponent
              name="isPublic"
              label={polyglot.t('NewTimePolicyGeneralStep.setToPrivate')}
              checked={!formik.values.isPublic}
              onChange={(_, checked) => {
                formik.setFieldValue('isPublic', !checked);
              }}
            />
            <Typography color="Grey" sx={{ pl: spacing.p25 }} variant="caption">
              {polyglot.t('NewTimePolicyGeneralStep.hideDetailsOfEvent')}
            </Typography>
          </Box>

          <SelectComponent
            name="cycleStartMonth"
            label={polyglot.t('NewTimePolicyGeneralStep.cycleStartMonth')}
            options={monthsOptions}
            value={formik.values.cycleStartMonth}
            onChange={formik.handleChange}
            compareValue={formik.values.cycleStartMonth}
            error={!!formik.errors.cycleStartMonth && formik.touched.cycleStartMonth}
            helperText={formik.touched.cycleStartMonth && (formik.errors.cycleStartMonth as string)}
          />

          <SelectComponent
            name="attachmentType"
            label={polyglot.t('NewTimePolicyGeneralStep.attachment')}
            options={attachmentOptions}
            value={formik.values.attachmentType ?? ''}
            onChange={formik.handleChange}
            compareValue={formik.values.attachmentType}
            error={!!formik.errors.attachmentType && formik.touched.attachmentType}
            helperText={formik.touched.attachmentType && (formik.errors.attachmentType as string)}
          />

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
