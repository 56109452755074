import React, { useContext, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { EnrolledDeviceEditDetailsDrawerContent } from '@v2/feature/device/components/device-details/enrolled-device-edit-details-drawer.component';
import { formatDistanceToNowStrict } from 'date-fns';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DevicePossessionDto, DeviceTransitDto } from '@/v2/feature/device/device.dto';
import { DeviceOwnership, DevicePossessionType } from '@/v2/feature/device/device.interface';
import { DeviceOSValueLabelOptions } from '@/v2/feature/device/device.util';
import {
  deviceTermSx,
  deviceValueSx,
} from '@/v2/feature/device/features/device-cards/components/devices-styles.layout';
import { EditTechSpecsDrawerContent } from '@/v2/feature/device/features/devices-company/components/edit-tech-specs-drawer.component';
import { EnrollmentStatus } from '@/v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const FieldValueComponent = ({ title, value }: { title: string; value: string | JSX.Element }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography component="dt" variant="body2" sx={deviceTermSx}>
        {title}
      </Typography>
      <Typography component="dd" variant="body2" sx={deviceValueSx}>
        {value}
      </Typography>
    </Box>
  );
};

export const TechSpecsViewModal = ({
  setIsOpen,
  isOpen,
  devicePossession,
  refresh,
  activeTransit,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  readonly devicePossession: DevicePossessionDto;
  readonly refresh: () => Promise<void>;
  readonly activeTransit: DeviceTransitDto | null;
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditEnrolledDevice, setIsEditEnrolledDevice] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const { device, isPossessionActive } = useMemo(() => {
    const device = devicePossession.device!;
    const isPossessionActive = !!devicePossession.startDate && !devicePossession.endDate;
    return { device, isPossessionActive };
  }, [devicePossession]);

  return (
    <DrawerModal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      onClose={() => {
        setIsEdit(false);
        setIsEditEnrolledDevice(false);
      }}
    >
      <Box>
        <Typography sx={{ ...themeFonts.title2, mb: spacing.m30 }}>Details</Typography>

        {!(
          !isPossessionActive ||
          !!activeTransit ||
          devicePossession.device?.ownership === DeviceOwnership.Company ||
          devicePossession.possessionType === DevicePossessionType.ZeltStorage
        ) &&
          !isEdit &&
          !isEditEnrolledDevice && (
            <Box sx={{ ...spacing.mb40 }}>
              <Typography sx={themeFonts.title2}>Order info</Typography>
              <Box component="section" sx={{ mt: spacing.m15 }}>
                <Box
                  component="dl"
                  sx={{ display: 'flex', flexDirection: 'column', margin: 0, padding: 0, gap: spacing.g15 }}
                >
                  {devicePossession.startDate && (
                    <FieldValueComponent
                      title="Delivered on"
                      value={
                        <Typography sx={themeFonts.title4}>{getDateString(devicePossession.startDate)}</Typography>
                      }
                    />
                  )}

                  {devicePossession.deliveryAddress && (
                    <FieldValueComponent
                      title="Delivered to"
                      value={<Typography sx={themeFonts.title4}>{devicePossession.deliveryAddress}</Typography>}
                    />
                  )}

                  {devicePossession?.device?.price &&
                    [DeviceOwnership.Rental, DeviceOwnership.CompanyPurchase].includes(
                      devicePossession?.device?.ownership
                    ) && (
                      <FieldValueComponent
                        title={
                          devicePossession?.device?.ownership === DeviceOwnership.Rental
                            ? 'Monthly rent'
                            : 'Purchase price'
                        }
                        value={<Typography sx={themeFonts.title4}>{devicePossession?.device?.price}</Typography>}
                      />
                    )}

                  {devicePossession?.device?.contractLength &&
                    [DeviceOwnership.Rental].includes(devicePossession?.device?.ownership) && (
                      <FieldValueComponent
                        title="Contract length"
                        value={
                          <Typography sx={themeFonts.title4}>{devicePossession?.device?.contractLength}</Typography>
                        }
                      />
                    )}

                  {devicePossession?.device && (
                    <FieldValueComponent
                      title="Billed by"
                      value={
                        <Typography sx={themeFonts.title4}>
                          {devicePossession?.device?.externallyInvoiced ? 'Two' : 'Zelt'}
                        </Typography>
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        {!isEdit && !isEditEnrolledDevice && (
          <Box>
            <Typography sx={themeFonts.title2}>Tech specs</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, mt: spacing.m15 }}>
              {device.deviceName && <FieldValueComponent title="Device name" value={device.deviceName} />}
              {device.manufacturer && <FieldValueComponent title="Manufacturer" value={device.manufacturer} />}
              {device.modelNumber && <FieldValueComponent title="Model" value={device.modelNumber} />}
              {device.modelName && <FieldValueComponent title="Model name" value={device.modelName} />}
              {device.serialNumber && <FieldValueComponent title="Serial number" value={device.serialNumber} />}
              {device.os && (
                <FieldValueComponent
                  title="OS"
                  value={DeviceOSValueLabelOptions.find((opt) => opt.value === device.os)?.label ?? device.os ?? 'N/A'}
                />
              )}
              {device.osVersion && <FieldValueComponent title="OS version" value={device.osVersion} />}
              {device.ram && <FieldValueComponent title="RAM" value={`${device.ram}GB`} />}
              {device.cpu && <FieldValueComponent title="CPU" value={`${device.cpu}-cores`} />}
              {device.storage && <FieldValueComponent title="Storage" value={`${device.storage}GB`} />}
              {device.gpu && <FieldValueComponent title="GPU" value={`${device.gpu}-cores`} />}
              {device.screenSize && <FieldValueComponent title="Screen Size" value={`${device.screenSize}"`} />}
              {device.lastScanned && (
                <FieldValueComponent
                  title="Last scanned"
                  value={formatDistanceToNowStrict(new Date(device.lastScanned))}
                />
              )}
              {device.lastCheckIn && (
                <FieldValueComponent
                  title="Last active"
                  value={formatDistanceToNowStrict(new Date(device.lastCheckIn))}
                />
              )}
            </Box>

            {device.ownership === DeviceOwnership.Company &&
              !Boolean(
                device.enrollmentStatus === 'enrolled' ||
                  device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
              ) && (
                <ScopesControl scopes={['devices:all']} context={scopesContext}>
                  <Box sx={{ ...spacing.mt40 }}>
                    <ButtonComponent
                      sizeVariant="medium"
                      colorVariant="secondary"
                      onClick={() => setIsEdit(true)}
                      fullWidth
                    >
                      Edit
                    </ButtonComponent>
                  </Box>
                </ScopesControl>
              )}
          </Box>
        )}

        {!isEditEnrolledDevice && (
          <Box>
            {device.ownership === DeviceOwnership.Company &&
              Boolean(
                device.enrollmentStatus === 'enrolled' ||
                  device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
              ) && (
                <ScopesControl scopes={['devices:all']} context={scopesContext}>
                  <Box sx={{ ...spacing.mt40 }}>
                    <ButtonComponent
                      sizeVariant="medium"
                      colorVariant="secondary"
                      onClick={() => setIsEditEnrolledDevice(true)}
                      fullWidth
                    >
                      Edit
                    </ButtonComponent>
                  </Box>
                </ScopesControl>
              )}
          </Box>
        )}

        {isEdit && (
          <Box>
            <EditTechSpecsDrawerContent devicePossessionId={devicePossession.id} device={device} refresh={refresh} />
          </Box>
        )}

        {isEditEnrolledDevice && (
          <Box>
            <EnrolledDeviceEditDetailsDrawerContent device={device} refresh={refresh} />
          </Box>
        )}
      </Box>
    </DrawerModal>
  );
};
