import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ConfigurationPayloadOption, DevicePolicyDto } from '@v2/feature/device/device.dto';
import { SupportedDeviceType } from '@v2/feature/device/device.interface';
import { FieldValueComponent } from '@v2/feature/device/device.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';

export const GeneralPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  isApple,
  isWindows,
  configurationPayloadOption,
  policy,
  allPolicies,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  policyName: string;
  isApple: boolean;
  isWindows: boolean;
  configurationPayloadOption?: ConfigurationPayloadOption | null;
  policy?: number[] | null;
  allPolicies?: DevicePolicyDto[] | null;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPolicyDto, setSelectedPolicyDto] = useState<DevicePolicyDto | undefined>(undefined);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      if (allPolicies && allPolicies?.length > 0 && policy) {
        const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;

        const selectedPolicy = allPolicies
          .filter((p) => p.supportedDeviceType.toString() === supportedDeviceType.toString())
          .find((p) => policy.includes(p.id));

        setSelectedPolicyDto(selectedPolicy);
      }
    } catch (error) {
      showMessage('Something went wrong. Could not load the company policies.', 'error');
    } finally {
      setLoading(false);
    }
  }, [allPolicies, isWindows, policy, showMessage]);

  useEffect(() => {
    if (!isApple) {
      refresh();
    }
  }, [isApple, refresh]);

  if (isApple) {
    if (!configurationPayloadOption) return null;

    const entries = Object.entries(configurationPayloadOption);
    const payloadIdentifierEntry = entries.filter(([key]) => key === 'PayloadIdentifier');
    const otherEntries = entries.filter(([key]) => key !== 'PayloadIdentifier');

    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            {otherEntries.map(([key, value]) => (
              <FieldValueComponent
                key={key}
                title={polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`)}
                value={<Typography variant="title4">{String(value)}</Typography>}
              />
            ))}
            {payloadIdentifierEntry.map(([key, value]) => (
              <FieldValueComponent
                key={key}
                title={polyglot.t(`Payload identifier`)}
                value={<Typography variant="title4">{String(value)}</Typography>}
              />
            ))}
          </Box>
        </Box>
      </DrawerModal>
    );
  } else {
    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose} loading={loading}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            {selectedPolicyDto && <Typography variant="caption">{selectedPolicyDto.description}</Typography>}
          </Box>
        </Box>
      </DrawerModal>
    );
  }
};
