import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { AvatarCard, AvatarCardProps, AvatarCardWidth } from '@/v2/components/avatar-card.component';
import { NavigationButtons } from '@/v2/components/navigation-buttons.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type TeamSlideCard = {
  user: CachedUser;
  cardProps: Partial<AvatarCardProps>;
  action: () => void;
  publicURL: string | null;
};

type TeamSlideProps = {
  cards: TeamSlideCard[];
  /**
   * set to true if partially-visible cards should be shown or false if only whole cards should be visible
   */
  partialCards?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
  width: number;
};

const avatarGap = spacing.g10;
const avatarGapValue = parseFloat(avatarGap);

const MaximumRenderedCards = 100;

export const TeamSlide = ({ cards, partialCards, sx, title, width }: TeamSlideProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const teamContainer = useRef<HTMLDivElement>(null);
  // marginRight is used to hide partially-visible cards when `partialCards` is false
  const [marginRight, setMarginRight] = useState(0);
  const renderedCardCount = Math.min(cards.length, MaximumRenderedCards);

  const getContainerWidth = useCallback(() => {
    return teamContainer.current
      ? Math.ceil(parseFloat(window.getComputedStyle(teamContainer.current).getPropertyValue('width'))) + marginRight
      : 0;
  }, [marginRight]);

  const calculateMaxScrollValue = useCallback(() => {
    if (!renderedCardCount) return 0;
    // how much space do the avatars take = width_of_avatars + width_of_gaps_between_avatars
    const totalAvatarsWidth = renderedCardCount * (AvatarCardWidth + avatarGapValue) - avatarGapValue;
    // get the size of the (smaller) container holding the avatars
    const containerWidth = getContainerWidth();
    return Math.max(totalAvatarsWidth - containerWidth, 0);
  }, [renderedCardCount, getContainerWidth]);

  const minScrollValue = 0;
  const maxScrollValue = calculateMaxScrollValue();

  const scrollAvatars = useCallback(
    (direction: 'left' | 'right') => {
      // how many avatars to scroll
      const avatarsPerScroll = 2;
      // the size (in px) to scroll the view
      const scrollAmount = (AvatarCardWidth + avatarGapValue) * avatarsPerScroll;
      setScrollPosition((position) => {
        return direction === 'left'
          ? Math.max(position - scrollAmount, minScrollValue)
          : Math.min(position + scrollAmount, maxScrollValue);
      });
    },
    [maxScrollValue]
  );

  const recalculateMargin = useCallback(() => {
    if (partialCards) {
      // if we are allowed to show partial cards, we don't need a margin
      setMarginRight(0);
      return;
    }
    const containerWidth = getContainerWidth();
    const maxCardsVisibleInContainer = Math.trunc(
      (containerWidth + avatarGapValue) / (AvatarCardWidth + avatarGapValue)
    );
    // the new margin is the difference betweem the container size and the size of the visible cards.
    const newmr = Math.trunc(
      containerWidth && maxCardsVisibleInContainer
        ? containerWidth - maxCardsVisibleInContainer * (AvatarCardWidth + avatarGapValue) + avatarGapValue
        : 0
    );
    setMarginRight(newmr);
  }, [getContainerWidth, partialCards]);

  useLayoutEffect(recalculateMargin, [recalculateMargin]);

  useEffect(() => {
    const onResize = () => {
      recalculateMargin();
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [recalculateMargin]);

  const renderedCards = useMemo(
    () =>
      cards
        .slice(0, renderedCardCount)
        .map(({ user, cardProps, action, publicURL }) => (
          <AvatarCard
            key={user.userId}
            size="large"
            userId={user.userId}
            title={`${user.firstName} ${user.lastName.slice(0, 1)}`}
            onClick={action}
            publicURL={publicURL}
            {...cardProps}
          />
        )),
    [cards, renderedCardCount]
  );

  return (
    <Stack sx={{ ...sx, width }}>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: marginRight + 'px' }}>
        <Typography sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
          {title}
        </Typography>
        <NavigationButtons
          disableBack={scrollPosition <= minScrollValue}
          disabledNext={scrollPosition >= maxScrollValue}
          onNext={() => scrollAvatars('right')}
          onBack={() => scrollAvatars('left')}
        />
      </Stack>
      <Stack
        ref={teamContainer}
        sx={{ overflow: 'hidden', marginTop: spacing.mt20, marginRight: `${marginRight ?? 0}px` }}
      >
        <Stack
          sx={{
            flexFlow: 'row',
            gap: avatarGap,
            width: 'fit-content',
            // setting a negative margin-left "scrolls" the view
            ml: `${-scrollPosition}px`,
            transition: 'margin-left 0.4s',
          }}
        >
          {renderedCards}
        </Stack>
      </Stack>
    </Stack>
  );
};
