import {
  CreateEmployeePensionDto,
  PayrunPensionDto,
  PensionContributionExternalDataDto,
  PensionProviderConversationDto,
  PensionSchemeDto,
  UpdateEmployeePensionDto,
  UpdatePensionGeneralDto,
  UpsertUserPensionDto,
  UpsertWorkerGroupDto,
  UserPensionDto,
} from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  LeavingEmployeeStates,
  StaffologyAutoEnrolment,
} from '@v2/feature/benefits/subfeature/pension/pension.interface';
import { ExternalDataProvider } from '@v2/feature/payroll/payroll.interface';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class PensionAPI {
  static async deletePension(schemeId: number): Promise<void> {
    await axios.delete(`/apiv2/pension/${schemeId}`);
  }

  static async createPensionScheme(payrollId: number, pensionScheme: UpsertUserPensionDto): Promise<number> {
    return (await axios.post(`/apiv2/pension?payrollId=${payrollId}`, pensionScheme)).data;
  }

  static async updatePensionGeneralData(
    pensionSchemeId: number,
    pensionScheme: UpdatePensionGeneralDto
  ): Promise<void> {
    await axios.put(`/apiv2/pension/${pensionSchemeId}/general`, pensionScheme);
  }

  static async updatePensionProviderSettings(
    pensionSchemeId: number,
    pensionScheme: UpsertUserPensionDto
  ): Promise<void> {
    await axios.put(`/apiv2/pension/${pensionSchemeId}/provider`, pensionScheme);
  }

  static async getEmployeeMemberships(): Promise<UserPensionDto[]> {
    return (await axios.get('/apiv2/employees'))?.data;
  }

  static async getCompanyPensionSchemes(): Promise<PensionSchemeDto[]> {
    return (await axios.get('/apiv2/pension'))?.data;
  }

  static async getPension(pensionSchemeId: number): Promise<PensionSchemeDto> {
    return (await axios.get(`/apiv2/pension/${pensionSchemeId}`))?.data;
  }

  static async getPayrunPensionRecordForPensionScheme(
    payrunId: number,
    pensionSchemeId: number
  ): Promise<PayrunPensionDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}`)).data;
  }

  static async fetchPensionSchemeEmployeesPension(pensionSchemeId: number): Promise<UserPensionDto[]> {
    return (await axios.get(`/apiv2/employees/pensionschemes/${pensionSchemeId}`))?.data;
  }

  static async addWorkerGroup(schemeId: number, workerGroup: UpsertWorkerGroupDto): Promise<void> {
    await axios.post(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup`, workerGroup);
  }

  static async deleteWorkerGroup(schemeId: number, workerGroupId: number): Promise<void> {
    await axios.delete(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup/${workerGroupId}`);
  }

  static async updateWorkerGroup(
    schemeId: number,
    workerGroupId: number,
    workerGroup: UpsertWorkerGroupDto
  ): Promise<void> {
    await axios.patch(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup/${workerGroupId}`, workerGroup);
  }

  static async getEmployeePension(employeeId: number): Promise<UserPensionDto> {
    return (await axios.get(`/apiv2/employees/${employeeId}/pension`))?.data;
  }

  static async createEmployeePension(
    employeeId: number,
    employeePension: CreateEmployeePensionDto,
    isRetrospectively = false
  ): Promise<void> {
    const url = `/apiv2/employees/${employeeId}/pension${isRetrospectively ? '?isRetrospectively=true' : ''}`;
    await axios.post(url, employeePension);
  }

  static async updateEmployeePension(employeeId: number, employeePension: UpdateEmployeePensionDto): Promise<void> {
    await axios.patch(`/apiv2/employees/${employeeId}/pension`, employeePension);
  }

  static async deleteEmployeePension(employeeId: number): Promise<void> {
    await axios.delete(`/apiv2/employees/${employeeId}/pension`);
  }

  static async getEmployeeAutoEnrolment(employeeId: number): Promise<StaffologyAutoEnrolment> {
    return (await axios.get(`/apiv2/employees/${employeeId}/pension/autoenrolment`))?.data;
  }

  static async assessEmployeeForAE(employeeId: number): Promise<UserPensionDto> {
    return (await axios.post(`/apiv2/employees/${employeeId}/pension/assessments`)).data;
  }

  static async assessEmployeeForAEInternally(employeeId: number): Promise<string> {
    return (await axios.post(`/apiv2/employees/${employeeId}/pension/internal-assessments`))?.data;
  }

  static async deferEmployeeEnrolment(employeeId: number, deferralDate: string): Promise<string> {
    return (await axios.post(`/apiv2/employees/${employeeId}/defer-enrollment`, { deferralDate }))?.data;
  }

  static async optOutPensionEmployees(
    employeeId: number,
    optOutSettings: {
      employeeState: LeavingEmployeeStates;
      leaveDate: string;
    }
  ): Promise<void> {
    await axios.put(`/apiv2/employees/${employeeId}/pension/optout`, optOutSettings);
  }

  static async getPensionExternalProviders(payrollId: number): Promise<ExternalDataProvider[]> {
    return (await axios.get(`/apiv2/pension/pensionschemes/external-providers?payrollId=${payrollId}`)).data;
  }

  static async getPapdisFile(
    pensionSchemeId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<PensionContributionExternalDataDto> {
    return (await axios.get(`/apiv2/pension/reports/${taxYear}/${payPeriod}/${periodNumber}/papdis/${pensionSchemeId}`))
      .data;
  }

  static async getCushonFile(
    pensionSchemeId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<PensionContributionExternalDataDto> {
    return (await axios.get(`/apiv2/pension/reports/${taxYear}/${payPeriod}/${periodNumber}/cushon/${pensionSchemeId}`))
      .data;
  }

  static async getSpecificPensionSchemeContribution(
    pensionSchemeId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<PensionProviderConversationDto> {
    return (
      await axios.get(
        `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}`
      )
    ).data;
  }

  static async markPensionSchemeContributionAsSent(
    pensionSchemeId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<void> {
    await axios.post(
      `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}/markassent`
    );
  }

  static async resubmitPensionSchemeContribution(
    pensionSchemeId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<void> {
    await axios.post(
      `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}`
    );
  }
}

export class PensionEndpoints {
  static getMinimumEmployeePensionStartDate(userId: number, payrollId: number): Endpoint<string> {
    return {
      url: `/apiv2/employees/${userId}/pension/minimum-enrollment-date?payrollId=${payrollId}`,
    };
  }

  static fetchPensionSchemeEmployeesPension(pensionSchemeId: number): Endpoint<UserPensionDto[]> {
    return {
      url: `/apiv2/employees/pensionschemes/${pensionSchemeId}`,
    };
  }

  static getPension(pensionSchemeId: number): Endpoint<PensionSchemeDto> {
    return {
      url: `/apiv2/pension/${pensionSchemeId}`,
    };
  }

  static getCountOfActivePensionSchemes(): Endpoint<number> {
    return {
      url: '/apiv2/pension/count',
    };
  }
}
