import { useLayoutEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { NewCompanyPayCode } from '@v2/feature/payroll/payroll-external.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AccountingCodeItem } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/components/accounting-code-item.component';
import {
  MAX_PAYLINE_CODE_LENGTH,
  MAX_PAYLINE_DESCRIPTION_LENGTH,
} from '@/v2/feature/payroll/payroll-external.interface';
import {
  AccountingFieldPaycodeMapping,
  CompanyPayrollAccountingConfig,
  NominalCodeDto,
} from '@/v2/feature/payroll/payroll.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollNewPayCodesPageProps = {
  payrollId: number;
  payCodesNames: string[];
  nominalCodes?: NominalCodeDto[] | null;
  reimportNominalCodes: () => Promise<void>;
  accountingConfig: CompanyPayrollAccountingConfig;
  createCompanyPaycode: (
    paycode: NewCompanyPayCode,
    accountingField?: AccountingFieldPaycodeMapping
  ) => Promise<boolean>;
  close: () => void;
};

export const PayrollNewPayCodesPage = ({
  createCompanyPaycode,
  accountingConfig,
  nominalCodes,
  reimportNominalCodes,
  payCodesNames,
  close,
}: PayrollNewPayCodesPageProps) => {
  const [updatingPayCode, setUpdatingPayCode] = useState<'adding'>();

  const validationSchema = useMemo(() => {
    return yup.object({
      code: yup.string().required('Pay code identifier is required').notOneOf(payCodesNames, 'Pay code already exists'),
      title: yup.string().required('Pay code name is required'),
      isDeduction: yup.boolean().required('Choose a pay code type'),
    });
  }, [payCodesNames]);

  const formik = useFormik({
    initialValues: {
      title: '',
      code: '',
      isDeduction: false,
      isNiable: false,
      isTaxable: false,
      isPensionable: false,
      isAttachable: false,
      accountingField: undefined as AccountingFieldPaycodeMapping | undefined,
    },
    validationSchema,
    onSubmit: async ({ accountingField, ...newPayCode }) => {
      setUpdatingPayCode('adding');
      const savedPaycode = await createCompanyPaycode(newPayCode, accountingField);
      setUpdatingPayCode(undefined);
      if (savedPaycode) close();
    },
  });

  useLayoutEffect(() => {
    // force the pay code identifier to have uppercase letters
    const cleanCode = formik.values.code.toUpperCase();
    if (cleanCode !== formik.values.code) {
      formik.setFieldValue('code', cleanCode);
    }
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">New code</Typography>
        <TextfieldComponent
          name="code"
          label="Pay code"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.touched.code && !!formik.errors.code}
          helperText={formik.touched.code && formik.errors.code}
          disabled={!!updatingPayCode}
          maxLength={MAX_PAYLINE_CODE_LENGTH}
          autoFocus
        />
        <TextfieldComponent
          name="title"
          label="Name"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.touched.title && !!formik.errors.title}
          helperText={formik.touched.title && formik.errors.title}
          disabled={!!updatingPayCode}
          maxLength={MAX_PAYLINE_DESCRIPTION_LENGTH}
        />
        <SelectComponent
          name="type"
          label="Type"
          options={[
            { label: 'Addition', value: 'addition' },
            { label: 'Deduction', value: 'deduction' },
          ]}
          onChange={(e) => formik.setFieldValue('isDeduction', e.target.value === 'deduction')}
          value={formik.values.isDeduction ? 'deduction' : 'addition'}
          disabled={!!updatingPayCode}
        />
        <Stack sx={{ gap: spacing.g15 }}>
          <Typography variant="title4">Subject to</Typography>
          <CheckboxComponent
            name="isNiable"
            label="National Insurance"
            checked={formik.values.isNiable}
            onChange={formik.handleChange}
            disabled={!!updatingPayCode}
          />
          <CheckboxComponent
            name="isTaxable"
            label="Tax"
            checked={formik.values.isTaxable}
            onChange={formik.handleChange}
            disabled={!!updatingPayCode}
          />
          <CheckboxComponent
            name="isPensionable"
            label="Pension"
            checked={formik.values.isPensionable}
            onChange={formik.handleChange}
            disabled={!!updatingPayCode}
          />
          <CheckboxComponent
            name="isAttachable"
            label="Attachment Orders"
            checked={formik.values.isAttachable}
            onChange={formik.handleChange}
            disabled={!!updatingPayCode}
          />
        </Stack>
        <AccountingCodeItem
          payCode={formik.values.code}
          nominalCodes={nominalCodes}
          reimportNominalCodes={reimportNominalCodes}
          accountingConfig={accountingConfig}
          accountingField={formik.values.accountingField}
          setAccountingField={(value: AccountingFieldPaycodeMapping) => {
            formik.setFieldValue('accountingField', value);
          }}
          disabled={!!updatingPayCode}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name="Save" loading={!!updatingPayCode} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
