import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { spacing } from '@/v2/styles/spacing.styles';

interface LockDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
  readonly isMobileAppleDevice: boolean;
}

export const LockDeviceDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen,
  isMobileAppleDevice,
}: LockDeviceDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <LockDeviceDrawerContent devicePossession={devicePossession} isMobileAppleDevice={isMobileAppleDevice} />
    </DrawerModal>
  );
};

const LockDeviceDrawerContent = ({
  devicePossession,
  isMobileAppleDevice,
}: {
  readonly devicePossession: DevicePossessionDto;
  readonly isMobileAppleDevice: boolean;
}) => {
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [showLockPinModal, setShowLockPinModal] = useState<boolean>(false);
  const [isMobileDevice] = useState<boolean>(isMobileAppleDevice);
  const [showMessage] = useMessage();
  const macOsLockMessage =
    'Mobile device management (MDM) administrators can lock a Mac with a six-digit personal identification number\n' +
    '            (PIN) and include a short message. After the command has been sent to the device, the device restarts and\n' +
    "            the user can see the message. The user can't restart into macOS or log in any other way until the PIN has\n" +
    '            been entered and validated by the Mac.';
  const mobileDeviceLockMessage =
    'Mobile device management (MDM) administrators can lock an iPhone or IPad include a short message. After the\n' +
    '            command has been sent to the device, the device locks and logs out from the current iCloud account. The user\n' +
    "            can remove the lock using either the device's regular passcode or FaceID.";
  const hexnodeDeviceLockMessage =
    'Mobile device management (MDM) administrators can lock a device with a six-digit personal identification number (PIN) and include a short message. After the\n' +
    ' command has been sent to the device, it restarts and the user can see the message. The user \n' +
    "can't restart the device or log in any other way until the PIN has been entered and validated by the system.";
  const getLockMessage = () => {
    if (!devicePossession.device?.inHouseMdm) {
      return hexnodeDeviceLockMessage;
    } else if (isMobileDevice) {
      return mobileDeviceLockMessage;
    } else {
      return macOsLockMessage;
    }
  };
  return (
    <>
      {showLockPinModal ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
          <Typography variant="title2">Unlock PIN</Typography>

          <Typography variant="caption" sx={{ mt: spacing.m5 }}>
            Remember your unlock PIN to unlock your device
          </Typography>

          <Typography variant="title2">4 5 9 8 4 6</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <Typography variant="title2">Lock device</Typography>
          <Typography variant="caption" color="Red">
            {getLockMessage()}
          </Typography>
          <Box sx={{ ...spacing.mt40 }}>
            <TextfieldComponent
              name="confirm"
              label=""
              placeholder="Type “Confirm” here to lock device"
              fullWidth
              value={confirmationText}
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmationText(event.target.value)}
              clearText={() => setConfirmationText('')}
            />
          </Box>

          <Box sx={{ mt: spacing.m30 }}>
            <ButtonComponent
              disabled={confirmationText !== 'Confirm'}
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={async () => {
                try {
                  if (devicePossession && devicePossession.id)
                    await DeviceAPI.lockDevice(
                      devicePossession.id,
                      isMobileDevice ? mobileDeviceLockMessage : macOsLockMessage
                    );
                  showMessage('Action launched successfully', 'success');
                  if (!isMobileDevice) {
                    setShowLockPinModal(true);
                  }
                } catch (error) {
                  showMessage('The launch of the action failed', 'error');
                }
              }}
            >
              Submit
            </ButtonComponent>
          </Box>
        </Box>
      )}
    </>
  );
};
