import React, { Dispatch, SetStateAction, Suspense, useContext, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { MultiUserAvatar } from '@v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceLengthUnit, AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, getPeriodFromAbsence, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import { AbsenceDrawerActionButtons } from '@v2/feature/absence/components/absence-drawer-action-buttons.component';
import { AbsenceDrawerPHButtons } from '@v2/feature/absence/components/absence-drawer-ph-buttons.component';
import { AbsenceOverlapDisplay } from '@v2/feature/absence/components/absence-overlap-display.component';
import { RequestPHCancellationDrawer } from '@v2/feature/absence/components/request-ph-cancellation-drawer.component';
import { AttachmentInDrawer } from '@v2/feature/absence/sections/absence-drawer/attachment-in-drawer.component';
import { ApproversList } from '@v2/feature/approval-rule/components/approvers-list.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { AbsenceDrawerPayItemPreview } from '@v2/feature/payroll/features/pay-item/components/absence-drawer-pay-item-preview.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateAbsenceStatuses } from '@v2/infrastructure/i18n/translate.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

interface AbsenceDrawerProps {
  readonly isOpen: boolean;
  readonly absence: AbsenceDto | undefined;
  readonly setAbsence?: React.Dispatch<React.SetStateAction<AbsenceDto | undefined>>;
  readonly setIsAbsenceDrawerViewOpen: Dispatch<SetStateAction<boolean>>;
  readonly handleApprove?: (a: AbsenceDto) => Promise<AbsenceDto | undefined>;
  readonly handleReject?: (a: AbsenceDto) => Promise<AbsenceDto | undefined>;
  readonly onDelete?: (absence: AbsenceDto) => Promise<void>;
  readonly onRejectCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onRequestCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onForceApproval?: (
    absence: AbsenceDto,
    status: AbsenceStatus.Approved | AbsenceStatus.Rejected
  ) => Promise<void>;
  readonly onEdit?: (absence: AbsenceDto) => void;
  readonly onClose?: () => void;
  readonly afterClose?: () => void;
  readonly showCalendarLink: boolean;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

interface AbsenceDrawerContentProps {
  readonly absence: AbsenceDto;
  readonly setAbsence?: React.Dispatch<React.SetStateAction<AbsenceDto | undefined>>;
  readonly handleApprove?: (a: AbsenceDto) => Promise<AbsenceDto | undefined>;
  readonly handleReject?: (a: AbsenceDto, rejectionNotes?: string) => Promise<AbsenceDto | undefined>;
  readonly onDelete?: (absence: AbsenceDto) => Promise<void>;
  readonly onRejectCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onRequestCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onForceApproval?: (
    absence: AbsenceDto,
    status: AbsenceStatus.Approved | AbsenceStatus.Rejected
  ) => Promise<void>;
  readonly onEdit?: (absence: AbsenceDto) => void;
  readonly showCalendarLink: boolean;
  readonly refresh: () => Promise<void>;
}

export const AbsenceViewDrawer = ({
  isOpen,
  absence,
  setAbsence,
  setIsAbsenceDrawerViewOpen,
  handleApprove,
  handleReject,
  onDelete,
  onRejectCancellation,
  onRequestCancellation,
  onForceApproval,
  onEdit,
  showCalendarLink = true,
  onClose,
  afterClose,
  loading,
  refresh,
}: AbsenceDrawerProps): React.JSX.Element => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsAbsenceDrawerViewOpen}
      onClose={onClose}
      afterClose={afterClose}
      loading={loading}
    >
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        {absence ? (
          <AbsenceViewDrawerContent
            absence={absence}
            setAbsence={setAbsence}
            handleApprove={handleApprove}
            handleReject={handleReject}
            onDelete={onDelete}
            onRejectCancellation={onRejectCancellation}
            onRequestCancellation={onRequestCancellation}
            onForceApproval={onForceApproval}
            onEdit={onEdit}
            showCalendarLink={showCalendarLink}
            refresh={refresh}
          />
        ) : (
          <AbsenceNotFound />
        )}
      </Suspense>
    </DrawerModal>
  );
};

export const AbsenceViewDrawerContent = ({
  absence,
  setAbsence,
  handleApprove,
  handleReject,
  onDelete,
  onRejectCancellation,
  onRequestCancellation,
  onForceApproval,
  onEdit,
  showCalendarLink,
  refresh,
}: AbsenceDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);

  const { data: userBalanceBreakdown } = useApiClient(
    absence?.status === AbsenceStatus.Pending && !absence.isPublicHoliday
      ? AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(absence.userId, {
          cycleStartYear: absence.carryOverYear,
          policyId: absence.policyId,
        })
      : null
  );
  const [isRequestCancellationOpen, setIsRequestCancellationOpen] = useState<boolean>(false);

  const { getCachedUserById } = useCachedUsers();

  const absenceUser = useMemo(() => getCachedUserById(absence.userId), [getCachedUserById, absence.userId]);
  const createdByUser = useMemo(
    () =>
      absence.createdBy
        ? absence.createdBy !== absence.userId
          ? getCachedUserById(absence.createdBy)
          : absenceUser
        : null,
    [absence.createdBy, absence.userId, absenceUser, getCachedUserById]
  );

  const userIdsInOverlapsDisplay = useMemo(() => [absence.userId], [absence.userId]);

  return (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: spacing.s2 }}>
        <Typography variant="title2">
          {absence.policy?.name
            ? absence.policy.name
            : absence.isPublicHoliday
            ? absence.notes
            : polyglot.t('AbsenceViewDrawerContent.timeEntry')}
        </Typography>

        {absence.canRequestPHDeletion && (
          <IconButton sx={tableIconButtonSx} onClick={() => setIsRequestCancellationOpen(true)}>
            <Trash {...iconSize} />
          </IconButton>
        )}
      </Box>

      <RequestPHCancellationDrawer
        isOpen={isRequestCancellationOpen}
        setIsOpen={setIsRequestCancellationOpen}
        absence={absence}
        refresh={refresh}
      />

      {absenceUser && (
        <DrawerViewerItem
          title={polyglot.t('AbsenceViewDrawerContent.employee')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <UserAvatar userId={absenceUser.userId} size="xxsmall" />
              <Typography variant="title4">
                {polyglot.t(absenceUser.displayName || `${absenceUser.firstName} ${absenceUser.lastName}`)}
              </Typography>
            </Box>
          }
        />
      )}
      {createdByUser && (
        <DrawerViewerItem
          title={polyglot.t('AbsenceViewDrawerContent.createdBy')}
          key="created-by"
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <UserAvatar userId={createdByUser.userId} size="xxsmall" />
              <Typography variant="title4">
                {polyglot.t(createdByUser.displayName || `${createdByUser.firstName} ${createdByUser.lastName}`)}
              </Typography>
            </Box>
          }
        />
      )}
      <DrawerViewerItem
        title={polyglot.t('AbsenceViewDrawerContent.period')}
        key="period"
        value={getPeriodFromAbsence(absence)}
      />
      {absence.notes && <DrawerViewerItem key="notes" title={polyglot.t('General.notes')} value={absence.notes} />}
      {absence.createdAt && (
        <DrawerViewerItem
          key="requested-on"
          title={polyglot.t('AbsenceViewDrawerContent.requestedOn')}
          value={getDateString(absence.createdAt) as string}
        />
      )}
      <DrawerViewerItem
        key="requestLength"
        title={polyglot.t('AbsenceViewDrawerContent.requestLength')}
        value={
          absence.totalLength > 0
            ? polyglot.t('AbsenceViewDrawerContent.requestLengthValue', {
                smart_count: absence.workdayCount,
                noOfHours: convertMinutesToClockHours(absence.totalLength, polyglot),
              })
            : polyglot.t('AbsenceViewDrawerContent.requestLengthValueDayOnly', {
                smart_count: absence.workdayCount,
              })
        }
      />
      {absence.policy?.allowanceType &&
        !isUnlimitedPolicy(absence.policy as Pick<AbsencePolicyDto, 'allowanceType'>) &&
        absence.status === AbsenceStatus.Pending &&
        userBalanceBreakdown &&
        userBalanceBreakdown[absence.policyId]?.currentBalance !== null && (
          <DrawerViewerItem
            key="remainingBalance"
            title={polyglot.t('AbsenceViewDrawerContent.remainingBalance')}
            value={
              userBalanceBreakdown[absence.policyId]?.lengthUnit === AbsenceLengthUnit.Day
                ? polyglot.t('General.noOfDays', {
                    smart_count: userBalanceBreakdown[absence.policyId]!.currentBalanceInDays!,
                  })
                : convertMinutesToClockHours(userBalanceBreakdown[absence.policyId]!.currentBalance, polyglot)
            }
          />
        )}
      {!absence.isPublicHoliday && (
        <DrawerViewerItem
          key="status"
          title={polyglot.t('AbsenceViewDrawerContent.status')}
          value={
            absence.status !== AbsenceStatus.Approved
              ? translateAbsenceStatuses(absence.status, polyglot)
              : absence.cancellationRequested
              ? polyglot.t('AbsenceViewDrawerContent.pendingCancellation')
              : translateAbsenceStatuses(AbsenceStatus.Approved, polyglot)
          }
        />
      )}
      {absence.isPublicHoliday && absence.status === AbsenceStatus.Pending && (
        <Typography variant="title4" color="DarkGrey">
          {polyglot.t('AbsenceViewDrawerContent.deletionRequested')}
        </Typography>
      )}
      {absence.isPublicHoliday && absence.status === AbsenceStatus.Rejected && (
        <Typography variant="title4" color="DarkGrey">
          {polyglot.t('AbsenceViewDrawerContent.deletionApproved')}
        </Typography>
      )}
      {absence.status === AbsenceStatus.Pending && <ApproversList approverSteps={absence.approverSteps} />}
      {absence?.approvedByIds && absence.approvedByIds.length > 0 && (
        <DrawerViewerItem
          key="approvedBy"
          title={polyglot.t('General.approvedBy')}
          value={<MultiUserAvatar userIds={absence.approvedByIds} />}
        />
      )}
      {absence?.rejectedByIds && absence.rejectedByIds.length > 0 && (
        <DrawerViewerItem
          key="rejectedBy"
          title={polyglot.t('General.rejectedBy')}
          value={<MultiUserAvatar userIds={absence.rejectedByIds} />}
        />
      )}
      {absence.status === AbsenceStatus.Rejected && absence.approvalNotes && (
        <DrawerViewerItem
          key="rejection-notes"
          title={polyglot.t('AbsenceViewDrawerContent.rejectionNotes')}
          value={absence.approvalNotes}
        />
      )}
      {absence.approvedOnTimestamp && [AbsenceStatus.Approved, AbsenceStatus.Rejected].includes(absence.status) && (
        <DrawerViewerItem
          key="date-approved"
          title={
            absence.status === AbsenceStatus.Approved
              ? polyglot.t('AbsenceViewDrawerContent.dateApproved')
              : polyglot.t('AbsenceViewDrawerContent.dateRejected')
          }
          value={getDateString(absence.approvedOnTimestamp) as string}
        />
      )}
      {absence.attachment &&
        (absence.userId === globalState.user.userId || absence.canEdit || absence.isInFullApproversList) && (
          <AttachmentInDrawer entityWithAttachment={absence} fileLabel={polyglot.t('DocumentModule.attachment')} />
        )}

      <AbsenceOverlapDisplay
        absenceId={absence.absenceId}
        absenceEnd={absence.end}
        absenceStart={absence.start}
        showCalendarLink={showCalendarLink}
        userIds={userIdsInOverlapsDisplay}
        isViewMode
      />
      {!absence.isPublicHoliday && (
        <ScopesControl scopes={['payroll:all']} context={{ userId: absence.userId }}>
          <AbsenceDrawerPayItemPreview userId={absence.userId} absenceId={absence.absenceId} />
        </ScopesControl>
      )}

      {absence.isPublicHoliday ? (
        <AbsenceDrawerPHButtons absence={absence} refresh={refresh} />
      ) : (
        <AbsenceDrawerActionButtons
          absence={absence}
          setAbsence={setAbsence}
          handleApprove={handleApprove}
          handleReject={handleReject}
          onDelete={onDelete}
          onRejectCancellation={onRejectCancellation}
          onRequestCancellation={onRequestCancellation}
          onForceApproval={onForceApproval}
          onEdit={onEdit}
        />
      )}
    </Box>
  );
};

const AbsenceNotFound = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box>
      <Typography variant="title2">{polyglot.t('AbsenceViewDrawerContent.timeEntry')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', ...spacing.gap20, marginTop: spacing.m30 }}>
        <Typography variant="caption">{polyglot.t('AbsenceViewDrawerContent.absenceNotFound')} </Typography>
      </Box>
    </Box>
  );
};
