import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { EquityAPI } from '@/api-client/equity.api';
import {
  CreateUserEquity,
  UpdateUserEquity,
  UserEquity,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import useMessage from '@/hooks/notification.hook';
import { equityTypeOptions } from '@/lib/employment';
import { nestErrorMessage } from '@/lib/errors';
import { CurrencySelect } from '@/v2/components/currency-select.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TypeableDateComponent } from '@/v2/components/forms/typeable-date.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { spacing } from '@/v2/styles/spacing.styles';

type NewEmployeeEquitySectionProps = {
  equity?: UserEquity | null;
  onNext: () => void;
  sx?: SxProps<Theme>;
  companyId: number;
  newUserId: number;
  currentUserId: number;
};

export const NewEmployeeEquitySection = ({
  companyId,
  equity,
  onNext,
  sx,
  newUserId,
  currentUserId,
}: NewEmployeeEquitySectionProps) => {
  const { polyglot } = usePolyglot();
  const NewEmployeeEquitySchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().trim().required(polyglot.t('NewEmployeeEquitySection.errorMessages.equityTypeRequired')),
        grantDate: Yup.string().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) =>
            schema
              .trim()
              .test(dateFieldTest)
              .required(polyglot.t('NewEmployeeEquitySection.errorMessages.grantDateRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        amount: Yup.number().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) => schema.required(polyglot.t('NewEmployeeEquitySection.errorMessages.amountRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        vestingStart: Yup.string().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) =>
            schema
              .trim()
              .test(dateFieldTest)
              .required(polyglot.t('NewEmployeeEquitySection.errorMessages.vestingDateRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        vestingPeriod: Yup.number().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) => schema.required(polyglot.t('NewEmployeeEquitySection.errorMessages.vestingPeriodRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        vestingCliff: Yup.number().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) => schema.required(polyglot.t('NewEmployeeEquitySection.errorMessages.vestingCliffRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        unitPrice: Yup.number().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) => schema.required(polyglot.t('NewEmployeeEquitySection.errorMessages.unitPriceRequired')),
          otherwise: (schema) => schema.optional(),
        }),
        currency: Yup.string().when('type', {
          is: (type: string) => type !== 'none',
          then: (schema) => schema.required(polyglot.t('NewEmployeeEquitySection.errorMessages.currencyRequired')),
          otherwise: (schema) => schema.optional(),
        }),
      }),
    [polyglot]
  );
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      type: equity?.type || 'none',
      grantDate: equity?.grantDate,
      amount: equity?.amount,
      vestingStart: equity?.vestingStart,
      vestingPeriod: equity?.vestingPeriod,
      vestingCliff: equity?.vestingCliff,
      unitPrice: equity?.unitPrice,
      currency: equity?.currency,
    },
    validateOnMount: true,
    validationSchema: NewEmployeeEquitySchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        if (values.type === 'none') {
          // delete any existing value
          if (equity) {
            await EquityAPI.deleteById(newUserId, equity.id);
          }
        } else if (equity) {
          // update the existing entry
          await EquityAPI.updateById({
            ...equity,
            ...values,
          } as UpdateUserEquity);
        } else {
          // create a new equity entry
          await EquityAPI.create({
            ...values,
            userId: newUserId,
            companyId,
            createdBy: currentUserId,
            updatedBy: currentUserId,
            reason: '',
          } as CreateUserEquity);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t('NewEmployeeEquitySection.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('NewEmployeeEquitySection.equity')}</Typography>

          <SelectComponent
            name="type"
            label={polyglot.t('NewEmployeeEquitySection.type')}
            options={[...equityTypeOptions(polyglot), { label: 'None', value: 'none' }]}
            value={formik.values.type}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.errors.type}
            helperText={hasSubmitted && formik.errors.type}
          />
          {formik.values.type !== 'none' && (
            <>
              <TypeableDateComponent
                name="grantDate"
                label={polyglot.t('NewEmployeeEquitySection.grantDate')}
                value={formik.values.grantDate}
                onChange={(value) => formik.setFieldValue('grantDate', value)}
                error={hasSubmitted && !!formik.errors.grantDate}
                helperText={hasSubmitted && formik.errors.grantDate}
                disabled={saving}
              />
              <TextfieldComponent
                name="amount"
                label={polyglot.t('NewEmployeeEquitySection.amount')}
                type="tel"
                value={formik.values.amount?.toString() ?? ''}
                disabled={saving}
                onChange={(e) => {
                  const newValue = Number(e.target.value.match(/^\d{1,9}/)?.[0] ?? 'nan');
                  formik.setFieldValue('amount', Number.isInteger(newValue) ? newValue : undefined);
                }}
                clearText={() => formik.setFieldValue('amount', undefined)}
                helperText={hasSubmitted && formik.errors.amount}
                error={hasSubmitted && !!formik.errors.amount}
              />
              <TypeableDateComponent
                name="vestingStart"
                label={polyglot.t('NewEmployeeEquitySection.vestingStart')}
                value={formik.values.vestingStart}
                onChange={(value) => formik.setFieldValue('vestingStart', value)}
                error={hasSubmitted && !!formik.errors.vestingStart}
                helperText={hasSubmitted && formik.errors.vestingStart}
                disabled={saving}
              />
              <TextfieldComponent
                name="vestingPeriod"
                label={polyglot.t('NewEmployeeEquitySection.vestingPeriod')}
                type="tel"
                value={formik.values.vestingPeriod?.toString() ?? ''}
                disabled={saving}
                onChange={(e) => {
                  const newValue = Number(e.target.value.match(/^\d{1,3}/)?.[0] ?? 'nan');
                  formik.setFieldValue('vestingPeriod', Number.isInteger(newValue) ? newValue : undefined);
                }}
                clearText={() => formik.setFieldValue('vestingPeriod', undefined)}
                helperText={hasSubmitted && formik.errors.vestingPeriod}
                error={hasSubmitted && !!formik.errors.vestingPeriod}
              />
              <TextfieldComponent
                name="vestingCliff"
                label={polyglot.t('NewEmployeeEquitySection.vestingCliff')}
                type="tel"
                value={formik.values.vestingCliff?.toString() ?? ''}
                disabled={saving}
                onChange={(e) => {
                  const newValue = Number(e.target.value.match(/^\d{1,3}/)?.[0] ?? 'nan');
                  formik.setFieldValue('vestingCliff', Number.isInteger(newValue) ? newValue : undefined);
                }}
                clearText={() => formik.setFieldValue('vestingCliff', undefined)}
                helperText={hasSubmitted && formik.errors.vestingCliff}
                error={hasSubmitted && !!formik.errors.vestingCliff}
              />

              <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
                <MoneyTextfieldComponent
                  name="unitPrice"
                  label={polyglot.t('NewEmployeeEquitySection.unitPrice')}
                  value={formik.values.unitPrice}
                  onChange={(newValue: number | undefined) => {
                    formik.setFieldValue('unitPrice', newValue);
                  }}
                  helperText={hasSubmitted && formik.errors.unitPrice}
                  error={hasSubmitted && !!formik.errors.unitPrice}
                />
                <CurrencySelect
                  name="currency"
                  onChange={(currency) => {
                    formik.setFieldValue('currency', currency);
                  }}
                  value={formik.values.currency}
                  helperText={hasSubmitted && formik.errors.currency}
                  error={hasSubmitted && !!formik.errors.currency}
                  sx={{ flexBasis: '40%' }}
                />
              </Stack>
            </>
          )}

          {(formik.isValid || hasSubmitted) && (
            <LoaderButton
              name={polyglot.t('General.continue')}
              loading={saving}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
