import React from 'react';

import { Dialog, DialogActions, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AppliedDevicePoliciesZeltDtoWithConfigurableFeature } from '@v2/feature/device/device.dto';

interface DialogBoxProps {
  open: boolean;
  onClose: () => void;
  appliedPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}

// Helper function to format camelCase to readable labels
const formatKeyToLabel = (key: string): string => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
    .replace(/^configurable/, '') // Remove 'configurable' prefix
    .trim() // Trim spaces
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};

export const SimpleInHouseMdmDeviceDialog: React.FC<DialogBoxProps> = ({ open, onClose, appliedPolicies }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: '#FFFFFF',
          color: 'black',
          maxWidth: '600px',
          maxHeight: '80vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        },
      }}
    >
      <Typography
        variant="title3"
        sx={{ padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0', textAlign: 'center' }}
      >
        Company Policies
      </Typography>
      <DialogContent
        sx={{
          padding: '16px',
          width: '100%',
          maxHeight: 'calc(80vh - 150px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <List sx={{ width: '100%' }}>
          {appliedPolicies &&
            Object.keys(appliedPolicies)
              .filter((key) => key.startsWith('configurable'))
              .map((key) => (
                <ListItem key={key} sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                  <ListItemText
                    primary={formatKeyToLabel(key)}
                    secondary={
                      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: 0 }}>
                        {JSON.stringify(
                          appliedPolicies[key as keyof AppliedDevicePoliciesZeltDtoWithConfigurableFeature],
                          null,
                          2
                        )}
                      </pre>
                    }
                    primaryTypographyProps={{ sx: { wordWrap: 'break-word', fontWeight: 'bold' } }}
                    secondaryTypographyProps={{ sx: { wordWrap: 'break-word', paddingLeft: '16px' } }}
                  />
                </ListItem>
              ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ padding: '16px', justifyContent: 'center' }}>
        <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={onClose}>
          Close
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
