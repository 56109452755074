import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@v2/feature/device/device.interface';
import { spacing } from '@v2/styles/spacing.styles';

interface DeviceActivationStepActivationProps {
  readonly deviceOrder: DeviceOrderDto;
  readonly goToNextStep: () => void;
}

export const DeviceActivationStepActivation = ({ deviceOrder, goToNextStep }: DeviceActivationStepActivationProps) => {
  const isDeviceInShipping = deviceOrder.status === DeviceOrderStatus.Shipping;
  const isDeviceDelivered = deviceOrder.status === DeviceOrderStatus.Delivered;

  return isDeviceInShipping || isDeviceDelivered ? (
    <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
        <Typography variant="title2">Welcome to your new laptop!</Typography>
        <Typography variant="caption">
          Follow a few simple steps to activate your new device. Only start this flow if you have received the device.
        </Typography>
        <Box>
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={goToNextStep}>
            My device arrived
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
        <Typography variant="title2">Your device was not shipped yet!</Typography>
        <Typography variant="caption">We will let you know once we ship the device.</Typography>
      </Box>
    </Box>
  );
};
