import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  CustomBenefitAllowanceType,
  CustomBenefitType,
  UserCustomBenefitPaymentDto,
  UserCustomBenefitPaymentStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isAllowanceBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { LocalDate } from '@v2/util/local-date';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userCustomBenefitPayments: UserCustomBenefitPaymentDto[];
  readonly type: 'used' | 'requested' | 'repaid';
  readonly benefitType: CustomBenefitType | undefined;
  readonly allowanceType: CustomBenefitAllowanceType | null | undefined;
}

export const BenefitPaymentsDrawer = ({
  isOpen,
  setIsOpen,
  userCustomBenefitPayments,
  type,
  benefitType,
  allowanceType,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <BenefitPaymentsDrawerContent
        type={type}
        userCustomBenefitPayments={userCustomBenefitPayments}
        benefitType={benefitType}
        allowanceType={allowanceType}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly type: 'used' | 'requested' | 'repaid';
  readonly userCustomBenefitPayments: UserCustomBenefitPaymentDto[];
  readonly benefitType: CustomBenefitType | undefined;
  readonly allowanceType: CustomBenefitAllowanceType | null | undefined;
}

export const BenefitPaymentsDrawerContent = ({
  type,
  userCustomBenefitPayments,
  benefitType,
  allowanceType,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const isAllowance = useMemo(() => isAllowanceBenefit(benefitType), [benefitType]);

  const paymentsToShow = useMemo(
    () =>
      userCustomBenefitPayments
        .filter((payment) =>
          type === 'used' || type === 'repaid'
            ? payment.status === UserCustomBenefitPaymentStatus.Approved
            : payment.status === UserCustomBenefitPaymentStatus.Pending
        )
        .sort((p1, p2) => (p1.date >= p2.date ? -1 : 1)),
    [userCustomBenefitPayments, type]
  );

  const groupedPayments = useMemo(() => {
    if (isAllowance && allowanceType === CustomBenefitAllowanceType.Yearly) {
      return paymentsToShow.reduce((groups, p) => {
        const yearString = new LocalDate(p.date).getDate().getFullYear().toString();
        if (!groups[yearString]) groups[yearString] = [];
        groups[yearString].push(p);

        return groups;
      }, {} as { [label: string]: UserCustomBenefitPaymentDto[] });
    }

    if (isAllowance && allowanceType === CustomBenefitAllowanceType.Monthly) {
      return paymentsToShow.reduce((groups, p) => {
        const label = new LocalDate(p.date).toLocaleDateString(undefined, {
          month: 'short',
          year: 'numeric',
        });
        if (!groups[label]) groups[label] = [];
        groups[label].push(p);

        return groups;
      }, {} as { [label: string]: UserCustomBenefitPaymentDto[] });
    }

    return { all: paymentsToShow };
  }, [isAllowance, allowanceType, paymentsToShow]);

  return paymentsToShow.length > 0 ? (
    <Box>
      <Typography variant="title2">
        {type === 'used'
          ? polyglot.t('BenefitModule.used')
          : type === 'requested'
          ? polyglot.t('BenefitModule.requested')
          : polyglot.t('BenefitModule.repaid')}
      </Typography>

      <Box sx={{ mt: '20px' }}>
        {Object.keys(groupedPayments).map((label) => {
          const groupTotal = groupedPayments[label].reduce((sum, p) => sum + p.amount, 0);
          return (
            <Box sx={{ mt: '10px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {isAllowance &&
                allowanceType &&
                [CustomBenefitAllowanceType.Yearly, CustomBenefitAllowanceType.Monthly].includes(allowanceType) && (
                  <Typography variant="captionSmall" color="Grey">
                    {label}
                  </Typography>
                )}
              {groupedPayments[label].map((payment) => {
                return (
                  <StyledTooltip key={`${payment.id}-tooltip`} title={payment.notes} placement="top">
                    <Box
                      key={payment.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0px 5px',
                        borderRadius: '10px',
                        ':hover': {
                          bgcolor: themeColors.Background,
                          // cursor: 'pointer',
                        },
                      }}
                    >
                      <Typography variant="caption">
                        {new LocalDate(payment.date).toLocaleDateString(undefined, {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </Typography>
                      <Typography variant="caption">
                        {formatMoney({ amount: payment.amount, asDecimal: true })}
                      </Typography>
                    </Box>
                  </StyledTooltip>
                );
              })}

              <Divider />
              <Box
                key={`${label}-total`}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  padding: '0px 5px',
                }}
              >
                <Typography variant="title4">{formatMoney({ amount: groupTotal, asDecimal: true })}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="title2">
        {type === 'used'
          ? polyglot.t('BenefitModule.used')
          : type === 'requested'
          ? polyglot.t('BenefitModule.requested')
          : polyglot.t('BenefitModule.repaid')}
      </Typography>

      <Typography variant="caption" sx={{ mt: '30px' }}>
        {polyglot.t('BenefitModule.noPaymentsToShow')}
      </Typography>
    </Box>
  );
};
