import { useCallback, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { AddUserFamilyMemberDrawer } from '@v2/feature/user/features/user-profile/details/components/user-family/add-user-family-member-drawer.component';
import { UserAPI } from '@v2/feature/user/user.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { capitalize } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/components/ArrowDown.svg';
import { ReactComponent as Edit } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { translateUserFamilyMemberType } from '@/v2/infrastructure/i18n/translate.util';

interface UserFamilyMembersSectionProps {
  readonly userId: number;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly refreshMembers: () => Promise<void>;
}

export const UserFamilyMembersSection = ({
  userId,
  userFamilyMembers,
  refreshMembers,
}: UserFamilyMembersSectionProps) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [mode, setMode] = useState<'delete' | null>(null);
  const [newMemberType, setNewMemberType] = useState<UserFamilyMemberType | null>(null);
  const [selectedMember, setSelectedMember] = useState<UserFamilyMemberDto | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onDelete = useCallback(
    async (memberId: number) => {
      try {
        await UserAPI.deleteUserFamilyMemberById(userId, memberId);

        await refreshMembers();
      } catch (error) {
        showMessage(
          `${polyglot.t('AddUserFamilyMemberDrawer.errorMessages.badRequest')}. ${nestErrorMessage(error)}`,
          'error'
        );
      }
    },
    [showMessage, userId, refreshMembers, polyglot]
  );

  return (
    <Box sx={drawerContentSx}>
      {userFamilyMembers.map((member) => (
        <Box key={member.id} sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <Box sx={{ display: 'flex', gap: spacing.g5, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="title4">{member.type}</Typography>

            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={(e) => {
                  e.stopPropagation();
                  setNewMemberType(member.type);
                  setSelectedMember(member);
                  setIsDrawerOpen(true);
                }}
              >
                <Edit {...iconSize} />
              </IconButton>

              <IconButton
                sx={tableIconButtonSx}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setMode('delete');
                  e.stopPropagation();
                }}
              >
                <Trash {...iconSize} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography variant="captionSmall">
              {polyglot.t('AddUserFamilyMemberDrawer.fullName', {
                type: translateUserFamilyMemberType(member.type, polyglot),
              })}
            </Typography>
            <Typography variant="title4">{member.name}</Typography>
          </Box>
          {member.dob && (
            <Box>
              <Typography variant="captionSmall">
                {polyglot.t('AddUserFamilyMemberDrawer.dob', {
                  type: translateUserFamilyMemberType(member.type, polyglot),
                })}
              </Typography>
              <Typography variant="title4">{member.dob}</Typography>
            </Box>
          )}
          {member.gender && (
            <Box>
              <Typography variant="captionSmall">
                {polyglot.t('AddUserFamilyMemberDrawer.memberTypeGender', {
                  type: translateUserFamilyMemberType(member.type, polyglot),
                })}
              </Typography>
              <Typography variant="title4">{capitalize(member.gender)}</Typography>
            </Box>
          )}

          {mode && (
            <NotificationModal
              isOpen={Boolean(anchorEl)}
              onClose={() => {
                setTimeout(() => {
                  setAnchorEl(null);
                  setMode(null);
                }, 400);
              }}
              anchorEl={anchorEl}
              takeAction={async () => {
                if (mode === 'delete') await onDelete(member.id);
                setMode(null);
              }}
              message={polyglot.t('AddUserFamilyMemberDrawer.confirmDelete')}
              callToAction={polyglot.t('General.yes')}
            />
          )}
        </Box>
      ))}

      <StyledMenuComponent
        options={[
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Spouse);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t('UserFamilyMemberType.Spouse'),
          },
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Partner);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t('UserFamilyMemberType.Partner'),
          },
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Child);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t('UserFamilyMemberType.Child'),
          },
        ]}
        actionButtonDetails={{
          type: 'button',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: polyglot.t('AddUserFamilyMemberDrawer.add'),
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }}
      />

      {newMemberType && (
        <AddUserFamilyMemberDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          member={selectedMember}
          onClose={() => {
            setNewMemberType(null);
            setSelectedMember(null);
            setIsDrawerOpen(false);
          }}
          type={newMemberType}
          userId={userId}
          refresh={refreshMembers}
        />
      )}
    </Box>
  );
};
