import { DepUserDeviceDto } from '@v2/feature/device/device.dto';
import {
  AssignDeviceToABM,
  DepDeviceInfoStatusCommand,
  InHouseMdmSyncProfileDto,
} from '@v2/feature/device/device.interface';
import { DepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class InHouseMdmAPI {
  static async getEnrolmentProfile(deviceId: number): Promise<string> {
    return (await axios.get(`/apiv2/zelt-mdm/enrollment/ota/enroll/${deviceId}`)).data;
  }

  static async setDepDeviceAndUser(depUserDeviceDto: DepUserDeviceDto): Promise<string> {
    return (await axios.post(`/apiv2/zelt-mdm/dep/assign/device`, depUserDeviceDto)).data;
  }

  static async syncDevicePolicy(inHouseMdmSyncProfileDto: InHouseMdmSyncProfileDto): Promise<string> {
    return (await axios.post(`/apiv2/zelt-mdm/device/policy/sync`, inHouseMdmSyncProfileDto)).data;
  }

  static async syncDeviceAgainstDep(depDeviceInfoStatusCommand: DepDeviceInfoStatusCommand): Promise<string> {
    return (await axios.post(`/apiv2/zelt-mdm/device/dep/sync`, depDeviceInfoStatusCommand)).data;
  }

  static async syncEnrolmentStatus(deviceId: number): Promise<string> {
    return (await axios.get(`/apiv2/zelt-mdm/device/${deviceId}/sync/enrolment-status`)).data;
  }

  static async getDepPublicKey(mdmServerName: string): Promise<string> {
    return (await axios.get(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}`)).data;
  }

  static async getDepAccountDetailsAndSave(mdmServerName: string): Promise<DepAccountDetails> {
    return (await axios.post(`/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`)).data;
  }

  static async getDepAccountDetails(mdmServerName: string): Promise<DepAccountDetails> {
    return (await axios.get(`/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`)).data;
  }

  static async assignDeviceToABM(assignDeviceToABM: AssignDeviceToABM): Promise<void> {
    await axios.post(`/apiv2/zelt-mdm/abm/assign`, assignDeviceToABM);
  }

  static async deleteDepPublicKey(mdmServerName: string): Promise<string> {
    return (await axios.delete(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}`)).data;
  }

  static async syncDepDevices(mdmServerName: string): Promise<string> {
    return (await axios.get(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}/sync-devices`)).data;
  }

  static async setAutoSyncDepDevice(mdmServerName: string, isEnabled: boolean): Promise<string> {
    return (
      await axios.patch(
        `/apiv2/zelt-mdm/dep/token/public/${mdmServerName}/auto-sync`,
        {},
        {
          params: {
            isEnabled: isEnabled,
          },
        }
      )
    ).data;
  }
}

export class InHouseEndpoints {
  static getDepAccountDetails(mdmServerName: string): Endpoint<DepAccountDetails> {
    return {
      url: `/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`,
    };
  }
}

export enum EnrollmentStatus {
  ENROLMENT_FINISHED = 'ENROLMENT_FINISHED',
  ENROLMENT_FAILED_NO_DEVICE_ID_IN_PROFILE = 'ENROLMENT_FAILED_NO_DEVICE_ID_IN_PROFILE',
  ENROLMENT_FAILED_CANNOT_UPDATE_DEVICE_IN_DB = 'ENROLMENT_FAILED_CANNOT_UPDATE_DEVICE_IN_DB',
}
