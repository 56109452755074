import { useContext, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ExpenseModal } from '@v2/feature/payments/pages/components/expense-modal.component';
import { Expense } from '@v2/feature/payments/payments.interface';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { PublicImageViewer } from '@/v2/components/upload/public-image-viewer.component';

interface PaymentExpensePreviewProps {
  readonly expense: Expense;
  readonly titleSx?: SxProps<Theme>;
}

export const PaymentExpensePreview = ({ expense, titleSx }: PaymentExpensePreviewProps) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const currentUserIsAdmin = hasScopes(['invoices:all'], getScopesContext(user));
  const [openExpenseModal, setOpenExpenseModal] = useState<boolean>(false);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="title4" sx={titleSx}>
          Expense
        </Typography>
        <ButtonComponent
          colorVariant="textUnderline"
          sizeVariant="small"
          onClick={() => setOpenExpenseModal(true)}
          style={{ paddingRight: 0 }}
        >
          View expense
        </ButtonComponent>
      </Box>

      {expense.attachment && (
        <PublicImageViewer fileName={expense.attachment} uploadName="Uploaded receipt" hasView hasDownload />
      )}

      <ExpenseModal
        isOpen={openExpenseModal}
        setIsOpen={setOpenExpenseModal}
        selectedExpense={expense}
        onClose={() => setOpenExpenseModal(false)}
        onActionPerformed={async () => {}}
        currentUserIsAdmin={currentUserIsAdmin}
      />
    </Box>
  );
};
