import {
  ConfigurableDeviceStatus,
  DeliveryMethod,
  DeviceApplication,
  DeviceExternalMatching,
  DeviceLocation,
  DeviceOrderStatus,
  DeviceOwnership,
  DevicePolicy,
  DevicePolicyType,
  DevicePolicyZeltType,
  DevicePossessionType,
  DeviceProtectionRating,
  DeviceTransitStatus,
  DeviceType,
  EnrolmentType,
  OrderDeliveryCountry,
  PasswordPolicyLevel,
  SupportedDeviceType,
  UnmatchedExternalDevice,
} from '@v2/feature/device/device.interface';
import { SiteDto } from '@v2/feature/site/site.dto';

export interface DeviceDto {
  id: number;
  deviceName: string | null;
  serialNumber: string | null;
  type: DeviceType;
  os: string | null;
  manufacturer: string | null;
  screenSize: number | null;
  ram: number | null;
  cpu: number | null;
  gpu: number | null;
  storage: number | null;
  price: number;
  contractLength: number | null;
  contractStartDate: string | null;
  modelName: string;
  modelNumber: string | null;
  ownership: DeviceOwnership;
  enrolledDate: Date | null;
  enrollmentStatus: string | null;
  supervised: boolean | null;
  encryptionEnabled: boolean | null;
  passwordProtected: boolean | null;
  osVersion: string | null;
  lastCheckIn: Date | null;
  lastScanned: Date | null;
  applications: DeviceApplication[] | null;
  policies: DevicePolicy[] | null;
  deviceModelId: number | null;
  lastLocation: DeviceLocation | null;
  isDecommissioned: boolean;
  isDamaged: boolean;
  externallyInvoiced: string | null;
  createdAt: Date;
  updatedAt: Date;
  deviceModel?: DeviceModelDto;
  internalNotes: string | null;
  customerNotes: string | null;
  inHouseMdm: boolean;
  icloudRestrictionEnabled: boolean | null;
  firewallEnabled: boolean | null;
  wifiMac: string | null;
  isAppleSilicon: boolean;
  availableStorage: number | null;
  passwordPolicyLevel: PasswordPolicyLevel;
  mdmable: boolean;
  latestAvailableOsVersion: string | null;
  enrolmentType: EnrolmentType;
  filevaultKey: string | null;
  additionalPasswordEnabled: boolean | null;
  screenRestrictionEnabled: boolean | null;
  screenLoginSettingsEnabled: boolean | null;
  browsingEnabled: boolean | null;
  securityEnabled: boolean | null;
  wifiEnabled: boolean | null;
  loginItemsEnabled: boolean | null;
  activationLockBypassKey: string | null;
  isDep: boolean | null;
  depDeviceUsername: string | null;
}

export interface DeviceProtectionDto {
  enrolled: boolean;
  supervised: boolean;
  passwordPolicy: boolean;
  encrypted: boolean;
  latestOs: boolean;
  recentlyActive: boolean;
  recentlyScanned: boolean;

  [key: string]: boolean;
}

export interface DeviceProtectionDetailsDto {
  id: number;
  params: DeviceProtectionDto;
  categoryRating?: DeviceProtectionRating[];
}

export interface SuperAdminDevicesResponseDto {
  devicePossessions: {
    items: DevicePossessionDto[];
  };
  matchings: DeviceExternalMatching;
}

export interface DevicePossessionWithExternalIdDto {
  devicePossession: DevicePossessionDto;
  externalId?: number | null;
}

export interface DeviceUpdateSuperadminDto {
  type: DeviceType;
  serialNumber?: string | null;
  internalNotes?: string | null;
  customerNotes?: string | null;
  price?: number | null;
  contractLength?: number | null;
  contractStartDate?: string | null;
  externallyInvoiced?: string | null;
}

export interface DeviceUpdateInHouseMdmSuperadminDto {
  deviceModel?: string | null;
  serialNumber?: string | null;
  enrolmentType?: EnrolmentType | null;
  enrolmentStatus?: string | null;
}

export type DeviceTechSpecs = Pick<
  DeviceDto,
  | 'type'
  | 'modelName'
  | 'modelNumber'
  | 'manufacturer'
  | 'os'
  | 'ram'
  | 'storage'
  | 'screenSize'
  | 'cpu'
  | 'gpu'
  | 'serialNumber'
  | 'internalNotes'
  | 'customerNotes'
  | 'deviceName'
  | 'isDep'
  | 'depDeviceUsername'
>;

export interface DevicePolicyDto {
  id: number;
  type: DevicePolicyType | DevicePolicyZeltType;
  name: string;
  description?: string;
  configuration?: any; // eg: Applications Policy => { appIds: [123, 1234] }
  externalId?: number;
  isInhouseMdm: boolean;
  hide: boolean;
  supportedDeviceType: SupportedDeviceType;
  // companyId?: number; - only in backend and only when default != true
  // default: boolean; - if false - then companyId is required
}

export type AppliedDevicePoliciesDto = {
  [key in DevicePolicyType]: number[];
};

export type AppliedDevicePoliciesZeltDtoWithConfigurableFeature = {
  [key in DevicePolicyType]: number[];
} & {
  configurablePasscode?: PasswordOption;
  configurableICloudRestriction?: ICloudRestrictionOptions;
  configurableAdditionalPasswordPolicy?: RestrictionAdditionalPasswordOption;
  configurableEncryptionPolicy?: FileVaultOptions;
  configurableFirewallPolicy?: FirewallOptions;
  configurableScreenSettingPolicy?: ScreenSettingsCombine;
  configurableBrowsing?: ParentalControlsContentFilterOptions;
  configurableSecurity?: RestrictionSecurityOptions;
  configurableApps?: number[];
  configurableLoginItems?: LoginItemsOptions;
  configurableWifi?: WifiOptions;
  configurableAutomaticOsUpdate?: ConfigurableAutomaticOsUpdate;
  configurableOsUpdateRestrictions?: RestrictionOsUpdatesOptions;
  configurableActivationLock?: ActivationLock;
};

export interface UnmatchedExternalDevicesResponseDto {
  deviceMatchingSaved: boolean;
  devices: UnmatchedExternalDevice[];
}

export interface CreateExistingDeviceDto {
  possessionType: DevicePossessionType;
  type: DeviceType;
  possessionId: number;
  modelName: string;
  deviceName: string | null;
  serialNumber: string | null;
  os: string | null;
  mdmServerName?: string | null;
  depDeviceUsername?: string | null;
}

export interface OrderDeviceDto {
  deliveryMethod: DeliveryMethod;
  deviceModelId: number;
  possessionType: DevicePossessionType;
  possessionId: number | undefined;
  contractLength: number;
  phoneNumber: string | null;
  delivery: {
    address: string;
    date: string;
    country: OrderDeliveryCountry;
  };
  isPurchase: boolean;
}

export interface ExternalUserCredentialsDto {
  username: string;
  password: string;
}

export interface DeviceModelDto {
  id: number;
  type: DeviceType;
  modelName: string;
  modelNumber: string | null;
  modelVersion: string | null;
  screenSize: number | null;
  os: string | null;
  manufacturer: string | null;
  cpuCores: number | null;
  gpuCores: number | null;
  ram: number | null;
  storage: number | null;
  price24: number;
  price36: number;
  priceEu24: number | null;
  priceEu36: number | null;
  fullPrice: number | null;
  fullPriceEu: number | null;
  deliveryDays: number;
  deliveryDaysEu: number | null;
  isAvailable: boolean;
  count?: number;
}

export interface DeviceDeliveryDetails {
  date: string;
  address: string;
  country: OrderDeliveryCountry;
}

export interface DevicePossessionDto {
  id: number;
  deviceId: number;
  possessionType: DevicePossessionType;
  companyId: number | null;
  possessionId: number;
  deliveryAddress: string | null;
  startDate: string | null;
  endDate: string | null;
  device?: DeviceDto;
  createdAt: Date;
  updatedAt: Date;
}

export interface ConfigurableDeviceData {
  status: ConfigurableDeviceStatus;
  ownerName: string;
  enrolment: string;
  devicePossession: DevicePossessionDto;
  sites: SiteDto[];
  order: DeviceOrderDto | null;
}

export interface DeviceTransitDto {
  id: number;
  companyId: number;
  deviceId: number;
  senderId: number;
  receiverId: number;
  status: DeviceTransitStatus;
  notes: string | null;
  senderAddress: string | null;
  deliveryAddress: string | null;
  createdBy: number;
  sender?: DevicePossessionDto;
  receiver?: DevicePossessionDto;
  device?: DeviceDto;
  createdAt: Date;
  updatedAt: Date;
}

export interface DeviceOrderDto {
  sender: any;
  id: number;
  companyId: number;
  deviceModelId: number;
  deviceId: number | null;
  status: DeviceOrderStatus;
  price: number;
  possessionId: number;
  deliveryDate: string | null;
  deliveryAddress: string | null;
  deliveryCountry: string | null;
  contractLength: number | null;
  phoneNumber: string | null;
  trackingLink: string | null;
  isPurchase: boolean | null;
  externallyInvoiced: string | null;
  eligibleForRefinance: boolean;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  deviceModel?: DeviceModelDto;
  possession?: DevicePossessionDto;
  device?: DeviceDto;
}

export type DeviceOrderUpdateDto = Pick<
  DeviceOrderDto,
  'deviceModelId' | 'price' | 'deliveryDate' | 'deliveryAddress' | 'contractLength' | 'phoneNumber'
>;

export interface DevicesStatsDto {
  noOfActiveDevices: number;
  noOfInventoryDevices?: number;
  noOfActiveDeviceOrders: number;
  noOfDeviceTransits: number;
  noOfActiveAccessories: number;
  noOfInventoryAccessories?: number;
  noOfEnrolledDevices: number;
}

export interface ChangeOwnerDeviceTransitDataDto {
  deliveryMethod: DeliveryMethod;
  possessionId: number;
  senderAddress: string | null;
  deliveryAddress: string | null;
  accountName: string | null;
}

export interface ICloudRestrictionOptions extends ConfigurationPayloadOption {
  allowCloudBookmarks?: boolean;
  allowCloudMail?: boolean;
  allowCloudCalendar?: boolean;
  allowCloudReminders?: boolean;
  allowCloudAddressBook?: boolean;
  allowCloudNotes?: boolean;
  allowCloudDocumentSync?: boolean;
  allowCloudKeychainSync?: boolean;
  allowCloudPhotoLibrary?: boolean;
}

export interface PasswordOption extends ConfigurationPayloadOption {
  minLength?: number;
  maxInactivity?: number;
  minComplexChars?: number;
  maxPINAgeInDays?: number;
  pinHistory?: number;
  allowSimple?: boolean;
  requireAlphanumeric?: boolean;
}

export interface RestrictionAdditionalPasswordOption extends ConfigurationPayloadOption {
  allowFingerprintForUnlock?: boolean;
  allowAutoUnlock?: boolean;
  allowPasswordProximityRequests?: boolean;
  allowPasswordSharing?: boolean;
  allowPasswordAutoFill?: boolean;
}

export interface FileVaultOptions extends ConfigurationPayloadOption {
  Enable?: string;
  Defer?: boolean;
  ShowRecoveryKey?: boolean;
  DeferForceAtUserLoginMaxBypassAttempts?: number;
}

export interface RestrictionSecurityOptions extends ConfigurationPayloadOption {
  allowCamera?: boolean;
  allowSafari?: boolean;
  allowAirDrop?: boolean;
  allowDictation?: boolean;
  allowScreenShot?: boolean;
  allowAssistant?: boolean;
}

export type ScreenSettingsCombine = RestrictionScreenOptions & ScreenLoginSettingsOptions;

export interface RestrictionScreenOptions extends ConfigurationPayloadOption {
  allowWallpaperModification?: boolean;
}

export interface ParentalControlsContentFilterOptions extends ConfigurationPayloadOption {
  restrictWeb?: boolean;
  useContentFilter?: boolean;
  whitelistEnabled?: boolean;
  filterBlacklist?: string[];
  filterWhitelist?: string[];
}

export interface ScreenLoginSettingsOptions extends ConfigurationPayloadOption {
  SHOWFULLNAME?: boolean;
  LoginwindowText?: string;
  ShutDownDisabled?: boolean;
  SleepDisabled?: boolean;
  RestartDisabled?: boolean;
  DisableConsoleAccess?: boolean;
}

export interface FirewallOptions extends ConfigurationPayloadOption {
  EnableFirewall?: boolean;
  BlockAllIncoming?: boolean;
  EnableStealthMode?: boolean;
}

export interface ConfigurationPayloadOption {
  PayloadIdentifier?: string;
  PayloadType?: string;
  PayloadUUID?: string;
  PayloadVersion?: number;
  PayloadDisplayName?: string;
}

export interface ConfigurablePoliciesDto {
  configurablePasscode?: PasswordOption | undefined;
  configurableICloudRestriction?: ICloudRestrictionOptions | undefined;
  configurableAdditionalPasswordPolicy?: RestrictionAdditionalPasswordOption | undefined;
  configurableEncryptionPolicy?: FileVaultOptions | undefined;
  configurableFirewallPolicy?: FirewallOptions | undefined;
  configurableScreenSettingPolicy?: ScreenSettingsCombine | undefined;
  configurableBrowsing?: ParentalControlsContentFilterOptions | undefined;
  configurableSecurity?: RestrictionSecurityOptions | undefined;
  configurableApps?: number[] | undefined;
  configurableLoginItems?: LoginItemsOptions | undefined;
  configurableWifi?: WifiOptions | undefined;
  configurableAutomaticOsUpdate?: ConfigurableAutomaticOsUpdate | undefined;
  configurableOsUpdateRestrictions?: RestrictionOsUpdatesOptions | undefined;
  configurableActivationLock?: ActivationLock | undefined;
}

export interface ZeltManagedApp {
  id: number;
  name: string;
  selected: boolean;
  externalId: number | undefined;
}

export interface ZeltManagedApps {
  apps: ZeltManagedApp[];
}

export enum DeviceInstalledApplicationStatus {
  INSTALLED = 'INSTALLED',
  NOT_INSTALLED_DOWNLOAD_CANCELLED = 'NOT_INSTALLED_DOWNLOAD_CANCELLED',
  NOT_INSTALLED_DOWNLOAD_FAILED = 'NOT_INSTALLED_DOWNLOAD_FAILED',
  NOT_INSTALLED_DOWNLOAD_PAUSED = 'NOT_INSTALLED_DOWNLOAD_PAUSED',
  NOT_INSTALLED_DOWNLOAD_WAITING = 'NOT_INSTALLED_DOWNLOAD_WAITING',
  SENT_TO_DEVICE = 'SENT_TO_DEVICE',
  UNKNOWN = 'UNKNOWN',
}

export interface DeviceManagedApplicationsDto {
  id: number;
  deviceId: number;
  applicationId: number;
  applicationName: string;
  iconName: string;
  bundleId: string;
  installationStatus: DeviceInstalledApplicationStatus;
}

export interface UpdateEnrolledDeviceDetailsDto {
  deviceName: string | null;
  customerNotes: string | null;
}

export interface UpdateDeviceNotesDto {
  customerNotes: string | null;
}

export interface AutoLaunchedApplicationDictionaryMangedItem {
  Path: string;
  Hide: boolean;
}

export interface LoginItemsOptions extends ConfigurationPayloadOption {
  autoLaunchedApplicationDictionaryManaged?: AutoLaunchedApplicationDictionaryMangedItem[];
}

export interface WifiOptions extends ConfigurationPayloadOption {
  AutoJoin?: boolean;
  EncryptionType?: string;
  HIDDEN_NETWORK?: boolean;
  SSID_STR?: string;
  Password?: string;
}

export type CheckInTime = {
  updatedAt: Date;
};

export type ConfigurableAutomaticOsUpdate = {
  automaticOsUpdate: boolean;
  installAction?: string;
};

export interface InHouseMdmOsUpdateDevice {
  zeltDeviceId: number;
  updates: ScheduleOSUpdateItem[];
}

export interface ScheduleOSUpdateItem {
  InstallAction: string;
  ProductVersion: string;
  ProductKey?: string;
  MaxUserDeferrals?: number;
}

export interface RestrictionOsUpdatesOptions extends ConfigurationPayloadOption {
  enforcedSoftwareUpdateDelay?: number;
  enforcedSoftwareUpdateNonOSDeferredInstallDelay?: number;
  forceDelayedSoftwareUpdates?: boolean;
  forceDelayedAppSoftwareUpdates?: boolean;
}

export type ActivationLock = {
  ActivationLockAllowedWhileSupervised?: boolean;
};

export function mergeDevicePolicies(
  obj1: AppliedDevicePoliciesDto,
  obj2: AppliedDevicePoliciesDto
): AppliedDevicePoliciesDto {
  const mergedPolicies: AppliedDevicePoliciesDto = {} as AppliedDevicePoliciesDto;
  const allKeys = new Set([...(Object.keys(obj1) as DevicePolicyType[]), ...(Object.keys(obj2) as DevicePolicyType[])]);

  allKeys.forEach((key) => {
    const mergedArray = [...(obj1[key] || []), ...(obj2[key] || [])];
    const uniqueValues = Array.from(new Set(mergedArray));

    if (uniqueValues.some((value) => value !== 0)) {
      mergedPolicies[key] = uniqueValues.filter((value) => value !== 0);
    } else {
      mergedPolicies[key] = uniqueValues;
    }
  });

  return mergedPolicies;
}

export interface DepUserDeviceDto {
  zeltDeviceId: number;
  serialNumber: string;
  userFullName: string;
  userShortName: string;
  companyId: number;
  depTokenName?: string;
}
