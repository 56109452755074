import { Pagination } from '@/models/company.model';

export type SubscriptionBill = {
  noOfActiveDays: number;
  isExpired?: boolean;
  noOfActiveUsers: number;
  isCancelled?: boolean;
  subscriptionId: number;
  productPlanId: number;
  subscriptionCost: number;
  totalDiscount: number;
  period: { startDate: Date; endDate: Date };
  product: string;
  planPrice: number;
};

export type Invoice = {
  id: string;
  status: InvoiceStatus;
  companyId: number;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  item: string;
  amountDuePreTax: number;
  totalAmountDuePreTax: number;
  amountDuePostTax: number;
  totalDiscount: number;
  breakdown: { breakdown: SubscriptionBill[] };
  company: {
    name: string;
  };
  externalId: string | null;
  createdAt: Date;
  billingFailedPayment?: { id: number };
};

export type DownloadInvoice = {
  id: string;
  companyId: number;
};

export type InvoicePageResult = {
  invoices: Invoice[];
  pagination: Pagination;
};

export enum InvoiceStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export interface BillingCharge {
  readonly id: number;
  readonly companyId: number;
  readonly userId: number;
  readonly amount: number;
  readonly type: string;
  readonly memo: string | null;
  readonly status: string;
  readonly paidAt: Date;
  readonly createdAt: Date;
  readonly invoiceId: string;
  readonly updatedAt: Date;
  readonly company: { id: number; name: string };
}

export type SuperAdminChargePageResult = {
  charges: BillingCharge[];
  pagination: Pagination;
};
