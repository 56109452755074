import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { MultipleSelectCheckbox } from '@v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import {
  EditableUserCustomBenefitDto,
  UserCustomBenefitDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getAdditionalMembersOptions } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface UserCustomBenefitWithIncludedProp extends Partial<UserCustomBenefitDto> {
  userId: number;
  included: boolean;
  customBenefitId: number;
}

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitWithIncludedProp;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const UpdateEffectiveUserCustomBenefitDrawer = ({
  isOpen,
  setIsOpen,
  userBenefit,
  refresh,
  onClose,
}: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(
    userBenefit.customBenefit && isRecurringBenefit(userBenefit.customBenefit.type)
      ? UserEndpoints.getUserFamilyMembers(userBenefit.userId)
      : null,
    { suspense: false }
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UpdateEffectiveUserCustomBenefitDrawerContent
        userBenefit={userBenefit}
        refresh={refresh}
        userFamilyMembers={userFamilyMembers ?? []}
        onClose={onClose}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitWithIncludedProp;
  readonly refresh: () => Promise<void>;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly onClose: () => void;
}

export const UpdateEffectiveUserCustomBenefitDrawerContent = ({
  userBenefit,
  refresh,
  userFamilyMembers,
  onClose,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const hasOpeningBalance = useMemo(() => isUsingOpeningBalance(userBenefit.customBenefit?.type), [userBenefit]);
  const isLoan = useMemo(() => isLoanBenefit(userBenefit.customBenefit?.type), [userBenefit]);

  const onSubmit = useCallback(
    async (values: EditableUserCustomBenefitDto) => {
      if (!userBenefit.id) return;

      const data: EditableUserCustomBenefitDto = {
        employerContribution: values.employerContribution ? Number(values.employerContribution) : null,
        employeeContribution: values.employeeContribution ? Number(values.employeeContribution) : null,
        dependants: values.dependants ? values.dependants : null,
        dependantsList: values.dependants ? values.dependantsList ?? null : null,
        openingBalance: hasOpeningBalance ? Number(values.openingBalance) : null,
        numberOfInstallments: isLoan ? Number(values.numberOfInstallments) : null,
      };
      try {
        setLoading(true);
        await CustomBenefitAPI.updateUserCustomBenefitById(
          userBenefit.userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          data
        );

        await refresh();
        onClose();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [hasOpeningBalance, isLoan, userBenefit, refresh, onClose, showMessage, polyglot]
  );

  const formik = useFormik<EditableUserCustomBenefitDto>({
    initialValues: {
      employerContribution: userBenefit?.employerContribution ?? null,
      employeeContribution: userBenefit?.employeeContribution ?? null,
      dependants: userBenefit?.dependants ?? null,
      dependantsList: userBenefit?.dependantsList ?? null,
      openingBalance: userBenefit?.openingBalance ?? null,
      numberOfInstallments: userBenefit?.numberOfInstallments ?? null,
    },
    validationSchema: yup.object({
      memberPremium: yup.number().typeError(polyglot.t('ValidationMessages.validValue')).nullable().notRequired(),
      memberContribution: yup.number().typeError(polyglot.t('ValidationMessages.validValue')).nullable().notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup
        .array()
        .of(yup.number().integer().typeError(polyglot.t('ValidationMessages.validValue')))
        .nullable()
        .notRequired(),
      openingBalance: hasOpeningBalance
        ? yup
            .number()
            .typeError(polyglot.t('ValidationMessages.validValue'))
            .required(polyglot.t('ValidationMessages.requiredField'))
        : yup.number().nullable().notRequired(),
      numberOfInstallments: isLoan
        ? yup
            .number()
            .typeError(polyglot.t('ValidationMessages.validValue'))
            .integer(polyglot.t('ValidationMessages.validValue'))
            .required(polyglot.t('ValidationMessages.requiredField'))
        : yup.number().nullable().notRequired(),
    }),
    onSubmit,
  });

  const dependantsListOptions = useMemo(() => {
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (
        userFamilyMembers
          ?.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type))
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (
        userFamilyMembers
          ?.filter((m) => m.type === UserFamilyMemberType.Child)
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    return userFamilyMembers?.map((m) => ({ value: m.id, label: m.name })) ?? [];
  }, [userFamilyMembers, formik.values.dependants]);

  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];

    return formik.values.dependantsList
      .map((id) => {
        const familyMember = userFamilyMembers.find((m) => m.id === id);

        return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
      })
      .filter(Boolean) as { label: string; value: number }[];
  }, [formik.values.dependantsList, userFamilyMembers]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {userBenefit.customBenefit?.name ?? polyglot.t('BenefitModule.userBenefit')}
        </Typography>

        <Box>
          <Typography variant="captionSmall">{polyglot.t('General.employee')}</Typography>
          <UserCell userId={userBenefit.userId} sx={{ mt: spacing.m5 }} nameVariant="title4" />
        </Box>

        <Box>
          <Typography variant="captionSmall">{polyglot.t('General.effectiveDate')}</Typography>
          <Typography variant="caption" sx={{ fontWeight: '500' }}>
            {userBenefit.effectiveDate}
          </Typography>
        </Box>

        {hasOpeningBalance && (
          <TextfieldComponent
            name="openingBalance"
            label={isLoan ? polyglot.t('BenefitModule.borrowed') : polyglot.t('BenefitModule.allowance')}
            value={formik.values.openingBalance}
            onChange={formik.handleChange}
            error={formik.touched.openingBalance && !!formik.errors.openingBalance}
            helperText={(formik.touched.openingBalance && formik.errors.openingBalance) ?? ' '}
            endAdornment="none"
          />
        )}

        {isLoan && (
          <TextfieldComponent
            name="numberOfInstallments"
            label={polyglot.t('BenefitModule.numberOfInstallments')}
            value={formik.values.numberOfInstallments}
            onChange={formik.handleChange}
            error={formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments}
            helperText={(formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) ?? ' '}
            endAdornment="none"
          />
        )}

        {!hasOpeningBalance && (
          <TextfieldComponent
            name="employerContribution"
            label={polyglot.t('BenefitModule.employerContribution')}
            value={formik.values.employerContribution}
            onChange={formik.handleChange}
            error={formik.touched.employerContribution && !!formik.errors.employerContribution}
            helperText={(formik.touched.employerContribution && formik.errors.employerContribution) ?? ' '}
            endAdornment="none"
          />
        )}

        {!hasOpeningBalance && (
          <TextfieldComponent
            name="employeeContribution"
            label={polyglot.t('BenefitModule.employeeContribution')}
            value={formik.values.employeeContribution}
            onChange={formik.handleChange}
            error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
            helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) ?? ' '}
            endAdornment="none"
          />
        )}

        {hasOpeningBalance ? null : userFamilyMembers.length > 0 ? (
          <SelectComponent
            name="dependants"
            label={polyglot.t('BenefitModule.additionalMembers')}
            options={[{ label: polyglot.t('General.none'), value: '' }, ...getAdditionalMembersOptions(polyglot)]}
            value={formik.values.dependants ?? ''}
            onChange={formik.handleChange}
            compareValue={formik.values.dependants}
            error={!!formik.errors.dependants && formik.touched.dependants}
            helperText={formik.touched.dependants && (formik.errors.dependants as string)}
          />
        ) : (
          <Box>
            <Typography variant="captionSmall">{polyglot.t('BenefitModule.additionalMembers')}</Typography>
            <Typography variant="caption">{polyglot.t('BenefitModule.includeAdditionalBeneficiaries')}</Typography>
          </Box>
        )}

        {userFamilyMembers.length > 0 && formik.values.dependants && (
          <MultipleSelectCheckbox<number>
            label={polyglot.t('BenefitModule.additionalMembersList')}
            id="dependantsList"
            limitTags={-1}
            options={dependantsListOptions}
            value={selectedDependantsList}
            onChange={(_, values) => {
              const updatedOptionList = values.map(({ value }) => value);
              formik.setFieldValue('dependantsList', updatedOptionList);
            }}
            isOptionEqualToValue={(x, y) => x.value === y.value}
            getOptionLabel={({ label }: { label: string }): string => label}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('BenefitModule.updateExisting')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
