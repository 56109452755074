import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Badge, Box, Fade } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import type { SxProps, Theme } from '@mui/material';

import { CompanyAPI } from '@/api-client/company.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { neutralColors, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type UserAvatarSize =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'small28'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'x100'
  | 'xxlarge'
  | 'xxxsmall';

export interface UserAvatarProps {
  readonly userId: number;
  /** @todo no point in having both tooltip and tooltipText */
  readonly tooltip?: boolean;
  readonly size?: UserAvatarSize;
  readonly sx?: SxProps<Theme>;
  /** @todo no point in having both tooltip and tooltipText */
  readonly tooltipText?: string | undefined;
  readonly onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly userInfo?: {
    userId: number;
    firstName: string;
    lastName: string;
    avatarHash: string;
    displayName: string;
  };
  readonly showBadge?: boolean;
  readonly badge?: React.JSX.Element;
  readonly name?: { firstName: string; lastName: string };
  readonly showName?: boolean;
  readonly nameVariant?: TypographyVariant;
  readonly nameColor?: keyof typeof themeColors;
  readonly nameSx?: SxProps<Theme>;
  readonly publicURL?: string | null;
}

export const sizes: Readonly<Record<UserAvatarSize, { readonly width: number; readonly height: number }>> = {
  xxlarge: { width: 135, height: 135 },
  x100: { width: 100, height: 100 },
  xlarge: { width: 80, height: 80 },
  large: { width: 66, height: 66 },
  medium: { width: 40, height: 40 },
  small: { width: 30, height: 30 },
  small28: { width: 28, height: 28 },
  xsmall: { width: 24, height: 24 },
  xxsmall: { width: 20, height: 20 },
  xxxsmall: { width: 14, height: 14 },
};

const fontSizeByAvatarSize = {
  xxlarge: themeFonts.title2,
  x100: themeFonts.title2,
  xlarge: themeFonts.title2,
  large: themeFonts.title2,
  medium: themeFonts.captionSmall,
  small: themeFonts.captionSmall,
  small28: themeFonts.captionSmall,
  xsmall: themeFonts.bodyTiny,
  xxsmall: themeFonts.bodyTiny,
  xxxsmall: themeFonts.bodyTiny,
};

function stringToColor(avatarName?: string): string {
  if (!avatarName) return 'white';
  let hash = 0;
  let i;
  for (i = 0; i < avatarName.length; i += 1) {
    hash = avatarName.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return `${color}5c`;
}

const getInitials = (name?: string) => {
  if (!name) return '';

  const nameParts = name.split(' ');
  if (nameParts.length === 1) {
    return (name.length >= 2 ? name.substring(0, 2) : name[0]).toUpperCase();
  }

  return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
};

interface AvatarWithInitialsProps extends Pick<UserAvatarProps, 'sx' | 'size' | 'onClick' | 'showBadge' | 'badge'> {
  readonly user?: UserSummaryDto;
}

function AvatarWithInitials({
  user,
  sx = {},
  onClick,
  size = 'small',
  showBadge,
  badge,
}: AvatarWithInitialsProps): JSX.Element {
  const initials = getInitials(user?.displayName);
  const styles = {
    '&:hover': onClick && {
      cursor: 'pointer',
      filter: 'brightness(70%)',
    },
    ...sx,
    ...sizes[size],
    bgcolor: stringToColor(user?.displayName),
    fontSize: fontSizeByAvatarSize[size],
    color: 'white',
    fontWeight: 'normal',
    textDecoration: 'none',
  };
  return (
    <Badge
      invisible={!showBadge}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badge}
    >
      <Avatar sx={styles} alt={user?.displayName} onClick={onClick}>
        {initials}
      </Avatar>
    </Badge>
  );
}

interface AvatarWithImageProps extends Pick<UserAvatarProps, 'sx' | 'size' | 'onClick' | 'showBadge' | 'badge'> {
  readonly src?: string;
  readonly user?: UserSummaryDto;
}

function AvatarWithImage({
  src,
  user,
  sx = {},
  onClick,
  size = 'small',
  showBadge,
  badge,
}: AvatarWithImageProps): React.JSX.Element {
  const styles = {
    '&:hover': onClick && {
      cursor: 'pointer',
      filter: 'brightness(70%)',
    },
    ...sx,
    ...sizes[size],
  };
  return (
    <Badge
      invisible={!showBadge}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badge}
    >
      <Avatar src={src} sx={styles} alt={user?.displayName} onClick={onClick} />
    </Badge>
  );
}

interface UserAvatarComponentProps extends UserAvatarProps {
  readonly user?: UserSummaryDto;
}

function UserAvatarComponent({
  user,
  tooltip = false,
  sx,
  size,
  onClick,
  tooltipText = '',
  showBadge = false,
  badge,
  publicURL,
}: UserAvatarComponentProps): React.JSX.Element {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const [publicURLString, setPublicURLString] = useState<string | undefined | null>(undefined);
  const title = tooltipText || user?.displayName ? polyglot.t(user?.displayName as string) : '' || '';

  const fetchPublicUrl = useCallback(async () => {
    try {
      const publicURL = await CompanyAPI.getPublicURL();
      setPublicURLString(publicURL);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, showMessage]);

  useEffect(() => {
    const urlToSet = state.publicURL || publicURL;
    if (urlToSet) {
      setPublicURLString(urlToSet);
    } else {
      fetchPublicUrl();
    }
  }, [state.publicURL, publicURL, fetchPublicUrl]);

  const avatarEl = useMemo(() => {
    const src = `${publicURLString}/${user?.avatarFileName}`;
    return !user?.avatarFileName && publicURLString ? (
      <AvatarWithInitials user={user} onClick={onClick} size={size} sx={sx} showBadge={showBadge} badge={badge} />
    ) : (
      <AvatarWithImage
        user={user}
        src={src!}
        onClick={onClick}
        size={size}
        sx={sx}
        showBadge={showBadge}
        badge={badge}
      />
    );
  }, [user, onClick, size, sx, showBadge, badge, publicURLString]);

  return tooltip && title ? <StyledTooltip title={title}>{avatarEl}</StyledTooltip> : avatarEl;
}

interface UserAvatarLoaderProps extends UserAvatarProps {
  readonly user?: UserSummaryDto;
}

const UserAvatarLoader = ({
  user,
  sx = {},
  onClick,
  size = 'small',
  showBadge = false,
  badge,
}: UserAvatarLoaderProps): JSX.Element => {
  return <AvatarWithInitials user={user} sx={sx} onClick={onClick} size={size} showBadge={showBadge} badge={badge} />;
};

export interface PlusAvatarProps {
  readonly count: number;
  readonly sx?: SxProps<Theme>;
  readonly size?: UserAvatarSize;
}

export function PlusAvatar({ count, size = 'small', sx }: PlusAvatarProps): JSX.Element {
  return (
    <Avatar
      sx={{
        bgcolor: neutralColors.n0,
        border: `2px solid ${neutralColors.n5}`,
        color: neutralColors.n5,
        fontSize: fontSizeByAvatarSize[size],
        ...sizes[size],
        ...sx,
      }}
    >
      +{count}
    </Avatar>
  );
}

export const UserAvatar = (props: UserAvatarProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { userId, userInfo, showName = false, nameVariant = 'caption', nameColor = 'DarkGrey', name, nameSx } = props;

  const user = (userInfo ?? getCachedUserById(userId)) as UserSummaryDto;

  const displayName = useMemo(
    () => (name ? polyglot.t(`${name.firstName} ${name.lastName}`) : user ? polyglot.t(user.displayName) : ''),
    [polyglot, user, name]
  );

  const avatarComponent = useMemo(
    () => (
      <Box sx={{ display: 'flex', gap: spacing.s1, alignItems: 'center' }}>
        <UserAvatarComponent {...props} user={user} tooltip={!showName} />
        {showName && user && (
          <Typography
            variant={nameVariant}
            color={nameColor}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...nameSx,
            }}
          >
            {displayName}
          </Typography>
        )}
      </Box>
    ),
    [props, user, showName, nameVariant, nameColor, nameSx, displayName]
  );

  const showTooltip = !showName && displayName;
  return user ? (
    <Suspense fallback={<UserAvatarLoader {...props} user={user} />}>
      <Fade timeout={2000}>
        {/* Fade requires a ref, so use React.Fragment to wrap UserAvatarComponent */}
        <>
          {showTooltip ? (
            <StyledTooltip title={displayName} placement="top">
              {avatarComponent}
            </StyledTooltip>
          ) : (
            avatarComponent
          )}
        </>
      </Fade>
    </Suspense>
  ) : (
    <></>
  );
};
